import { useEffect, useState } from "react";
import { fileTimeStamp } from "../../../../lib/fileTimeStamp";
import { JSONToCSVConvertor } from "../../../../lib/jsonToCSVConvertor";
import { sortJson } from "../../../../lib/sortJson";
import { ErrorMessage } from "../../../../Shared/errorMessage/errorMessage";
import { ModalDeleteConfirmation } from "../../../../Shared/modalDeleteConfirmation/modalDeleteConfirmation";
import { Loading } from "../../../../Shared/loading/loading";
import { createClientMedication, deleteClientMedication, getClientMedicationById, getClientMedicationsByClientId, updateClientMedication } from "./clientMedicationsListRest";
import { ClientMedicationsEditDialog } from "./components/clientMedicationsEditDialog";
import { ClientMedicationsListTableBody } from "./components/clientMedicationsListTableBody";
import { ClientMedicationsListTableFooter } from "./components/clientMedicationsListTableFooter";
import "./clientMedications.css";

export const ClientMedicationsList = ({ clientId }) => {
    //=== Vars =============================================================

    const medicationInitialValue = {
        id: null,
        startDate: null,
        medication: null,
        controlled: false,
        dose: null,
        frequency: null,
        route: null,
        discontinuedDate: null,
        class: null,
        archived: null,
    };

    //=== States =============================================================

    //Medications state
    const [medication, setMedication] = useState(medicationInitialValue);

    //Modal display state
    const [show, setShow] = useState(false);

    //Modal Delete Confirmation state
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);

    //Medications data state
    const [data, setData] = useState([]);

    //Medications filtered data state
    const [displayData, setDisplayData] = useState([]);

    //Selected id state
    const [id, setId] = useState(null);

    //Grid records per page state
    const [pageSize, setPageSize] = useState(5);

    //Grid page # state
    const [pageNumber, setPageNumber] = useState(1);

    //Grid number of pages state
    const [numPages, setNumPages] = useState(null);

    //filter value state
    const [filter, setFilter] = useState(null);

    //order by direction state
    const [sortAsc, setSortAsc] = useState(true);

    //Use Effects ===========================================================

    //Loading client Medications...
    useEffect(() => {
        loadMedications(clientId);
    }, [clientId]); // eslint-disable-line react-hooks/exhaustive-deps

    //update # pages when displayData || pageSize changes
    useEffect(() => {
        pageSize === "All" ? setNumPages(1) : setNumPages(Math.ceil(displayData.length / pageSize));
    }, [displayData, pageSize]);

    //update display data when data || filter changes
    useEffect(() => {
        setDisplayData(
            !filter
                ? data
                : data.filter((medication) => {
                      return medication.medication?.toLowerCase().includes(filter) || medication.class?.toLowerCase().includes(filter) || medication.dose.toLowerCase().includes(filter) || medication.frequency.toLowerCase().includes(filter) || medication.route.toLowerCase().includes(filter);
                  })
        );

        //when filter, reset to page 1
        setPageNumber(1);
    }, [data, filter]);

    //=== Methods ============================================================

    const isError = (obj) => Object.prototype.toString.call(obj) === "[object Error]";

    const loadMedication = (id) => {
        getClientMedicationById(id)
            .then((data) => {
                setMedication(data);
                setShow(true);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading Lace Tool Length of Stay list");
                }
                setLoading(false);
            });
    };

    const loadMedications = (clientId) => {
        setLoading(true);
        getClientMedicationsByClientId(clientId)
            .then((data) => {
                setData(data);
                setDisplayData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading health assessments");
                }
                setLoading(false);
            });
    };

    //Event handlers =====================================================

    //show add new Medication modal
    const handleAddNewClick = () => {
        const emptyMedication = medicationInitialValue;
        setId(null);
        setMedication(emptyMedication);
        setShow(true);
    };

    //download csv
    const handleDownloadClick = () => {
        JSONToCSVConvertor(displayData, `Infinity_ClientMedicationsList_${fileTimeStamp()}`, true);
    };

    //show remove confirmation dialog
    const handleRemoveClick = (id) => {
        setId(id);
        setShowConfirmDelete(true);
    };

    //handle confirm remove Branch button click
    const handleConfirmRemoveClick = (id) => {
        deleteClientMedication(id)
            .then((ret) => {
                setData(data.filter((med) => med.id !== id));
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error deleting data (fetch)");
                }
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });
    };

    //show edit medication modal
    const handleEditClick = (id) => {
        setId(id);
        if (id) {
            loadMedication(id);
        }
    };

    //sort columns
    const handleSortClick = (prop, propType) => {
        setSortAsc((sortAsc) => !sortAsc);
        setDisplayData(sortJson(displayData, prop, propType, sortAsc));
    };

    //handle page size changes
    const handlePageSizeChange = (e) => {
        e.target.value === "All" ? setPageSize(data.length) : setPageSize(e.target.value);
        //when pagesize changes, reset to page 1
        setPageNumber(1);
    };

    //handle page number changes
    const handlePageNumberChange = (e) => {
        setPageNumber(e.target.value);
    };

    //handle reload button click
    const handleReloadClick = () => {
        loadMedications(clientId);
    };

    //handle save Medications click
    const handleSaveClick = (newData) => {
        newData = {
            ...newData,
            clientId: clientId,
        };

        if (newData.id) {
            updateClientMedication(newData)
                .then((ret) => {
                    const newArrayData = [...data];
                    const index = newArrayData.findIndex((item) => item.id === newData.id);
                    newArrayData[index] = ret;
                    setData(newArrayData);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        } else {
            createClientMedication(newData)
                .then((ret) => {
                    const newArrayData = [...data];
                    newArrayData.push(ret);
                    setData(newArrayData);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        }
    };

    //handle close create dialog
    const handleCloseClick = () => setShow(false);

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //Render =================================================================

    return (
        <section id="MedicationsSection">
            <h5 className="bold-style" style={{ fontSize: "14px" }}>
                Medications
            </h5>
            <hr />
            <div className="row pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">{operationError && <ErrorMessage msg={operationError} />}</div>
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <button type="button" className="btn btn-primary btn-sm" onClick={handleAddNewClick}>
                                Add New Medication
                            </button>
                            {loading && <Loading />}
                        </div>
                        <div className="col-md-4 align-end">
                            <div className="input-group">
                                <input type="text" className="form-control form-control-sm" placeholder="Search...." onChange={handleSearchInput} />
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Reload" onClick={handleReloadClick}>
                                    <i className="fa fa-refresh"></i>
                                </button>
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Download" onClick={handleDownloadClick}>
                                    <i className="fa fa-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped table-hover table-sm clientMedicationsList mt-1">
                        <thead>
                            <tr>
                                <th className="sort small" onClick={() => handleSortClick("startDate", "string")}>
                                    Start Date
                                </th>
                                <th className="sort small" onClick={() => handleSortClick("discontinuedDate", "string")}>
                                    Discontinued
                                </th>
                                <th className="sort small" onClick={() => handleSortClick("medication", "string")}>
                                    Medication
                                </th>
                                <th className="sort small" onClick={() => handleSortClick("class", "string")}>
                                    Class
                                </th>
                                <th className="small">Controlled</th>
                                <th className="sort small" onClick={() => handleSortClick("dose", "string")}>
                                    Dose
                                </th>
                                <th className="sort small" onClick={() => handleSortClick("frequency", "string")}>
                                    Frequency
                                </th>
                                <th className="sort small" onClick={() => handleSortClick("route", "string")}>
                                    Route
                                </th>
                                <th className="small">Archived</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ClientMedicationsListTableBody currentPage={Number(pageNumber)} numberPerPage={Number(pageSize)} items={displayData} filter={filter} loading={loading} loadingError={loadingError} handleEditClick={handleEditClick} handleRemoveClick={handleRemoveClick} />
                        </tbody>
                        <tfoot>
                            <ClientMedicationsListTableFooter handlePageNumberChange={handlePageNumberChange} handlePageSizeChange={handlePageSizeChange} numPages={numPages} numRecords={displayData.length} />
                        </tfoot>
                    </table>
                </div>
            </div>

            {/* Modal components */}
            <ClientMedicationsEditDialog id={id} data={medication} setData={setMedication} show={show} setShow={setShow} handleClose={handleCloseClick} handleSaveClick={handleSaveClick} />
            <ModalDeleteConfirmation show={showConfirmDelete} setShow={setShowConfirmDelete} handleConfirmRemoveClick={handleConfirmRemoveClick} id={id} />
        </section>
    );
};

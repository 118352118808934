import React from "react";
import { callAPI } from "../../../lib/Global.js";
import { FormBuilder, FieldGroup, FieldControl, Validators } from "react-reactive-form";
import TextInput from "../../../Controls/TextInput.js";
import CheckBox from "../../../Controls/CheckBox.js";
import SelectInput from "../../../Controls/SelectInput.js";
import { swalAlert, swalToast, swalToast2 } from "../../../lib/Swal.js";

class FamilyMembersModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, clientFamilyMemberDetails: {}, isDataUpdated: false };
    }

    componentDidMount() {
        // this.getClientFamilyMembersDetails(this.props.clientId);
        this.populateFamilyMembersForm(this.props.familyMemberData);
    }

    getClientFamilyMembersDetails = async (clientId) => {
        const clientFamilyMemberDetailsList = await callAPI("GetClientFamilyMembersByClientId", { id: clientId });
        this.populateFamilyMembersForm(clientFamilyMemberDetailsList);
    };

    populateFamilyMembersForm = (clientFamilyMemberDetailsList) => {
        if (Array.isArray(clientFamilyMemberDetailsList) && clientFamilyMemberDetailsList.length > 0) {
            // let clientFamilyMemberDetails = clientFamilyMemberDetailsList[0];
            let clientFamilyMemberDetails = this.props.familyMemberData[0];
            this.familyMembersForm.patchValue({
                id: clientFamilyMemberDetails.id,
                clientId: clientFamilyMemberDetails.clientId,
                firstName: clientFamilyMemberDetails.firstName,
                lastName: clientFamilyMemberDetails.lastName,
                phoneNumber: clientFamilyMemberDetails.phoneNumber,
                phoneNumberType: clientFamilyMemberDetails.phoneNumberType,
                email: clientFamilyMemberDetails.email,
                relationshipToTheRecipientOfCare: clientFamilyMemberDetails.relationshipToTheRecipientOfCare,
                makePrimaryContact: clientFamilyMemberDetails.makePrimaryContact,
                makeEmergencyContact: clientFamilyMemberDetails.makeEmergencyContact,
                receiveNotificationsAndStatusUpdatesViaEmail: clientFamilyMemberDetails.receiveNotificationsAndStatusUpdatesViaEmail,
            });
        } else {
            this.familyMembersForm.patchValue({ clientId: this.props.clientId });
        }
    };

    familyMembersForm = FormBuilder.group({
        id: null,
        clientId: [null, Validators.required],
        firstName: [null, [Validators.required, Validators.maxLength(50)]],
        lastName: [null, [Validators.required, Validators.maxLength(50)]],
        phoneNumber: [null, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+0-9]*$")]],
        phoneNumberType: [null, Validators.required],
        email: [null, [Validators.email, Validators.maxLength(150)]],
        relationshipToTheRecipientOfCare: [null, [Validators.required, Validators.maxLength(100)]],
        makePrimaryContact: false,
        makeEmergencyContact: false,
        receiveNotificationsAndStatusUpdatesViaEmail: false,
    });

    handleSave = async (e) => {
        this.setState({ isLoading: true });
        this.setState({ isDataUpdated: !this.state.isDataUpdated });

        let api = this.familyMembersForm.value.id !== null ? "UpdateClientFamilyMember" : "CreateClientFamilyMember";
        let message = this.familyMembersForm.value.id !== null ? "Updated" : "Created";
        const r = await callAPI(api, this.familyMembersForm.value);
        this.setState({ isLoading: false });

        if (r?.isSuccess) {
            swalToast2("Family Members for Client " + message + " successfully.");
            // this.getClientFamilyMembersDetails(this.props.clientId);
            this.closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || "Something went wrong!!!"}</span>`;
            swalAlert("e", errorMsg);
        }
    };

    closeModal = (val) => {
        this.props.closeClientIdentityModal(val);
    };

    render() {
        return (
            <>
                <div className={`modal fade show`} style={{ display: "block" }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div className="modal-dialog modal-md modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-success" id="exampleModalLabel">
                                    Family Members
                                </h5>
                                <i className="bi bi-x-lg" onClick={() => this.closeModal(false)} style={{ cursor: "pointer" }}></i>
                            </div>
                            <div className="modal-body">
                                {this.state.isLoading && !this.state.isDataUpdated && (
                                    <>
                                        <div>Please Wait...</div>
                                    </>
                                )}

                                {this.state.isDataUpdated && (
                                    <>
                                        <div>Completed...</div>
                                    </>
                                )}

                                {!this.state.isLoading && (
                                    <>
                                        <FieldGroup
                                            control={this.familyMembersForm}
                                            render={({ get, invalid, pristine }) => (
                                                <form>
                                                    <div className="row mb-2">
                                                        <div className="col-6">
                                                            <FieldControl name="firstName" render={TextInput} meta={{ label: "First name", maxLengthVal: 50 }} />
                                                        </div>
                                                        <div className="col-6">
                                                            <FieldControl name="lastName" render={TextInput} meta={{ label: "Last name", maxLengthVal: 50 }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">
                                                            <FieldControl name="phoneNumber" render={TextInput} meta={{ label: "Phone Number", patternErrMsg: "Only numbers are allowed", maxLengthVal: 10, minLengthVal: 10 }} />
                                                        </div>
                                                        <div className="col-6">
                                                            <FieldControl
                                                                name="phoneNumberType"
                                                                render={SelectInput}
                                                                meta={{
                                                                    label: "Primary Contact Phone Number Type",
                                                                    options: [{ text: "Mobile" }, { text: "Home" }, { text: "Work" }, { text: "Other" }],
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">
                                                            <FieldControl name="email" render={TextInput} meta={{ label: "Email Address", emailErrMsg: "Invalid Email", maxLengthVal: 150 }} />
                                                        </div>

                                                        <div className="col-3 mt-4 pt-2 align-items-center pe-0">
                                                            <FieldControl name="makePrimaryContact" render={CheckBox} meta={{ label: "Make primary contact" }} />
                                                        </div>
                                                        <div className="col-3 mt-4 pt-2 align-items-center px-0">
                                                            <FieldControl name="makeEmergencyContact" render={CheckBox} meta={{ label: "Make emergency contact" }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">
                                                            <FieldControl
                                                                name="relationshipToTheRecipientOfCare"
                                                                render={SelectInput}
                                                                meta={{
                                                                    label: "Relationship to client",
                                                                    options: [
                                                                        // { text: 'Myself' },
                                                                        { text: "Parent" },
                                                                        { text: "Grandparent" },
                                                                        { text: "Sibling" },
                                                                        { text: "Spouse" },
                                                                        { text: "Friend" },
                                                                        { text: "Child" },
                                                                        { text: "Aunt or Uncle" },
                                                                        { text: "Niece or Nephew" },
                                                                        { text: "Cousin" },
                                                                        { text: "Neighbor" },
                                                                        { text: "Other" },
                                                                    ],
                                                                }}
                                                            />
                                                        </div>
                                                        {/* <div className="col-3 align-items-center">
                                                        <FieldControl
                                                            name="makePrimaryContact"
                                                            render={CheckBox}
                                                            meta={{ label: "Make primary contact" }}
                                                        />
                                                    </div>
                                                    <div className="col-3">
                                                        <FieldControl
                                                            name="makeEmergencyContact"
                                                            render={CheckBox}
                                                            meta={{ label: "Make emergency contact" }} />
                                                    </div> */}
                                                        <div className="col-6 mt-4 pt-2">
                                                            <FieldControl name="receiveNotificationsAndStatusUpdatesViaEmail" render={CheckBox} meta={{ label: "Receive notifications & status updates via email" }} />
                                                        </div>
                                                    </div>

                                                    <div className="row text-lg-end pb-1 pt-3 mx-0 px-0" style={{ borderTop: "1px solid lightgray" }}>
                                                        <div className="col-md-12">
                                                            <button type="button" onClick={() => this.closeModal(false)} className="btn btn-outline-secondary">
                                                                Close
                                                            </button>
                                                            &nbsp;&nbsp;
                                                            <button type="button" onClick={() => this.handleSave()} className="btn btn-primary" disabled={invalid || pristine || this.state.isLoading}>
                                                                {this.state.isLoading ? "Please wait..." : "Save"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default FamilyMembersModal;

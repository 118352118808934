import React from 'react';
import {
    FormBuilder,
    Validators,
    FieldControl,
    FieldGroup
} from "react-reactive-form";
import { withRouter } from 'react-router-dom';
import { swalAlert, swalToast } from './../../../../lib/Swal.js';
import { callAPI, getQueryStringValue } from '../../../../lib/Global.js';

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false };
    }

    componentDidMount() {
        const email = getQueryStringValue('email');
        const rpc = getQueryStringValue('rpc');
        this.passwordResetForm.patchValue({ email, resetPasswordCode: rpc });
    }

    passwordResetForm = FormBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        resetPasswordCode: [null, Validators.required],
        newPassword: [null, Validators.required],
        confirmPassword: [null, Validators.required]
    });

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.passwordResetForm.value.newPassword !== this.passwordResetForm.value.confirmPassword) {
            const errorMsg = 'New Password and Confirm Password do not match.';
            swalAlert('i', errorMsg);
            return;
        }

        this.passwordResetForm.updateValueAndValidity(this.passwordResetForm.value);
        this.setState({ isLoading: true });

        const r = await callAPI('ResetPassword', this.passwordResetForm.value);
        this.setState({ isLoading: false });
        this.passwordResetForm.updateValueAndValidity(this.passwordResetForm.value);
        if (r.isResetPasswordSuccess) {
            swalToast('Reset Password successful, Please login with new password.');
            this.props.history.push('login');
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    render() {
        return (
            <>
                <div className="container d-flex align-items-center flex-column position-absolute top-50 start-50 translate-middle">
                    <div style={{ 'max-width': '600px' }} className="card border-info">
                        <img src="./img/login-image.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Set New Password</h5>
                            <footer className="text-muted pb-6" style={{ 'margin-top': '-10px' }}> </footer>
                            <form className="row g-3" onSubmit={() => this.handleSubmitLoginOtp}>
                                <FieldGroup
                                    control={this.passwordResetForm}
                                    render={({ invalid, pristine, pending }) => (
                                        <>
                                            <FieldControl
                                                name="newPassword"
                                                render={({ handler, touched, hasError }) => (
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon11"><i className="bi bi-key-fill" />                                                    </span>
                                                        <input type="password" className={"form-control " + (pristine ? '' : touched && hasError("required") ? 'is-invalid' : 'is-valid')} placeholder="Enter New Password" {...handler()} required />
                                                        <div className="invalid-feedback">
                                                            <span> {touched && hasError("required") && "New Password is required"} </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <FieldControl
                                                name="confirmPassword"
                                                render={({ handler, touched, hasError }) => (
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon12"><i className="bi bi-key-fill" />                                                    </span>
                                                        <input type="password" className={"form-control " + (pristine ? '' : touched && hasError("required") ? 'is-invalid' : 'is-valid')} placeholder="Enter Confirm Password" {...handler()} required />
                                                        <div className="invalid-feedback">
                                                            <span> {touched && hasError("required") && "Confirm Password is required"} </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <div className="row g-3">
                                                <div className="d-grid gap-2 col-6 mx-auto">
                                                    <button className={"mb-3 btn " + (pristine || invalid ? 'btn-secondary' : 'btn-success')} disabled={invalid || pristine || this.state.isLoading} onClick={e => this.handleSubmit(e)}>{this.state.isLoading ? 'Please wait...' : 'Save'}</button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                />
                            </form>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(PasswordReset);

import { useEffect, useState } from "react";
import { fileTimeStamp } from "../../../../lib/fileTimeStamp";
import { JSONToCSVConvertor } from "../../../../lib/jsonToCSVConvertor";
import { sortJson } from "../../../../lib/sortJson";
import { ErrorMessage } from "../../../../Shared/errorMessage/errorMessage";
import { Loading } from "../../../../Shared/loading/loading";
import { acknowledgeClientHealthAssessment, createClientHealthAssessment, getClientHealthAssessmentById, getClientHealthAssessmentsByClientId, updateClientHealthAssessment } from "./clientHealthAssessmentListRest";
import { ClientHealthAssessmentDialog } from "./components/clientHealthAssessmentDialog";
import { ClientHealthAssessmentListTableBody } from "./components/clientHealthAssessmentListTableBody";
import { ClientHealthAssessmentListTableFooter } from "./components/clientHealthAssessmentListTableFooter";

export const ClientHealthAssessmentList = ({ clientId }) => {
    //=== Vars =============================================================

    const healthAssessmentInitialValue = {
        id: null,
        completedBy: null,
        completedOn: null,
        acknowledgeBy: null,
        acknowledgeOn: null,
        laceTool: {
            lengthOfStay: null,
            acuteAdmission: null,
            comorbidity: null,
            emergencyDepartmentVisits: null,
        },
        fallRisk: {
            anyAdministeredAntiepileptics: null,
            anyAdministeredBenzodiazepines: null,
            fallRiskFactors: null,
            getUpAndGoTest: null,
        },
        homeSafety: {
            adequateLightingInitial: "N/A",
            adequateLightingRecertification: "N/A",
            adequateFurnitureInitial: "N/A",
            adequateFurnitureRecertification: "N/A",
            adequatePathwaysInitial: "N/A",
            adequatePathwaysRecertification: "N/A",
            adequateStairwaysHallwaysExitsInitial: "N/A",
            adequateStairwaysHallwaysExitsRecertification: "N/A",
            adequateRugsInitial: "N/A",
            adequateRugsRecertification: "N/A",
            adequateStepsStairsInitial: "N/A",
            adequateStepsStairsRecertification: "N/A",
            adequateFlashlightInitial: "N/A",
            adequateFlashlightRecertification: "N/A",
            adequateEletricalCordsInitial: "N/A",
            adequateEletricalCordsRecertification: "N/A",
            adequateBathtubShowerFloorInitial: "N/A",
            adequateBathtubShowerFloorRecertification: "N/A",
            adequateBathroomHandGrippersInitial: "N/A",
            adequateBathroomHandGrippersRecertification: "N/A",
            adequateRefrigerationMedicationsInitial: "N/A",
            adequateRefrigerationMedicationsRecertification: "N/A",
            adequateMedicationLabelsInitial: "N/A",
            adequateMedicationLabelsRecertification: "N/A",
            adequateMedicationSecuredInitial: "N/A",
            adequateMedicationSecuredRecertification: "N/A",
            adequateSharpObjectsSecuredInitial: "N/A",
            adequateSharpObjectsSecuredRecertification: "N/A",
            adequateCleaningSubstancesSecuredInitial: "N/A",
            adequateCleaningSubstancesSecuredRecertification: "N/A",
            adequatePoisonInitial: "N/A",
            adequatePoisonRecertification: "N/A",
            adequateNoSmokingSignInitial: "N/A",
            adequateNoSmokingSignRecertification: "N/A",
            adequateOxigenInitial: "N/A",
            adequateOxigenRecertification: "N/A",
            adequateOxigenTubingHazardInitial: "N/A",
            adequateOxigenTubingHazardRecertification: "N/A",
            adequateLiftingAidsInitial: "N/A",
            adequateLiftingAidsRecertification: "N/A",
            adequateTelephoneAccessInitial: "N/A",
            adequateTelephoneAccessRecertification: "N/A",
            adequateEmergencyTelephoneListInitial: "N/A",
            adequateEmergencyTelephoneListRecertification: "N/A",
            adequateFireScapePlanKnowledgeInitial: "N/A",
            adequateFireScapePlanKnowledgeRecertification: "N/A",
            adequateSmokeAlarmInitial: "N/A",
            adequateSmokeAlarmRecertification: "N/A",
            adequateEvacuationPlanKnowledgeInitial: "N/A",
            adequateEvacuationPlanKnowledgeRecertification: "N/A",
            adequateHomeEnvironmentForCareInitial: "N/A",
            adequateHomeEnvironmentForCareRecertification: "N/A",
        },
        commendation: null,
    };

    //=== States =============================================================

    //Health Assessment state
    const [healthAssessment, setHealthAssessment] = useState(healthAssessmentInitialValue);

    //Modal display state
    const [show, setShow] = useState(false);

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);

    //Health Assessment data state
    const [data, setData] = useState([]);

    //Health Assessment filtered data state
    const [displayData, setDisplayData] = useState([]);

    //Selected id state
    const [id, setId] = useState(null);

    //acknowledge mode state
    const [acknowledgeMode, setAcknowledgeMode] = useState(false);

    //read only modal
    const [readOnlyMode, setReadOnlyMode] = useState(false);

    //Grid records per page state
    const [pageSize, setPageSize] = useState(5);

    //Grid page # state
    const [pageNumber, setPageNumber] = useState(1);

    //Grid number of pages state
    const [numPages, setNumPages] = useState(null);

    //filter value state
    const [filter, setFilter] = useState(null);

    //order by direction state
    const [sortAsc, setSortAsc] = useState(true);

    //Use Effects ===========================================================

    //Loading client Health Assessment...
    useEffect(() => {
        loadHealthAssessments(clientId);
    }, [clientId]); // eslint-disable-line react-hooks/exhaustive-deps

    //update # pages when displayData || pageSize changes
    useEffect(() => {
        pageSize === "All" ? setNumPages(1) : setNumPages(Math.ceil(displayData.length / pageSize));
    }, [displayData, pageSize]);

    //update display data when data || filter changes
    useEffect(() => {
        setDisplayData(
            !filter
                ? data
                : data.filter(({ completedOn, completedBy, acknowledgeOn, acknowledgeBy }) => {
                      return completedOn?.toLowerCase().includes(filter) || completedBy?.toLowerCase().includes(filter) || acknowledgeOn?.toLowerCase()?.includes(filter) || acknowledgeBy.toLowerCase().includes(filter);
                  })
        );

        //when filter, reset to page 1
        setPageNumber(1);
    }, [data, filter]);

    //=== Methods ============================================================

    const isError = (obj) => Object.prototype.toString.call(obj) === "[object Error]";

    const loadHealthAssessment = (id) => {
        getClientHealthAssessmentById(id)
            .then((data) => {
                setHealthAssessment(data);
                setShow(true);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading Lace Tool Length of Stay list");
                }
                setLoading(false);
            });
    };

    const loadHealthAssessments = (clientId) => {
        setLoading(true);
        getClientHealthAssessmentsByClientId(clientId)
            .then((data) => {
                setData(data);
                setDisplayData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading health assessments");
                }
                setLoading(false);
            });
    };

    //Event handlers =====================================================

    //show add new Assessment modal
    const handleAddNewClick = () => {
        setId(null);
        setAcknowledgeMode(false);
        setReadOnlyMode(false);
        setHealthAssessment(healthAssessmentInitialValue);
        setShow(true);
    };

    //download csv
    const handleDownloadClick = () => {
        JSONToCSVConvertor(displayData, `Infinity_ClientHealthAssessmentList_${fileTimeStamp()}`, true);
    };

    //show edit health assessment modal
    const handleEditClick = (id) => {
        setAcknowledgeMode(false);
        setReadOnlyMode(false);
        setId(id);
        if (id) {
            loadHealthAssessment(id);
        }
    };

    //show edit health assessment modal for acknowledgment
    const handleAcknowledgeClick = (id) => {
        setAcknowledgeMode(true);
        setReadOnlyMode(false);
        setId(id);
        if (id) {
            loadHealthAssessment(id);
        }
    };

    //show edit health assessment modal for view
    const handleViewClick = (id) => {
        setAcknowledgeMode(true);
        setReadOnlyMode(true);
        setId(id);
        if (id) {
            loadHealthAssessment(id);
        }
    };

    //sort columns
    const handleSortClick = (prop, propType) => {
        setSortAsc((sortAsc) => !sortAsc);
        setDisplayData(sortJson(displayData, prop, propType, sortAsc));
    };

    //handle page size changes
    const handlePageSizeChange = (e) => {
        e.target.value === "All" ? setPageSize(data.length) : setPageSize(e.target.value);
        //when pagesize changes, reset to page 1
        setPageNumber(1);
    };

    //handle page number changes
    const handlePageNumberChange = (e) => {
        setPageNumber(e.target.value);
    };

    //handle reload button click
    const handleReloadClick = () => {
        loadHealthAssessments(clientId);
    };

    //handle save Health Assessment click
    const handleSaveClick = (newData) => {
        newData = {
            ...newData,
            laceTool: {
                ...newData.laceTool,
                comorbidity: newData.laceTool?.comorbidity?.map((i) => (i.value ? i.value : i)),
            },
            fallRisk: {
                ...newData.fallRisk,
                fallRiskFactors: newData.fallRisk?.fallRiskFactors?.map((i) => (i.value ? i.value : i)),
            },

            clientId: clientId,
        };

        if (newData.id) {
            updateClientHealthAssessment(newData)
                .then((ret) => {
                    const newArrayData = [...data];
                    const index = newArrayData.findIndex((item) => item.id === newData.id);
                    newArrayData[index] = ret;
                    setData(newArrayData);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        } else {
            createClientHealthAssessment(newData)
                .then((ret) => {
                    const newArrayData = [...data];
                    newArrayData.push(ret);
                    setData(newArrayData);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        }
    };

    //handle acknowledge Health Assessment click
    const handleAcknowledgementClick = (id) => {
        if (id) {
            acknowledgeClientHealthAssessment(id)
                .then((ret) => {
                    const newArrayData = [...data];
                    const index = newArrayData.findIndex((item) => item.id === id);
                    newArrayData[index] = ret;
                    setData(newArrayData);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        }
    };

    //handle close create dialog
    const handleCloseClick = () => setShow(false);

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //Render =================================================================

    return (
        <section id="HealthAssessmentsSection">
            <h5 className="bold-style" style={{ fontSize: "14px" }}>
                Health Assessments
            </h5>
            <hr />
            <div className="row pt-5">
                <div className="col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col-md-12">{operationError && <ErrorMessage msg={operationError} />}</div>
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <button type="button" className="btn btn-primary btn-sm" onClick={handleAddNewClick}>
                                Add New Assessment
                            </button>
                            {loading && <Loading />}
                        </div>
                        <div className="col-md-4 align-end">
                            <div className="input-group">
                                <input type="text" className="form-control form-control-sm" placeholder="Search...." onChange={handleSearchInput} />
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Reload" onClick={handleReloadClick}>
                                    <i className="fa fa-refresh"></i>
                                </button>
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Download" onClick={handleDownloadClick}>
                                    <i className="fa fa-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped table-hover table-sm clientHealthAssessmentList mt-1">
                        <thead>
                            <tr>
                                <th className="sort small" onClick={() => handleSortClick("completedOn", "string")}>
                                    Completed on
                                </th>
                                <th className="sort small" onClick={() => handleSortClick("completedBy", "string")}>
                                    Completed by
                                </th>
                                <th className="sort small" onClick={() => handleSortClick("acknowledgeOn", "string")}>
                                    Acknowledge on
                                </th>
                                <th className="sort small" onClick={() => handleSortClick("acknowledgeBy", "string")}>
                                    Acknowledge by
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ClientHealthAssessmentListTableBody currentPage={Number(pageNumber)} numberPerPage={Number(pageSize)} items={displayData} filter={filter} loading={loading} loadingError={loadingError} handleEditClick={handleEditClick} handleAcknowledgeClick={handleAcknowledgeClick} handleViewClick={handleViewClick} />
                        </tbody>
                        <tfoot>
                            <ClientHealthAssessmentListTableFooter handlePageNumberChange={handlePageNumberChange} handlePageSizeChange={handlePageSizeChange} numPages={numPages} numRecords={displayData.length} />
                        </tfoot>
                    </table>
                </div>
            </div>

            {/* Modal components */}
            <ClientHealthAssessmentDialog id={id} data={healthAssessment} setData={setHealthAssessment} show={show} setShow={setShow} acknowledgeMode={acknowledgeMode} readOnly={readOnlyMode} handleClose={handleCloseClick} handleSaveClick={handleSaveClick} handleAcknowledgeClick={handleAcknowledgementClick} />
        </section>
    );
};

import React from 'react';
import styles from '../styles/common.module.css'
import moment from 'moment';

const DateInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group">
        <label for="formControlInput1" className={`mb-1 ${styles.labelStyling}`}>{meta.label}</label>
        <input type="date" className="form-control" placeholder={`Enter ${meta.label}`} {...handler()} max={moment().format("YYYY-MM-DD")} />
        <span className="d-block text-danger">
            {touched
                && hasError("required")
                && `${meta.label} is required`}
            {hasError("email")
                && `Invalid Email`}
            {hasError("isExist") && `Invalid Date`}
        </span>
    </div>
);

export default DateInput;
import React from 'react';
import { callAPI } from '../../../lib/Global.js';
import ClientInsurance from './ClientInsurance.js';
import ClientPaymentModal from './ClientPaymentModal.js';
import '../../../styles/common.css';
import clientStyles from './clientInfo.module.css';

class ClientPayment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showClientPaymentModal: false,
            clientPaymentList: [],
            paymentId: ''
        }
    }

    componentDidMount() {
        const clientId = this.props.clientId;
        this.getClientPaymentListByClientId(clientId)
    }

    getClientPaymentListByClientId = async (clientId) => {
        const r = await callAPI('getClientPaymentListByClientId', { id: clientId });
        console.log(r);
        this.setState({ clientPaymentList: r });
    }


    toggleClientPaymentsModal = (isDataReload, paymentId) => {
        if (isDataReload) {
            this.getClientPaymentListByClientId(this.props.clientId);
        }
        this.setState({ paymentId: paymentId });
        this.setState({ showClientPaymentModal: !this.state.showClientPaymentModal });
        this.props.togglebackDrop();
    }



    render() {
        const { showClientPaymentModal, clientPaymentList, paymentId } = this.state;
        return (
            <>
                {showClientPaymentModal && <ClientPaymentModal clientId={this.props.clientId} paymentId={paymentId} toggleClientPaymentsModal={this.toggleClientPaymentsModal} />}


                <h5 className="bold-style" style={{ fontSize: '14px' }}>Payment</h5>
                <hr />
                <div className={clientStyles.addContactWrapper} style={{ width: '170px' }} onClick={() => this.toggleClientPaymentsModal(false, '')}>
                    <i class="bi bi-person-plus-fill text-success"></i>
                    <span className="medium-style hover-item" style={{ color: '#3CAF8A' }}> Add New Payment Type</span>
                </div>
                {clientPaymentList.length > 0 && <table class="table table-hover table-sm table-borderless">
                    <thead>
                        <tr className={clientStyles.tHeadStyling}>
                            <th scope="col"> <span className="medium-style">Type</span></th>
                            <th scope="col"><span className="medium-style">Account #</span></th>
                            <th scope="col"><span className="medium-style">Exp Date</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientPaymentList?.map((d, i) => {
                            return (<tr key={i} className="border-bottom">
                                <td width="2%" className="medium-style">{d.paymentMethod}</td>
                                <td width="5%" className="medium-style" style={{ color: '#3CAF8A', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.toggleClientPaymentsModal(false, d.id)}>{d.accountOrCardNumber}</td>
                                <td width="5%" className="medium-style">{d.dateExp}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>}

                <ClientInsurance clientId={this.props.clientId} {...this.props} />
            </>
        )
    }
}


export default ClientPayment;
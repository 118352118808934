import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Tree from "./Tree";
import AdminTiles from "./AdminTiles";
import { Admin } from "../../HCC/Components/admin/admin";
import RolesAndPermission from "./RolesAndPermission";

import { withRouter } from "react-router-dom";
import { UserGroupList } from "./userGroupList/userGroupList";
import UsersList from "./UsersList";

const StyledFileExplorer = styled.div`
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    border: 1px solid red;
`;

const TreeWrapper = styled.div`
    width: 250px;
`;

class AdminMenu extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        selectedTreeNode: null,
    };

    onSelect = (file) => {
        const { match } = this.props;
        this.setState({ selectedTreeNode: file });
        if (file.path === "/Admin Home") {
            this.props.history.push("/admin");
        } else {
            this.props.history.push(`${match.url}${file.path}`);
        }
    };

    render() {
        // const { selectedTreeNode } = this.state;
        const { match } = this.props;
        return (
            <div style={{ padding: "1%" }}>
                <div class="row col-md-12">
                    {/* <div class="col-md-4" style={{ width: "20%" }}>
                        <Tree onSelect={this.onSelect} />
                    </div> */}
                    {/* <div class="col-md-8"> */}
                        {/* {!selectedTreeNode?.component && <AdminTiles />}
                    {selectedTreeNode?.component === 'Admin Landing Page' && <AdminTiles />}
                    {selectedTreeNode?.component === 'Service Portal' && <Admin />}
                    {selectedTreeNode?.component === 'Roles & Permissions' && <RolesAndPermission />} */}

                        <Switch>
                            <Route exact path={`${match.path}`}>
                                <AdminTiles />
                            </Route>
                            <Route exact path={`${match.path}/Users & Groups`}>
                                <AdminTiles />
                            </Route>
                            <Route exact path={`${match.path}/Account`}>
                                <AdminTiles />
                            </Route>
                            <Route exact path={`${match.path}/Global settings`}>
                                <AdminTiles />
                            </Route>
                            <Route exact path={`${match.path}/Activity logging`}>
                                <AdminTiles />
                            </Route>
                            <Route exact path={`${match.path}/Account/Roles & Permissions`}>
                                <RolesAndPermission />
                            </Route>
                            <Route exact path={`${match.path}/Global settings/Service Portal`}>
                                <Admin />
                            </Route>
                            <Route exact path={`${match.path}/Users & Groups/Groups`}>
                                <UserGroupList />
                            </Route>
                            <Route exact path={`${match.path}/Users & Groups/Users`}>
                                <UsersList />
                            </Route>
                        </Switch>
                    </div>
                {/* </div> */}
            </div>
        );
    }
}
export default withRouter(AdminMenu);

import React, { useState, useEffect } from 'react';
import { callAPI, callAPIforFileUpload, isFileWithInMBof } from '../../../lib/Global.js';
import '../../../styles/common.css';
import styles from './clientInfo.module.css';
import { swalAlert, swalToast, swalToast2 } from '../../../lib/Swal.js';




const ClientPhoto = ({ photo, clientId }) => {

    const [isLoadingImage, setIsLoading] = useState(false);
    const [previewImage, setPreviewimage] = useState("");
    const [blobName, setBlobName] = useState("");

    useEffect(() => {
        getClientPhoto(photo);
    }, [photo])

    const getClientPhoto = async (photo) => {
        if (photo) {
            const initBase64Content = 'data:image/jpeg;base64,';
            setIsLoading(true);
            const p = await callAPI('ClientPhotoDownload', { blobName: photo });
            if (p && p.blobBase64) {
                setPreviewimage(initBase64Content + p.blobBase64);
                setIsLoading(false);
                setBlobName(p.blobNameInFull);
            } else {
                setIsLoading(false);
            }
        }
    }

    const handleSelectedImage = async (e) => {
        let file = e.target.files[0];
        let allowedExtension = ['jpeg', 'png', 'PNG', 'JPEG', 'JPG', 'jpg'];
        let validFile = allowedExtension.includes(file.name.substring(file.name.lastIndexOf('.') + 1));
        if (!validFile) {
            swalAlert('e', "This is not an approved format. Please upload a JPG or PNG image");
        } else if (!isFileWithInMBof(file.size, 2)) {
            swalAlert('e', "The image you are trying to upload is too large. Please select a image smaller than 2MB");
        } else {
            if (blobName) {
                await handleDeleteImage(blobName);
            }
            await handleUploadImage(file);
        }
    }

    const handleUploadImage = async (imageFile) => {
        setIsLoading(true);
        let data = new FormData();
        data.append('file', imageFile);
        data.append('id', clientId);
        const r = await callAPIforFileUpload('ClientPhotoAdd', data);
        setIsLoading(false);
        if (r.isSuccess) {
            swalToast2('Image has been successfully uploaded');
            getClientPhoto(r.blobName);
        }
    }

    const handleDeleteImage = async (blobName) => {
        setIsLoading(true);
        const d = await callAPI('ClientPhotoDelete', { blobName: blobName, id: clientId });
        setIsLoading(false);
        setPreviewimage("");
        if (d.isSuccess) {
            swalToast2('Image has been successfully Deleted');
        }
    }


    return (
        <>
            <div className={styles.photoBorder}>
                {!isLoadingImage ?
                    previewImage ? <div className="image-wrapper">
                        <img src={previewImage} className="img-profile" alt="..." />
                        <label className="medium-style del" for="file-input" style={{ position: 'absolute', cursor: 'pointer' }}>Update</label></div>
                        : <label for="file-input"><i class="bi bi-person-plus-fill" style={{ fontSize: '2rem', cursor: 'pointer' }}></i></label>
                    : <div class="spinner-border spinner-border-sm"></div>}
            </div>
            <input id="file-input" type="file" style={{ display: 'none' }} onChange={(e) => handleSelectedImage(e)} />
        </>
    );
}


export default ClientPhoto;
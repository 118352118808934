import { useEffect, useState } from "react";
import { ErrorMessage } from "../../../../Shared/errorMessage/errorMessage";
import { Loading } from "../../../../Shared/loading/loading";
import { createClientServices, getClientServicesById, updateClientServices } from "./clientServicesRest.js";
import { ClientServicesEditDialog } from "./components/clientServicesEditDialog.jsx";
import { getAllProducts } from "../admin/components/productList/productListRest";
import { getAllCaseTypes } from "../admin/components/caseTypeList/caseTypeListRest";
import moment from "moment";
import styles from "../../../../styles/common.module.css";

export const ClientServices = ({ clientId }) => {
    //Client Services data state
    const [services, setServices] = useState({
        id: null,
        assessmentDate: null,
        assessmentTypes: [],
        firstVisit: null,
        servicesNeeded: [],
    });

    //Products List state
    const [listProducts, setListProducts] = useState([]);

    //Case Types List state
    const [listCaseTypes, setListCaseTypes] = useState([]);

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //Modal display state
    const [showServicesModal, setShowServicesModal] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //Use Effects ===========================================================

    //Loading hospices...
    useEffect(() => {
        loadAllProducts();
        loadAllCaseTypes();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Loading client Services...
    useEffect(() => {
        loadClientServices(clientId);
    }, [clientId]); // eslint-disable-line react-hooks/exhaustive-deps

    //Event handlers =====================================================

    //handle open edit basic intake dialog
    const handleEditServicesClick = () => setShowServicesModal(true);

    //handle close edit basic intake dialog
    const handleCloseClick = () => setShowServicesModal(false);

    //handle save basic intake click
    const handleSaveServicesClick = ({ assessmentTypes, assessmentDate, hadCareInPast, hospiceName, firstVisit, servicesNeeded, clientNeeds, mentalStatus }) => {
        if (services.id) {
            updateClientServices({
                id: services.id,
                clientId: clientId,
                assessmentDate: assessmentDate,
                assessmentTypes: assessmentTypes,
                firstVisit: firstVisit,
                servicesNeeded: servicesNeeded,
            })
                .then((ret) => {
                    setServices(ret);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        } else {
            createClientServices({
                clientId: clientId,
                assessmentDate: assessmentDate,
                assessmentTypes: assessmentTypes,
                firstVisit: firstVisit,
                servicesNeeded: servicesNeeded,
            })
                .then((ret) => {
                    setServices(ret);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        }
    };

    //Methods ===============================================================

    const isError = (obj) => Object.prototype.toString.call(obj) === "[object Error]";

    const loadAllProducts = () => {
        setLoading(true);
        getAllProducts()
            .then((data) => {
                const options = data.map((item) => {
                    return { label: item.name, value: item.name };
                });
                setListProducts(options);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading services list");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        setLoading(false);
    };

    const loadAllCaseTypes = () => {
        setLoading(true);
        getAllCaseTypes()
            .then((data) => {
                const options = data.map((item) => {
                    return { label: item.name, value: item.name };
                });
                setListCaseTypes(options);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading Assessment Types list");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        setLoading(false);
    };

    const loadClientServices = (id) => {
        setLoading(true);
        getClientServicesById(id)
            .then((data) => {
                setServices(data);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading client services");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        setLoading(false);
    };

    //Render ===============================================================

    return (
        <section id="ServicesSection">
            <h5 className="bold-style" style={{ fontSize: "14px" }}>
                Services
            </h5>
            <hr />

            <div className="row">
                <div className="col-md-8 offset-md-1">{operationError && <ErrorMessage msg={operationError} />}</div>
            </div>

            <div className="row">
                <div className="col-md-2 text-end">{loading && <Loading />}</div>
            </div>

            <div className="row py-3">
                <div className="col-md-3">
                    <label className={`mb-1 d-block ${styles.labelStyling}`}>Initial Assessment Date:</label>
                    <span className="regular-style text-primary">{services.assessmentDate ? moment(services.assessmentDate).utc().tz("America/New_York").format("MM/DD/YYYY") : "-" || ""}</span>
                </div>
                <div className="col-md-3">
                    <label className={`mb-1 d-block ${styles.labelStyling}`}>First Visit:</label>
                    <span className="regular-style text-primary">{services.firstVisit ? moment(services.firstVisit).utc().tz("America/New_York").format("MM/DD/YYYY") : "-" || ""}</span>
                </div>

                <div className="col-md-3">
                    <label className={`mb-1 d-block ${styles.labelStyling}`}>Assessment Types</label>
                    <span className="regular-style text-primary">
                        {services.assessmentTypes.map((i) => {
                            return (
                                <>
                                    {i}
                                    <br />
                                </>
                            );
                        })}
                    </span>
                </div>
                {/* <div className="col-md-3">
                    <label className={`mb-1 d-block ${styles.labelStyling}`}>Ongoing Services Needed</label>
                    <span className="regular-style text-primary">
                        {services.servicesNeeded.map((i) => {
                            return (
                                <>
                                    {i}
                                    <br />
                                </>
                            );
                        })}
                    </span>
                </div> */}
            </div>

            <div className="row py-3">
                <div className="col-md-2">
                    <button type="button" className="btn btn-primary btn-sm" onClick={handleEditServicesClick}>
                        Edit Services
                    </button>
                </div>
            </div>
            <hr />

            <ClientServicesEditDialog show={showServicesModal} setShow={setShowServicesModal} handleClose={handleCloseClick} handleSaveClick={handleSaveServicesClick} listAssessmentTypes={listCaseTypes} listClientServicesNeeded={listProducts} services={services} />
        </section>
    );
};
////

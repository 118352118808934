import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { callAPI, callAPIWithpathParameter } from '../../lib/Global.js';
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import TextInput from '../../Controls/TextInput.js';
import TextArea from '../../Controls/TextArea.js';
import SelectInput from '../../Controls/SelectInput.js';
import { swalAlert, swalToast2 } from '../../lib/Swal.js';
import CheckBox from '../../Controls/CheckBox.js';

const usersForm = FormBuilder.group({
    id: null,
    userId: null,
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    email: [null, Validators.required],
    isBlocked: false,
    status: null,
    jobTitle: null,
    roleIdsAssigned: null
});

const UsersModal = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [allUserData, setAllUserData] = useState([]);

    useEffect(() => {
        if (props.userId) {
            usersForm.get('email').disable();
            if (usersForm.value.roleIdsAssigned === null) {
                usersForm.value.roleIdsAssigned = [];
            }
            usersForm.updateValueAndValidity(usersForm.value)
        } else {
            usersForm.get('email').enable();
            usersForm.reset({
                isBlocked: false,
                roleIdsAssigned: []
            });
        }

        getUsersData(props.userId);
    }, []);

    // toggle status dropdown to chagne the status
    const toggleStatus = () => {
        if (usersForm.value.status === "Enable") {
            usersForm.get('isBlocked').setValue(false);
        } else {
            usersForm.get('isBlocked').setValue(true);
        }
        usersForm.updateValueAndValidity(usersForm.value)
    }

    // populate the clicked form with values
    const getUsersData = async (id) => {

        if (id) {
            const r = await callAPIWithpathParameter('User', id, 'GET', '');
            setAllUserData(r?.data);
            populateUserForm(r?.data);
        } else {
            usersForm.patchValue({ userId: props.userId })
        }

    }

    const populateUserForm = (userData) => {

        if (Array.isArray(userData) && userData.length > 0) {
            let userDataDetail = userData[0];

            usersForm.patchValue({
                id: userDataDetail.id,
                userId: userDataDetail.userId,
                firstName: userDataDetail.fname,
                lastName: userDataDetail.lname,
                email: userDataDetail.email,
                status: userDataDetail.isBlocked ? 'Disable' : 'Enable',
                isBlocked: userDataDetail.isBlocked,
                jobTitle: userDataDetail.jobTitle,
                roleIdsAssigned: userDataDetail.roleIdsAssigned
            })

        }
    }

    const closeModal = (val) => {
        props.toggleUsersModal(val);
    }

    const handleSave = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const v = usersForm.value;
        console.log("===========", v)
        if (props.userId) {
            v.email = allUserData[0].email;
        }

        let message = usersForm.value.id ? 'User account modified' : 'New user added';
        let http_verb = usersForm.value.id ? 'PUT' : 'POST';

        const r = await callAPI('User', v, http_verb);
        setIsLoading(false);

        if (r?.success) {
            swalToast2(message + ' successfully.');
            closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    return (
        <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
            <div className="modal-dialog modal-md modal-lg" role="document">
                <div className="modal-content bg-light">
                    <div className="modal-header">
                        <h5 className="modal-title text-success" id="exampleModalLabel">{props.userId ? 'Modify User' : 'Add User'}</h5>
                        <i className="bi bi-x-lg" onClick={() => closeModal(false)} style={{ cursor: 'pointer' }}></i>
                    </div>
                    <div className="modal-body">
                        {/* {(isLoading) && <>
                            <div>Please Wait...</div>
                        </>} */}

                        {/* {!isLoading && <> */}
                        <FieldGroup
                            control={usersForm}
                            render={({ get, invalid, pristine }) => (
                                <form>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <FieldControl
                                                name="firstName"
                                                render={TextInput}
                                                meta={{ label: "First name", maxLengthVal: 50 }}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <FieldControl
                                                name="lastName"
                                                render={TextInput}
                                                meta={{ label: "Last name", maxLengthVal: 50 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <FieldControl
                                                name="email"
                                                render={TextInput}
                                                meta={{ label: "User Email", emailErrMsg: "Invalid Email", maxLengthVal: 150 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <FieldControl
                                                name="jobTitle"
                                                render={TextInput}
                                                meta={{ label: "Job Title" }}
                                            />
                                        </div>
                                        {props.userId &&
                                            <div className="col-6">
                                                <FieldControl
                                                    name="status"
                                                    render={SelectInput}
                                                    meta={{
                                                        toggleStatus: toggleStatus,
                                                        label: "Status",
                                                        options: [
                                                            { text: 'Enable' },
                                                            { text: 'Disable' }
                                                        ]
                                                    }}
                                                />
                                            </div>
                                        }
                                        {!props.userId &&
                                            <div className='col-6'>
                                                <FieldControl
                                                    name="roleIdsAssigned"
                                                    render={({ handler, touched, hasError }) => (
                                                        <div class="form-group">
                                                            <label for="formControlSelect1" className={`mb-1`}>Role Assigned</label>
                                                            <select multiple="multiple" className="form-control form-select" id="exampleFormControlSelect1" {...handler()}>
                                                                <option disabled={true} selected value="">-- Please Select --</option>
                                                                {
                                                                    props.allRoles?.map((m, i) => {
                                                                        return (
                                                                            <option key={i} value={m.value ? m.value : m.text}>{m.text}</option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                            <span className="d-block text-danger mt-1">
                                                                {touched && hasError("required")
                                                                    && `Please select an option`}
                                                            </span>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {props.userId &&
                                        <div>
                                            <div className='col-12'>
                                                <FieldControl
                                                    name="roleIdsAssigned"
                                                    render={({ handler, touched, hasError }) => (
                                                        <div class="form-group">
                                                            <label for="formControlSelect1" className={`mb-1`}>Role Assigned</label>
                                                            <select multiple="multiple" className="form-control form-select" id="exampleFormControlSelect1" {...handler()}>
                                                                <option disabled={true} selected value="">-- Please Select --</option>
                                                                {
                                                                    props.allRoles?.map((m, i) => {
                                                                        return (
                                                                            <option key={i} value={m.value ? m.value : m.text}>{m.text}</option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                            <span className="d-block text-danger mt-1">
                                                                {touched && hasError("required")
                                                                    && `Please select an option`}
                                                            </span>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                    }
                                    <div className="d-flex justify-content-center">
                                        <div className="my-2 gap-3 pb-2">
                                            <button type="button" onClick={(e) => handleSave(e)} className="btn btn-lg btn-success " disabled={isLoading || pristine || invalid} >{isLoading ? 'Please wait...' : props.userId ? 'Update' : 'Save'}</button>
                                            <button type="button" onClick={() => closeModal(false)} className="btn btn-link">Cancel</button>
                                        </div>
                                    </div>

                                </form>
                            )}
                        />
                        {/* </>} */}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default UsersModal;
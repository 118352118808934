import { ToggleSwitch } from "./ToggleSwitch";

//=== Style =====
import "./clientHAHomeSafety.css";

export const ClientHAHomeSafety = ({ homeSafetyData, setHomeSafetyData, acknowledgeMode }) => {
    const changeHandler = (e) => {
        let newData = { ...homeSafetyData, [e.name]: e.value };
        setHomeSafetyData(newData);
    };

    //=== Render  ============================================================

    return (
        <section id="clientHealthAssessmentHomeSafety">
            <>
                <div className="row small homeSafety">
                    <div className="col-md-7 small pb-2"></div>
                    <div className="col-md-2 small pb-2 ps-0">Initial</div>
                    <div className="col-md-3 small pb-2">Recertification</div>

                    <div className="col-md-7 small pb-2">1. Lighting is adequate in client care area(s).</div>

                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateLightingInitial ? homeSafetyData?.adequateLightingInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateLightingInitial ? homeSafetyData?.adequateLightingInitial : "N/A"} name="adequateLightingInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateLightingRecertification ? homeSafetyData?.adequateLightingRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateLightingRecertification ? homeSafetyData?.adequateLightingRecertification : "N/A"} name="adequateLightingRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">2. Furniture is arranged to allow free movement.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateFurnitureInitial ? homeSafetyData?.adequateFurnitureInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateFurnitureInitial ? homeSafetyData?.adequateFurnitureInitial : "N/A"} name="adequateFurnitureInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateFurnitureRecertification ? homeSafetyData?.adequateFurnitureRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateFurnitureRecertification ? homeSafetyData?.adequateFurnitureRecertification : "N/A"} name="adequateFurnitureRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">3. Pathways are clear in heavy traffic areas.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequatePathwaysInitial ? homeSafetyData?.adequatePathwaysInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequatePathwaysInitial ? homeSafetyData?.adequatePathwaysInitial : "N/A"} name="adequatePathwaysInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequatePathwaysRecertification ? homeSafetyData?.adequatePathwaysRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequatePathwaysRecertification ? homeSafetyData?.adequatePathwaysRecertification : "N/A"} name="adequatePathwaysRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">&nbsp;&nbsp;&nbsp;&nbsp;Stairways, hallways and exits are clear of clutter and loose objects.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateStairwaysHallwaysExitsInitial ? homeSafetyData?.adequateStairwaysHallwaysExitsInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateStairwaysHallwaysExitsInitial ? homeSafetyData?.adequateStairwaysHallwaysExitsInitial : "N/A"} name="adequateStairwaysHallwaysExitsInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateStairwaysHallwaysExitsRecertification ? homeSafetyData?.adequateStairwaysHallwaysExitsRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateStairwaysHallwaysExitsRecertification ? homeSafetyData?.adequateStairwaysHallwaysExitsRecertification : "N/A"} name="adequateStairwaysHallwaysExitsRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">4. Throw rugs are eliminated or fastened down.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateRugsInitial ? homeSafetyData?.adequateRugsInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateRugsInitial ? homeSafetyData?.adequateRugsInitial : "N/A"} name="adequateRugsInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateRugsRecertification ? homeSafetyData?.adequateRugsRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateRugsRecertification ? homeSafetyData?.adequateRugsRecertification : "N/A"} name="adequateRugsRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">5. Steps or stairs have accompanying sturdy hand rails or banisters.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateRugsInitial ? homeSafetyData?.adequateRugsInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateRugsInitial ? homeSafetyData?.adequateRugsInitial : "N/A"} name="adequateStepsStairsInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateStepsStairsRecertification ? homeSafetyData?.adequateStepsStairsRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateStepsStairsRecertification ? homeSafetyData?.adequateStepsStairsRecertification : "N/A"} name="adequateStepsStairsRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">6. A flashlight/light switch/lamp is located within easy reach of the client.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateFlashlightInitial ? homeSafetyData?.adequateFlashlightInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateFlashlightInitial ? homeSafetyData?.adequateFlashlightInitial : "N/A"} name="adequateFlashlightInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateFlashlightRecertification ? homeSafetyData?.adequateFlashlightRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateFlashlightRecertification ? homeSafetyData?.adequateFlashlightRecertification : "N/A"} name="adequateFlashlightRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">&nbsp;&nbsp;&nbsp;&nbsp;Electrical cords are placed close to walls and out of the pathway.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateEletricalCordsInitial ? homeSafetyData?.adequateEletricalCordsInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateEletricalCordsInitial ? homeSafetyData?.adequateEletricalCordsInitial : "N/A"} name="adequateEletricalCordsInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateEletricalCordsInitial ? homeSafetyData?.adequateEletricalCordsInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateEletricalCordsRecertification ? homeSafetyData?.adequateEletricalCordsRecertification : "N/A"} name="adequateEletricalCordsRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">&nbsp;&nbsp;&nbsp;&nbsp;There is a non-skid surface or mat on the bathtub or shower floor.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateBathtubShowerFloorInitial ? homeSafetyData?.adequateBathtubShowerFloorInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateBathtubShowerFloorInitial ? homeSafetyData?.adequateBathtubShowerFloorInitial : "N/A"} name="adequateBathtubShowerFloorInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateBathtubShowerFloorRecertification ? homeSafetyData?.adequateBathtubShowerFloorRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateBathtubShowerFloorRecertification ? homeSafetyData?.adequateBathtubShowerFloorRecertification : "N/A"} name="adequateBathtubShowerFloorRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">&nbsp;&nbsp;&nbsp;&nbsp;Hand grippers are installed in the bathroom, when appropriate.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateBathroomHandGrippersInitial ? homeSafetyData?.adequateBathroomHandGrippersInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateBathroomHandGrippersInitial ? homeSafetyData?.adequateBathroomHandGrippersInitial : "N/A"} name="adequateBathroomHandGrippersInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateBathroomHandGrippersRecertification ? homeSafetyData?.adequateBathroomHandGrippersRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateBathroomHandGrippersRecertification ? homeSafetyData?.adequateBathroomHandGrippersRecertification : "N/A"} name="adequateBathroomHandGrippersRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">&nbsp;&nbsp;&nbsp;&nbsp;Refrigeration is available for proper storage of solutions/medications.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateRefrigerationMedicationsInitial ? homeSafetyData?.adequateRefrigerationMedicationsInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateRefrigerationMedicationsInitial ? homeSafetyData?.adequateRefrigerationMedicationsInitial : "N/A"} name="adequateRefrigerationMedicationsInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateRefrigerationMedicationsRecertification ? homeSafetyData?.adequateRefrigerationMedicationsRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateRefrigerationMedicationsRecertification ? homeSafetyData?.adequateRefrigerationMedicationsRecertification : "N/A"} name="adequateRefrigerationMedicationsRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">7. All medications are clearly labeled.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateMedicationLabelsInitial ? homeSafetyData?.adequateMedicationLabelsInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateMedicationLabelsInitial ? homeSafetyData?.adequateMedicationLabelsInitial : "N/A"} name="adequateMedicationLabelsInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateMedicationLabelsRecertification ? homeSafetyData?.adequateMedicationLabelsRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateMedicationLabelsRecertification ? homeSafetyData?.adequateMedicationLabelsRecertification : "N/A"} name="adequateMedicationLabelsRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-12 small pb-2">8. The following items are in secured areas and out of the reach of children and confused individuals:</div>

                    <div className="col-md-7 small pb-2">&nbsp;&nbsp;&nbsp;&nbsp;a. Medications</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateMedicationSecuredInitial ? homeSafetyData?.adequateMedicationSecuredInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateMedicationSecuredInitial ? homeSafetyData?.adequateMedicationSecuredInitial : "N/A"} name="adequateMedicationSecuredInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateMedicationSecuredRecertification ? homeSafetyData?.adequateMedicationSecuredRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateMedicationSecuredRecertification ? homeSafetyData?.adequateMedicationSecuredRecertification : "N/A"} name="adequateMedicationSecuredRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">&nbsp;&nbsp;&nbsp;&nbsp;b. Sharp objects</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateSharpObjectsSecuredInitial ? homeSafetyData?.adequateSharpObjectsSecuredInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateSharpObjectsSecuredInitial ? homeSafetyData?.adequateSharpObjectsSecuredInitial : "N/A"} name="adequateSharpObjectsSecuredInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateSharpObjectsSecuredRecertification ? homeSafetyData?.adequateSharpObjectsSecuredRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateSharpObjectsSecuredRecertification ? homeSafetyData?.adequateSharpObjectsSecuredRecertification : "N/A"} name="adequateSharpObjectsSecuredRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">&nbsp;&nbsp;&nbsp;&nbsp;c. Cleaning substances</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateCleaningSubstancesSecuredInitial ? homeSafetyData?.adequateCleaningSubstancesSecuredInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateCleaningSubstancesSecuredInitial ? homeSafetyData?.adequateCleaningSubstancesSecuredInitial : "N/A"} name="adequateCleaningSubstancesSecuredInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateCleaningSubstancesSecuredRecertification ? homeSafetyData?.adequateCleaningSubstancesSecuredRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateCleaningSubstancesSecuredRecertification ? homeSafetyData?.adequateCleaningSubstancesSecuredRecertification : "N/A"} name="adequateCleaningSubstancesSecuredRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">9. Poisons (bug killer, weed killer, etc).</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequatePoisonInitial ? homeSafetyData?.adequatePoisonInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequatePoisonInitial ? homeSafetyData?.adequatePoisonInitial : "N/A"} name="adequatePoisonInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequatePoisonRecertification ? homeSafetyData?.adequatePoisonRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequatePoisonRecertification ? homeSafetyData?.adequatePoisonRecertification : "N/A"} name="adequatePoisonRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">10. No smoking sign posted, if oxygen in use.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateNoSmokingSignInitial ? homeSafetyData?.adequateNoSmokingSignInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateNoSmokingSignInitial ? homeSafetyData?.adequateNoSmokingSignInitial : "N/A"} name="adequateNoSmokingSignInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateNoSmokingSignRecertification ? homeSafetyData?.adequateNoSmokingSignRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateNoSmokingSignRecertification ? homeSafetyData?.adequateNoSmokingSignRecertification : "N/A"} name="adequateNoSmokingSignRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">11. Oxygen is placed in a non-smoking area and away from an open flame.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateOxigenInitial ? homeSafetyData?.adequateOxigenInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateOxigenInitial ? homeSafetyData?.adequateOxigenInitial : "N/A"} name="adequateOxigenInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateOxigenRecertification ? homeSafetyData?.adequateOxigenRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateOxigenRecertification ? homeSafetyData?.adequateOxigenRecertification : "N/A"} name="adequateOxigenRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">12. Oxygen tubing hazard.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateOxigenTubingHazardInitial ? homeSafetyData?.adequateOxigenTubingHazardInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateOxigenTubingHazardInitial ? homeSafetyData?.adequateOxigenTubingHazardInitial : "N/A"} name="adequateOxigenTubingHazardInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateOxigenTubingHazardRecertification ? homeSafetyData?.adequateOxigenTubingHazardRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateOxigenTubingHazardRecertification ? homeSafetyData?.adequateOxigenTubingHazardRecertification : "N/A"} name="adequateOxigenTubingHazardRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">13. Proper lifting aids are present for immobile clients or those clients requiring assistance with transfers.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateLiftingAidsInitial ? homeSafetyData?.adequateLiftingAidsInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateLiftingAidsInitial ? homeSafetyData?.adequateLiftingAidsInitial : "N/A"} name="adequateLiftingAidsInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateLiftingAidsRecertification ? homeSafetyData?.adequateLiftingAidsRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateLiftingAidsRecertification ? homeSafetyData?.adequateLiftingAidsRecertification : "N/A"} name="adequateLiftingAidsRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">14. There is access to a working telephone (land line).</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateTelephoneAccessInitial ? homeSafetyData?.adequateTelephoneAccessInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateTelephoneAccessInitial ? homeSafetyData?.adequateTelephoneAccessInitial : "N/A"} name="adequateTelephoneAccessInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateTelephoneAccessRecertification ? homeSafetyData?.adequateTelephoneAccessRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateTelephoneAccessRecertification ? homeSafetyData?.adequateTelephoneAccessRecertification : "N/A"} name="adequateTelephoneAccessRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">15. A list of emergency telephone numbers is present in the home.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateEmergencyTelephoneListInitial ? homeSafetyData?.adequateEmergencyTelephoneListInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateEmergencyTelephoneListInitial ? homeSafetyData?.adequateEmergencyTelephoneListInitial : "N/A"} name="adequateEmergencyTelephoneListInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateEmergencyTelephoneListRecertification ? homeSafetyData?.adequateEmergencyTelephoneListRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateEmergencyTelephoneListRecertification ? homeSafetyData?.adequateEmergencyTelephoneListRecertification : "N/A"} name="adequateEmergencyTelephoneListRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">16. Client/caregiver verbalizes an understanding of the fire escape plan and route from the home.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateFireScapePlanKnowledgeInitial ? homeSafetyData?.adequateFireScapePlanKnowledgeInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateFireScapePlanKnowledgeInitial ? homeSafetyData?.adequateFireScapePlanKnowledgeInitial : "N/A"} name="adequateFireScapePlanKnowledgeInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateFireScapePlanKnowledgeRecertification ? homeSafetyData?.adequateFireScapePlanKnowledgeRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateFireScapePlanKnowledgeRecertification ? homeSafetyData?.adequateFireScapePlanKnowledgeRecertification : "N/A"} name="adequateFireScapePlanKnowledgeRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">17. Smoke alarms are present on each floor of the house.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateSmokeAlarmInitial ? homeSafetyData?.adequateSmokeAlarmInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateSmokeAlarmInitial ? homeSafetyData?.adequateSmokeAlarmInitial : "N/A"} name="adequateSmokeAlarmInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateSmokeAlarmRecertification ? homeSafetyData?.adequateSmokeAlarmRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateSmokeAlarmRecertification ? homeSafetyData?.adequateSmokeAlarmRecertification : "N/A"} name="adequateSmokeAlarmRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">18. Client/family verbalizes understanding of emergency/evacuation plan.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateEvacuationPlanKnowledgeInitial ? homeSafetyData?.adequateEvacuationPlanKnowledgeInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateEvacuationPlanKnowledgeInitial ? homeSafetyData?.adequateEvacuationPlanKnowledgeInitial : "N/A"} name="adequateEvacuationPlanKnowledgeInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateEvacuationPlanKnowledgeRecertification ? homeSafetyData?.adequateEvacuationPlanKnowledgeRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateEvacuationPlanKnowledgeRecertification ? homeSafetyData?.adequateEvacuationPlanKnowledgeRecertification : "N/A"} name="adequateEvacuationPlanKnowledgeRecertification" onChange={changeHandler} />}
                    </div>

                    <div className="col-md-7 small pb-2">19. Home environment is suitable for care.</div>
                    <div className="col-md-2 small pb-2 ps-0">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateHomeEnvironmentForCareInitial ? homeSafetyData?.adequateHomeEnvironmentForCareInitial : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateHomeEnvironmentForCareInitial ? homeSafetyData?.adequateHomeEnvironmentForCareInitial : "N/A"} name="adequateHomeEnvironmentForCareInitial" onChange={changeHandler} />}
                    </div>
                    <div className="col-md-3 small pb-2">
                        {acknowledgeMode && <div className="text-primary fw-bold">{homeSafetyData?.adequateHomeEnvironmentForCareRecertification ? homeSafetyData?.adequateHomeEnvironmentForCareRecertification : "N/A"}</div>}
                        {!acknowledgeMode && <ToggleSwitch values={["Yes", "N/A", "No"]} selected={homeSafetyData?.adequateHomeEnvironmentForCareRecertification ? homeSafetyData?.adequateHomeEnvironmentForCareRecertification : "N/A"} name="adequateHomeEnvironmentForCareRecertification" onChange={changeHandler} />}
                    </div>
                </div>
            </>
        </section>
    );
};

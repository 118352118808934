import { Link } from "react-router-dom";

export const Admin = () => {
    return (
        <section id="Admin">
            <div className="row">
                <div className="col-md-10">
                    <div className="row">
                        <div className="col-md-4">
                            <h4 className="text-primary py-3">Global Settings</h4>
                        </div>
                    </div>

                    <div className="row">
                        <div>
                            <div class="my-2 medium-style" style={{ paddingLeft: "2%", marginBottom: "0 !important" }}>
                                <h6>Service Portal</h6>
                            </div>
                            <span style={{ backgroundColor: "#eee", padding: "2%", marginLeft: "3.8%", display: "block" }} class="m-3 medium-style">
                                Managed Services
                            </span>
                        </div>
                        <div className="col-md-12">
                            <ul>
                                <li>
                                    <Link to="/admin/limitation">Basic Intake Client Limitation List</Link>
                                </li>
                                <li>
                                    <Link to="/admin/need">Basic Intake Client Need List</Link>
                                </li>
                                <li>
                                    <Link to="/admin/branch">Branch List</Link>
                                </li>
                                <li>
                                    <Link to="/admin/casetype">Case Type List</Link>
                                </li>
                                <li>
                                    <Link to="/admin/jobtitle">Job Title</Link>
                                </li>
                                <li>
                                    <Link to="/admin/hospice">Hospice List</Link>
                                </li>
                                <li>
                                    <Link to="/admin/hospital">Hospital List</Link>
                                </li>
                                <li>
                                    <Link to="/admin/product">Product List</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

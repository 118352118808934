import React, { useState, useEffect } from "react";
import { callAPI } from "../../../lib/Global.js";
import LifestyleBackground from "./LifestyleBackground.js";
import styles from "./clientInfo.module.css";
import "../../../styles/common.css";

const LifeStyleDetails = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [LifeStyleData, setLifeStyleData] = useState({});
    const [showClientLifestyleBackgroundModal, setShowClientLifestyleBackgroundModal] = useState(false);

    useEffect(() => {
        const clientId = props.clientId;
        getClientLifestyleAndBackGroundDetailsById(clientId);
    }, []);

    const getClientLifestyleAndBackGroundDetailsById = async (clientId) => {
        const r = await callAPI("GetClientLifestyleByClientId", { id: clientId });
        setIsLoading(false);
        r && setLifeStyleData(r);
    };

    const toggleLifestyleBackgroundModal = async (isDataReload) => {
        if (isDataReload) {
            await getClientLifestyleAndBackGroundDetailsById(props.clientId);
        }
        setShowClientLifestyleBackgroundModal(!showClientLifestyleBackgroundModal);
        props.togglebackDrop();
    };

    const { livingSituation = "", othersLivingWithClientName = "", smoking = "", relationship = "", language = "", religion = "", pet = "", occupation = "", education = "", howOftenEnjoysOutdoors = "", enjoySocializing = "" } = LifeStyleData;

    let lang = "";

    language.split(",").map((i, k) => {
        lang += i + ", ";
    });

    if (isLoading) {
        return <div>Please wait...</div>;
    }

    return (
        <div>
            {showClientLifestyleBackgroundModal && <LifestyleBackground LifeStyleData={LifeStyleData} closeClientIdentityModal={toggleLifestyleBackgroundModal} clientId={props.clientId} />}
            <h5 className="bold-style" style={{ fontSize: "14px" }}>
                Lifestyle & Background
            </h5>
            <hr />
            <div className="row mt-3">
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Living situation
                    </label>
                    <span className="regular-style text-primary">{livingSituation}</span>
                </div>
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Others living with client
                    </label>
                    <span className="regular-style text-primary">
                        {relationship}-{othersLivingWithClientName}
                    </span>
                </div>
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Smoker?
                    </label>
                    <span className="regular-style text-primary">{smoking}</span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Language
                    </label>
                    <span className="regular-style text-primary">{lang.replace(/,\s*$/, "")}</span>
                </div>
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Religion
                    </label>
                    <span className="regular-style text-primary">{religion}</span>
                </div>
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Pet?
                    </label>
                    <span className="regular-style text-primary">{pet} </span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Education
                    </label>
                    <span className="regular-style text-primary">{education}</span>
                </div>
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Enjoys Outdoor?
                    </label>
                    <span className="regular-style text-primary">{howOftenEnjoysOutdoors}</span>
                </div>
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Enjoys Socializing?
                    </label>
                    <span className="regular-style text-primary">{enjoySocializing}</span>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-4">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Occupation
                    </label>
                    <span className="regular-style text-primary">{occupation} </span>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-3">
                    <span className="btn btn-sm btn-primary" onClick={() => toggleLifestyleBackgroundModal(false)}>
                        Edit Lifestyle & Background
                    </span>
                </div>
            </div>
            <hr />
        </div>
    );
};

export default LifeStyleDetails;

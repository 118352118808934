import { Modal } from "react-bootstrap";
import Select from "react-select";

export const UserGroupEditDialog = ({ userGroup, setUserGroup, show, setShow, handleSaveClick, handleClose, listUsers }) => {
    //=== Handlers ===========================================================

    const handleSave = () => {
        handleSaveClick({ userGroupName: userGroup?.name, users: userGroup?.users });
        setShow(false);
    };

    const nameChangeHandler = (e) => {
        const nameNew = e.target.value;
        setUserGroup({ ...userGroup, name: nameNew });
    };

    const usersChangeHandler = (e) => {
        const usersNew = e?.map((i) => i.value);
        setUserGroup({ ...userGroup, users: usersNew });
    };

    //========================================================================

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="text-primary">User Group</Modal.Title>
            </Modal.Header>
            <form>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <span className="my-1 d-block">Name</span>
                            <input type="text" className="form-control" placeholder={`Enter Name`} value={userGroup?.name} name="name" onChange={nameChangeHandler} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="users" className={`mb-1`}>
                                Users
                            </label>
                            <Select isMulti menuPlacement="top" options={listUsers} value={userGroup?.users?.map((usr) => listUsers?.find((i) => i.value === usr))} onChange={usersChangeHandler}></Select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
                        Close
                    </button>
                    <button type="button" className="btn btn-primary" disabled={false} onClick={handleSave}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

import { AuthApis } from "../Modules/Auth/Constants/Constants";
import { ServerApis } from "../Modules/HCC/Constants/Constants";
import { AdminApis } from "../Modules/Admin/Constants/Constants";
import StaticProperties from "../utils/StaticProperties";
import moment from "moment";

const apis = {
    ...AuthApis,
    ...ServerApis,
    ...AdminApis,
};

export const ReCaptcha_SITE_KEY = "6LdpxgscAAAAABagJLGwm1m2TFmEkvaGI4Y3MoYa";

export const callAPI = async (url, body, http_verb = "POST") => {
    // return new Promise(resolve => { resolve({ isLoginOtpValid: true, token: 'Test Token' }); });
    const token = sessionStorage.getItem("token");

    const headers = {
        "Content-Type": "application/json",
        Authorization: null,
    };
    if (token) {
        headers.Authorization = "bearer " + token.substring(0, token.length - 3).substring(3);
    }
    return fetch(apis[url], {
        method: http_verb,
        headers: headers,
        body: JSON.stringify(body),
    })
        .then((data) => data.json())
        .catch((c) => console.error(c));
};

export const callAPIforCounty = async (state, http_verb = "GET") => {
    const url = "https://cpa.caringpeopleinc.com/cpapi/api/v1/County/GetCountyDropDownListByStateCode/" + state;
    const headers = {
        ApiKey: "d2e53581-9997-48de-8316-c554f8c8e908",
    };
    return fetch(url, {
        method: http_verb,
        headers: headers,
    })
        .then((data) => data.json())
        .catch((c) => console.error(c));
}

export const callAPIForCityStateByZipCode = async (zipCode, http_verb = 'POST') => {
    const uspsUserId = "369CARIN5493"; // Its Pen Jackson's id
    const xmlStrParam = `<CityStateLookupRequest USERID=%22${uspsUserId}%22><ZipCode ID=%220%22><Zip5>${zipCode}</Zip5></ZipCode></CityStateLookupRequest>`;
    const url = `https://production.shippingapis.com/ShippingApi.dll?API=CityStateLookup&XML=${xmlStrParam}`;
    return fetch(url, {
        method: http_verb,
    })
        .then(data => data.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(data => xmlToJson(data))
        .catch(c => console.error(c));
}

export const callAPIforFileUpload = async (url, data) => {
    // return new Promise(resolve => { resolve({ isLoginOtpValid: true, token: 'Test Token' }); });
    const token = sessionStorage.getItem("token");
    const headers = {
        Authorization: null,
    };
    if (token) {
        headers.Authorization = "bearer " + token.substring(0, token.length - 3).substring(3);
    }
    return fetch(apis[url], {
        method: "POST",
        headers: headers,
        body: data,
    })
        .then((data) => data.json())
        .catch((c) => console.error(c));
};

export const callAPIWithpathParameter = async (url, pathParams, method, data, module = "HCC") => {
    // return new Promise(resolve => { resolve({ isLoginOtpValid: true, token: 'Test Token' }); });

    const pathParamsString = typeof pathParams === "string" ? pathParams : pathParams.join("/");

    const apiUrlTemp = apis[url];
    const apiUrlTemp2 = apiUrlTemp.split("?code=");
    const apiUrl = pathParamsString ? `${apiUrlTemp2[0]}/${pathParamsString}?code=${apiUrlTemp2[1]}` : `${apiUrlTemp2[0]}?code=${apiUrlTemp2[1]}`;

    const token = sessionStorage.getItem("token");
    const headers = {
        Authorization: null,
    };
    if (token) {
        headers.Authorization = "bearer " + token.substring(0, token.length - 3).substring(3);
    }
    return fetch(apiUrl, {
        method: method,
        headers: headers,
    })
        .then((data) => data.json())
        .catch((c) => console.error(c));
};

export const getQueryStringValue = (variable) => {
    var query = window.location.search.substring(1);
    if (!query) {
        return false;
    }

    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) {
            return pair[1];
        }
    }

    return false;
};

export const verifyReCaptcha = (action) => {
    return new Promise((res, rej) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(ReCaptcha_SITE_KEY, { action }).then(async (token) => {
                const v = await callAPI("VerifyReCaptcha", { token });
                return res(v);
            });
        });
    });
};

export const removeRecaptcha = () => {
    const recaptchaScript = document.getElementById("recaptcha-key");
    if (recaptchaScript) {
        recaptchaScript.remove();
    }

    const recaptchaElement = document.getElementsByClassName("grecaptcha-badge");
    if (recaptchaElement.length) {
        recaptchaElement[0].remove();
    }
};

export const isFileWithInMBof = (fileSize, maxSizeInMB) => {
    return fileSize / (1024 * 1024) <= maxSizeInMB;
};

export const dateValidator = (control) => {
    if (control.value) {
        if (moment(control.value).isValid()) {
            return null;
        } else {
            return { isExist: true };
        }
    }
    return null;
};

export let userRoles = [];

const getLoggedInUsersRoles = () => {
    return StaticProperties.loggedInUsersRolePermission; // read role from jwt
    // return global.userRoles;
};

// this is hard code user roles for implementing role based accessibility functionality
const allUserRoles = [];

let ALLOWED_MODULE_SUBJECTS_SCOPES = [];

// const MODULES = [
//     'client',
//     'Admin',
// ];

// this is for building a custom common array for handling all the functionalities
// export const buildAllowedModuleSubjectScopeArr = (pageName = '') => {
//     let allUserRoles = getLoggedInUsersRoles();
//     console.log(allUserRoles);
//     ALLOWED_MODULE_SUBJECTS_SCOPES = [];
//     ALLOWED_MODULE_SUBJECTS_SCOPES.push(...hardCodeRoles);

//     allUserRoles?.forEach(r => {
//         r?.permissions?.forEach(p => {
//             ALLOWED_MODULE_SUBJECTS_SCOPES.push({
//                 // 'purpose': 'module/page/others',
//                 'purpose': p.purpose || 'Module',
//                 'module': p.module,
//                 'page': p.pageName || 'All',
//                 'type': p.type,
//                 'action': p.action || 'All',
//                 'subject': p.subject || 'All',
//                 'scope': p.scope,
//                 // 'allowedRoles': mn === 'Clients' ? clientRoleArr : adminRoleArr,
//                 'allowedRoles': r.roleName // ['ClientRole1', 'ClientRole2']
//             });
//         });
//     });

//     ALLOWED_MODULE_SUBJECTS_SCOPES = ALLOWED_MODULE_SUBJECTS_SCOPES.forEach(f => {

//     });

/*
    MODULES.map((mn, k) => {

        let clientRoleArr = [];
        let adminRoleArr = [];

        allUserRoles?.forEach((i, j) => {

            i?.permissions?.forEach((m, b) => {

                // if (m.module === 'Clients')
                //     clientRoleArr.push(i.roleName)
                // if (m.module === 'Admin')
                //     adminRoleArr.push(i.roleName)

                if (m.module === mn) {
                    ALLOWED_MODULE_SUBJECTS_SCOPES.push({
                        // 'purpose': 'module/page/others',
                        'purpose': m.purpose,
                        'module': m.module,
                        'page': pageName,
                        'type': m.type,
                        'action': m.action,
                        'subject': m.subject,
                        'scope': m.scope,
                        // 'allowedRoles': mn === 'Clients' ? clientRoleArr : adminRoleArr,
                        'allowedRoles': ['ClientRole1', 'ClientRole2']
                    });
                }
            })
        })

    });*/

export const canModuleAllowed = (m) => {
    if (!m) return false;
    const userRoles = getLoggedInUsersRoles();

    const restrincted = userRoles.some((s) => s.permissions.some((p) => p.module === m && p.purpose === "Module" && p.type === "Restriction"));
    if (restrincted) return false;

    return userRoles.some((s) => s.permissions.some((p) => p.module === m && p.purpose === "Module" && p.type === "Permission"));
};

export const canPageAllowed = (m, page) => {
    if (!page || !m) return false;
    const userRoles = getLoggedInUsersRoles();

    const restrincted = userRoles.some((s) => s.permissions.some((p) => p.module === m && p.pageName === page && p.purpose === "Page" && p.type === "Restriction"));
    if (restrincted) return false;

    return userRoles.some((s) => s.permissions.some((p) => p.module === m && p.pageName === page && p.purpose === "Page" && p.type === "Permission"));
};

export const canSubjectAllowed = (m, page, subject) => {
    if (!page || !m || !subject) return false;
    const userRoles = getLoggedInUsersRoles();

    const restrincted = userRoles.some((s) => s.permissions.some((p) => p.module === m && p.pageName === page && p.subject === subject && p.purpose === "others" && p.type === "Restriction"));
    if (restrincted) return false;

    return userRoles.some((s) => s.permissions.some((p) => p.module === m && p.pageName === page && p.subject === subject && p.purpose === "others" && p.type === "Permission"));
};

export const canScopeVisible = (m, page, subject, scope) => {
    if (!page || !m || !subject || !scope) return false;
    const userRoles = getLoggedInUsersRoles();

    const restrincted = userRoles.some((s) => s.permissions.some((p) => p.module === m && p.pageName === page && p.subject === subject && p.scope === scope && p.purpose === "others" && p.type === "Restriction"));
    if (restrincted) return false;

    return userRoles.some((s) => s.permissions.some((p) => p.module === m && p.pageName === page && p.subject === subject && p.scope === scope && p.purpose === "others" && p.type === "Permission"));
};

// calling method for building custom Arr.
// buildAllowedModuleSubjectScopeArr();

// this is for checking module accessibility in allowed permissions
export const canModuleAllowed1 = (m) => {
    userRoles = getLoggedInUsersRoles();

    // Restriction
    const restrictedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.purpose === "module" && i.type === "restriction")?.map((m1) => m1.allowedRoles);
    const isRestictedForMe = userRoles.every((a) => restrictedRoles.includes(a));
    console.log(userRoles);
    console.log(restrictedRoles);
    console.log(isRestictedForMe);
    if (isRestictedForMe) {
        return false;
    }

    // remove restricted roles.

    const newUserRoles = userRoles.filter((f) => !restrictedRoles.includes(f));
    console.log(newUserRoles);
    // Permission
    const permittedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.purpose === "module" && i.type === "permission")?.map((m1) => m1.allowedRoles);
    console.log(newUserRoles.some((a) => permittedRoles.includes(a)));
    return newUserRoles.some((a) => permittedRoles.includes(a));
};

// this is for checking page accessibility in allowed permissions
export const canPageAllowed1 = (m, p) => {
    userRoles = getLoggedInUsersRoles();

    // Restriction
    const restrictedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.purpose === "page" && i.type === "restriction")?.map((m1) => m1.allowedRoles);
    const isRestictedForMe = userRoles.every((a) => restrictedRoles.includes(a));

    if (isRestictedForMe) {
        return false;
    }

    // remove restricted roles.

    const newUserRoles = userRoles.filter((f) => !restrictedRoles.includes(f));

    // Permission
    const permittedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.purpose === "page" && i.type === "permission")?.map((m1) => m1.allowedRoles);
    return newUserRoles.some((a) => permittedRoles.includes(a));
};

// this is for checking subject accessibility in allowed permissions based on type=permissions/restriction
export const canSubjectClickable1 = (m, p, s) => {
    userRoles = getLoggedInUsersRoles();

    // Restriction
    const restrictedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.type === "restriction" && i.purpose === "others")?.map((m1) => m1.allowedRoles);
    const isRestictedForMe = userRoles.every((a) => restrictedRoles.includes(a));

    if (isRestictedForMe) {
        return false;
    }

    // remove restricted roles.

    const newUserRoles = userRoles.filter((f) => !restrictedRoles.includes(f));

    // Permission
    const permittedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.type === "permission" && i.purpose === "others")?.map((m1) => m1.allowedRoles);
    return newUserRoles.some((a) => permittedRoles.includes(a));
};

// this is for checking scope accessibility in allowed permissions based on type=permissions/restriction
export const canScopeVisible1 = (m, p, s, scope) => {
    userRoles = getLoggedInUsersRoles();

    // Restriction
    const restrictedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.purpose === "others" && i.type === "restriction")?.map((m1) => m1.allowedRoles);
    const isRestictedForMe = userRoles.every((a) => restrictedRoles.includes(a));

    if (isRestictedForMe) {
        return false;
    }

    // remove restricted roles.

    const newUserRoles = userRoles.filter((f) => !restrictedRoles.includes(f));

    // Permission
    const permittedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.purpose === "others" && i.type === "permission")?.map((m1) => m1.allowedRoles);
    return newUserRoles.some((a) => permittedRoles.includes(a));
};

// this is for action=create/manage in allowed permissions
export const canActionCreateAllowed = (m, p, s) => {
    userRoles = getLoggedInUsersRoles();

    // Restriction
    const restrictedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.type === "restriction" && i.purpose === "others" && (i.action === "create" || i.action === "manage"))?.map((m1) => m1.allowedRoles);
    const isRestictedForMe = userRoles.every((a) => restrictedRoles.includes(a));

    if (isRestictedForMe) {
        return false;
    }

    // remove restricted roles.

    const newUserRoles = userRoles.filter((f) => !restrictedRoles.includes(f));

    // Permission
    const permittedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.type === "permission" && i.purpose === "others" && (i.action === "create" || i.action === "manage"))?.map((m1) => m1.allowedRoles);
    return newUserRoles.some((a) => permittedRoles.includes(a));
};

// this is for action=delete/manage in allowed permissions
export const canActionDeleteAllowed = (m, p, s) => {
    userRoles = getLoggedInUsersRoles();

    // Restriction
    const restrictedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.type === "restriction" && i.purpose === "others" && (i.action === "delete" || i.action === "manage"))?.map((m1) => m1.allowedRoles);
    const isRestictedForMe = userRoles.every((a) => restrictedRoles.includes(a));

    if (isRestictedForMe) {
        return false;
    }

    // remove restricted roles.

    const newUserRoles = userRoles.filter((f) => !restrictedRoles.includes(f));

    // Permission
    const permittedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.type === "permission" && i.purpose === "others" && (i.action === "delete" || i.action === "manage"))?.map((m1) => m1.allowedRoles);
    return newUserRoles.some((a) => permittedRoles.includes(a));
};

// this is for action=update/manage in allowed permissions
export const canActionUpdateAllowed = (m, p, s) => {
    userRoles = getLoggedInUsersRoles();

    // Restriction
    const restrictedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.type === "restriction" && i.purpose === "others" && (i.action === "update" || i.action === "manage"))?.map((m1) => m1.allowedRoles);
    const isRestictedForMe = userRoles.every((a) => restrictedRoles.includes(a));

    if (isRestictedForMe) {
        return false;
    }

    // remove restricted roles.

    const newUserRoles = userRoles.filter((f) => !restrictedRoles.includes(f));

    // Permission
    const permittedRoles = ALLOWED_MODULE_SUBJECTS_SCOPES.filter((i) => i.module === m && i.page === p && i.subject === s && i.type === "permission" && i.purpose === "others" && (i.action === "update" || i.action === "manage"))?.map((m1) => m1.allowedRoles);
    return newUserRoles.some((a) => permittedRoles.includes(a));
};

export const userWithAssignedRolesPermission = (userRoles) => {
    let allUserRolewithPerm = StaticProperties.allUserRoles;

    return allUserRolewithPerm.filter((a) => userRoles.includes(a.roleName));
};

export const redirectToLandingPage = (module) => {
    const v = canModuleAllowed(module) ? "/clients" : "/home";
    return v;
};

export const phoneNumberWithDashes = (number) => {
    return number?.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
};

export const getMimetypeForAFile = (extension) => {
    let mimeType = "";
    switch (extension.toLowerCase()) {
        case "pdf":
            mimeType = "data:application/pdf;base64,";
            break;
        case "png":
            mimeType = "data:image/png;base64,";
            break;
        case "jpeg":
            mimeType = "data:image/jpeg;base64,";
            break;
        case "jpg":
            mimeType = "data:image/jpeg;base64,";
            break;
        case "txt":
            mimeType = "data:text/plain;base64,";
            break;
        case "doc":
            mimeType = "data:application/msword;base64,";
            break;
        case "bmp":
            mimeType = "data:image/bmp;base64,";
            break;
        default:
            break;
    }
    return mimeType;
}


export function xmlToJson(xml) {

    // Create the return object
    var obj = {};

    if (xml.nodeType == 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
            obj["@attributes"] = {};
            for (var j = 0; j < xml.attributes.length; j++) {
                var attribute = xml.attributes.item(j);
                obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (xml.nodeType == 3) { // text
        obj = xml.nodeValue;
    }

    // do children
    if (xml.hasChildNodes()) {
        for (var i = 0; i < xml.childNodes.length; i++) {
            var item = xml.childNodes.item(i);
            var nodeName = item.nodeName;
            if (typeof (obj[nodeName]) == "undefined") {
                obj[nodeName] = xmlToJson(item);
            } else {
                if (typeof (obj[nodeName].push) == "undefined") {
                    var old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(xmlToJson(item));
            }
        }
    }
    return obj;
};

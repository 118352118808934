import React, { useState, useEffect } from "react";
import styles from '../../../styles/common.module.css';
import { callAPI } from '../../../lib/Global';

const AutoComplete = ({ history }) => {

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");
  const [clientSearchData, setClientSearchData] = useState("");
  const [clientId, setClientId] = useState('');

  useEffect(() => {

    const timeoutId = setTimeout(() => {    
      if(input) {    
        getClientsInfo(input?.trim())  
      }    

    }, 200)
    
    return () => clearTimeout(timeoutId)
    
    }, [input]);


  const getClientsInfo = async (userInput) => {

    // here fetching data from API
    const r = await callAPI('SearchClientForAutocomplte', { id: userInput });
    const arr = [];

    if (r) {
      r.filter(
        (k, v) => {
          arr[v] = k.label;
        }
      );

      setClientSearchData(r);

      setFilteredSuggestions(arr);
      setActiveSuggestionIndex(0);
      setShowSuggestions(true);

    }

  }

  // here, navigating to the selected client by mouse click
  const onClientSelection = (e) => {
    const v = e.currentTarget.id;
    redirectToCP(v);
  };

  const redirectToCP = (v) => {
    history.push('/');
    setTimeout(() => {
      history.push('/clientprofile/' + v);

      setFilteredSuggestions([]);
      setInput('');
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    }, 50);
  }

  // here, moving up and down the dropdown list of clients results.
  const onKeyDown = (e) => {

    // User pressed the enter key
    e.keyCode === 13 && redirectToCP(clientId);

    // User pressed the left arrow
    if (e.keyCode === 37) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);

    }

    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);

    }

    // User pressed the right arrow
    else if (e.keyCode === 39) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);

    }

    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  // here, listing of all suggestions are displayed.
  const SuggestionsListComponent = ({ clientSearchData }) => {

    return filteredSuggestions.length ? (
      <ul className={styles.suggestions}>
        {filteredSuggestions.map((suggestion, index) => {
          let className;

          // Flag the active suggestion with active class
          if (index === activeSuggestionIndex) {
            setClientId('');
            className = styles.suggestionActive;
            setClientId(clientSearchData[activeSuggestionIndex]?.id);
          }

          return (
            <li className={className} id={clientSearchData[index]?.id} key={index} onClick={onClientSelection} style={{
              padding: '0.5rem',
              fontSize: '0.9rem',
              fontWeight: '500'
            }} onKeyDown={() => console.log('li keydown')}>
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className={styles.noSuggestions}>
        <em>No suggestions found.</em>
      </div>
    );
  };

  return (
    <>
      <input
        type="text"
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={onKeyDown}
        value={input}
        className={`form-control ${styles.autoCompleteInput}`}
        placeholder="Search"
      />
      {showSuggestions && input && <SuggestionsListComponent clientSearchData={clientSearchData} />}
    </>
  );
};

export default AutoComplete;
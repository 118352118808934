import React, { useState, useEffect } from 'react';
import { callAPI } from '../../../lib/Global.js';
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import TextInput from '../../../Controls/TextInput.js';
import TextArea from '../../../Controls/TextArea.js';
import SelectInput from '../../../Controls/SelectInput.js';
import CheckBox from '../../../Controls/CheckBox.js';
import { swalAlert, swalToast } from '../../../lib/Swal.js';
import styles from '../../../styles/common.module.css';

const UpdateClientBranchCardModal = (props) => {

    const { currentClientBranch, branchList } = props;

    const [isLoading, setisLoading] = useState(false);

    const closeModal = () => {
        props.toggleClientBranchCardModal();
    }

    const handleSave = async (e) => {
        props.newClientBranch(clientBranchesForm.value.clientBranches);
        props.toggleClientBranchCardModal();
    }

    const clientBranchesForm = FormBuilder.group({
        clientBranches: [null, [Validators.required, Validators.maxLength(255)]]
    });

    useEffect(() => {
        clientBranchesForm.patchValue({
            clientBranches: currentClientBranch?.value
        });
    }, []);

    console.log(branchList)

    return (
        <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-success" id="exampleModalLabel">Client Branch</h5>
                        <i className="bi bi-x-lg" onClick={() => closeModal()} style={{ cursor: 'pointer' }}></i>
                    </div>
                    <div className="modal-body">
                        <FieldGroup
                            control={clientBranchesForm}
                            render={({ get, invalid, pristine }) => (
                                <form>
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <FieldControl
                                                name="clientBranches"
                                                render={SelectInput}
                                                meta={{
                                                    label: "",
                                                    options: branchList
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <div className="d-grid gap-2 pb-1">
                                            <button type="button" onClick={() => handleSave()} className="btn btn-primary" disabled={invalid || pristine || isLoading}>{isLoading ? 'Please wait...' : 'Save'}</button>
                                            {/* <button type="button" onClick={() => closeModal(false)} className="btn btn-link">Cancel</button> */}
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateClientBranchCardModal;
import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import { callAPI, callAPIWithpathParameter } from '../../../lib/Global.js';
import { swalAlert, swalToast, swalToast2 } from '../../../lib/Swal.js';
import SelectInput from '../../../Controls/SelectInput.js';
import DateInput from '../../../Controls/DateInput.js';
import TextArea from '../../../Controls/TextArea.js';
import styles from './clientInfo.module.css';
import '../../../styles/common.css';


class ClientStatusModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            viewTable: false,
            isLoading: false,
            statusHistoryThree: [],
            statusHistoryList: [],
            branchName: null,
        }
    }


    async componentDidMount() {
        const { currentClientStatus, clientBranchId, branchesListArr } = this.props;
        this.populateClientStatusForm(currentClientStatus);
        await this.getTopthreeStatusHistory(this.props.clientId);
        let branch = branchesListArr.find(branch => branch.value === clientBranchId)
        this.setState({ branchName: branch?.text });
    }

    populateClientStatusForm = (currentClientStatus) => {
        if (currentClientStatus) {
            this.clientStatusForm.patchValue({ ...currentClientStatus, id: null, statusChangeDate: moment(currentClientStatus?.statusChangeDate).format('YYYY-MM-DD') });
        } else {
            this.clientStatusForm.patchValue({ clientId: this.props.clientId });
        }
    }

    getTopthreeStatusHistory = async (clientId) => {
        this.setState({ viewTable: false });
        const r = await callAPIWithpathParameter('ClientStatus', clientId + '/' + '3', 'GET', '');
        // let updateStatusHistory = this.statusHistoryWithLeadStatus(r.data);
        this.setState({ statusHistoryList: r.data });
        this.clientStatusForm.updateValueAndValidity(this.clientStatusForm.value);
    }

    clientStatusForm = FormBuilder.group({
        id: null,
        clientId: [null, Validators.required],
        status: [null, Validators.required],
        statusChangeReason: [null, Validators.required],
        statusChangeDate: null,
        additionalNotes: null,
    });

    showFullTable = async () => {
        this.setState({ viewTable: true });
        const r = await callAPIWithpathParameter('ClientStatus', this.props.clientId, 'GET', '');
        // let updateStatusHistory = this.statusHistoryWithLeadStatus(r.data);
        this.setState({ statusHistoryList: r.data });
        this.clientStatusForm.updateValueAndValidity(this.clientStatusForm.value);
    }

    // statusHistoryWithLeadStatus = (statusHistoryList) => {
    //     let updateStatusHistory = statusHistoryList?.map(statusHistory => {
    //         statusHistory.leadStatus = this.props.leadStatus;
    //         return statusHistory;
    //     });
    //     return updateStatusHistory;
    // }

    handleSave = async (e) => {
        this.setState({ isLoading: true });
        this.clientStatusForm.updateValueAndValidity(this.clientStatusForm.value);
        const r = await callAPI('CreateClientStatus', this.clientStatusForm.value);
        this.setState({ isLoading: false });

        if (r.success) {
            swalToast2('Status changed successfully.');
            this.props.closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    render() {
        const { status, primaryContactFirstName, primaryContactLastName, clientId } = this.props;
        return (
            <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="exampleModalLabel">Status</h5>
                            <i className="bi bi-x-lg" onClick={() => this.props.closeModal(false)} style={{ cursor: 'pointer' }}></i>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Client Name</label>
                                    <span>{primaryContactFirstName} {primaryContactLastName}</span>
                                </div>
                                <div className="col-6">
                                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Managing Branch</label>
                                    <span>{this.state.branchName}</span>
                                </div>
                            </div>
                            <FieldGroup
                                control={this.clientStatusForm}
                                render={({ get, invalid, pristine }) => (
                                    <form>
                                        {!this.state.viewTable && <>
                                            <div className="row mb-2">
                                                <div className="col-6">
                                                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Current Status</label>
                                                    <span className="semi-bold-style" style={{ fontSize: '14px', color: '#3CAF8A' }}>{status}</span>
                                                </div>
                                                <div className="col-6">
                                                    <FieldControl
                                                        name="status"
                                                        render={SelectInput}
                                                        meta={{
                                                            label: "New status",
                                                            options: [
                                                                { text: 'Active' },
                                                                { text: 'Discharged' },
                                                                { text: 'Hold' }
                                                            ]
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">
                                                    <FieldControl
                                                        name="statusChangeReason"
                                                        render={SelectInput}
                                                        meta={{
                                                            label: "Status change reason",
                                                            options: [
                                                                { text: 'Hospitalized (Long Term)' },
                                                                { text: 'Dissatisfied with Service' },
                                                                { text: 'Deceased' }
                                                            ]
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <FieldControl
                                                        name="statusChangeDate"
                                                        render={DateInput}
                                                        meta={{ label: "Status change date" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-12">
                                                    <FieldControl
                                                        name="additionalNotes"
                                                        render={TextArea}
                                                        meta={{ label: "Additional notes" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2 mt-4">
                                                <div className="col-6">
                                                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Status History</label>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <span className="bold-color-style" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.showFullTable()}>View All</span>
                                                </div>
                                            </div>
                                        </>}
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <table className="table table-sm table-striped table-borderless">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"> <span className={styles.labelStyling} style={{ fontSize: '11px', textDecoration: 'underline' }}>Status</span></th>
                                                            <th scope="col"><span className={styles.labelStyling} style={{ fontSize: '11px', textDecoration: 'underline' }}>Changed By</span></th>
                                                            <th scope="col"><span className={styles.labelStyling} style={{ fontSize: '11px', textDecoration: 'underline' }}>Reason</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.statusHistoryList?.map((d, i) => {
                                                            return (<tr key={i}>
                                                                <td width="30%">{d.status}</td>
                                                                <td width="30%">{d.updateBy}</td>
                                                                <td>{d.statusChangeReason}</td>
                                                            </tr>)
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row text-lg-center pb-1">
                                            <div className="col-12">
                                                {!this.state.viewTable ? <button type="button" onClick={() => this.handleSave()} className="btn btn-primary" disabled={invalid || pristine}> {this.state.isLoading ? "Please wait..." : "Continue"}</button>
                                                    : <span className="bold-color-style" style={{ textDecoration: 'underline', cursor: 'pointer', color: '#666666' }} onClick={() => this.getTopthreeStatusHistory(clientId)}>Back</span>}
                                            </div>
                                        </div>
                                    </form>

                                )}

                            />
                            {/* {this.state.viewTable &&
                                <>
                                    <table class="table table-sm table-striped table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col"> <span className={styles.labelStyling} style={{ fontSize: '11px', textDecoration: 'underline' }}>Status</span></th>
                                                <th scope="col"><span className={styles.labelStyling} style={{ fontSize: '11px', textDecoration: 'underline' }}>Changed By</span></th>
                                                <th scope="col"><span className={styles.labelStyling} style={{ fontSize: '11px', textDecoration: 'underline' }}>Reason</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.statusHistoryList?.map((d, i) => {
                                                return (<tr key={i}>
                                                    <td width="30%">{d.status}</td>
                                                    <td width="30%">{d.updateBy}</td>
                                                    <td width="30%">{d.statusChangeReason}</td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="row mb-2 mt-4">
                                        <div className="col-12 text-center">
                                            <span className="bold-color-style" style={{ textDecoration: 'underline', cursor: 'pointer', color: '#666666' }} onClick={() => this.getTopthreeStatusHistory(clientId)}>Back</span>
                                        </div>
                                    </div></>} */}

                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default ClientStatusModal;
import { Http_BadRequest, Http_OK } from "../../../../../../lib/httpStatusCodes";
import { sortJson } from "../../../../../../lib/sortJson";

const apiUrl = `${process.env.REACT_APP_AUTH_BASE_URL}JobTitle`;
//const apiUrl = `${process.env.REACT_APP_LOCAL_BASE_URL}JobTitle`;

//apiUrl = "http://localhost:7073/api/jobtitle";

//=======================================================================

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: null,
};

export const getAllJobTitles = () => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}${process.env.REACT_APP_AUTH_KEY_CODE}`, { method: "GET", headers: headers })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                let data = sortJson(json.data, "name", "string", true);
                                return res(data);
                            });
                            break;
                        default:
                            rej(`Error loading data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => {
                reject(err);
            });
    });
};

//=======================================================================

export const createJobTitles = (data) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}${process.env.REACT_APP_AUTH_KEY_CODE}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to create data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const deleteJobTitles = (id) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${apiUrl}/${id}${process.env.REACT_APP_AUTH_KEY_CODE}`, { method: "DELETE", headers: headers })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to delete data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

import "../clientHealthAssessment.css";
import { useEffect, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { getClientHealthAssessmentFallRiskToolOptionsList, getClientHealthAssessmentLaceToolOptionsList } from "../clientHealthAssessmentListRest";
import { ClientHALaceTool } from "./clientHALaceTool";
import { ClientHAFallRiskTool } from "./clientHAFallRiskTool";
import { ClientHAKatzIndex } from "./clientHAKatzIndex";
import { ClientHAHomeSafety } from "./clientHAHomeSafety";
import { Loading } from "../../../../../Shared/loading/loading";

export const ClientHealthAssessmentDialog = ({ id, data, setData, show, setShow, readOnly, acknowledgeMode, handleAcknowledgeClick, handleSaveClick, handleClose }) => {
    //=== States =============================================================

    //Fall Risk tool Options List state
    const [fallRiskToolOptionsList, setFallRiskToolOptionsList] = useState({
        fallRiskFactorList: [],
        getUpAndGoTestList: [],
    });

    //Lace tool Options List state
    const [laceToolOptionsList, setLaceToolOptionsList] = useState({
        comorbidityList: [],
        emergencyVisitList: [],
        lengthOfStayList: [],
    });

    //fall Risk data state
    const [fallRiskData, setFallRiskData] = useState(data.fallRisk);

    //Home Safety data state
    const [homeSafetyData, setHomeSafetyData] = useState(data.homeSafety);

    //Katz Index data state
    const [katzIndexData, setKatzIndexData] = useState(data.katzIndex);

    //lace tool data state
    const [laceToolData, setLaceToolData] = useState(data.laceTool);

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //errors state
    const [loadingError, setLoadingError] = useState(null); // eslint-disable-line

    //Use Effects ===========================================================

    //Loading lace tool and fallRisk dropdown options...
    useEffect(() => {
        (async () => {
            await loadFallRiskToolOptionsList();
            await loadLaceToolOptionsList();
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //updating health assessment data when Fall Risk data changes ...
    useEffect(() => {
        const newData = { ...data, fallRisk: fallRiskData };
        setData(newData);
    }, [fallRiskData]); // eslint-disable-line react-hooks/exhaustive-deps

    //updating health assessment data when Home Safety data changes ...
    useEffect(() => {
        const newData = { ...data, homeSafety: homeSafetyData };
        setData(newData);
    }, [homeSafetyData]); // eslint-disable-line react-hooks/exhaustive-deps

    //updating health assessment data when Katz Index Tool data changes ...
    useEffect(() => {
        const newData = { ...data, katzIndex: katzIndexData };
        setData(newData);
    }, [katzIndexData]); // eslint-disable-line react-hooks/exhaustive-deps

    //updating health assessment data when Lace Tool data changes ...
    useEffect(() => {
        const newData = { ...data, laceTool: laceToolData };
        setData(newData);
    }, [laceToolData]); // eslint-disable-line react-hooks/exhaustive-deps

    //=== Handlers ===========================================================

    const handleSave = () => {
        const newData = {
            ...data,
            id: id,
        };

        handleSaveClick(newData);
        setShow(false);
    };

    const handleAcknowledge = () => {
        handleAcknowledgeClick(id);
        setShow(false);
    };

    //=== Methods ============================================================

    const isError = (obj) => Object.prototype.toString.call(obj) === "[object Error]";

    const loadFallRiskToolOptionsList = () => {
        setLoading(true);
        getClientHealthAssessmentFallRiskToolOptionsList()
            .then((data) => {
                setFallRiskToolOptionsList({
                    fallRiskFactorList: data.fallRiskFactorList.map((item) => {
                        return { label: item.name, value: item.name, score: item.score };
                    }),
                    getUpAndGoTestList: data.getUpAndGoTestList,
                });
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading Fall Risk Tool options");
                }
            });
        setLoading(false);
    };

    const loadLaceToolOptionsList = () => {
        setLoading(true);
        getClientHealthAssessmentLaceToolOptionsList()
            .then((data) => {
                setLaceToolOptionsList({
                    comorbidityList: data.comorbidityList.map((item) => {
                        return { label: item.name, value: item.name, score: item.score };
                    }),
                    emergencyVisitList: data.emergencyVisitList,
                    lengthOfStayList: data.lengthOfStayList,
                });
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading Lace Tool options");
                }
            });
        setLoading(false);
    };

    //=== Render =============================================================

    return (
        <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="text-primary">Health Assessment</Modal.Title>
                <div className="col-md-6 text-end">{loading && <Loading />}</div>
            </Modal.Header>

            <form>
                <Modal.Body>
                    <Tabs defaultActiveKey="laceTool" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="laceTool" title="Lace Tool">
                            <ClientHALaceTool laceToolData={data.laceTool} setLaceToolData={setLaceToolData} acknowledgeMode={acknowledgeMode} laceToolOptionsList={laceToolOptionsList} />
                        </Tab>
                        <Tab eventKey="fallRisk" title="Fall Risk">
                            <ClientHAFallRiskTool fallRiskData={data.fallRisk} setFallRiskData={setFallRiskData} acknowledgeMode={acknowledgeMode} fallRiskToolOptionsList={fallRiskToolOptionsList} />
                        </Tab>
                        <Tab eventKey="katzIndex" title="Katz Index">
                            <ClientHAKatzIndex katzIndexData={data.katzIndex} setKatzIndexData={setKatzIndexData} acknowledgeMode={acknowledgeMode} />
                        </Tab>
                        <Tab eventKey="homeSafety" title="Home Safety">
                            <ClientHAHomeSafety homeSafetyData={data.homeSafety} setHomeSafetyData={setHomeSafetyData} acknowledgeMode={acknowledgeMode} />
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
                        Close
                    </button>

                    {!readOnly && !acknowledgeMode && (
                        <button type="button" className="btn btn-primary" onClick={handleSave}>
                            Save Changes
                        </button>
                    )}

                    {!readOnly && acknowledgeMode && (
                        <button type="button" className="btn btn-primary" onClick={handleAcknowledge}>
                            Acknowledge
                        </button>
                    )}
                </Modal.Footer>
            </form>
        </Modal>
    );
}; //ClientHealthAssessmentDialog

import React, { } from 'react';
import { withRouter } from 'react-router-dom';
import './AdminSidePanel.css';
import '../../styles/common.css';


const AdminSidePanel = (props) => {

    const redirectToComponent = (path) => {
        props.history.push(path);
        props.toggleAdminSidePanel();
        props.setOpenSideBar(true);
        props.setExpandedAdmin(true);
    }

    return (
        <>
            <nav className={`navbar navbar-expand-lg d-flex flex-column align-items-start ${!props.openAdminPanel ? "side-panel" : "side-panel active"}`} >
                <div className="container-fluid d-block ">
                    <div className="row mt-2">
                        <div className="col-10">
                            <h6 className="medium-style" style={{ fontSize: '18px', letterSpacing: '0.1px', color: '#3CAF8A' }}>Add New</h6>
                        </div>
                        <div className="col-2">
                            <i className="bi bi-x-lg" role="button" onClick={() => props.toggleAdminSidePanel()} />
                        </div>
                    </div>
                    <div className="row border-bottom pt-5 pb-5">
                        <div className="col-12 text-center">
                            <h5 className="bold-style">Add New User</h5>
                            <button className="btn btn-success btn-block rounded-pill" onClick={() => redirectToComponent('/admin/Users & Groups/Users')}>New User</button>
                        </div>
                    </div>
                    <div className="row border-bottom pt-5 pb-5">
                        <div class="col-12 text-center ">
                            <h5 className="bold-style">Add New Group</h5>
                            <button className="btn btn-success btn-block rounded-pill" onClick={() => redirectToComponent('/admin/Users & Groups/Groups')}>New Group</button>
                        </div>
                    </div>
                    <div className="row border-bottom pt-5 pb-5">
                        <div class="col-12 text-center">
                            <h5 className="bold-style">Add New Role</h5>
                            <button className="btn btn-success btn-block rounded-pill" onClick={() => redirectToComponent('/admin/Account/Roles & Permissions')}>New Role</button>
                        </div>
                    </div>

                </div>
            </nav>
        </>
    )
}

export default withRouter(AdminSidePanel);
import React from "react";
import ClientDietaryRequirements from "./ClientDietaryRequirements.js"
import ClientEatingDetails from "./ClientEatingDetails.js";

const ClientDietaryAndEating = (props) => {
    return (
        <>
            <ClientDietaryRequirements {...props} />
            <ClientEatingDetails {...props} />
        </>
    )
}

export default ClientDietaryAndEating;
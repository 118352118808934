import React from 'react';
import { FormBuilder, Validators, FieldControl, FieldGroup } from "react-reactive-form";
import { withRouter } from 'react-router-dom';
import { swalAlert, swalToast, swalToast2 } from '../../../lib/Swal.js';
import styles from '../../../styles/common.module.css'
import { callAPI } from '../../../lib/Global.js';
import TextInput from '../../../Controls/TextInput.js';

class ClientInsuranceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, isDataUpdated: false };
    }

    componentWillUnmount() {
        this.clientInsuranceForm.get('hasInsurance').valueChanges.unsubscribe();
    }

    async componentDidMount() {
        await this.getClientInsuranceByClientId();
        this.clientInsuranceForm.get('hasInsurance').valueChanges.subscribe(v => {
            console.error(v)
            this.clientInsuranceForm.patchValue({ policyNumber: null, claimNumber: null });
            if (v === 'Yes') {
                this.clientInsuranceForm.get('policyNumber').enable();
                this.clientInsuranceForm.get('claimNumber').enable();
            } else {
                this.clientInsuranceForm.get('policyNumber').disable();
                this.clientInsuranceForm.get('claimNumber').disable();
            }
        });
    }

    clientInsuranceForm = FormBuilder.group({
        id: [null],
        clientId: [null, Validators.required],
        hasInsurance: [null, [Validators.required, Validators.maxLength(3)]],
        policyNumber: [null, [Validators.maxLength(20)]],
        claimNumber: [null, [Validators.maxLength(20)]]
    });

    getClientInsuranceByClientId = async () => {
        this.setState({ isLoading: true });

        const r = await callAPI('GetClientInsuranceByClientId', { id: this.props.clientId });
        this.setState({ isLoading: false });

        this.clientInsuranceForm.patchValue(r ? r : { clientId: this.props.clientId });

        if (r && r.hasInsurance === 'Yes') {
            this.clientInsuranceForm.get('policyNumber').enable();
            this.clientInsuranceForm.get('claimNumber').enable();
        } else if (r && r.hasInsurance === 'No') {
            this.clientInsuranceForm.get('policyNumber').disable();
            this.clientInsuranceForm.get('claimNumber').disable();
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const v = { ...this.clientInsuranceForm.value };
        if (v.hasInsurance === 'No') {
            v.policyNumber = null;
            v.claimNumber = null;
        } else {
            if (!v.policyNumber) { swalAlert('e', 'Please enter Policy Number.'); return; }
            if (!v.claimNumber) { swalAlert('e', 'Please enter Claim Number.'); return; }
        }

        this.clientInsuranceForm.updateValueAndValidity(this.clientInsuranceForm.value);

        this.setState({ isLoading: true });
        this.setState({ isDataUpdated: !this.state.isDataUpdated });

        const apiText = this.clientInsuranceForm.value.id ? 'UpdateClientInsurance' : 'CreateClientInsurance';
        let message = this.clientInsuranceForm.value.id !== null ? 'Updated' : 'Saved';
        const r = await callAPI(apiText, v);
        this.setState({ isLoading: false });

        this.clientInsuranceForm.updateValueAndValidity(this.clientInsuranceForm.value);

        if (r?.isSuccess) {
            swalToast2('Client Insurance ' + message + ' successfully.');
            this.closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    closeModal = (isDataToReload) => {
        this.props.closeClientIdentityModal(isDataToReload);
    }

    render() {
        // <Values value={value} /> // for form value debug purpose.
        return (
            <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="exampleModalLabel">Insurance</h5>
                            <i className="bi bi-x-lg" onClick={() => this.closeModal(false)} style={{ cursor: 'pointer' }}></i>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                {(this.state.isLoading && !this.state.isDataUpdated) && <>
                                    <div>Please Wait...</div>
                                </>}

                                {this.state.isDataUpdated && <>
                                    <div>Completed...</div>
                                </>}
                                {!this.state.isLoading && <>
                                    <form className="row g-2 pt-2">
                                        <FieldGroup
                                            control={this.clientInsuranceForm}
                                            render={({ invalid, pristine, value }) => (
                                                <>
                                                    <div className="row mb-2">
                                                        <div className="col-12">
                                                            <FieldControl
                                                                name="hasInsurance"
                                                                render={({ handler, touched, hasError, meta }) => (
                                                                    <>
                                                                        <div className="row my-1">
                                                                            <label for="formControlInput1" className={`mb-1 ${styles.labelStyling}`}>{meta.label}</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input class="form-check-input" {...handler("radio", "Yes")} />
                                                                            <label class={`form-check-label ${styles.labelStyling}`} for="RadioYes">
                                                                                Yes
                                                                            </label>

                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input class="form-check-input"  {...handler("radio", "No")} />
                                                                            <label class={`form-check-label ${styles.labelStyling}`} for="RadioNo">
                                                                                No
                                                                            </label>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                meta={{ label: "Has Insurance?" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-12">
                                                            <FieldControl
                                                                name="policyNumber"
                                                                render={TextInput}
                                                                meta={{ label: "Policy Number", maxLengthVal: 20 }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-12">
                                                            <FieldControl
                                                                name="claimNumber"
                                                                render={TextInput}
                                                                meta={{ label: "Claim Number", maxLengthVal: 20 }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-center">
                                                        <div className="d-grid gap-2 pb-1">
                                                            <button type="button" onClick={e => this.handleSubmit(e)} className="btn btn-primary" disabled={invalid || pristine || this.state.isLoading}>{this.state.isLoading ? 'Please wait...' : 'Save'}</button>
                                                        </div>
                                                    </div>

                                                </>
                                            )}
                                        />
                                    </form>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(ClientInsuranceModal);
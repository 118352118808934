import { PageNumberOptions } from "./pageNumberOptions";

export const CaseTypeListTableFooter = ({ handlePageNumberChange, handlePageSizeChange, numPages, numRecords }) => {
    return (
        <tr>
            <td colSpan="4">
                <div className="row">
                    <div className="col-md-1">
                        <label htmlFor="PageNumber" className="small">
                            Page
                        </label>
                        <select className="form-select form-select-sm" id="PageNumber" name="PageNumber" onChange={handlePageNumberChange}>
                            <PageNumberOptions numItems={numPages} />
                        </select>
                    </div>
                    <div className="col-md-1">
                        <label htmlFor="PageSize" className="small">
                            Size
                        </label>
                        <select className="form-select form-select-sm" id="PageSize" name="PageSize" onChange={handlePageSizeChange}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="All">All</option>
                        </select>
                    </div>
                    <div className="offset-md-8 col-md-2 text-end">
                        # Records: <b>{numRecords}</b>
                    </div>
                </div>
            </td>
        </tr>
    );
};

import React, { useState, useEffect } from 'react';
import ClientStatusModal from './ClientStatusModal.js';
import { callAPIWithpathParameter } from '../../../lib/Global.js';
import styles from './clientInfo.module.css';
import '../../../styles/common.css';

const ClientStatus = (props) => {
    const [openModal, setopenModal] = useState(false);
    const [clientStatusList, setClientStatusList] = useState([]);
    const [status, setStatus] = useState("");
    const { clientId } = props;
    useEffect(() => {
        if (clientId) {
            getClientStatusListById(clientId);
        }
    }, []);

    const getClientStatusListById = async (id) => {
        const r = await callAPIWithpathParameter('ClientStatus', id, 'GET', '');
        setClientStatusList(r.data);
        r.data?.[r.data.length - 1] ? setStatus(r.data?.[r.data.length - 1].status) : setStatus("-");
    }


    const toggleClientStatusModal = (isDataToReload) => {
        if (isDataToReload) {
            getClientStatusListById(clientId);
        }
        setopenModal(!openModal);
        props.togglebackDrop();
    }
    return (
        <>
            {openModal && <ClientStatusModal currentClientStatus={clientStatusList?.[clientStatusList.length - 1]} closeModal={toggleClientStatusModal} status={status} {...props} />}
            <div className={`text-center ${styles.card}`}>
                <div className="p-2 text-center"><label for="formControlSelect1" className="mb-1 bold-color-style">Status</label></div>
                <span className={styles.statusContainerStyling} onClick={() => toggleClientStatusModal(false)}><label className="semi-bold-style">{status}</label></span>
            </div>
        </>
    )
}

export const MemoizedClientStatus = React.memo(ClientStatus);
import { useEffect, useState } from "react";
import { BranchEditDialog } from "./components/branchEditDialog";
import { BranchListTableBody } from "./components/branchListTableBody";
import { BranchListTableFooter } from "./components/branchListTableFooter";
import { ModalDeleteConfirmation } from "../../../../../../Shared/modalDeleteConfirmation/modalDeleteConfirmation";
import { Loading } from "../../../../../../Shared/loading/loading";
import { ErrorMessage } from "../../../../../../Shared/errorMessage/errorMessage";
import { createBranch, deleteBranch, getAllBranches, getBranchById, updateBranch } from "./branchListRest";

//=== Helpers =====
import { sortJson } from "../../../../../../lib/sortJson";
import { JSONToCSVConvertor } from "../../../../../../lib/jsonToCSVConvertor";
import { fileTimeStamp } from "../../../../../../lib/fileTimeStamp";

//=== Style =====
import "./branchListStyle.css";

export const BranchList = () => {
    //Branch state
    const [branch, setBranch] = useState();

    //Modal display state
    const [show, setShow] = useState(false);
    //Modal Delete Confirmation state
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    //Loading data flag state
    const [loading, setLoading] = useState(false);
    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);
    //Branch data state
    const [data, setData] = useState([]);
    //Branch filtered data state
    const [displayData, setDisplayData] = useState([]);
    //Selected id
    const [id, setId] = useState(null);

    //Grid records per page state
    const [pageSize, setPageSize] = useState(5);
    //Grid page # state
    const [pageNumber, setPageNumber] = useState(1);
    //Grid number of pages state
    const [numPages, setNumPages] = useState(null);

    //filter value state
    const [filter, setFilter] = useState(null);
    //order by direction state
    const [sortAsc, setSortAsc] = useState(true);

    //Event handlers =====================================================

    //show add new Branch modal
    const handleAddNewClick = () => {
        setId(null);
        setBranch(null);
        setShow(true);
    };

    //handle close create dialog
    const handleCloseClick = () => setShow(false);

    //handle confirm remove Branch button click
    const handleConfirmRemoveClick = (id) => {
        deleteBranch(id)
            .then((ret) => {
                setData(data.filter((Branch) => Branch.id !== id));
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error deleting data (fetch)");
                }
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });
    };

    //handle download csv
    const handleDownloadClick = () => {
        JSONToCSVConvertor(displayData, `Infinity_ClientHealthAssessment_${fileTimeStamp()}.csv`, true);
    };

    //handle edit
    const handleEditClick = (id) => {
        setId(id);
        if (id) {
            loadBranch(id);
        }
    };

    //handle page number changes
    const handlePageNumberChange = (e) => {
        setPageNumber(e.target.value);
    };

    //handle page size changes
    const handlePageSizeChange = (e) => {
        e.target.value === "All" ? setPageSize(data.length) : setPageSize(e.target.value);
        //when pagesize changes, reset to page 1
        setPageNumber(1);
    };

    //handle reload button click
    const handleReloadClick = () => {
        loadAllBranches();
    };

    //show remove confirmation dialog
    const handleRemoveClick = (id) => {
        setId(id);
        setShowConfirmDelete(true);
    };

    //handle save Branch click
    const handleSaveBranchClick = (newData) => {
        alert(id);
        if (id) {
            newData = { ...newData, id: id };
            updateBranch(newData)
                .then((ret) => {
                    const newArrayData = [...data];
                    const index = newArrayData.findIndex((item) => item.id === newData.id);
                    newArrayData[index] = ret;
                    setData(newArrayData);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        } else {
            createBranch(newData)
                .then((ret) => {
                    const newArrayData = [...data];
                    newArrayData.push(ret);
                    setData(newArrayData);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        }
    };

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //handle sort columns
    const handleSortClick = (prop, propType) => {
        setSortAsc((sortAsc) => !sortAsc);
        setDisplayData(sortJson(displayData, prop, propType, sortAsc));
    };

    //=== Methods ============================================================

    const isError = (obj) => Object.prototype.toString.call(obj) === "[object Error]";

    const loadAllBranches = () => {
        setLoading(true);
        getAllBranches()
            .then((data) => {
                setData(data);
                setDisplayData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    const loadBranch = (id) => {
        getBranchById(id)
            .then((data) => {
                setBranch(data);
                setShow(true);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading Lace Tool Length of Stay list");
                }
                setLoading(false);
            });
    };

    //Use Effects ===========================================================

    //Loading Branches...
    useEffect(() => {
        loadAllBranches();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //update # pages when displayData || pageSize changes
    useEffect(() => {
        pageSize === "All" ? setNumPages(1) : setNumPages(Math.ceil(displayData.length / pageSize));
    }, [displayData, pageSize]);

    //update display data when data || filter changes
    useEffect(() => {
        setDisplayData(
            !filter
                ? data
                : data.filter(({ name, city, state }) => {
                      return name.toLowerCase().includes(filter) || city.toLowerCase().includes(filter) || state.toLowerCase().includes(filter);
                  })
        );

        //when filter, reset to page 1
        setPageNumber(1);
    }, [data, filter]);

    //========================================================================

    return (
        <section id="BranchListSection">
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="text-primary py-3">Branch List</h2>
                        </div>
                        <div className="col-md-8">{operationError && <ErrorMessage msg={operationError} />}</div>
                    </div>

                    <div className="row">
                        <div className="col-md-9">
                            <button type="button" className="btn btn-primary btn-sm" onClick={handleAddNewClick}>
                                Add New Branch
                            </button>
                            {loading && <Loading />}
                        </div>
                        <div className="col-md-3 align-end">
                            <div className="input-group">
                                <input type="text" className="form-control form-control-sm" placeholder="Search...." onChange={handleSearchInput} />
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Reload" onClick={handleReloadClick}>
                                    <i className="fa fa-refresh"></i>
                                </button>
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Download" onClick={handleDownloadClick}>
                                    <i className="fa fa-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped table-hover table-sm branchList mt-1">
                        <thead>
                            <tr>
                                <th className="sort" onClick={() => handleSortClick("name", "string")}>
                                    Branch Name
                                </th>
                                <th className="sort" onClick={() => handleSortClick("city", "string")}>
                                    City
                                </th>
                                <th className="sort" onClick={() => handleSortClick("state", "string")}>
                                    State
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <BranchListTableBody currentPage={Number(pageNumber)} numberPerPage={Number(pageSize)} items={displayData} filter={filter} handleEditClick={handleEditClick} handleRemoveClick={handleRemoveClick} loading={loading} loadingError={loadingError} />
                        </tbody>
                        <tfoot>
                            <BranchListTableFooter handlePageNumberChange={handlePageNumberChange} handlePageSizeChange={handlePageSizeChange} numPages={numPages} numRecords={displayData.length} />
                        </tfoot>
                    </table>
                </div>
            </div>
            {/* Modal components */}
            <ModalDeleteConfirmation show={showConfirmDelete} setShow={setShowConfirmDelete} handleConfirmRemoveClick={handleConfirmRemoveClick} id={id} />
            <BranchEditDialog id={id} data={branch} setData={setBranch} show={show} setShow={setShow} handleClose={handleCloseClick} handleSaveClick={handleSaveBranchClick} />
        </section>
    );
};

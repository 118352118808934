import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import Navbar from "./Shared/Navbar/Navbar.js";
import Login from "./Modules/Auth/Components/Login/Login.js";
import SSOAssert from "./Modules/Auth/Components/Login/SSOAssert.js";
import CantAccessYourAccount from "./Modules/Auth/Components/PasswordReset/CantAccessYourAccount.js";
import PasswordReset from "./Modules/Auth/Components/PasswordReset/PasswordReset.js";
import ClientInfo from "./Modules/HCC/Components/ClientInfo.js";
import ClientProfile from "./Modules/HCC/Components/ClientProfile.js";
import Sidebar from "./Shared/Sidebar/Sidebar";
import Dashboard from "./Modules/HCC/Components/Dashboard.js";
import Breadcrumb from "./Shared/Breadcrumbs/Breadcrumbs";
import CardManagerSidebar from "./Shared/CardManagerSidebar/CardManagerSidebar";
import { HospiceList } from "./Modules/HCC/Components/admin/components/hospiceList/hospiceList";
import { HospitalList } from "./Modules/HCC/Components/admin/components/hospitalList/hospitalList";
import { BranchList } from "./Modules/HCC/Components/admin/components/branchList/branchList";
import { BasicIntakeLimitationList } from "./Modules/HCC/Components/admin/components/basicIntakeLimitationList/basicIntakeLimitationList";
import { BasicIntakeNeedList } from "./Modules/HCC/Components/admin/components/basicIntakeNeedsList/basicIntakeNeedList";

import { callAPI, canModuleAllowed } from "./lib/Global.js";
import { ProductList } from "./Modules/HCC/Components/admin/components/productList/productList";
import { CaseTypeList } from "./Modules/HCC/Components/admin/components/caseTypeList/caseTypeList";
import { JobTitleList } from "./Modules/HCC/Components/admin/components/jobTitleList/jobTitleList";
import { Admin } from "./Modules/HCC/Components/admin/admin";
import RolesAndPermissions from "./Modules/Admin/Components/RolesAndPermission";
import AdminMenu from "./Modules/Admin/Components/AdminMenu";
import { swalConfirmOptions } from "./lib/Swal.js";
import { Redirect } from "react-router-dom";
import AdminSidePanel from "./Shared/AdminSidePanel/AdminSidePanel";
import BackDrop from "./Shared/AdminSidePanel/BackDrop";
import { buildAllowedModuleSubjectScopeArr, userWithAssignedRolesPermission } from "./lib/Global.js";
import StaticProperties from "./utils/StaticProperties";

const assignedUserRoles = [{
    "roleName": "ClientsRole1",
    "description": "General application user",
    "permissions": [
        {
            "type": "Permission",
            "action": "Delete",
            "module": "All",
            "subject": "All",
            "scopes": [],
            "purpose": "Others"
        },
        {
            "type": "Permission",
            "action": "Manage",
            "module": "All",
            "subject": "All",
            "scopes": [],
            "purpose": "Others"
        },
        {
            "type": "Permission",
            "action": "Create",
            "module": "Clients",
            "subject": "Profile",
            "scopes": [
                "Relationship"
            ],
            "purpose": "Others"
        },
        {
            "type": "Restriction",
            "action": "Manage",
            "module": "Clients",
            "subject": "Basic Intake",
            "scopes": [
                "Relationship"
            ],
            "purpose": "Others"
        },
        {
            "type": "Permission",
            "action": "Manage",
            "module": "Clients",
            "subject": "Profile",
            "scopes": [
                "Relationship"
            ],
            "purpose": "Others"
        },
        {
            "type": "Permission",
            "action": "All",
            "module": "Clients",
            "subject": "All",
            "scopes": [],
            "purpose": "Module"
        },
    ],
    "id": "61e08488ca10964c75ee5689",
    "createDt": "2022-01-13T19:59:04.024Z",
    "createBy": "LoggedInUserGoesHere",
    "updateDt": "2022-04-01T07:08:42.991Z",
    "updateBy": "UpdatedByGoesHere"
},
{
    "roleName": "Administrator",
    "description": "All powerfull Administrator user!",
    "permissions": [
        {
            "type": "Permission",
            "action": "Manage",
            "module": "All",
            "subject": "",
            "scope": []
        }
    ],
    "id": "61e9a355700a54fz00b0e5fc",
    "createDt": "2022-01-20T18:08:37.001Z",
    "createBy": "LoggedInUserGoesHere",
    "updateDt": "2022-01-21T14:11:37.534Z",
    "updateBy": "UpdatedByGoesHere"
}, {
    "roleName": "ClientsRole2",
    "description": "General application user",
    "permissions": [
        {
            "type": "Permission",
            "action": "Delete",
            "module": "All",
            "subject": "All",
            "scopes": [],
            "purpose": "Others"
        },
        {
            "type": "Permission",
            "action": "Manage",
            "module": "All",
            "subject": "All",
            "scopes": [],
            "purpose": "Others"
        },
        {
            "type": "Permission",
            "action": "Create",
            "module": "Clients",
            "subject": "Profile",
            "scopes": [
                "Relationship"
            ],
            "purpose": "Others"
        },
        {
            "type": "Restriction",
            "action": "Manage",
            "module": "Clients",
            "subject": "Basic Intake",
            "scopes": [
                "Relationship"
            ],
            "purpose": "Others"
        },
        {
            "type": "Permission",
            "action": "Manage",
            "module": "Clients",
            "subject": "Profile",
            "scopes": [
                "Relationship"
            ],
            "purpose": "Others"
        },
        {
            "type": "Permission",
            "action": "All",
            "module": "Clients",
            "subject": "All",
            "scopes": [],
            "purpose": "Module"
        },
    ],
    "id": "61e08488ca10964c75ee5689",
    "createDt": "2022-01-13T19:59:04.024Z",
    "createBy": "LoggedInUserGoesHere",
    "updateDt": "2022-04-01T07:08:42.991Z",
    "updateBy": "UpdatedByGoesHere"
}]
const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
};

function App(props) {
    let history = useHistory();
    const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem("token"));
    const [openSideBar, setOpenSideBar] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [ClientInfoData, setClientInfoData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [breadcrumbName, setBreadCrumbName] = useState("");
    const [dashboardTiles, setDashboardTiles] = useState([]);
    const [isTileChanged, setTileChanged] = useState(false);
    const [openCardManager, setOpenCardManager] = useState(false);
    const [openAdminPanel, setOpenAdminPanel] = useState(false);
    const [expandedAdmin, setExpandedAdmin] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const hardcodedRoles = [{
        "roleName": "Clients",
        "description": "Clients",
        "permissions": [
            {
                "purpose": "Module",
                "type": "Permission",
                "module": "client",
                "pageName": "All",
                "action": "All",
                "subject": "All",
                "scopes": [
                    "Relationship"
                ]
            }
        ],
        "id": "624d46034830f66551d81a59",
        "createDt": "2022-04-06T07:49:23.195Z",
        "createBy": "LoggedInUserGoesHere",
        "updateDt": "2022-04-06T07:49:45.111Z",
        "updateBy": "UpdatedByGoesHere"
    }];

    const markAsLogin = (token) => {
        sessionStorage.setItem("token", JSON.stringify(token));
        setIsLoggedIn(true);

        const jwt = sessionStorage.getItem("token");
        const jwtData = jwt.split(".")[1];
        const decodedJwtJsonData = window.atob(jwtData);
        const decodedJwtData = JSON.parse(decodedJwtJsonData);

        sessionStorage.setItem("loggedInUserName", decodedJwtData?.name || decodedJwtData?.User);
        // global.userRoles = decodedJwtData?.userRoles ? JSON.parse(decodedJwtData.userRoles) : [];
        // global.userRoles = assignedUserRoles;
        const userRoles = decodedJwtData?.role ? decodedJwtData.role : []; //['Clients','Admin']
        StaticProperties.loggedInUsersRolePermission = userWithAssignedRolesPermission(userRoles);
        StaticProperties.loggedInUsersRolePermission.push(...hardcodedRoles);
        // buildAllowedModuleSubjectScopeArr();
        // console.log(global.userRoles);
    };

    const markAsLogOut = async () => {
        const msg = "You are about to logout.  Do you want to continue to logout?";
        await swalConfirmOptions.fire({ title: msg }).then((result) => {
            if (result.value) {
                sessionStorage.removeItem("token");
                setIsLoggedIn(false);
                return <Redirect to="/login" />;
            }
        });
    };

    // const token = getToken();
    // if (!token) {
    //   // return <Login markAsLogin={markAsLogin} />
    // }

    const openAndCloseSideBar = () => {
        setOpenSideBar(!openSideBar);
    };

    const handleInputSearch = (val) => {
        setSearchInput(val);
    };

    const handleSearch = async () => {
        const r = await callAPI("SearchClients", { str: searchInput?.trim() });
        setClientInfoData(r);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const toggleDasboardTiles = (tiles) => {
        setDashboardTiles(tiles);
        setTileChanged(!isTileChanged);
    };

    const toggleCardManager = () => {
        setOpenCardManager(!openCardManager);
    };

    const toggleAdminSidePanel = () => {
        setOpenAdminPanel(!openAdminPanel);
    };
    return (
        <Router>
            {isLoggedIn && <Navbar handleSearch={handleSearch} handleInputSearch={handleInputSearch} toggleModal={toggleModal} toggleCardManager={toggleCardManager} openCardManager={openCardManager} toggleAdminSidePanel={toggleAdminSidePanel} />}
            {isLoggedIn && <Sidebar openAndCloseSideBar={openAndCloseSideBar} openSideBar={openSideBar} markAsLogOut={markAsLogOut} setOpenSideBar={setOpenSideBar} expandedAdmin={expandedAdmin} setExpandedAdmin={setExpandedAdmin} userRoles={userRoles} />}
            {isLoggedIn && <CardManagerSidebar toggleDasboardTiles={toggleDasboardTiles} openCardManager={openCardManager} />}
            <AdminSidePanel openAdminPanel={openAdminPanel} toggleAdminSidePanel={toggleAdminSidePanel} setOpenSideBar={setOpenSideBar} setExpandedAdmin={setExpandedAdmin} />
            {openAdminPanel && <BackDrop />}
            <div className={openSideBar ? isLoggedIn && "container-margin-style" : isLoggedIn && "container-no-margin-style"}>
                {/* {isLoggedIn && <Breadcrumb breadcrumbName={breadcrumbName} />} */}
                <Switch>
                    <PrivateRoute path="/" exact>
                        <div className="text-primary">No routes found....</div>
                    </PrivateRoute>
                    <Route path="/login">
                        <Login markAsLogin={markAsLogin} />
                    </Route>
                    <Route path="/SamlConsume">
                        <SSOAssert markAsLogin={markAsLogin} />
                    </Route>
                    <Route path="/cant-access-your-account">
                        <CantAccessYourAccount />
                    </Route>
                    <Route path="/password-reset">
                        <PasswordReset />
                    </Route>

                    <Route path="/admin/branch" component={BranchList} />
                    <Route path="/admin/caseType" component={CaseTypeList} />
                    <Route path="/admin/jobTitle" component={JobTitleList} />
                    <Route path="/admin/hospice" component={HospiceList} />
                    <Route path="/admin/hospital" component={HospitalList} />
                    <Route path="/admin/limitation" component={BasicIntakeLimitationList} />
                    <Route path="/admin/need" component={BasicIntakeNeedList} />
                    <Route path="/admin/product" component={ProductList} />
                    {/* {<Route path="/admin/global-settings/service-portal" component={Admin} />} */}

                    <PrivateRoute path="/home">
                        <Dashboard dashboardTiles={dashboardTiles} toggleCardManager={setOpenCardManager} />
                    </PrivateRoute>
                    <PrivateRoute path="/clients">
                        <ClientInfo clientInfoData={ClientInfoData} showModal={showModal} toggleModal={toggleModal} />
                    </PrivateRoute>
                    <PrivateRoute path="/clientprofile/:clientId">
                        <ClientProfile setBreadCrumbName={setBreadCrumbName} showModal={showModal} toggleModal={toggleModal} />
                    </PrivateRoute>
                    <PrivateRoute path="/Account/RolesPermissions">
                        <RolesAndPermissions />
                    </PrivateRoute>
                    <PrivateRoute path="/admin">
                        <AdminMenu />
                    </PrivateRoute>
                </Switch>
            </div>
        </Router>
    );
}

export default App;

import React from 'react';
import {
    FormBuilder,
    Validators,
    FieldControl,
    FieldGroup
} from "react-reactive-form";
import { withRouter } from 'react-router-dom';
import { swalAlert, swalToast } from '../../../../lib/Swal.js';
import { callAPI, verifyReCaptcha, removeRecaptcha } from '../../../../lib/Global.js';
import AddReCaptchaScript from '../ReCaptcha/ReCaptcha.js';
import styles from './login.module.css';
// import AES from "crypto-js/aes";
// import CryptoENC from 'crypto-js/enc-utf8';

export const SSO_Url = process.env.SSO_Url;

class EmailLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isLoading: false };
        // var CryptoJS = require("crypto-js");

        // // Encrypt
        // var ciphertext = AES.encrypt('my message', 'secret key 123').toString();
        // console.log(ciphertext);
        // // Decrypt
        // var bytes = AES.decrypt(ciphertext, 'secret key 123');
        // var originalText = bytes.toString(CryptoENC);

        // console.log(originalText); // 'my message'
    }

    componentWillUnmount() {
        removeRecaptcha();
    }

    loginForm = FormBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        password: [null, Validators.required]
    });

    handleSubmit = async (e) => {
        e.preventDefault();

        this.loginForm.updateValueAndValidity(this.loginForm.value);
        this.setState({ isLoading: true });

        // verifyReCaptcha starts
        const v = await verifyReCaptcha('LoginSubmit');
        if (!v?.isReCaptchaValid) {
            swalAlert('e', 'reCAPTCHA v3 validation failed: The response parameter is invalid or malformed. Expected action did not match.  Please contact system administrator for help.');
            return;
        }
        // verifyReCaptcha ends

        const r = await callAPI('EmailLogin', this.loginForm.value);
        this.setState({ isLoading: false });
        this.loginForm.updateValueAndValidity(this.loginForm.value);
        if (r.isLoginValid) {
            this.props.onLoginSuccess(this.loginForm.value.email);
            swalToast('Login success, check your email for OTP.');
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    handleReset = (e) => {
        e.preventDefault();
        this.setState({ isLoading: false });
        this.loginForm.reset();
    }

    handleSingleSignOn = (e) => {
        e.preventDefault();
        // for local middleware
        // window.location.replace('https://login.microsoftonline.com/6e0ee269-9f63-4c17-8082-a9dec3dfb385/saml2?SAMLRequest=jVHLasMwEPwVo3ss23FqVdgG5wWBFErS9tCbUJVGIGld7To0f18nIadC6XWY2Xlsjcq7XnYDHcPOfA0GKfn2LmDDhhgkKLQog%2FIGJWm57562skgz6Q2pD0WKJZtlw9aiXAohum46L8tFPhdlOa1mXbkSRZ4tH1cseTMRLYSGjeJRgziYTUBSgUYoK4pJlk8y8ZIXMstlWaXVw%2Bz9wntWiPZkGnZQDg1LOkQTaby0gICDN3Fv4slq87rbNuxI1KPk3IFW7ghIsiqmgrNbIXlt%2BnetPgKBBseSNURtrqvcvdv6Gjv%2BZx51j8naS6gx07hW3zur1QWN5tMixXMKwVsdAeFAqQbP9YAEfmQiAu%2Bj9Sqea37zbWv%2B%2B1ftDw%3D%3D');
        // foe dev middleware
        window.location.replace('https://login.microsoftonline.com/6e0ee269-9f63-4c17-8082-a9dec3dfb385/saml2?SAMLRequest=jZFLa8MwEIT%2FitE9fsWmqrANzgsCKZSk6aE3Vd00Aktytau06a%2BvnZBTofQ6zO7ON1uhNF0v2kBHu4WPAEjRl%2Bks1ix4K5xEjcJKAyhIiV37sBF5nAoDJN8kSRatFzVb8WLBOW%2Fb6awo5tmMF8X0rmyLJc%2BzdHG%2FZNEzeNTO1mwYHmYQA6wtkrQ0SGmeT9JskvKnrBRpJsoizvLyZfQ9SkR9gpodZIfAohYRPA2b5s5iMOB34E9awX67qdmRqEeRJNoetNV0HtFi%2BR08fMIragKMLRC78okL%2BN%2BUvXfklOtYtHJewaWkW5SmulD4%2F7Qlb6lZM2YcIg7l9X2nlRxVD%2B8ayZ9jZ41W3qE7UKycSVRAcmZwIrqk99pIf66S692mSn6%2FrvkB');
    }

    render() {
        const { history } = this.props
        return (
            <div>
                <div className="row">
                    <AddReCaptchaScript />
                </div>
                <div style={{
                    height: '100vh',
                    position: 'relative',
                    backgroundImage: `url('../img/login-background-img.jpg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}>
                    <div style={{
                        position: 'absolute',
                        height: '100%',
                        backgroundColor: 'rgb(0, 0, 0)',
                        width: '100%',
                        opacity: '0.7'
                    }}>
                    </div>
                </div>

                <img src="./img/caring-people-logo.svg" alt="Caring people Logo" className={styles.loginLogoImgStyling} />
                <div className="container d-flex align-items-center flex-column position-absolute top-50 start-50 translate-middle">
                    <div className={`card border-0 py-5 px-3 ${styles.loginCardStyling}`}>
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src="./img/login-header-image.svg" className={`card-img-top ${styles.loginImgStyling}`} alt="..." />
                            </div>
                            <div className="col-12 text-center">
                                <span className={`${styles.loginTitleStyling}`}>Built On Passion and Excellence</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title mt-2"><span className={styles.signInLabelStyling}>Sign In</span></h3>
                            <footer className="text-muted pb-6" ><span className={styles.contInfinityLabelStyling}>to continue to Infinity application</span></footer>
                            <form className="row g-2">
                                <FieldGroup
                                    control={this.loginForm}
                                    render={({ invalid, pristine, pending }) => (
                                        <>
                                            <FieldControl
                                                name="email"
                                                render={({ handler, touched, hasError }) => (
                                                    <div className="input-group">
                                                        <input type="text" className={`form-control rounded-0 ${styles.loginInputStyling} ` + (pristine ? '' : touched && (hasError("required") || hasError("email")) ? 'is-invalid' : 'is-valid')} placeholder="Username" {...handler()} required />
                                                        <div className="invalid-feedback">
                                                            <span>
                                                                {touched &&
                                                                    hasError("required") &&
                                                                    "Username is required"}
                                                                {touched &&
                                                                    hasError("email") &&
                                                                    "Invalid email id"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <FieldControl
                                                name="password"
                                                render={({ handler, touched, hasError }) => (
                                                    <div className="input-group">
                                                        <input type="password" className={`form-control rounded-0 ${styles.loginInputStyling} ` + (pristine ? '' : touched && hasError("required") ? 'is-invalid' : 'is-valid')} placeholder="Password" {...handler()} required />
                                                        <div className="invalid-feedback">
                                                            <span>
                                                                {touched &&
                                                                    hasError("required") &&
                                                                    "Password is required"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <div className="row mt-4">
                                                <div className="col-6 text-end">
                                                    <button className={`btn ${styles.signInButtonStyling} `} disabled={invalid || pristine || this.state.isLoading} onClick={e => this.handleSubmit(e)}>{this.state.isLoading ? 'Please wait...' : 'Sign In'}</button>
                                                </div>
                                                <div className="col-6 text-start">
                                                    <button className={`btn mb-2 ${styles.SSOButtonStyling}`} onClick={e => this.handleSingleSignOn(e)}>Single Sign On</button>
                                                </div>
                                            </div>

                                            <div className="text-center mt-3">
                                                <span className={styles.cantAccessYourAccount} onClick={() => history.push("/cant-access-your-account")}>Can't access your account?</span>
                                            </div>


                                        </>
                                    )}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}


export default withRouter(EmailLogin);
import React, { useState } from "react";
import { TAB_HEADING, TAB_DATA } from "./Constants.js";
import FamilyDynamics from "../../Modules/HCC/Components/FamilyDynamics.js";
import FamilyMembers from "../../Modules/HCC/Components/FamilyMembers.js";
import LifeStyleDetails from "../../Modules/HCC/Components/LifeStyleDetails.js";
import ClientCareStaffPreferences from "../../Modules/HCC/Components/CareStaffPreferences.js";
import ClientPayment from "../../Modules/HCC/Components/ClientPayment.js";
// import ClientProfileIdentity from "../../Modules/HCC/Components/ClientProfileIdentity.js";
import ClientIdentityAndAddresses from "../../Modules/HCC/Components/ClientIdentityAndAddressess.js";
import ClientContactList from "../../Modules/HCC/Components/ClientContactList.js";
import { ClientHealthAssessmentList } from "../../Modules/HCC/Components/clientHealthAssessment/clientHealthAssessmentList.jsx";
import { ClientBasicIntake } from "../../Modules/HCC/Components/clientBasicIntake/clientBasicIntake.jsx";
import { ClientServices } from "../../Modules/HCC/Components/clientServices/clientServices.jsx";
import ClientAddressList from "../../Modules/HCC/Components/ClientAddressList.js";
import ClientJournalList from "../../Modules/HCC/Components/ClientJournalList.js";
import ClientDocuments from "../../Modules/HCC/Components/ClientDocuments.js";
import ClientPsycotherapyRiskAssessment from '../../Modules/HCC/Components/ClientPsycotherapyRiskAssessment.js';
import { ClientMedicationsList } from "../../Modules/HCC/Components/clientMedications/clientMedicationsList.jsx";
import ClientDietaryRequirements from "../../Modules/HCC/Components/ClientDietaryRequirements.js";
import ClientMobilityDetails from "../../Modules/HCC/Components/ClientMobilityDetails.js";
import ClientEatingDetails from "../../Modules/HCC/Components/ClientEatingDetails.js";
import ClientPhysicianDetails from "../../Modules/HCC/Components/ClientPhysicianDetails.js";
import ClientDietaryAndEating from "../../Modules/HCC/Components/ClientDietaryAndEating.js";

const Tabs = (props) => {
    const { clientId, toggleStartOfCareModal } = props;
    const [toggleTabs, setToggleTabs] = useState(2);

    const changeTabs = (index) => {
        setToggleTabs(index);
    };

    return (
        <>
            <div className="container-fluid">
                <ul className="nav nav-tabs">
                    {TAB_DATA.map((tabHeading) => (
                        <li className="nav-item">
                            <button className={toggleTabs === tabHeading.index ? "nav-link active" : "nav-link"} style={{ padding: "0.5rem 0.8rem" }} onClick={() => changeTabs(tabHeading.index)}>
                                <span className="medium-style" style={{ fontSize: "11px" }}>
                                    {tabHeading.tabName}
                                </span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="container-fluid">
                <div className={toggleTabs === TAB_HEADING.CONTACTS ? "content  active-content" : "content"}>{toggleTabs === TAB_HEADING.CONTACTS && clientId && <ClientContactList clientId={clientId} {...props} />}</div>

                <div className={toggleTabs === TAB_HEADING.PROFILE ? "content  active-content" : "content"}>{toggleTabs === TAB_HEADING.PROFILE && clientId && <ClientIdentityAndAddresses clientId={clientId} {...props} />}</div>

                <div className={toggleTabs === TAB_HEADING.FAMILY_DYNAMICS ? "content  active-content" : "content"}>{toggleTabs === TAB_HEADING.FAMILY_DYNAMICS && clientId && <FamilyDynamics clientId={clientId} {...props} />}</div>

                <div className={toggleTabs === TAB_HEADING.START_OF_CARE ? "content  active-content" : "content"}>
                    <button type="button" className="btn modal-btn" onClick={() => toggleStartOfCareModal(false)}>
                        Start Of Care
                    </button>
                </div>

                <div className={toggleTabs === TAB_HEADING.FAMILY_MEMBERS ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.FAMILY_MEMBERS && clientId && <FamilyMembers clientId={clientId} {...props} />}
                </div>

                <div className={toggleTabs === TAB_HEADING.LIFESTYLE_BACKGROUND ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.LIFESTYLE_BACKGROUND && clientId && <LifeStyleDetails clientId={clientId} {...props} />}
                </div>

                <div className={toggleTabs === TAB_HEADING.CARE_STAFF ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.CARE_STAFF && clientId && <ClientCareStaffPreferences clientId={clientId} {...props} />}
                </div>
                <div className={toggleTabs === TAB_HEADING.PAYMENT ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.PAYMENT && clientId && <ClientPayment clientId={clientId} {...props} />}
                </div>

                <div className={toggleTabs === TAB_HEADING.HEALTH_ASSESSMENT ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.HEALTH_ASSESSMENT && clientId && <ClientHealthAssessmentList clientId={clientId} />}
                </div>
                <div className={toggleTabs === TAB_HEADING.BASIC_INTAKE ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.BASIC_INTAKE && clientId && <ClientBasicIntake clientId={clientId} />}
                </div>
                <div className={toggleTabs === TAB_HEADING.SERVICES ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.SERVICES && clientId && <ClientServices clientId={clientId} />}
                </div>
                <div className={toggleTabs === TAB_HEADING.ADDRESSES ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.ADDRESSES && clientId && <ClientAddressList clientId={clientId} />}
                </div>
                <div className={toggleTabs === TAB_HEADING.DOCUMENTS ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.DOCUMENTS && clientId && <ClientDocuments clientId={clientId} />}
                </div>

                <div className={toggleTabs === TAB_HEADING.JOURNAL ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.JOURNAL && clientId && <ClientJournalList clientId={clientId} {...props} />}
                </div>
                <div className={toggleTabs === TAB_HEADING.PSYCOTHERAPY ? "content  active-content" : "content"}>
                    {toggleTabs === TAB_HEADING.PSYCOTHERAPY && clientId && <ClientPsycotherapyRiskAssessment clientId={clientId} {...props} />}
                </div>
                <div className={toggleTabs === TAB_HEADING.MEDICATIONS ? "content  active-content" : "content"}>{toggleTabs === TAB_HEADING.MEDICATIONS && clientId && <ClientMedicationsList clientId={clientId} />}</div>

                <div className={toggleTabs === TAB_HEADING.DIETARY ? "content  active-content" : "content"}>{toggleTabs === TAB_HEADING.DIETARY && clientId && <ClientDietaryAndEating clientId={clientId} />}</div>
                <div className={toggleTabs === TAB_HEADING.MOBILITY ? "content  active-content" : "content"}>{toggleTabs === TAB_HEADING.MOBILITY && clientId && <ClientMobilityDetails clientId={clientId} />}</div>
                <div className={toggleTabs === TAB_HEADING.PHYSICIAN ? "content  active-content" : "content"}>{toggleTabs === TAB_HEADING.PHYSICIAN && clientId && <ClientPhysicianDetails clientId={clientId} />}</div>
            </div>
        </>
    );
};

export default Tabs;

import React, { useEffect, useState } from 'react';
import { FaFile, FaFolder, FaFolderOpen, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components';
import last from 'lodash/last';
import { Admin } from '../../HCC/Components/admin/admin';
import rolePermissionStyle from '../style/common.module.css';

const getPaddingLeft = (level, type) => {
    let paddingLeft = level * 20;
    if (type === 'file') paddingLeft += 20;
    return paddingLeft;
}

const StyledTreeNode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  padding-left: ${props => getPaddingLeft(props.level, props.type)}px;
  background: ${props => props.bkcolor}
`;

const NodeIcon = styled.div`
  font-size: 12px;
  margin-right: ${props => props.marginRight ? props.marginRight : 5}px;
`;

const getNodeLabel = (node) => last(node.path.split('/'));

const TreeNode = (props) => {
    const { setPrveNode, node, getChildNodes, level, onToggle, onNodeSelect, backgroundColor } = props;

    return (
        <React.Fragment>
            <div className={rolePermissionStyle.treenode}>
                <StyledTreeNode level={level} type={node.type} bkcolor={node.backgroundColor}>
                    <NodeIcon onClick={() => onToggle(node)}>
                        {node.type === 'folder' && (node?.isOpen ? <FaChevronDown /> : <FaChevronRight />)}
                    </NodeIcon>

                    <NodeIcon marginRight={2}>
                        {node.type === 'file' && <FaFile />}
                        {node.type === 'folder'}
                        {/* {node.type === 'folder' && node?.isOpen === true && <FaFolderOpen />} */}
                        {/* {node.type === 'folder' && !node?.isOpen && <FaFolder />} */}
                    </NodeIcon>


                    <span role="button" onClick={() => [onNodeSelect(node), onToggle(node)]}>
                        {getNodeLabel(node)}
                    </span>
                </StyledTreeNode>
            </div>
            {node?.isOpen && getChildNodes(node).map((childNode, key) => {
                return (
                    <TreeNode
                        key={key}
                        {...props}
                        node={childNode}
                        level={level + 1}
                    />
                )
            })}
        </React.Fragment>
    );
}

TreeNode.defaultProps = {
    level: 0
};

export default TreeNode;

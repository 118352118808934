import React, { useState, useEffect } from 'react';
import ClientAddressModal from './ClientAddressModal.js';
import { callAPI } from '../../../lib/Global';
import { swalConfirmOptions, swalToast2, swalAlert } from "../../../lib/Swal.js";
import styles from './clientInfo.module.css';
import "../../../styles/common.css";

const ClientAddressList = (props) => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [address, setAddress] = useState({});
    const { clientId } = props;


    const handleClose = () => setShow(false);

    useEffect(() => {
        getAddressListByClientId(clientId);
    }, []);

    const getAddressListByClientId = async (clientId) => {
        setIsLoading(true);
        const r = await callAPI('GetAddressesByClientId', { id: clientId });
        setIsLoading(false);
        setAddressList(r);
    }

    const handleShow = async (addressId) => {
        setShow(true);
        if (addressId) {
            const r = await callAPI('GetAddressById', { id: addressId });
            setAddress(r);
        } else {
            setAddress({});
        }
    }

    const confirmDeleteAddressModal = async (addressId) => {
        const msg = "You are about to delete this address.<br> Do you want to continue?";
        await swalConfirmOptions.fire({ title: msg }).then(async (result) => {
            if (result.value) {
                const r = await callAPI("DeleteClientAddressById", { id: addressId });
                if (r.isSuccess) {
                    swalToast2('Address successfully deleted.');
                    getAddressListByClientId(clientId);
                } else {
                    const errorMsg = `<span class="text-danger">${r.errorMsg}</span>`;
                    swalAlert('e', errorMsg);
                }
            }
        });
    }

    return (
        <>
            <ClientAddressModal clientId={clientId} selectedAddr={address} show={show} handleClose={handleClose} getAddressListByClientId={getAddressListByClientId} />
            {!isLoading && <><h5 className="bold-style" style={{ fontSize: '14px' }}>Addresses</h5>
                <hr /></>}
            <div className={styles.addContactWrapper} onClick={() => handleShow()}>
                <i class="bi bi-geo-alt-fill text-success"></i>
                <span className="medium-style hover-item" style={{ color: '#3CAF8A' }}> Add Address</span>
            </div>
            {!isLoading && <table class="table table-hover table-sm table-borderless">
                <thead>
                    <tr className={styles.tHeadStyling}>
                        <th scope="col"><span className="medium-style">Addresses</span></th>
                        <th scope="col" colspan="3"><span className="medium-style">Type</span></th>
                    </tr>
                </thead>
                <tbody>
                    {addressList?.map((a, i) => {
                        return (<tr key={i} className="border-bottom">
                            <td width="30%" className="medium-style">{a.addresses}</td>
                            <td width="40%" className="medium-style">{a.addressType}</td>
                            <td width="5%"><i class="bi bi-pencil-fill text-success" style={{ cursor: 'pointer' }} title="Edit" onClick={() => handleShow(a.id)}></i></td>
                            <td width="5%"><i class="bi bi-trash-fill text-success" role="button" title="Delete" onClick={() => confirmDeleteAddressModal(a.id)}></i></td>
                        </tr>)
                    })}
                </tbody>
            </table>
            }
        </>
    )

}

export default ClientAddressList;
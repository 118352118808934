import { useEffect, useState } from "react";
import { UserGroupEditDialog } from "./components/userGroupEditDialog";
import { UserGroupListTableBody } from "./components/userGroupListTableBody";
import { UserGroupListTableFooter } from "./components/userGroupListTableFooter";
import { ModalDeleteConfirmation } from "../../../../Shared/modalDeleteConfirmation/modalDeleteConfirmation";
import { ErrorMessage } from "../../../../Shared/errorMessage/errorMessage.jsx";
import { SuccessMessage } from "../../../../Shared/successMessage/successMessage.jsx";
import { Loading } from "../../../../Shared/loading/loading";
import { createUserGroups, deleteUserGroups, getAllUserGroups, getAllUsers, getUserGroupById, updateUserGroups } from "./userGroupListRest";

//=== Helpers =====
import { sortJson } from "../../../../lib/sortJson";
import { JSONToCSVConvertor } from "../../../../lib/jsonToCSVConvertor";
import { fileTimeStamp } from "../../../../lib/fileTimeStamp";

//=== Style =====
import "./userGroupListStyle.css";

export const UserGroupList = () => {
    //Modal display state
    const [show, setShow] = useState(false);
    //Modal Delete Confirmation state
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    //Loading data flag state
    const [loading, setLoading] = useState(false);
    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);

    //success message state
    const [successMsg, setSuccessMsg] = useState(null);

    //Limitations List state
    const [listUsers, setListUsers] = useState([]);

    //UserGroup data state
    const [data, setData] = useState([]);
    //UserGroup filtered data state
    const [displayData, setDisplayData] = useState([]);
    //Selected id
    const [id, setId] = useState(null);
    //Selected Group
    const [userGroup, setUserGroup] = useState({ id: null, name: null, users: [] });

    //Grid records per page state
    const [pageSize, setPageSize] = useState(5);
    //Grid page # state
    const [pageNumber, setPageNumber] = useState(1);
    //Grid number of pages state
    const [numPages, setNumPages] = useState(null);

    //filter value state
    const [filter, setFilter] = useState(null);
    //order by direction state
    const [sortAsc, setSortAsc] = useState(true);

    //Use Effects ===========================================================

    //Loading users list...
    useEffect(() => {
        loadAllUsers();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Event handlers =====================================================

    //show add new Basic Intake Need modal
    const handleAddNewClick = () => {
        const defaultUserGroup = { id: null, name: null, users: [] };
        setUserGroup(() => defaultUserGroup);
        setShow(true);
    };

    //handle close create dialog
    const handleCloseClick = () => setShow(false);

    //handle confirm remove userGroup button click
    const handleConfirmRemoveClick = (id) => {
        deleteUserGroups(id)
            .then((ret) => {
                setData(data.filter((userGroup) => userGroup.id !== id));
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error deleting data (fetch)");
                }
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });
    };

    //handle download csv
    const handleDownloadClick = () => {
        JSONToCSVConvertor(displayData, `Infinity_UserGroups_${fileTimeStamp()}.csv`, true);
    };

    //show remove confirmation dialog
    const handleEditClick = (id) => {
        setId(id);
        loadUserGroupById(id);
        setShow(true);
    };

    //handle page number changes
    const handlePageNumberChange = (e) => {
        setPageNumber(e.target.value);
    };

    //handle page size changes
    const handlePageSizeChange = (e) => {
        e.target.value === "All" ? setPageSize(data.length) : setPageSize(e.target.value);
        //when pagesize changes, reset to page 1
        setPageNumber(1);
    };

    //handle reload button click
    const handleReloadClick = () => {
        loadAllUserGroups();
    };

    //show remove confirmation dialog
    const handleRemoveClick = (id) => {
        setId(id);
        setShowConfirmDelete(true);
    };

    //handle save userGroup click
    const handleSaveUserGroupClick = ({ userGroupName, users }) => {
        if (userGroup?.id) {
            updateUserGroups({ id: id, Name: userGroupName, Users: users })
                .then((ret) => {
                    const newArrayData = [...data];
                    let foundIndex = newArrayData.findIndex((element) => element.id === id);
                    newArrayData.splice(foundIndex, 1, ret);
                    setData(newArrayData);
                    setSuccessMsg("Group successfully updated.");
                    //hide message after 3s
                    setTimeout(() => setSuccessMsg(null), 3000);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        } else {
            createUserGroups({ Name: userGroupName, Users: users })
                .then((ret) => {
                    const newArrayData = [...data];
                    newArrayData.push(ret);
                    setData(newArrayData);
                    setSuccessMsg("New Group successfully added.");
                    //hide message after 3s
                    setTimeout(() => setSuccessMsg(null), 3000);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        }
    };

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //handle sort columns
    const handleSortClick = (prop, propType) => {
        setSortAsc((sortAsc) => !sortAsc);
        setDisplayData(sortJson(displayData, prop, propType, sortAsc));
    };

    //=== Methods ============================================================

    const isError = (obj) => Object.prototype.toString.call(obj) === "[object Error]";

    const loadAllUserGroups = () => {
        setLoading(true);
        getAllUserGroups()
            .then((data) => {
                setData(data);
                setDisplayData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    const loadUserGroupById = (id) => {
        setLoading(true);
        getUserGroupById(id)
            .then((data) => {
                setUserGroup({ id: data?.id, name: data?.name, users: data?.users });
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    const loadAllUsers = () => {
        setLoading(true);
        getAllUsers()
            .then((data) => {
                const options = data.map((item) => {
                    return { label: item.name, value: item.id };
                });
                setListUsers(options);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading users list");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        setLoading(false);
    };

    //Use Effects ===========================================================

    //Loading userGroups...
    useEffect(() => {
        loadAllUserGroups();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //update # pages when displayData || pageSize changes
    useEffect(() => {
        pageSize === "All" ? setNumPages(1) : setNumPages(Math.ceil(displayData.length / pageSize));
    }, [displayData, pageSize]);

    //update display data when data || filter changes
    useEffect(() => {
        setDisplayData(
            !filter
                ? data
                : data.filter(({ name }) => {
                      return name.toLowerCase().includes(filter);
                  })
        );

        //when filter, reset to page 1
        setPageNumber(1);
    }, [data, filter]);

    //========================================================================

    return (
        <section id="UserGroupListSection">
            <div className="row">
                <div className="col-md-11 offset-md-1">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="text-primary py-3">Group Management</h2>
                        </div>
                        <div className="col-md-6">
                            {operationError && <ErrorMessage msg={operationError} />}
                            {successMsg && <SuccessMessage msg={successMsg} />}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-9">
                            <button type="button" className="btn btn-primary btn-sm" onClick={handleAddNewClick}>
                                Add New Group
                            </button>
                            {loading && <Loading />}
                        </div>
                        <div className="col-md-3 align-end">
                            <div className="input-group">
                                <input type="text" className="form-control form-control-sm" placeholder="Search...." onChange={handleSearchInput} />
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Reload" onClick={handleReloadClick}>
                                    <i className="fa fa-refresh"></i>
                                </button>
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Download" onClick={handleDownloadClick}>
                                    <i className="fa fa-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped table-hover table-sm userGroupList mt-1">
                        <thead>
                            <tr>
                                <th className="sort" onClick={() => handleSortClick("name", "string")}>
                                    Group Name
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <UserGroupListTableBody currentPage={Number(pageNumber)} numberPerPage={Number(pageSize)} items={displayData} filter={filter} handleEditClick={handleEditClick} handleRemoveClick={handleRemoveClick} loading={loading} loadingError={loadingError} />
                        </tbody>
                        <tfoot>
                            <UserGroupListTableFooter handlePageNumberChange={handlePageNumberChange} handlePageSizeChange={handlePageSizeChange} numPages={numPages} numRecords={displayData.length} />
                        </tfoot>
                    </table>
                </div>
            </div>
            <ModalDeleteConfirmation show={showConfirmDelete} setShow={setShowConfirmDelete} handleConfirmRemoveClick={handleConfirmRemoveClick} id={id} />
            <UserGroupEditDialog show={show} setShow={setShow} handleSaveClick={handleSaveUserGroupClick} handleClose={handleCloseClick} listUsers={listUsers} userGroup={userGroup} setUserGroup={setUserGroup} />
        </section>
    );
};

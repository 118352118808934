import React from 'react';
import styles from '../styles/common.module.css'

const TextInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group">
        <label for="formControlInput1" className={`mb-1 ${styles.labelStyling}`}>{meta.label}</label>
        <input type="text" maxLength={meta.maxLengthVal} className={`form-control ${styles.modalInputStyling}`} placeholder={`${meta.label}`} {...handler()} disabled={meta.disabled} />
        <span className="d-block text-danger">
            {touched
                && hasError("required")
                && `${meta.label} is required `}
            {hasError("email")
                && `${meta.emailErrMsg} `}
            {hasError("pattern") && `${meta.patternErrMsg}`}
            {hasError("maxLength") && `Max Length of ${meta.maxLengthVal} Exceeded `}
            {hasError("minLength") && `Min Length of ${meta.minLengthVal} Required `}
            {hasError(meta.customError) && `${meta.customErrorMsg}`}
        </span>
    </div>
);

export default TextInput;
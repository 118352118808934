import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { callAPI } from '../../../lib/Global.js';
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import TextInput from '../../../Controls/TextInput.js';
import TextArea from '../../../Controls/TextArea.js';
import SelectInput from '../../../Controls/SelectInput.js';
import { swalAlert, swalToast2 } from '../../../lib/Swal.js';
import CheckBox from '../../../Controls/CheckBox.js';

const ClientJournalViewModal = (props) => {

    const closeModal = (val) => {
        props.toggleJournalForClientViewModal(val)
    }

    return (
        <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-success" id="exampleModalLabel">Journal Detail</h5>
                        <i className="bi bi-x-lg" onClick={() => closeModal(false)} style={{ cursor: 'pointer' }}></i>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="row">
                                <div className="col-4 my-4">
                                    <label className="d-block text-center">Entered:</label>
                                </div>
                                <div className="col-8 my-4">
                                    {props.journalViewData?.createBy}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label className="d-block text-center">Category:</label>
                                </div>
                                <div className="col-8">
                                    {props.journalViewData?.category}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 my-4">
                                    <label className="d-block text-center">Medium:</label>
                                </div>
                                <div className="col-8 my-4">
                                    {props.journalViewData?.medium}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 my-2">
                                    <label className="d-block text-center">Journal:</label>
                                </div>
                                <div className="col-8 my-2">
                                    <textArea>{props.journalViewData?.journal}</textArea>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientJournalViewModal;
import React from 'react';
import EmailLogin from './EmailLogin.js';
import ValidateLoginOtp from './ValidateLoginOtp.js';
import { callAPIWithpathParameter } from '../../../../lib/Global.js';
import StaticProperties from '../../../../utils/StaticProperties.js';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { dispLoginOtpForm: false, email: null };
        sessionStorage.removeItem('token'); // this will ensure, removal of any logged in user details are removed.
    }

    async componentDidMount() {
        const r = await callAPIWithpathParameter("RolePermissions", '', 'GET', '', "Admin");
        StaticProperties.allUserRoles = r?.data;
    }

    dispLoginOtpForm = (email) => {
        this.setState({ dispLoginOtpForm: true, email: email });
    }

    markAsLogin = (token) => {
        this.props.markAsLogin(token);
    }

    render() {
        const dispLoginOtpForm = this.state.dispLoginOtpForm;
        let displayableForm;
        if (dispLoginOtpForm) {
            displayableForm = <ValidateLoginOtp markAsLogin={this.markAsLogin} loginSuccessEmail={this.state.email} />
        } else {
            displayableForm = <EmailLogin onLoginSuccess={this.dispLoginOtpForm} />
        }
        return (
            <>
                {displayableForm}
            </>
        );
    }
}

export default Login;
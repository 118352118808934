import { useEffect, useState } from "react";
import { ErrorMessage } from "../../../../Shared/errorMessage/errorMessage";
import { Loading } from "../../../../Shared/loading/loading";
import { getAllBasicIntakeLimitations } from "../admin/components/basicIntakeLimitationList/basicIntakeLimitationListRest";
import { getAllBasicIntakeNeeds } from "../admin/components/basicIntakeNeedsList/basicIntakeNeedListRest";
import { getAllHospices } from "../admin/components/hospiceList/hospiceListRest";
import { createClientBasicIntake, getClientBasicIntakeById, updateClientBasicIntake } from "./clientBasicIntakeRest.js";
import { ClientBasicIntakeEditDialog } from "./components/clientBasicIntakeEditDialog";
import "./clientBasicIntake.css";

export const ClientBasicIntake = ({ clientId }) => {
    //Client Basic Intake data flag state
    const [basicIntake, setBasicIntake] = useState({
        id: null,
        reasonForCare: "",
        hadCareInPast: true,
        diagnosis: "",
        limitations: [],
        clientNeeds: [],
        mentalStatus: [],
        hospice: {
            name: "",
        },
    });

    //Client Needs List state
    const [listClientNeeds, setListClientNeeds] = useState([]);

    //Limitations List state
    const [listLimitations, setListLimitations] = useState([]);

    //Hospice List state
    const [listHospices, setListHospices] = useState([]);

    //Loading data flag state
    const [loading, setLoading] = useState(false);

    //Modal display state
    const [showBasicIntakeModal, setShowBasicIntakeModal] = useState(false);

    //errors state
    const [operationError, setOperationError] = useState(null);

    //Use Effects ===========================================================

    //Loading hospices...
    useEffect(() => {
        loadAllClientNeeds();
        loadAllHospices();
        loadAllLimitations();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Loading client Basic Intake...
    useEffect(() => {
        loadClientBasicIntake(clientId);
    }, [clientId]); // eslint-disable-line react-hooks/exhaustive-deps

    //Event handlers =====================================================

    //handle open edit basic intake dialog
    const handleEditBasicIntakeClick = () => setShowBasicIntakeModal(true);

    //handle close edit basic intake dialog
    const handleCloseClick = () => setShowBasicIntakeModal(false);

    //handle save basic intake click
    const handleSaveBasicIntakeClick = ({ diagnosis, reasonForCare, hadCareInPast, hospiceName, hospiceId, limitations, clientNeeds, mentalStatus }) => {
        if (basicIntake.id) {
            updateClientBasicIntake({
                id: basicIntake.id,
                clientId: clientId,
                reasonForCare: reasonForCare,
                hadCareInPast: hadCareInPast === "Yes",
                diagnosis: diagnosis,
                hospiceId: hospiceId,
                limitations: limitations,
                clientNeeds: clientNeeds,
                mentalStatus: mentalStatus,
            })
                .then((ret) => {
                    setBasicIntake(ret);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        } else {
            createClientBasicIntake({
                clientId: clientId,
                reasonForCare: reasonForCare,
                hadCareInPast: hadCareInPast === "Yes",
                diagnosis: diagnosis,
                hospiceId: hospiceId,
                limitations: limitations,
                clientNeeds: clientNeeds,
                mentalStatus: mentalStatus,
            })
                .then((ret) => {
                    setBasicIntake(ret);
                })
                .catch((err) => {
                    try {
                        setOperationError(() => (isError(err) ? err.message : err));
                    } catch (error) {
                        setOperationError("Error saving data (fetch)");
                    }
                    //hide message after 5s
                    setTimeout(() => setOperationError(null), 5000);
                });
        }
    };

    //Methods ===============================================================

    const isError = (obj) => Object.prototype.toString.call(obj) === "[object Error]";

    const loadAllClientNeeds = () => {
        setLoading(true);
        getAllBasicIntakeNeeds()
            .then((data) => {
                const options = data.map((item) => {
                    return { label: item.name, value: item.name };
                });
                setListClientNeeds(options);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading hospice list");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        setLoading(false);
    };

    const loadAllHospices = () => {
        setLoading(true);
        getAllHospices()
            .then((data) => {
                setListHospices(data);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading hospice list");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        setLoading(false);
    };

    const loadAllLimitations = () => {
        setLoading(true);
        getAllBasicIntakeLimitations()
            .then((data) => {
                const options = data.map((item) => {
                    return { label: item.name, value: item.name };
                });
                setListLimitations(options);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading hospice list");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        setLoading(false);
    };

    const loadClientBasicIntake = (id) => {
        setLoading(true);
        getClientBasicIntakeById(id)
            .then((data) => {
                setBasicIntake(data);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error loading basic intake");
                }

                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });

        setLoading(false);
    };

    //Render ===============================================================

    return (
        <section id="BasicIntakeSection">
            <h5 className="bold-style" style={{ fontSize: "14px" }}>
                Basic Intake
            </h5>
            <hr />

            <div className="row">
                <div className="col-md-8">{operationError && <ErrorMessage msg={operationError} />}</div>
            </div>

            <div className="row">
                <div className="col-md-2 text-end">{loading && <Loading />}</div>
            </div>

            <div className="row py-3">
                <div className="col-md-3">
                    <label className={`mb-1 d-block labelStyling`}>Reason for Care</label>
                    <span className="regular-style text-primary">{basicIntake.reasonForCare}</span>
                </div>
                <div className="col-md-3">
                    <label className={`mb-1 d-block labelStyling`}>Had Care in the Past?</label>
                    <span className="regular-style text-primary">{basicIntake.id ? (basicIntake.hadCareInPast ? "Yes" : "No") : ""}</span>
                </div>
                <div className="col-md-3">
                    <label className={`mb-1 d-block labelStyling`}>Diagnosis</label>
                    <span className="regular-style text-primary">{basicIntake.diagnosis}</span>
                </div>
                <div className="col-md-3">
                    <label className={`mb-1 d-block labelStyling`}>Hospice</label>
                    <span className="regular-style text-primary">{basicIntake.hospice?.name}</span>
                </div>
            </div>

            <div className="row pt-3">
                <div className="col-md-3">
                    <label className={`mb-1 d-block labelStyling`}>Limitations</label>
                    <span className="regular-style text-primary">
                        {basicIntake.limitations.map((i) => {
                            return (
                                <>
                                    {i}
                                    <br />
                                </>
                            );
                        })}
                    </span>
                </div>
                <div className="col-md-3">
                    <label className={`mb-1 d-block labelStyling`}>Client Needs</label>
                    <span className="regular-style text-primary">
                        {basicIntake.clientNeeds.map((i) => {
                            return (
                                <>
                                    {i}
                                    <br />
                                </>
                            );
                        })}
                    </span>
                </div>

                <div className="col-md-3">
                    <label className={`mb-1 d-block labelStyling`}>Mental Status</label>
                    <span className="regular-style text-primary">
                        {basicIntake.mentalStatus.map((i) => {
                            return (
                                <>
                                    {i.name}:&nbsp;&nbsp;{i.value}
                                    <br />
                                </>
                            );
                        })}
                    </span>
                </div>
            </div>

            <div className="row pt-3">
                <div className="col-md-2">
                    <button type="button" className="btn btn-primary btn-sm" onClick={handleEditBasicIntakeClick}>
                        Edit Basic Intake
                    </button>
                </div>
            </div>
            <hr />

            <ClientBasicIntakeEditDialog show={showBasicIntakeModal} setShow={setShowBasicIntakeModal} handleClose={handleCloseClick} handleSaveClick={handleSaveBasicIntakeClick} listHospices={listHospices} listLimitations={listLimitations} listClientNeeds={listClientNeeds} basicIntake={basicIntake} />
        </section>
    );
};
////

import { useEffect, useState } from "react";
import { OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import Select from "react-select";

export const ClientHALaceTool = ({ laceToolData, setLaceToolData, acknowledgeMode, laceToolOptionsList }) => {
    //=== States =============================================================

    //Lace Score state
    const [laceToolScore, setLaceToolScore] = useState(0);

    //Use Effects ===========================================================

    //Loading lace tool and fallRisk dropdown options...
    useEffect(() => {
        //1. Length of stay
        let score = parseInt(laceToolData?.lengthOfStay ? laceToolData?.lengthOfStay : 0);

        //2. Acute Admission
        score += parseInt(laceToolData?.acuteAdmission === "Yes" ? 7 : 0);

        //3. Comorbidity
        const scoreComorbidity = laceToolData?.comorbidity?.reduce((acc, item) => {
            return acc + laceToolOptionsList.comorbidityList.find((i) => i.value === item)?.score;
        }, 0);

        score += scoreComorbidity ? scoreComorbidity : 0;

        //4. Emergency Visits
        score += parseInt(laceToolData?.emergencyDepartmentVisits ? laceToolData?.emergencyDepartmentVisits : 0);

        setLaceToolScore(() => score);
    }, [laceToolData, laceToolOptionsList]);

    //=== Handlers ===========================================================

    const lengthOfStayChangeHandler = (e) => {
        const laceToolDataNew = { ...laceToolData, lengthOfStay: e.target.value };
        setLaceToolData(laceToolDataNew);
    };

    const acuteAdmissionChangeHandler = (e) => {
        const laceToolDataNew = { ...laceToolData, acuteAdmission: e.target.value };
        setLaceToolData(laceToolDataNew);
    };

    const comorbidityChangeHandler = (e) => {
        const laceToolDataNew = { ...laceToolData, comorbidity: e?.map((i) => i.value) };
        setLaceToolData(laceToolDataNew);
    };

    const emergencyDepartmentVisitsChangeHandler = (e) => {
        const laceToolDataNew = { ...laceToolData, emergencyDepartmentVisits: e.target.value };
        setLaceToolData(laceToolDataNew);
    };

    //=== Popovers  ==========================================================
    const lengthStayPopover = (
        <Popover id="lengthStayPopover">
            <PopoverBody>Including day of admission and discharge.</PopoverBody>
        </Popover>
    );

    const acuteAdmissionPopover = (
        <Popover id="acuteAdmissionPopover">
            <PopoverBody>Was the client admitted to hospital via the emergency department?</PopoverBody>
        </Popover>
    );

    const emergencyDepartmentVisitsPopover = (
        <Popover id="emergencyDepartmentVisitsPopover">
            <PopoverBody>How many times has the client visited an emergency department in the 6 months prior to admission (not including the emergency department visit immediately preceding the current admission)? Enter this number or 4 (whichever is smaller)</PopoverBody>
        </Popover>
    );

    //=== Render  ============================================================

    return (
        <section id="clientHealthAssessmentLaceTool">
            <>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="lengthOfStay" className={`mb-1`}>
                                Length of Stay
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={lengthStayPopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </label>

                            {acknowledgeMode && (
                                <>
                                    <br></br>
                                    <div className="ps-4 text-primary fw-bold">{laceToolData?.lengthOfStay}</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <select className={"form-select"} id="lengthOfStay" onChange={lengthOfStayChangeHandler} defaultValue={laceToolData?.lengthOfStay}>
                                    <option value="">Please Select</option>
                                    {laceToolOptionsList?.lengthOfStayList.map((item) => (
                                        <option key={item.score} value={item.score}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            )}

                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="col-6">
                        <>
                            <span className="my-1 d-block">
                                Acute Admission?
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={acuteAdmissionPopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </span>

                            {acknowledgeMode && (
                                <>
                                    <div className="ps-4 text-primary fw-bold">{laceToolData?.acuteAdmission}</div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Yes" name="acuteAdmission" onChange={acuteAdmissionChangeHandler} checked={laceToolData?.acuteAdmission === "Yes"} />
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="No" name="acuteAdmission" onChange={acuteAdmissionChangeHandler} checked={laceToolData?.acuteAdmission === "No"} />
                                        <label className="form-check-label">No</label>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {acknowledgeMode && (
                            <>
                                <label htmlFor="lengthStay" className={`mb-1`}>
                                    Comorbidity
                                </label>
                                <div className="row ps-4 text-primary fw-bold">
                                    {laceToolData?.comorbidity
                                        ?.sort(function (a, b) {
                                            if (a < b) {
                                                return -1;
                                            }
                                            if (a > b) {
                                                return 1;
                                            }
                                            return 0;
                                        })
                                        .map((item) => (
                                            <div className="col-6">
                                                <li>{item}</li>
                                            </div>
                                        ))}
                                </div>
                                <div className="d-block text-danger small text-end">&nbsp;</div>
                            </>
                        )}

                        {!acknowledgeMode && (
                            <>
                                <label htmlFor="comorbidity" className={`mb-1`}>
                                    Comorbidity
                                </label>
                                <Select isMulti options={laceToolOptionsList?.comorbidityList} value={laceToolOptionsList?.comorbidityList.value} defaultValue={laceToolData?.comorbidity?.map((frd) => laceToolOptionsList?.comorbidityList?.find((i) => i.value === frd))} onChange={comorbidityChangeHandler}></Select>
                                <div className="d-block text-danger small text-end">&nbsp;</div>
                            </>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="lengthStay" className={`mb-1`}>
                                Emergency Department Visits
                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={emergencyDepartmentVisitsPopover}>
                                    <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </label>

                            {acknowledgeMode && (
                                <>
                                    <br></br>
                                    <div className="ps-4 text-primary fw-bold">{laceToolData?.emergencyDepartmentVisits}</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <select className="form-select" id="emergencyDepartmentVisits" onChange={emergencyDepartmentVisitsChangeHandler} defaultValue={laceToolData.emergencyDepartmentVisits}>
                                    {laceToolOptionsList.emergencyVisitList.map((item) => (
                                        <option key={item.score} value={item.score}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </>

            <div className="col-6">
                <LaceToolScore score={laceToolScore} />
            </div>
        </section>
    );
};

//====================================================================

const LaceToolScore = ({ score }) => {
    return (
        <div className="form-group">
            <label className={`mb-1`}>Lace Score</label>
            <br></br>
            <div className="ps-4 text-primary fw-bold">{score}</div>
        </div>
    );
};

import React from 'react';
import { withRouter } from 'react-router-dom';
import { swalAlert, swalToast, swalConfirmOptions, swalToast2 } from './../../../../lib/Swal.js';
import { callAPI, getQueryStringValue, redirectToLandingPage } from '../../../../lib/Global.js';

class SSOAssert extends React.Component {
    /*constructor(props) {
        super(props);
        this.state = { isLoading: false };
    }*/

    componentDidMount() {
        const isMobile = getQueryStringValue('isMobile');
        const isError = getQueryStringValue('isError');
        const token = getQueryStringValue('tok');
        const isFirstTimeUser = getQueryStringValue('isFirstTimeUser');

        const isLocalUserAlreadyExistsWithThisEmail = getQueryStringValue('isLocalUserAlreadyExistsWithThisEmail');
        const uid = getQueryStringValue('uid');
        const newUserData = getQueryStringValue('newUserData');

        if (isError.toLowerCase() === 'true') {
            swalAlert('e', 'Something went wrong!!!, please try again later.');
            this.props.history.push('/login');
        }

        if (isLocalUserAlreadyExistsWithThisEmail && isLocalUserAlreadyExistsWithThisEmail?.toLowerCase() === 'true') {

            const msg = 'Are you sure you want to overwrite local user?';

            swalConfirmOptions.fire({ title: msg }).then(async (result) => {

                if (result.value) {
                    const v = JSON.parse(decodeURIComponent(newUserData));

                    const newUser = {
                        id: uid,
                        firstName: v.firstName,
                        lastName: v.lastName,
                        email: v.email
                    }

                    await callAPI("OverWriteSSO", newUser, "PUT");
                    swalToast2(`Local User is overwritten successfully.`);

                }
                this.props.markAsLogin(JSON.stringify(token));
                const route = redirectToLandingPage('client');
                this.props.history.push(route);
            });

        } else if (token) {
            this.props.markAsLogin(JSON.stringify(token));
            const route = redirectToLandingPage('client');
            this.props.history.push(route);
        }
    }

    /* Not using this method
    handleSubmit = async (e) => {
        e?.preventDefault();
        const ssoReqBody = { "SAMLResponse": this.state.SAMLResponse, "AccessToken": this.state.SAMLResponse, "IdToken": this.state.SAMLResponse };
        const r = await callAPI('SSOAssert', ssoReqBody);
        if (r.token) {
            this.props.markAsLogin(JSON.stringify(r.token));
            this.props.history.push('home');
        } else {
            const errorMsg = `<span class="text-danger">Something went wrong!!!</span>`;
            swalAlert('e', errorMsg);
        }
    }*/

    /* useful only oath2 mode -- not using now so keep it for future ref/change
    getSSOQueryStringValue = (variable) => {
        var query = window.location.hash;
        if (!query) {
            return (false);
        }

        var vars = query.split("&");

        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }

        return (false);
    }

    componentDidMount() {
        const access_token = this.getSSOQueryStringValue('#access_token');
        const id_token = this.getSSOQueryStringValue('id_token');

        this.setState({ access_token, id_token });
        setTimeout(() => {
            this.handleSubmit();
        }, 100);
    }

    handleSubmit = async (e) => {
        e?.preventDefault();
        const ssoReqBody = { "AccessToken": this.state.access_token, "IdToken": this.state.id_token };
        const r = await callAPI('SSOAssert', ssoReqBody);
        if (r.token) {
            this.props.markAsLogin(JSON.stringify(r.token));
            this.props.history.push('home');
        } else {
            const errorMsg = `<span class="text-danger">Something went wrong!!!</span>`;
            swalAlert('e', errorMsg);
        }
    }
    */

    render() {
        return (
            <>
                <div className="container d-flex align-items-center flex-column position-absolute top-50 start-50 translate-middle">
                    <h3 className="card-title">Please wait....</h3>
                </div>
            </>
        );
    }
}

export default withRouter(SSOAssert);
import moment from "moment";
import "moment-timezone";

export const ClientHealthAssessmentListTableBody = ({ currentPage, numberPerPage, items, loadingError, loading, handleEditClick, handleAcknowledgeClick, handleViewClick }) => {
    const begin = (Number(currentPage) - 1) * Number(numberPerPage);
    const end = begin + Number(numberPerPage);
    const data = items.slice(begin, end);

    if (loadingError)
        return (
            <tr>
                <td colSpan="5" className="table-danger text-center">
                    {loadingError}
                </td>
            </tr>
        );

    return data.length === 0
        ? !loading && (
              <tr>
                  <td colSpan="5" className="table-danger text-center">
                      No Assessments to display
                  </td>
              </tr>
          )
        : data.map(({ completedOn, completedBy, acknowledgeOn, acknowledgeBy, id }) => {
              return (
                  <tr key={id}>
                      <td>{moment(completedOn).utc().tz("America/New_York").format("MM/DD/YYYY hh:mm a")}</td>
                      <td>{completedBy}</td>
                      <td>{acknowledgeOn ? moment(acknowledgeOn).utc().tz("America/New_York").format("MM/DD/YYYY hh:mm a") : "-"}</td>
                      <td>{acknowledgeBy}</td>
                      <td className="text-end">
                          {!acknowledgeOn && (
                              <>
                                  <button
                                      type="button"
                                      className="btn btn-sm btn-primary "
                                      onClick={() => {
                                          handleEditClick(id);
                                      }}
                                  >
                                      <i className="fa fa-pencil" title="Edit"></i>
                                  </button>

                                  <button
                                      type="button"
                                      className="btn btn-sm btn-outline-secondary ms-2"
                                      onClick={() => {
                                          handleAcknowledgeClick(id);
                                      }}
                                  >
                                      <i className="fa fa-check" title="Acknowledge"></i>
                                  </button>
                              </>
                          )}

                          {acknowledgeOn && (
                              <button
                                  type="button"
                                  className="btn btn-sm btn-primary ms-2"
                                  onClick={() => {
                                      handleViewClick(id);
                                  }}
                              >
                                  <i className="fa fa-eye" title="View"></i>
                              </button>
                          )}
                      </td>
                  </tr>
              );
          });
};

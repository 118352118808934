import React, { useEffect, useState } from 'react';
import { callAPI } from '../../../lib/Global.js';
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import { swalAlert, swalToast2 } from '../../../lib/Swal.js';
import styles from "../../../styles/common.module.css";

const SelectInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group d-flex" >
        <label for="formControlSelect1" className={`mb-1 ${styles.labelStyling} col-6`}>{meta.label}</label>
        <div className="col-2 offset-1">
            <select onClick={meta.toggleStatus} multiple={meta.multiple} size={meta.size} className="form-control form-select" id="exampleFormControlSelect1" {...handler()}>
                <option disabled={true} selected value="">-- Please Select --</option>
                {
                    meta.options?.map((m, i) => {
                        return (
                            <option key={i} value={m.value ? m.value : m.text}>{m.text}</option>
                        );
                    })
                }
            </select>
            <span className="d-block text-danger mt-1">
                {touched && hasError("required")
                    && `Please select an option`}
            </span>
        </div>
    </div >
);

const clientPsycotherapyRiskForm = FormBuilder.group({
    id: null,
    clientId: [null, Validators.required],
    botheredByLittleInterestOrThings: [null, Validators.required],
    botheredByfeelingDownOrDepressed: [null, Validators.required],
    littleInterestOrPleasureInThings: [0, Validators.required],
    feelingDownOrDepressedOrHopeless: [0, Validators.required],
    troubleFallingAsleepOrSleepingTooMuch: [0, Validators.required],
    feelingTiredOrLittleEnergy: [0, Validators.required],
    poorApetiteOrOverEating: [0, Validators.required],
    feeelingBadAboutYourself: [0, Validators.required],
    troubleConcentratingOnThings: [0, Validators.required],
    movingOrSpeakingSlowly: [0, Validators.required],
    thoughtsThatWouldBeBetterOff: [0, Validators.required],
    checkProblem: [null, Validators.required],
    score: 0
});

const ClientPsycotherapyRiskAssessment = ({ clientId }) => {
    const [isLoading, setisLoading] = useState(false);
    const [psycotherapyRiskScore, setPsycotherapyRiskScore] = useState(0);

    useEffect(() => {
        getPsycotherapyRiskAssessmentForClient(clientId);

        clientPsycotherapyRiskForm.get("littleInterestOrPleasureInThings").valueChanges.subscribe((selectedValue) => {
            calculatePsycotherapyRiskScore();
        });
        clientPsycotherapyRiskForm.get("feelingDownOrDepressedOrHopeless").valueChanges.subscribe((selectedValue) => {
            calculatePsycotherapyRiskScore();
        });
        clientPsycotherapyRiskForm.get("troubleFallingAsleepOrSleepingTooMuch").valueChanges.subscribe((selectedValue) => {
            calculatePsycotherapyRiskScore();
        });
        clientPsycotherapyRiskForm.get("feelingTiredOrLittleEnergy").valueChanges.subscribe((selectedValue) => {
            calculatePsycotherapyRiskScore();
        });
        clientPsycotherapyRiskForm.get("poorApetiteOrOverEating").valueChanges.subscribe((selectedValue) => {
            calculatePsycotherapyRiskScore();
        });
        clientPsycotherapyRiskForm.get("feeelingBadAboutYourself").valueChanges.subscribe((selectedValue) => {
            calculatePsycotherapyRiskScore();
        });
        clientPsycotherapyRiskForm.get("troubleConcentratingOnThings").valueChanges.subscribe((selectedValue) => {
            calculatePsycotherapyRiskScore();
        });
        clientPsycotherapyRiskForm.get("movingOrSpeakingSlowly").valueChanges.subscribe((selectedValue) => {
            calculatePsycotherapyRiskScore();
        });
        clientPsycotherapyRiskForm.get("thoughtsThatWouldBeBetterOff").valueChanges.subscribe((selectedValue) => {
            calculatePsycotherapyRiskScore();
        });

        return () => {
            clientPsycotherapyRiskForm.reset();
            clientPsycotherapyRiskForm.get('littleInterestOrPleasureInThings').valueChanges.unsubscribe();
            clientPsycotherapyRiskForm.get("thoughtsThatWouldBeBetterOff").valueChanges.unsubscribe();
            clientPsycotherapyRiskForm.get("movingOrSpeakingSlowly").valueChanges.unsubscribe();
        }
    }, [clientId]);

    const getPsycotherapyRiskAssessmentForClient = async (clientId) => {
        setisLoading(true)
        const r = await callAPI("GetPsycotherapyRiskByClientId", { id: clientId });

        if (r) {
            clientPsycotherapyRiskForm.patchValue({ ...r });
            setPsycotherapyRiskScore(r.score);
            clientPsycotherapyRiskForm.updateValueAndValidity(clientPsycotherapyRiskForm.value);
        } else {
            clientPsycotherapyRiskForm.patchValue({ clientId: clientId });
        }
        setisLoading(false);
        console.log(clientPsycotherapyRiskForm.value);
    }


    const calculatePsycotherapyRiskScore = () => {
        const psycotherapyRiskArr = [clientPsycotherapyRiskForm.get("littleInterestOrPleasureInThings").value,
        clientPsycotherapyRiskForm.get("feelingDownOrDepressedOrHopeless").value,
        clientPsycotherapyRiskForm.get("troubleFallingAsleepOrSleepingTooMuch").value,
        clientPsycotherapyRiskForm.get("feelingTiredOrLittleEnergy").value,
        clientPsycotherapyRiskForm.get("poorApetiteOrOverEating").value,
        clientPsycotherapyRiskForm.get("feeelingBadAboutYourself").value,
        clientPsycotherapyRiskForm.get("troubleConcentratingOnThings").value,
        clientPsycotherapyRiskForm.get("movingOrSpeakingSlowly").value,
        clientPsycotherapyRiskForm.get("thoughtsThatWouldBeBetterOff").value];
        const score = psycotherapyRiskArr.reduce(
            (previousValue, currentValue) => {
                let num1 = isNaN(parseInt(previousValue)) ? 0 : parseInt(previousValue);
                let num2 = isNaN(parseInt(currentValue)) ? 0 : parseInt(currentValue);
                return num1 + num2;
            },
            0
        );
        clientPsycotherapyRiskForm.patchValue({ score: score });
        setPsycotherapyRiskScore(score);
        clientPsycotherapyRiskForm.updateValueAndValidity(clientPsycotherapyRiskForm.value);
    }

    const handleSave = async () => {
        console.log(clientPsycotherapyRiskForm.value);
        clientPsycotherapyRiskForm.updateValueAndValidity(clientPsycotherapyRiskForm.value);
        setisLoading(true);

        const apiText = clientPsycotherapyRiskForm.value.id ? 'UpdatePsycotherapyRiskForClient' : 'CreatePsycotherapyRiskForClient';
        let message = clientPsycotherapyRiskForm.value.id !== null ? 'Updated' : 'Saved';
        const r = await callAPI(apiText, clientPsycotherapyRiskForm.value);
        setisLoading(false);
        clientPsycotherapyRiskForm.updateValueAndValidity(clientPsycotherapyRiskForm.value);

        if (r.isSuccess) {
            swalToast2('Psycotherapy Risk Assessment ' + message + ' successfully.');
            getPsycotherapyRiskAssessmentForClient(clientId);
            clientPsycotherapyRiskForm.markAsPristine();
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    const patchCheckBoxVal = (obj) => {
        clientPsycotherapyRiskForm.patchValue(obj);
        clientPsycotherapyRiskForm.markAsDirty();
    }

    return (
        <>
            <div className="row mb-2">
                <div className="col-6">
                    <h5 className="bold-style" style={{ fontSize: '14px' }}>Patient Health Questionnaire</h5>
                </div>
                <div className="col-6 d-flex align-items-center">
                    <h5 className="bold-style" style={{ fontSize: '14px' }}>Score:</h5>
                    <span className="semi-bold-style ms-2" style={{ fontSize: '14px', color: '#3CAF8A', marginBottom: "0.5rem" }}>{psycotherapyRiskScore}</span>
                </div>
            </div>
            <hr />
            {isLoading ? <div>Please Wait...</div> :
                <div>
                    <FieldGroup
                        control={clientPsycotherapyRiskForm}
                        render={({ get, invalid, pristine, dirty }) => (
                            <form>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="botheredByLittleInterestOrThings"
                                            render={({ handler, touched, hasError, meta }) => (
                                                <>
                                                    <label for="formCheck1" className={`mb-1 ${styles.labelStyling} col-6`}>{meta.label}</label>
                                                    <div class="form-check d-inline-block offset-1 col-1">
                                                        <input type="checkbox" className="form-check-input" checked={clientPsycotherapyRiskForm.value.botheredByLittleInterestOrThings === "Yes"} onClick={() => patchCheckBoxVal({ botheredByLittleInterestOrThings: "Yes" })} />
                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioYou">
                                                            Yes
                                                        </label>

                                                    </div>
                                                    <div className="form-check d-inline-block col-1">
                                                        <input type="checkbox" className="form-check-input" checked={clientPsycotherapyRiskForm.value.botheredByLittleInterestOrThings === "No"} onClick={() => patchCheckBoxVal({ botheredByLittleInterestOrThings: "No" })} />
                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                            No
                                                        </label>
                                                    </div></>
                                            )}
                                            meta={{
                                                label: "During the past two weeks, have you often been bothered by little interest or pleasure in doing things?",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="botheredByfeelingDownOrDepressed"
                                            render={({ handler, touched, hasError, meta }) => (
                                                <>
                                                    <label for="formCheck1" className={`mb-1 ${styles.labelStyling} col-6`}>{meta.label}</label>
                                                    <div class="form-check d-inline-block offset-1 col-1">
                                                        <input type="checkbox" class="form-check-input" checked={clientPsycotherapyRiskForm.value.botheredByfeelingDownOrDepressed === "Yes"} onClick={() => patchCheckBoxVal({ botheredByfeelingDownOrDepressed: "Yes" })} />
                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioYou">
                                                            Yes
                                                        </label>

                                                    </div>
                                                    <div className="form-check d-inline-block col-1">
                                                        <input type="checkbox" class="form-check-input" checked={clientPsycotherapyRiskForm.value.botheredByfeelingDownOrDepressed === "No"} onClick={() => patchCheckBoxVal({ botheredByfeelingDownOrDepressed: "No" })} />
                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                            No
                                                        </label>
                                                    </div></>
                                            )}
                                            meta={{ label: "During the past two weeks, have you often been bothered by feeling down, depressed or hopeless?", maxLengthVal: 40 }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <label for="formCheck1" className={`mb-1 ${styles.labelStyling} col-6`}>*If the answer to both questions is No,the screen is negative for depression</label>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <label for="formCheck1" className={`mb-1 ${styles.labelStyling} col-6`}>Over the last 2 weeks, how often have you been bothered by any of the following problems?</label>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="littleInterestOrPleasureInThings"
                                            render={SelectInput}
                                            meta={{
                                                label: "Little interest or pleasure in doing things",
                                                options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not at all", value: "0" },
                                                    { text: "Several days", value: 1 },
                                                    { text: "More than half the days", value: 2 },
                                                    { text: "Nearly every day", value: 3 },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="feelingDownOrDepressedOrHopeless"
                                            render={SelectInput}
                                            meta={{
                                                label: "Feeling down, depressed, or hopeless", options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not at all", value: "0" },
                                                    { text: "Several days", value: 1 },
                                                    { text: "More than half the days", value: 2 },
                                                    { text: "Nearly every day", value: 3 },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="troubleFallingAsleepOrSleepingTooMuch"
                                            render={SelectInput}
                                            meta={{
                                                label: "Trouble falling asleep, or staying asleep, or sleeping too much", options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not at all", value: "0" },
                                                    { text: "Several days", value: 1 },
                                                    { text: "More than half the days", value: 2 },
                                                    { text: "Nearly every day", value: 3 },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="feelingTiredOrLittleEnergy"
                                            render={SelectInput}
                                            meta={{
                                                label: "Feeling tired or having little energy", options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not at all", value: "0" },
                                                    { text: "Several days", value: 1 },
                                                    { text: "More than half the days", value: 2 },
                                                    { text: "Nearly every day", value: 3 },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="poorApetiteOrOverEating"
                                            render={SelectInput}
                                            meta={{
                                                label: "Poor appetite or overeating", options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not at all", value: "0" },
                                                    { text: "Several days", value: 1 },
                                                    { text: "More than half the days", value: 2 },
                                                    { text: "Nearly every day", value: 3 },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>



                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="feeelingBadAboutYourself"
                                            render={SelectInput}
                                            meta={{
                                                label: "Feeling bad about yourself - or that you are a failure, or have let yourself or your family down", options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not at all", value: "0" },
                                                    { text: "Several days", value: 1 },
                                                    { text: "More than half the days", value: 2 },
                                                    { text: "Nearly every day", value: 3 },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="troubleConcentratingOnThings"
                                            render={SelectInput}
                                            meta={{
                                                label: "Trouble concentrating on things, such as reading the newspaper or watching television", options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not at all", value: "0" },
                                                    { text: "Several days", value: 1 },
                                                    { text: "More than half the days", value: 2 },
                                                    { text: "Nearly every day", value: 3 },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="movingOrSpeakingSlowly"
                                            render={SelectInput}
                                            meta={{
                                                label: "Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around more than usual", options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not at all", value: "0" },
                                                    { text: "Several days", value: 1 },
                                                    { text: "More than half the days", value: 2 },
                                                    { text: "Nearly every day", value: 3 },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="thoughtsThatWouldBeBetterOff"
                                            render={SelectInput}
                                            meta={{
                                                label: "Thoughts that you would be better off dead or of hurting yourself in some way", options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not at all", value: "0" },
                                                    { text: "Several days", value: 1 },
                                                    { text: "More than half the days", value: 2 },
                                                    { text: "Nearly every day", value: 3 },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="checkProblem"
                                            render={SelectInput}
                                            meta={{
                                                label: "If you checked off any problem, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?", options: [
                                                    // { text: 'Myself' },
                                                    { text: "Not difficult at all" },
                                                    { text: "Somewhat difficult" },
                                                    { text: "Very difficult" },
                                                    { text: "Extremely difficult" },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="d-grid gap-2 pb-1">
                                        <button type="button" onClick={() => handleSave()} className="btn btn-primary" disabled={isLoading || invalid || pristine || !dirty}>{isLoading ? 'Please wait...' : 'Save'}</button>

                                    </div>
                                </div>

                            </form>
                        )}
                    />
                </div>}
        </>
    )
}

export default ClientPsycotherapyRiskAssessment;
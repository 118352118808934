import { useEffect, useState } from "react";
import Select from "react-select";

export const ClientHAFallRiskTool = ({ fallRiskData, setFallRiskData, acknowledgeMode, fallRiskToolOptionsList }) => {
    //=== States =============================================================

    //Fall Risk Score state
    const [fallRiskToolScore, setFallRiskToolScore] = useState(0);

    //Use Effects ===========================================================

    //Loading lace tool and fallRisk dropdown options...
    useEffect(() => {
        //1. Fall Risk Factors
        let score = fallRiskData?.fallRiskFactors?.reduce((acc, item) => {
            return acc + fallRiskToolOptionsList.fallRiskFactorList.find((i) => i.value === item)?.score;
        }, 0);

        score = score ? score : 0;

        //2. Any Administered Antiepileptics
        score += parseInt(fallRiskData?.anyAdministeredAntiepileptics === "Yes" ? 2 : 0);

        //3. Any Administered Benzodiazepines
        score += parseInt(fallRiskData?.anyAdministeredBenzodiazepines === "Yes" ? 1 : 0);

        //4. Get Up and Go Test
        score += parseInt(fallRiskData?.getUpAndGoTest ? fallRiskData?.getUpAndGoTest : 0);

        setFallRiskToolScore(() => score);
    }, [fallRiskData, fallRiskToolOptionsList]);

    //=== Handlers ===========================================================

    const fallRiskFactorsChangeHandler = (e) => {
        const fallRiskDataNew = { ...fallRiskData, fallRiskFactors: e?.map((i) => i.value) };
        setFallRiskData(fallRiskDataNew);
    };

    const anyAdministeredAntiepilepticsChangeHandler = (e) => {
        const fallRiskDataNew = { ...fallRiskData, anyAdministeredAntiepileptics: e.target.value };
        setFallRiskData(fallRiskDataNew);
    };

    const anyAdministeredBenzodiazepinesChangeHandler = (e) => {
        const fallRiskDataNew = { ...fallRiskData, anyAdministeredBenzodiazepines: e.target.value };
        setFallRiskData(fallRiskDataNew);
    };

    const getUpAndGoTestChangeHandler = (e) => {
        const fallRiskDataNew = { ...fallRiskData, getUpAndGoTest: e.target.value };
        setFallRiskData(fallRiskDataNew);
    };

    //=== Render  ============================================================

    return (
        <section id="clientHealthAssessmentFallRiskTool">
            <>
                <div className="row">
                    <div className="col-12">
                        {acknowledgeMode && (
                            <>
                                <label htmlFor="fallRiskFactors" className={`mb-1`}>
                                    Fall Risk Factors
                                </label>
                                <div className="row ps-4 text-primary fw-bold">
                                    {fallRiskData?.fallRiskFactors
                                        ?.sort(function (a, b) {
                                            if (a < b) {
                                                return -1;
                                            }
                                            if (a > b) {
                                                return 1;
                                            }
                                            return 0;
                                        })
                                        .map((item) => (
                                            <div className="col-6">
                                                <li>{item}</li>
                                            </div>
                                        ))}
                                </div>
                                <div className="d-block text-danger small text-end">&nbsp;</div>
                            </>
                        )}

                        {!acknowledgeMode && (
                            <>
                                <label htmlFor="fallRiskFactors" className={`mb-1`}>
                                    Fall Risk Factors
                                </label>
                                <Select isMulti options={fallRiskToolOptionsList?.fallRiskFactorList} value={fallRiskToolOptionsList?.fallRiskFactorList.value} defaultValue={fallRiskData?.fallRiskFactors?.map((frd) => fallRiskToolOptionsList?.fallRiskFactorList?.find((i) => i.value === frd))} onChange={fallRiskFactorsChangeHandler}></Select>
                                <div className="d-block text-danger small text-end">&nbsp;</div>
                            </>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <>
                            <span className="my-1 d-block">Any Administered Antiepileptics?</span>

                            {acknowledgeMode && (
                                <>
                                    <div className="ps-4 text-primary fw-bold">{fallRiskData?.anyAdministeredAntiepileptics}</div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Yes" name="anyAdministeredAntiepileptics" onChange={anyAdministeredAntiepilepticsChangeHandler} checked={fallRiskData?.anyAdministeredAntiepileptics === "Yes"} />
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="No" name="anyAdministeredAntiepileptics" onChange={anyAdministeredAntiepilepticsChangeHandler} checked={fallRiskData?.anyAdministeredAntiepileptics === "No"} />
                                        <label className="form-check-label">No</label>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </>
                    </div>

                    <div className="col-6">
                        <>
                            <span className="my-1 d-block">Any Administered Benzodiazepines?</span>

                            {acknowledgeMode && (
                                <>
                                    <div className="ps-4 text-primary fw-bold">{fallRiskData?.anyAdministeredBenzodiazepines}</div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Yes" name="anyAdministeredBenzodiazepines" onChange={anyAdministeredBenzodiazepinesChangeHandler} checked={fallRiskData?.anyAdministeredBenzodiazepines === "Yes"} />
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="No" name="anyAdministeredBenzodiazepines" onChange={anyAdministeredBenzodiazepinesChangeHandler} checked={fallRiskData?.anyAdministeredBenzodiazepines === "No"} />
                                        <label className="form-check-label">No</label>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="getUpAndGoTest" className={`mb-1`}>
                                Get-Up-and-Go Test
                            </label>

                            {acknowledgeMode && (
                                <>
                                    <br></br>
                                    <div className="ps-4 text-primary fw-bold">{fallRiskToolOptionsList?.getUpAndGoTestList.find((i) => i.score === fallRiskData?.getUpAndGoTest)?.name}</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <select className="form-select" id="getUpAndGoTest" onChange={getUpAndGoTestChangeHandler} defaultValue={fallRiskData.getUpAndGoTest}>
                                    {fallRiskToolOptionsList?.getUpAndGoTestList.map((item) => (
                                        <option key={item.score} value={item.score}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </>

            <div className="col-6">
                <FallRiskToolScore score={fallRiskToolScore} />
            </div>
        </section>
    );
};

//====================================================================

const FallRiskToolScore = ({ score }) => {
    return (
        <div className="form-group">
            <label className={`mb-1`}>Fall Risk Score</label>
            <br></br>
            <div className="ps-4 text-primary fw-bold">{score}</div>
        </div>
    );
};

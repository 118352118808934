import React, { useState, useEffect } from "react";
import ClientJournalModal from "./ClientJournalModal.js";
import ClientJournalViewModal from "./ClientJournalViewModal.js";
import { callAPI, callAPIWithpathParameter } from "../../../lib/Global";
import styles from "./clientInfo.module.css";
import "../../../styles/common.css";
import moment from "moment";
import { swalToast2, swalConfirmOptions } from "../../../lib/Swal.js";

const ClientJournalList = (props) => {
    const { clientId } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [journalList, setJournalList] = useState([]);

    const [showJournalForClientModal, setShowJournalForClientModal] = useState(false);
    const [showJournalForClientViewModal, setShowJournalForClientViewModal] = useState(false);
    const [journalViewData, setJournalViewData] = useState([]);

    const [clientJournalId, setClientJournalId] = useState("");

    useEffect(() => {
        getJournalListForClientById(clientId);
    }, []);

    const deleteJournal = (id) => {
        const msg = "You are about to delete this Journal. Are you sure you want to delete?";
        swalConfirmOptions.fire({ title: msg }).then(async (result) => {
            if (result.value) {
                setIsLoading(true);
                await callAPIWithpathParameter("Journal", id, "DELETE", "");
                setIsLoading(false);
                swalToast2(`Journal successfully deleted.`);
                getJournalListForClientById(clientId);
            }
        });
    };

    const getJournalListForClientById = async (clientId) => {
        setIsLoading(true);
        const r = await callAPI("GetJournalByClientId", { id: clientId });
        setIsLoading(false);
        setJournalList(r);
    };

    const toggleJournalForClientModal = async (isDataToReload, id = "") => {
        if (isDataToReload) {
            getJournalListForClientById(clientId);
        }

        id ? setClientJournalId(id) : setClientJournalId("");

        setShowJournalForClientModal(!showJournalForClientModal);
        props.togglebackDrop();
    };

    const toggleJournalForClientViewModal = (data) => {
        if (data) {
            setJournalViewData(data);
        }
        setShowJournalForClientViewModal(!showJournalForClientViewModal);
        props.togglebackDrop();
    };

    return (
        <div>
            {showJournalForClientModal && <ClientJournalModal toggleJournalForClientModal={toggleJournalForClientModal} clientJournalId={clientJournalId} clientId={clientId} />}
            {showJournalForClientViewModal && <ClientJournalViewModal clientId={clientId} toggleJournalForClientViewModal={toggleJournalForClientViewModal} journalViewData={journalViewData} />}

            {isLoading ? (
                <div>Please Wait...</div>
            ) : (
                <>
                    <h5 className="bold-style" style={{ fontSize: "14px" }}>
                        Journals
                    </h5>
                    <hr />
                    <div className={styles.addContactWrapper} onClick={() => toggleJournalForClientModal(false)}>
                        <i class="bi bi-plus text-success"></i>
                        <span className="medium-style hover-item" style={{ color: "#3CAF8A" }}>
                            {" "}
                            Create Journal
                        </span>
                    </div>
                </>
            )}

            {!isLoading && (
                <table class="table table-hover table-sm table-borderless">
                    <thead>
                        <tr className={styles.tHeadStyling}>
                            <th scope="col">
                                {" "}
                                <span className="medium-style">Date</span>
                            </th>
                            <th scope="col">
                                <span className="medium-style">Category</span>
                            </th>
                            <th scope="col">
                                <span className="medium-style">Sub Category</span>
                            </th>
                            <th scope="col">
                                <span className="medium-style">Entered by</span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {journalList?.map((d, i) => {
                            return (
                                <tr key={i} className="border-bottom">
                                    <td width="20%" className="medium-style">
                                        {moment(d.createDt).format("MM/DD/YYYY hh:mm A")}
                                    </td>
                                    <td width="20%" className="medium-style pt-2">
                                        {d.category}
                                    </td>
                                    <td width="20%" className="medium-style pt-2">
                                        {d.subCategory}
                                    </td>
                                    <td width="20%" className="medium-style pt-2">
                                        {d.createBy}
                                    </td>
                                    <td width="20%" align="right" className="tex-right pe-5">
                                        <i className="btn btn-sm btn-primary fa fa-eye me-2" style={{ cursor: "pointer" }} onClick={() => toggleJournalForClientViewModal(d)}></i>
                                        <i className="btn btn-sm btn-primary fa fa-pencil me-2" style={{ cursor: "pointer" }} onClick={() => toggleJournalForClientModal(false, d.id)}></i>
                                        <i className="btn btn-sm btn-danger fa fa-trash me-2" style={{ cursor: "pointer" }} onClick={() => deleteJournal(d.id)}></i>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default ClientJournalList;

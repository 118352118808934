import React from 'react';

const AdminTiles = () => {

    const tilesData = [
        {
            icon: 'bi bi-person',
            header: 'Account',
            description: 'Account-wide configuration options and license information',
            color: 'red',
        },
        {
            icon: 'bi bi-people',
            header: 'Users & Groups',
            description: 'Users, Groups and Queues',
            color: 'blue'
        },
        {
            icon: 'bi bi-gear',
            header: 'Integrations',
            description: 'Integrations with other products and applications',
            color: 'green'
        },
        {
            icon: 'bi bi-gear',
            header: 'Global Settings',
            description: 'Service Portal, Custom States and Service Desk Settings',
            color: 'brown'
        },
        {
            icon: 'bi bi-person',
            header: 'Activity logging',
            description: 'Account-wide configuration options and license information',
            color: 'brown'
        },
        {
            icon: 'bi bi-person',
            header: 'CMDS',
            description: 'Configuration item types and dependencies',
            color: 'green'
        },
        {
            icon: 'bi bi-person',
            header: 'CMDS',
            description: 'Configuration item types and dependencies',
            color: 'blue'
        },
        {
            icon: 'bi bi-person red-text',
            header: 'CMDS',
            description: 'Configuration item types and dependencies',
            color: 'red'
        },

    ];


    return (
        <>
            <div class="row">
                {tilesData.map((i, j) => {
                    return (
                        <div class="col-md-3">
                            <div class="card-columns">
                                <div class="card text-center mb-3 deep-orange" style={{ borderColor: "#000", minHeight: "190px" }}>
                                    <div class="card-body">
                                        {/* <img src={i.image} style={{ padding: "20px" }} /> */}
                                        <i class={i.icon} style={{ fontSize: "40px", color: i.color }}></i>
                                        <h6 style={{ fontWeight: 'bolder' }}>{i.header}</h6>
                                        <p style={{ fontSize: '12px' }}><small class="text-muted">{i.description}</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default AdminTiles;
import React from 'react';
import { FormBuilder, Validators, FieldControl, FieldGroup } from "react-reactive-form";
import { withRouter } from 'react-router-dom';
import { swalAlert, swalToast, swalToast2 } from '../../../lib/Swal.js';
import styles from '../../../styles/common.module.css'
import { callAPI } from '../../../lib/Global.js';
import TextInput from '../../../Controls/TextInput.js';
import ClientInsuranceModal from '../Components/ClientInsuranceModal.js';

class ClientInsurance extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, setShowClientInsuranceModal: false, clientInsuranceData: {} };
    }

    componentWillUnmount() {
        this.clientInsuranceForm.get('hasInsurance').valueChanges.unsubscribe();
    }

    async componentDidMount() {
        await this.getClientInsuranceByClientId();
        this.clientInsuranceForm.get('hasInsurance').valueChanges.subscribe(v => {
            console.error(v)
            this.clientInsuranceForm.patchValue({ policyNumber: null, claimNumber: null });
            if (v === 'Yes') {
                this.clientInsuranceForm.get('policyNumber').enable();
                this.clientInsuranceForm.get('claimNumber').enable();
            } else {
                this.clientInsuranceForm.get('policyNumber').disable();
                this.clientInsuranceForm.get('claimNumber').disable();
            }
        });
    }

    clientInsuranceForm = FormBuilder.group({
        id: [null],
        clientId: [null, Validators.required],
        hasInsurance: [null, [Validators.required, Validators.maxLength(3)]],
        policyNumber: [null, [Validators.maxLength(20)]],
        claimNumber: [null, [Validators.maxLength(20)]]
    });

    getClientInsuranceByClientId = async () => {
        this.setState({ isLoading: true });

        const r = await callAPI('GetClientInsuranceByClientId', { id: this.props.clientId });
        this.setState({ isLoading: false, clientInsuranceData: r });

        this.clientInsuranceForm.patchValue(r ? r : { clientId: this.props.clientId });

        if (r && r.hasInsurance === 'Yes') {
            this.clientInsuranceForm.get('policyNumber').enable();
            this.clientInsuranceForm.get('claimNumber').enable();
        } else if (r && r.hasInsurance === 'No') {
            this.clientInsuranceForm.get('policyNumber').disable();
            this.clientInsuranceForm.get('claimNumber').disable();
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const v = { ...this.clientInsuranceForm.value };
        if (v.hasInsurance === 'No') {
            v.policyNumber = null;
            v.claimNumber = null;
        } else {
            if (!v.policyNumber) { swalAlert('e', 'Please enter Policy Number.'); return; }
            if (!v.claimNumber) { swalAlert('e', 'Please enter Claim Number.'); return; }
        }

        this.clientInsuranceForm.updateValueAndValidity(this.clientInsuranceForm.value);
        this.setState({ isLoading: true });

        const apiText = this.clientInsuranceForm.value.id ? 'UpdateClientInsurance' : 'CreateClientInsurance';
        let message = this.clientInsuranceForm.value.id !== null ? 'Updated' : 'Saved';
        const r = await callAPI(apiText, v);
        this.setState({ isLoading: false });

        this.clientInsuranceForm.updateValueAndValidity(this.clientInsuranceForm.value);

        if (r?.isSuccess) {
            swalToast2('Client Insurance ' + message + ' successfully.');
            await this.getClientInsuranceByClientId();
            this.closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    toggleClientInsuranceModal = async (isDataToReload) => {
        if (isDataToReload) {
            this.getClientInsuranceByClientId();
        }
        this.setState({
            setShowClientInsuranceModal: !this.state.setShowClientInsuranceModal
        });
        this.props.togglebackDrop();
    }

    render() {
        // <Values value={value} /> // for form value debug purpose.
        return (
            <>
                {this.state.setShowClientInsuranceModal && <ClientInsuranceModal clientInsuranceData={this.state.clientInsuranceData} closeClientIdentityModal={this.toggleClientInsuranceModal} clientId={this.props.clientId} />}

                <div style={{ marginTop: '30px' }}>
                    <h5 className="modal-title text-success" id="exampleModalLabel" style={{ fontSize: '25px' }} >Insurance</h5>
                    <hr />
                </div>

                <div className="row mt-3">
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Has Insurance</label>
                        <span>{this.state.clientInsuranceData?.hasInsurance || ''}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Policy Number</label>
                        <span>{this.state.clientInsuranceData?.policyNumber || ''}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Claim Number</label>
                        <span>{this.state.clientInsuranceData?.claimNumber || ''}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-3">
                        <span className="bold-color-style" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.toggleClientInsuranceModal(false)}>Edit Client Insurance</span>
                    </div>
                </div>
            </>
        )
    }
}


export default withRouter(ClientInsurance);
import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "./clientInfo.module.css";
import "../../../styles/common.css";
import { callAPI, phoneNumberWithDashes } from "../../../lib/Global.js";
import ClientEditIdentity from "./ClientEditIdentity.js";

const ClientProfileIdentity = (props) => {
    const [clientIdentityData, setClientIndentityData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showIdentityModal, setShowIdentityModal] = useState(false);

    useEffect(() => {
        const clientId = props.clientId;
        getClientIdentityById(clientId);
    }, []);

    const getClientIdentityById = async (clientId) => {
        const r = await callAPI("GetClientProfileViewByClientId", { id: clientId });
        setIsLoading(false);
        setClientIndentityData(r);
    };

    const toggleClientIdentityModal = async () => {
        setShowIdentityModal(!showIdentityModal);
        props.togglebackDrop();
    };

    const closeClientIdentityModal = (isDataReload) => {
        if (isDataReload) {
            getClientIdentityById(props.clientId);
        }
        // setShowIdentityModal(!showIdentityModal);
        toggleClientIdentityModal();
    };
    const { recipientOfCareFirstName = "", recipientOfCareLastName = "", gender = "", birthDate = "", nickName = "", recipientOfCarePhoneNumber = "", recipientOfCareEmail = "", clientSize = "", weightInLbs = "", heightInFt = "", heightInInches = "" } = clientIdentityData;

    if (isLoading) {
        return <div>Please wait...</div>;
    }

    return (
        <div>
            {showIdentityModal && <ClientEditIdentity clientIdentityData={clientIdentityData} closeClientIdentityModal={closeClientIdentityModal} />}
            <h5 className="bold-style" style={{ fontSize: "14px" }}>
                Identity
            </h5>
            <hr />
            <div className="row mt-3">
                <div className="col-3">
                    <span for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        First Name
                    </span>
                    <span className="regular-style text-primary">{recipientOfCareFirstName}</span>
                </div>
                <div className="col-3">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Last Name
                    </label>
                    <span className="regular-style text-primary">{recipientOfCareLastName}</span>
                </div>
                <div className="col-3">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Nick Name
                    </label>
                    <span className="regular-style text-primary">{nickName}</span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-3">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Gender
                    </label>
                    <span className="regular-style text-primary">{gender === "M" ? "Male" : "Female"}</span>
                </div>
                <div className="col-3">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Birth Date
                    </label>
                    <span className="regular-style text-primary">{moment(birthDate).format("MM/DD/YYYY")}</span>
                </div>
                <div className="col-3">
                    <span for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Phone
                    </span>
                    <span className="regular-style text-primary">{phoneNumberWithDashes(recipientOfCarePhoneNumber)}</span>
                </div>
                <div className="col-3">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Email Address
                    </label>
                    <span className="regular-style text-primary">{recipientOfCareEmail}</span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-3">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Client Size
                    </label>
                    <span className="regular-style text-primary">{clientSize}</span>
                </div>
                <div className="col-3">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Weight
                    </label>
                    <span className="regular-style text-primary">{weightInLbs && weightInLbs + " Lbs"} </span>
                </div>
                <div className="col-3">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                        Height
                    </label>
                    <span className="regular-style text-primary">
                        {heightInFt && heightInFt + " Ft"} {heightInInches && heightInInches + " Inches"}{" "}
                    </span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-3">
                    <span className="btn btn-primary btn-sm" onClick={() => toggleClientIdentityModal()}>
                        Edit Identity
                    </span>
                </div>
            </div>
            <hr />
        </div>
    );
};

export default ClientProfileIdentity;

import React, { useState, useEffect } from 'react';
import { callAPI, callAPIWithpathParameter, dateValidator, callAPIForCityStateByZipCode, callAPIforCounty } from '../../../lib/Global.js';
import { FormBuilder, FieldGroup, FieldControl, Validators, AbstractControl } from 'react-reactive-form';
import styles from './clientInfo.module.css';
import TextInput from '../../../Controls/TextInput.js';
import SelectInput from '../../../Controls/SelectInput.js';
import DateInput from '../../../Controls/DateInput.js';
import NumberInput from '../../../Controls/NumberInput.js';
import { swalAlert, swalToast, swalToast2 } from '../../../lib/Swal.js';

import { ageCalculation } from '../../../lib/DateTimeOps.js';
import { getAllBranches } from './admin/components/branchList/branchListRest.js';

const asyncIsROCPhoneExists = async (control) => {
    const ph = control?.value;
    if (!ph || ph.length !== 10) {
        return null;
    }

    const r = await callAPIWithpathParameter('IsROCPhoneExists', ph, 'GET', null);

    if (r?.r) {
        control.setErrors({ "alreadyExists": true });
    } else {
        return null;
    }
};

let rocInfoForm;

const asyncIsROCClientExistsFn = async (control) => {
    const fn = control?.value;
    if (!fn || fn.length === 0 || !rocInfoForm?.value.recipientOfCareLastName || rocInfoForm?.value.recipientOfCareLastName?.length === 0 || !rocInfoForm?.value.birthDate || rocInfoForm?.value.birthDate?.length === 0) {
        return null;
    }
    const paramArr = [fn, rocInfoForm?.value.recipientOfCareLastName, rocInfoForm?.value.birthDate];

    const r = await callAPIWithpathParameter('IsROCClientExists', paramArr, 'GET', null);

    if (r?.r) {
        control.setErrors({ "clientAlreadyExists": true });
    } else {
        return null;
    }
};

const asyncIsROCClientExistsLn = async (control) => {
    const ln = control?.value;
    if (!ln || ln.length === 0 || !rocInfoForm?.value.recipientOfCareFirstName || rocInfoForm?.value.recipientOfCareFirstName?.length === 0 || !rocInfoForm?.value.birthDate || rocInfoForm?.value.birthDate?.length === 0) {
        return null;
    }

    const paramArr = [rocInfoForm?.value.recipientOfCareFirstName, ln, rocInfoForm?.value.birthDate];

    const r = await callAPIWithpathParameter('IsROCClientExists', paramArr, 'GET', null);

    if (r?.r) {
        rocInfoForm?.get('recipientOfCareFirstName').setErrors({ "clientAlreadyExists": true });
    } else {
        rocInfoForm?.get('recipientOfCareFirstName').setErrors({ "clientAlreadyExists": false });
    }
};

const asyncIsROCClientExistsDOB = async (control) => {
    const dob = control?.value;
    if (!dob || dob.length === 0 || !rocInfoForm?.value.recipientOfCareLastName || rocInfoForm?.value.recipientOfCareLastName?.length === 0 || !rocInfoForm?.value.recipientOfCareFirstName || rocInfoForm?.value.birthDate?.recipientOfCareFirstName === 0) {
        return null;
    }
    const paramArr = [rocInfoForm?.value.recipientOfCareFirstName, rocInfoForm?.value.recipientOfCareLastName, dob];

    const r = await callAPIWithpathParameter('IsROCClientExists', paramArr, 'GET', null);

    if (r?.r) {
        rocInfoForm?.get('recipientOfCareFirstName').setErrors({ "clientAlreadyExists": true });
    } else {
        rocInfoForm?.get('recipientOfCareFirstName').setErrors({ "clientAlreadyExists": false });
    }
};


const clientInfoForm = FormBuilder.group({
    primaryContactFirstName: [null, [Validators.required, Validators.maxLength(50)]],
    primaryContactLastName: [null, [Validators.required, Validators.maxLength(50)]],
    primaryContactPhoneNumber: [null, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+0-9]*$"), asyncIsROCPhoneExists]],
    primaryContactPhoneNumberType: [null, Validators.maxLength(10)],
    primaryContactEmail: [null, [Validators.email, Validators.maxLength(150)]],
    howDidYouHearAboutUs: [null, [Validators.required, Validators.maxLength(100)]],
    areYouTheOneWhoNeedsCare: [null, [Validators.required, Validators.maxLength(25)]],
    relationshipToTheRecipientOfCare: [null, [Validators.maxLength(100)]]
});

rocInfoForm = FormBuilder.group({
    recipientOfCareFirstName: [null, [Validators.required, Validators.maxLength(50), asyncIsROCClientExistsFn]],
    recipientOfCareLastName: [null, [Validators.required, Validators.maxLength(50), asyncIsROCClientExistsLn]],
    gender: [null, [Validators.required, Validators.maxLength(1)]],
    // age: [null, Validators.min(0)],
    birthDate: [null, [dateValidator, asyncIsROCClientExistsDOB]],
    recipientOfCarePhoneNumber: [null, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+0-9]*$"), asyncIsROCPhoneExists]],
    recipientOfCarePhoneNumberType: [null, Validators.maxLength(10)],
    recipientOfCareEmail: [null, [Validators.email, Validators.maxLength(150)]],
    addressLine1: [null, [Validators.required, Validators.maxLength(255)]],
    addressLine2: [null, [Validators.maxLength(255)]],
    addressType: [null, [Validators.required, Validators.maxLength(50)]],
    city: [null, [Validators.required, Validators.maxLength(100)]],
    state: [null, [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
    zipCode: [null, [Validators.required, Validators.maxLength(5), Validators.minLength(5), Validators.pattern("^[0-9]*$")]],
    branch: [null, [Validators.required]],
    status: ['-', [Validators.maxLength(10)]],
    leadStatus: [null, [Validators.required, Validators.maxLength(10)]],
    salesRep: [null, [Validators.maxLength(36)]],
    county: [null, [Validators.required, Validators.maxLength(50)]]
});

const ClientModal = (props) => {

    const [isROC, setIsROC] = useState(false);
    const [clientInfo, setClientInfo] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [countyList, setCountyList] = useState([]);



    useEffect(() => {
        clientInfoForm.get('areYouTheOneWhoNeedsCare').valueChanges
            .subscribe(selectedValue => {
                if (selectedValue === 'You') {
                    // clientInfoForm.patchValue({ relationshipToTheRecipientOfCare: "Myself" });
                    clientInfoForm.get('relationshipToTheRecipientOfCare').disable();
                    clientInfoForm.get('relationshipToTheRecipientOfCare').setValidators([Validators.maxLength(100)])
                } else {
                    clientInfoForm.get('relationshipToTheRecipientOfCare').enable();
                    clientInfoForm.get('relationshipToTheRecipientOfCare').setValidators([Validators.required, Validators.maxLength(100)])
                }
                clientInfoForm.patchValue({ relationshipToTheRecipientOfCare: "" });
            });

        rocInfoForm.get('birthDate').valueChanges
            .subscribe(selectedValue => {
                if (selectedValue) {

                    if (ageCalculation(selectedValue) === null) {
                        rocInfoForm.patchValue({ birthDate: null });
                    }
                }
            });

        rocInfoForm.get("zipCode").valueChanges.subscribe((selectedValue) => {
            if (selectedValue?.length === 5) {
                getCityAndStateZipCode(selectedValue);
            } else if (selectedValue?.length === 4) {
                rocInfoForm.patchValue({ city: null, state: null });
            }
        });


        return () => {
            clientInfoForm.reset();
            rocInfoForm.reset({ status: "-" });
            clientInfoForm.get('areYouTheOneWhoNeedsCare').valueChanges.unsubscribe();
            rocInfoForm.get("birthDate").valueChanges.unsubscribe();
            rocInfoForm.get("zipCode").valueChanges.unsubscribe();
        }
    }, []);

    const getCityAndStateZipCode = async (zipCode) => {
        const p = await callAPIForCityStateByZipCode(zipCode);
        // Destructuring the City State object to get required city and state
        const { CityStateLookupResponse: { ZipCode: { City: { "#text": city } = {}, State: { "#text": state } = {} } } } = p;
        rocInfoForm.patchValue({ city: city, state: state });
        if (!city || !state) {
            swalAlert('e', 'Invalid Zip Code');
        } else {
            setIsLoading(true);
            rocInfoForm.updateValueAndValidity(rocInfoForm.value);
            const c = await callAPIforCounty(state);
            console.log(c);
            let countyArr = []
            c.data.forEach(d => {
                countyArr.push({ "text": d.text });
            });
            setIsLoading(false);
            setCountyList(countyArr);
            rocInfoForm.updateValueAndValidity(rocInfoForm.value);

        }
    }


    const handleNext = () => {
        setClientInfo(clientInfoForm.value);
        if (clientInfoForm.value.areYouTheOneWhoNeedsCare === 'You') {
            rocInfoForm.patchValue({
                recipientOfCareFirstName: clientInfoForm.value.primaryContactFirstName,
                recipientOfCareLastName: clientInfoForm.value.primaryContactLastName,
                recipientOfCareEmail: clientInfoForm.value.primaryContactEmail,
                recipientOfCarePhoneNumberType: clientInfoForm.value.primaryContactPhoneNumberType,
                recipientOfCarePhoneNumber: clientInfoForm.value.primaryContactPhoneNumber
            });
            rocInfoForm.get('recipientOfCarePhoneNumberType').disable();
            setIsDisabled(true);
            rocInfoForm.updateValueAndValidity(rocInfoForm.value);
        } else {
            rocInfoForm.reset({ status: "-" });
            rocInfoForm.get('recipientOfCarePhoneNumberType').enable();
            setIsDisabled(false);
        }
        setIsROC(!isROC);
    }

    const handleBack = () => {
        setIsROC(!isROC);
        const v = { ...clientInfo };

        /*if (v.areYouTheOneWhoNeedsCare === 'You') {
        }*/
        v.relationshipToTheRecipientOfCare = v.relationshipToTheRecipientOfCare ? v.relationshipToTheRecipientOfCare : '';
        clientInfoForm.setValue(v);
        clientInfoForm.updateValueAndValidity(clientInfoForm.value);
    }

    const handleSave = async () => {
        setIsLoading(true);
        rocInfoForm.updateValueAndValidity(rocInfoForm.value);
        const v = { ...clientInfo, ...rocInfoForm.value };
        if (v.areYouTheOneWhoNeedsCare === 'You') {
            v.relationshipToTheRecipientOfCare = 'MySelf';
            v.recipientOfCarePhoneNumberType = v.primaryContactPhoneNumberType;
        }
        const r = await callAPI('CreateClient', v);
        setIsLoading(false);
        if (r?.isSuccess) {
            swalToast2('Primary Contact & ROC Info is saved successfully.');
            closeModal(true);
        } else {
            swalAlert('e', r?.errorMsg || 'Something went wrong!!! please contact admin.');
        }
    }

    const closeModal = (isDataToReload) => {
        props.toggleModal(isDataToReload);
    }


    return (
        <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-success" id="exampleModalLabel">New ROC | Primary Contact</h5>
                        <i className="bi bi-x-lg" onClick={() => closeModal(false)} style={{ cursor: 'pointer' }}></i>
                    </div>
                    {!isROC ? <>
                        <div className="modal-body">
                            <FieldGroup
                                control={clientInfoForm}
                                render={({ get, invalid }) => (
                                    <form>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <FieldControl
                                                    name="primaryContactFirstName"
                                                    render={TextInput}
                                                    meta={{ label: "First name", maxLengthVal: 50 }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FieldControl
                                                    name="primaryContactLastName"
                                                    render={TextInput}
                                                    meta={{ label: "Last name", maxLengthVal: 50 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <FieldControl
                                                    name="primaryContactPhoneNumber"
                                                    render={TextInput}
                                                    meta={{ label: "Primary Contact Phone No", patternErrMsg: "Only numbers are allowed", maxLengthVal: 10, minLengthVal: 10, customError: 'alreadyExists', customErrorMsg: 'Phone number already exists.' }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FieldControl
                                                    name="primaryContactPhoneNumberType"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Primary Contact Phone Number Type",
                                                        options: [
                                                            { text: 'Mobile' },
                                                            { text: 'Home' },
                                                            { text: 'Work' },
                                                            { text: 'Other' }
                                                        ]
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <FieldControl
                                                    name="primaryContactEmail"
                                                    render={TextInput}
                                                    meta={{ label: "Primary Contact Email", emailErrMsg: "Invalid Email", maxLengthVal: 150 }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FieldControl
                                                    name="howDidYouHearAboutUs"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "How did you hear about us?",
                                                        options: [
                                                            { text: 'Referral Network' },
                                                            { text: 'Physician' },
                                                            { text: 'Association' },
                                                            { text: 'Sub Acute' },
                                                            { text: 'Medicaid' },
                                                            { text: 'Assisted Living' },
                                                            { text: 'GCM' },
                                                            { text: 'Independent Living' },
                                                            { text: 'LTC Agent' },
                                                            { text: 'Homecare Agency Private Pay' },
                                                            { text: 'Hospital' },
                                                            { text: 'Attorney' },
                                                            { text: 'Word of mouth' }
                                                        ]
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <FieldControl
                                                    name="areYouTheOneWhoNeedsCare"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <>
                                                            <label for="formCheck1" className={`mb-1 ${styles.labelStyling}`}>{meta.label}</label>
                                                            <div class="form-check">
                                                                <input class="form-check-input" {...handler("radio", "You")} />
                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioYou">
                                                                    You
                                                                </label>

                                                            </div>
                                                            <div className="form-check">
                                                                <input class="form-check-input"  {...handler("radio", "Someone else")} />
                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                                    Someone else
                                                                </label>
                                                            </div></>
                                                    )}
                                                    meta={{ label: "Who needs care ?" }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FieldControl
                                                    name="relationshipToTheRecipientOfCare"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Relationship with client",
                                                        options: [
                                                            // { text: 'Myself' },
                                                            { text: 'Parent' },
                                                            { text: 'Grandparent' },
                                                            { text: 'Sibling' },
                                                            { text: 'Spouse' },
                                                            { text: 'Friend' },
                                                            { text: 'Child' },
                                                            { text: 'Aunt or Uncle' },
                                                            { text: 'Niece or Nephew' },
                                                            { text: 'Cousin' },
                                                            { text: 'Neighbor' },
                                                            { text: 'Other' }
                                                        ]
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row text-lg-end pb-1 pt-3" style={{ borderTop: "1px solid lightgray" }}>
                                            <div className="d-flex justify-content-end">
                                                <button type="button" onClick={() => handleNext()} className="btn btn-primary" disabled={invalid}>Next</button>
                                            </div>
                                        </div>

                                    </form>
                                )}
                            />
                        </div>
                    </> :
                        <div className="modal-body">
                            <FieldGroup
                                control={rocInfoForm}
                                render={({ get, invalid }) => (
                                    <form>
                                        <div className="row  mb-2">
                                            <div className="col-6">
                                                <FieldControl
                                                    name="recipientOfCareFirstName"
                                                    render={TextInput}
                                                    meta={{ label: "Recipient Of Care First Name", maxLengthVal: 150, disabled: isDisabled, customError: 'clientAlreadyExists', customErrorMsg: 'Client already exists. Please check your information and try again.' }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FieldControl
                                                    name="recipientOfCareLastName"
                                                    render={TextInput}
                                                    meta={{ label: "Recipient Of Care Last Name", maxLengthVal: 150, disabled: isDisabled }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <FieldControl
                                                    name="gender"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Gender",
                                                        options: [
                                                            { value: 'M', text: 'Male' },
                                                            { value: 'F', text: 'Female' }
                                                        ]
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FieldControl
                                                    name="birthDate"
                                                    render={DateInput}
                                                    meta={{ label: "Birth Date" }}
                                                />
                                            </div>
                                            {/* <div className="col-6" >
                                                <FieldControl
                                                    name="age"
                                                    render={NumberInput}
                                                    meta={{ label: "Age", minVal: 1 }}
                                                />
                                            </div> */}
                                        </div>
                                        <div className="row mb-2">
                                            {/* <div className="col-6">
                                                <FieldControl
                                                    name="birthDate"
                                                    render={DateInput}
                                                    meta={{ label: "Birth Date" }}
                                                />
                                            </div> */}
                                            <div className="col-6">
                                                <FieldControl
                                                    name="recipientOfCarePhoneNumber"
                                                    render={TextInput}
                                                    meta={{ label: "Recipient Of Care Phone Number", patternErrMsg: "Please, Enter 10 digit Mobile Number", maxLengthVal: 10, minLengthVal: 10, disabled: isDisabled, customError: 'alreadyExists', customErrorMsg: 'Phone number already exists.' }}
                                                />
                                            </div>

                                            <div className="col-6" >
                                                <FieldControl
                                                    name="recipientOfCarePhoneNumberType"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Recipient Of Care Phone Number Type",
                                                        options: [
                                                            { text: 'Mobile' },
                                                            { text: 'Home' },
                                                            { text: 'Work' },
                                                            { text: 'Other' }
                                                        ],
                                                        disabled: isDisabled
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            {/* <div className="col-6" >
                                                <FieldControl
                                                    name="recipientOfCarePhoneNumberType"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Receipient Of Care Phone Number Type",
                                                        options: [
                                                            { text: 'Mobile' },
                                                            { text: 'Home' },
                                                            { text: 'Work' },
                                                            { text: 'Other' }
                                                        ]
                                                    }}
                                                />
                                            </div> */}

                                            <div className="col-6">
                                                <FieldControl
                                                    name="recipientOfCareEmail"
                                                    render={TextInput}
                                                    meta={{ label: "Recipient of Care Email", emailErrMsg: "Invalid Email", maxLengthVal: 150, disabled: isDisabled }}
                                                />
                                            </div>

                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <FieldControl
                                                    name="addressLine1"
                                                    render={TextInput}
                                                    meta={{ label: "Address Line 1", maxLengthVal: 255 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <FieldControl
                                                    name="addressLine2"
                                                    render={TextInput}
                                                    meta={{ label: "Address Line 2", maxLengthVal: 255 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                <FieldControl
                                                    name="addressType"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Address Type",
                                                        options: [
                                                            { text: 'House' },
                                                            { text: 'Apartment' },
                                                            { text: 'ALF' },
                                                            { text: 'Subacute' },
                                                            { text: 'Temporary Residence' }
                                                        ]
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <FieldControl
                                                    name="zipCode"
                                                    render={TextInput}
                                                    meta={{ label: "Zip Code", maxLengthVal: 5, minLengthVal: 5, patternErrMsg: "Only numbers are allowed" }}
                                                />
                                            </div>

                                            <div className="col-6">
                                                <FieldControl
                                                    name="branch"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Branch",
                                                        options: props.branchesListArr
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <FieldControl
                                                    name="city"
                                                    render={TextInput}
                                                    meta={{ label: "City", maxLengthVal: 100, disabled: true }}
                                                />
                                            </div>

                                            <div className="col-6">
                                                <FieldControl
                                                    name="state"
                                                    render={TextInput}
                                                    meta={{ label: "State", maxLengthVal: 2, minLengthVal: 2, disabled: true }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                {isLoading ? <div>Please wait...</div> :
                                                    <>  <FieldControl
                                                        name="county"
                                                        render={SelectInput}
                                                        meta={{
                                                            label: "County",
                                                            options: countyList
                                                        }}
                                                    /></>}
                                            </div>
                                            <div className="col-6">
                                                <FieldControl
                                                    name="leadStatus"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Lead Status",
                                                        options: [
                                                            { text: 'Hot' },
                                                            { text: 'Warm' },
                                                            { text: 'Cold' },
                                                            { text: 'Non-Admit' }
                                                        ]
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row text-lg-end pb-1 pt-3" style={{ borderTop: "1px solid lightgray" }}>
                                            <div className="d-flex justify-content-end">
                                                <button type="button" onClick={() => handleBack()} className="btn btn-outline-secondary me-3">Back</button>
                                                <button type="button" onClick={() => handleSave()} className="btn btn-primary" disabled={invalid}>{isLoading ? "Please wait..." : "Save"}</button>
                                            </div>
                                        </div>

                                    </form>)} />
                        </div>}
                </div>
            </div>
        </div>
    )
}


export default ClientModal;
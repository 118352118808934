import moment from "moment";
import "moment-timezone";

export const ClientMedicationsListTableBody = ({ currentPage, numberPerPage, items, loadingError, loading, handleEditClick, handleRemoveClick }) => {
    const begin = (Number(currentPage) - 1) * Number(numberPerPage);
    const end = begin + Number(numberPerPage);
    const data = items.slice(begin, end);

    if (loadingError)
        return (
            <tr>
                <td colSpan="10" className="table-danger text-center">
                    {loadingError}
                </td>
            </tr>
        );

    return data.length === 0
        ? !loading && (
              <tr>
                  <td colSpan="10" className="table-danger text-center">
                      No Medications to display
                  </td>
              </tr>
          )
        : data.map((item) => {
              return (
                  <tr key={item?.id}>
                      <td>{moment(item?.startDate).utc().tz("America/New_York").format("MM/DD/YYYY")}</td>
                      <td>{moment(item?.discontinuedDate).utc().tz("America/New_York").format("MM/DD/YYYY")}</td>
                      <td>{item?.medication}</td>
                      <td>{item?.class}</td>
                      <td>{item?.controlled ? "Yes" : "No"}</td>
                      <td>{item?.dose}</td>
                      <td>{item?.frequency}</td>
                      <td>{item?.route}</td>
                      <td>{item?.archived ? "Yes" : "No"}</td>
                      <td className="text-end">
                          <button
                              type="button"
                              className="btn btn-sm btn-primary "
                              onClick={() => {
                                  handleEditClick(item?.id);
                              }}
                          >
                              <i className="fa fa-pencil" title="Edit"></i>
                          </button>

                          <button
                              type="button"
                              className="btn btn-sm btn-danger ms-2"
                              onClick={() => {
                                  handleRemoveClick(item.id);
                              }}
                          >
                              <i className="fa fa-trash" title="Remove"></i>
                          </button>
                      </td>
                  </tr>
              );
          });
};

export const JobTitleListTableBody = ({ currentPage, numberPerPage, items, handleRemoveClick, loadingError, loading }) => {
    const begin = (Number(currentPage) - 1) * Number(numberPerPage);
    const end = begin + Number(numberPerPage);
    const data = items.slice(begin, end);

    if (loadingError)
        return (
            <tr>
                <td colSpan="4" className="table-danger text-center">
                    {loadingError}
                </td>
            </tr>
        );

    return data.length === 0
        ? !loading && (
              <tr>
                  <td colSpan="4" className="table-danger text-center">
                      No Case Types to display
                  </td>
              </tr>
          )
        : data.map(({ name, id }) => {
              return (
                  <tr key={id}>
                      <td>{name}</td>
                      <td className="text-end">
                          <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                  handleRemoveClick(id);
                              }}
                          >
                              <i className="fa fa-trash" title="Remove"></i>
                          </button>
                      </td>
                  </tr>
              );
          });
};

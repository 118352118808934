import React, { Component } from 'react';
import { FormBuilder, FieldGroup, FormArray, FieldControl, Validators } from 'react-reactive-form';
import TextInput from '../../../Controls/TextInput.js';
import CheckBox from '../../../Controls/CheckBox.js';
import SelectInput from '../../../Controls/SelectInput.js';
import { swalAlert, swalToast, swalToast2 } from '../../../lib/Swal.js';
import { callAPI } from '../../../lib/Global.js';
import { moduleSubjectScope } from '../Constants/Constants';

import chipsStyle from './common.module.css';
import Chips, { Chip } from 'react-chips/lib/Chips.js';
class RolePermissionsModal extends Component {

    moduleVal = [];

    constructor(props) {
        super(props);
        const modulesArr = [...new Set(moduleSubjectScope.map(m => m.module))].map(m => { return { text: m } });
        this.state = { isLoading: false, subjects: [], scops: [], modules: modulesArr, bgColor: '#fff', selectedScopes: [] };
    }

    componentDidMount() {

        if (this.props.index === 0) {
            if (this.props.updateRolePermissionData.module === 'All') {
                this.rolePermissionForm.get('scopes').clearValidators()
            }
        }

        if (this.props.updateRolePermissionData) {

            let subjects = [...new Set(moduleSubjectScope.filter(f => f.module === this.props.updateRolePermissionData.module).map(m => m.subject))].map(m => { return { text: m } });
            subjects = subjects.sort();

            this.setState({ subjects: subjects });

            let scopes = [...new Set(moduleSubjectScope.filter(f => f.subject === this.props.updateRolePermissionData.subject).map(m => m.scope))].map(m => { return { text: m } });
            scopes = scopes.sort();

            this.setState({ scops: scopes });

            this.setState({ isUpdatedRolePermissionData: true });
            this.populateRolePermissionForm(this.props.updateRolePermissionData);

        }

        this.rolePermissionForm.get("module").valueChanges.subscribe(v => {
            if (v) {
                this.rolePermissionForm.get("subject").setValue(null);
                this.rolePermissionForm.get("scopes").setValue(null);

                let subjects = [...new Set(moduleSubjectScope.filter(f => f.module === v).map(m => m.subject))].map(m => { return { text: m } });
                subjects = subjects.sort();

                this.setState({ subjects: subjects });
            }
        });
        this.rolePermissionForm.get("subject").valueChanges.subscribe(v => {
            if (v) {
                this.rolePermissionForm.get("scopes").setValue(null);

                let scopes = [...new Set(moduleSubjectScope.filter(f => f.subject === v).map(m => m.scope))].map(m => { return { text: m } });
                scopes = scopes.sort();

                this.setState({ scops: scopes });
            }
        })

        this.rolePermissionForm.get('module').valueChanges.subscribe(v => {
            if (v === 'All') {
                this.rolePermissionForm.get('scopes').clearValidators()
            }
        })

        if (this.rolePermissionForm.value.module === 'All') {
        }
    }

    populateRolePermissionForm = (updateRolePermissionData) => {

        const scope = updateRolePermissionData.scopes[0]?.split(',');

        this.setState({
            selectedScopes: updateRolePermissionData.scopes
        })
        this.rolePermissionForm.patchValue({
            type: updateRolePermissionData.type,
            action: updateRolePermissionData.action,
            module: updateRolePermissionData.module,
            scopes: updateRolePermissionData.scopes,
            subject: updateRolePermissionData.subject,
        })

    }

    componentWillUnmount() {
        this.rolePermissionForm.get("module").valueChanges.unsubscribe();
        this.rolePermissionForm.get("scopes").valueChanges.unsubscribe();
    }

    rolePermissionForm = FormBuilder.group({
        type: [null, Validators.required],
        action: [null, [Validators.required, Validators.maxLength(50)]],
        module: [null, [Validators.required, Validators.maxLength(50)]],
        subject: [null, [Validators.required, Validators.maxLength(50)]],
        scopes: [null, Validators.required],
    });


    closeModal = (val) => {
        this.props.toggleRolePermissionModal(this.props.roleId, '', val);
    }

    onChange = chips => {
        chips = chips.filter(element => this.state.scops?.map(m => m.text)?.includes(element));

        this.setState({
            selectedScopes: chips
        });
        // this.rolePermissionForm.get('scopes').setValue(chips.join());
        this.rolePermissionForm.get('scopes').setValue(chips);
        this.rolePermissionForm.updateValueAndValidity(this.rolePermissionForm.value);
    }

    handleSave = (e) => {

        if (this.rolePermissionForm.value.module === 'All') {
            this.rolePermissionForm.get('scopes').setValue([]);
            this.rolePermissionForm.updateValueAndValidity(this.rolePermissionForm.value);
        }

        this.setState({ isLoading: true });
        this.props.updateRolePermissionsObject(this.rolePermissionForm.value, this.props.roleId, this.state.isUpdatedRolePermissionData, this.props.index);

    }

    render() {
        return (
            <>
                <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div className="modal-dialog modal-md " role="document">
                        <div className="modal-content bg-light">
                            <div className="modal-header">
                                <h5 className="modal-title text-success" id="exampleModalLabel">{this.props.roleName}</h5>
                                <i className="bi bi-x-lg" onClick={() => this.closeModal(false)} style={{ cursor: 'pointer' }}></i>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <FieldGroup
                                        control={this.rolePermissionForm}
                                        render={({ get, invalid, pristine }) => (
                                            <>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="type"
                                                            render={SelectInput}
                                                            meta={{
                                                                label: "Type",
                                                                options: [
                                                                    { text: 'Permission' },
                                                                    { text: 'Restriction' },
                                                                ]
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="action"
                                                            render={SelectInput}
                                                            meta={{
                                                                label: "Action",
                                                                options: [
                                                                    { text: 'Manage' },
                                                                    { text: 'Create' },
                                                                    { text: 'Read' },
                                                                    { text: 'Delete' }
                                                                ]
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="module"
                                                            render={SelectInput}
                                                            meta={{
                                                                label: "Module",
                                                                options: this.state.modules
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="subject"
                                                            render={({ handler, touched, hasError }) => (
                                                                <div class="form-group">
                                                                    <label for="formControlSelect1" className={`mb-1`}>Subject</label>
                                                                    <select className="form-control form-select" id="exampleFormControlSelect1" {...handler()}>
                                                                        <option disabled={true} selected value="">-- Please Select --</option>
                                                                        {
                                                                            this.state.subjects?.map((m, i) => {
                                                                                return (
                                                                                    <option key={i} value={m.value ? m.value : m.text}>{m.text}</option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </select>
                                                                    <span className="d-block text-danger mt-1">
                                                                        {touched && hasError("required")
                                                                            && `Please select an option`}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <FieldControl
                                                        name="scopes"
                                                        render={({ handler, touched, hasError }) => (
                                                            <div class="form-group">
                                                                <label for="formControlSelect1" className={`mb-1`}>Scopes</label>
                                                                <Chips
                                                                    value={this.state.selectedScopes}
                                                                    onChange={this.onChange}
                                                                    suggestions={this.state.scops?.map(m => m.text)}

                                                                />
                                                                <span className="d-block text-danger mt-1">
                                                                    {touched && hasError("required")
                                                                        && `Please select an option`}
                                                                </span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row text-lg-end pb-1">
                                                    <div className="d-flex justify-content-center">
                                                        <button type="button" className="btn btn-success rounded-pill" onClick={() => this.handleSave()} disabled={invalid || pristine || this.state.isLoading}>{this.state.isLoading ? 'Please wait...' : 'Save'}</button>
                                                        <button type="button" onClick={() => this.closeModal(false)} className="btn btn-link">Cancel</button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default RolePermissionsModal;
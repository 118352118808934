import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./clientBasicIntakeEditDialog.css";
import Select from "react-select";
import { ToggleSwitch } from "./ToggleSwitch";

export const ClientBasicIntakeEditDialog = ({ basicIntake, listHospices, listLimitations, listClientNeeds, show, setShow, handleSaveClick, handleClose }) => {
    //Reason For Care state
    const [reasonForCare, setReasonForCare] = useState("");

    //Had Care In Past state
    const [hadCareInPast, setHadCareInPast] = useState("");

    //Diagnosis state
    const [diagnosis, setDiagnosis] = useState("");

    //Hospice Name state
    const [hospiceName, setHospiceName] = useState("");
    const [hospiceId, setHospiceId] = useState(null);

    //Client Needs state
    const [needs, setNeeds] = useState([]);

    //Limitation state
    const [limitations, setLimitations] = useState([]);

    //Mental Status state
    const [mentalStatus, setMentalStatus] = useState(basicIntake?.mentalStatus);

    useEffect(() => {
        setHospiceName(basicIntake?.hospiceName);
        setHospiceId(basicIntake?.hospiceId);
        setReasonForCare(basicIntake?.reasonForCare);
        setHadCareInPast(basicIntake?.hadCareInPast ? "Yes" : "No");
        setDiagnosis(basicIntake?.diagnosis);
        setMentalStatus(basicIntake?.mentalStatus);
        setLimitations(basicIntake?.limitations);
        setNeeds(basicIntake?.clientNeeds);
    }, [basicIntake]);

    //=== Handlers ===========================================================

    const handleSave = () => {
        const saveObj = { diagnosis: diagnosis, reasonForCare: reasonForCare, hadCareInPast: hadCareInPast, hospiceId: hospiceId, hospiceName: hospiceName, limitations: limitations, clientNeeds: needs, mentalStatus: mentalStatus };
        handleSaveClick(saveObj);
        setShow(false);
    };

    const handleHospiceChange = (e) => {
        setHospiceName(e.target.options[e.target.options.selectedIndex].text);
        setHospiceId(e.target.options[e.target.options.selectedIndex].value === "" ? null : e.target.options[e.target.options.selectedIndex].value);
    };

    const clientNeedsChangeHandler = (e) => {
        let clientNeedsNew = e?.map((i) => i.value);
        setNeeds(clientNeedsNew);
    };

    const limitationChangeHandler = (e) => {
        let limitationsNew = e?.map((i) => i.value);
        setLimitations(limitationsNew);
    };

    const mentalStatusValueChange = (e) => {
        const mentalStatusNew = mentalStatus.map((x) => {
            const item = x.name === e.name ? e : x;
            return item;
        });

        setMentalStatus(mentalStatusNew);
    };

    const hadCareInPastChangeHandler = (e) => {
        const hadCareInPastNew = e.target.value;
        setHadCareInPast(hadCareInPastNew);
    };

    const reasonForCareChangeHandler = (e) => {
        const reasonForCareNew = e.target.value;
        setReasonForCare(reasonForCareNew);
    };

    const diagnosisChangeHandler = (e) => {
        const diagnosisNew = e.target.value;
        setDiagnosis(diagnosisNew);
    };

    const mentalStatusClick = (event) => {
        const target = event.target;
        const checked = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        if (checked) {
            const vet = [...mentalStatus, { name: name, value: "Sometimes" }];
            setMentalStatus(vet);
        } else {
            let vet = mentalStatus.filter(function (obj) {
                return obj.name !== name;
            });
            setMentalStatus(vet);
            //mentalStatus.current = mentalStatus.current.filter(function (obj) {
            //    return obj.name !== name;
            //});
        }
    };

    return (
        <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="text-primary">Edit Basic Intake</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <>
                    <div className="row">
                        <div className="col-8">
                            <span className="my-1 d-block">Reason for Care</span>
                            <div className="form-check form-check-inline">
                                <input type="radio" className="form-check-input" value="Companionship" name="ReasonForCare" onChange={reasonForCareChangeHandler} checked={reasonForCare === "Companionship"} />
                                <label className="form-check-label">Companionship</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" className="form-check-input" value="Skilled Nursing" name="ReasonForCare" onChange={reasonForCareChangeHandler} checked={reasonForCare === "Skilled Nursing"} />
                                <label className="form-check-label">Skilled Nursing</label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input type="radio" className="form-check-input" value="ADL or ADL assistance" name="ReasonForCare" onChange={reasonForCareChangeHandler} checked={reasonForCare === "ADL or ADL assistance"} />
                                <label className="form-check-label">ADL</label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input type="radio" className="form-check-input" value="Housekeeping or errands or appointments" name="ReasonForCare" onChange={reasonForCareChangeHandler} checked={reasonForCare === "Housekeeping or errands or appointments"} />
                                <label className="form-check-label">Housekeeping or errands or appointments</label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input type="radio" className="form-check-input" value="N/A" name="ReasonForCare" onChange={reasonForCareChangeHandler} checked={reasonForCare === "N/A"} />
                                <label className="form-check-label">N/A</label>
                            </div>

                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-3">
                            <span className="my-1 d-block">Had Care in the Past?</span>
                            <div className="form-check form-check-inline">
                                <input type="radio" className="form-check-input" value="Yes" name="HadCareInPast" onChange={hadCareInPastChangeHandler} checked={hadCareInPast === "Yes"} />
                                <label className="form-check-label">Yes</label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input type="radio" className="form-check-input" value="No" name="HadCareInPast" onChange={hadCareInPastChangeHandler} checked={hadCareInPast === "No"} />
                                <label className="form-check-label">No</label>
                            </div>

                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Diagnosis</span>
                            <input type="text" className="form-control" placeholder={`Enter Diagnosis`} value={diagnosis} name="diagnosis" onChange={diagnosisChangeHandler} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-6">
                            <label htmlFor="state" className={`mb-1`}>
                                Hospice
                            </label>
                            <select className="form-select" id="hospiceId" onChange={handleHospiceChange} value={hospiceId}>
                                <option value="">Please Select</option>
                                {listHospices &&
                                    listHospices.map(({ name, id }) => {
                                        return (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        );
                                    })}
                            </select>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="comorbidity" className={`mb-1`}>
                                Limitations
                            </label>
                            <Select isMulti menuPlacement="top" options={listLimitations} value={listLimitations.value} defaultValue={basicIntake?.limitations?.map((frd) => listLimitations?.find((i) => i.value === frd))} onChange={limitationChangeHandler}></Select>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="comorbidity" className={`mb-1`}>
                                Client Needs
                            </label>
                            <Select isMulti menuPlacement="top" options={listClientNeeds} value={listClientNeeds.value} defaultValue={basicIntake?.clientNeeds?.map((frd) => listClientNeeds?.find((i) => i.value === frd))} onChange={clientNeedsChangeHandler}></Select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label className={`mb-1 mt-3`}>Mental Status</label>
                        </div>
                    </div>

                    <div className="row">
                        <MentalStatus checked={mentalStatus.find((i) => i.name === "alert")} name="alert" label="Alert" value={mentalStatus.find((i) => i.name === "alert")?.value} onClick={mentalStatusClick} onValueChange={mentalStatusValueChange} />
                        <MentalStatus checked={mentalStatus.find((i) => i.name === "confused")} name="confused" label="Confused" value={mentalStatus.find((i) => i.name === "confused")?.value} onClick={mentalStatusClick} onValueChange={mentalStatusValueChange} />
                        <MentalStatus checked={mentalStatus.find((i) => i.name === "forgetful")} name="forgetful" label="Forgetful" value={mentalStatus.find((i) => i.name === "forgetful")?.value} onClick={mentalStatusClick} onValueChange={mentalStatusValueChange} />
                        <MentalStatus checked={mentalStatus.find((i) => i.name === "disoriented")} name="disoriented" label="Disoriented" value={mentalStatus.find((i) => i.name === "disoriented")?.value} onClick={mentalStatusClick} onValueChange={mentalStatusValueChange} />
                        <MentalStatus checked={mentalStatus.find((i) => i.name === "lethargic")} name="lethargic" label="Lethargic" value={mentalStatus.find((i) => i.name === "lethargic")?.value} onClick={mentalStatusClick} onValueChange={mentalStatusValueChange} />
                        <MentalStatus checked={mentalStatus.find((i) => i.name === "comatose")} name="comatose" label="Comatose" value={mentalStatus.find((i) => i.name === "comatose")?.value} onClick={mentalStatusClick} onValueChange={mentalStatusValueChange} />
                        <MentalStatus checked={mentalStatus.find((i) => i.name === "agitated")} name="agitated" label="Agitated/Combative" value={mentalStatus.find((i) => i.name === "agitated")?.value} onClick={mentalStatusClick} onValueChange={mentalStatusValueChange} />
                        <MentalStatus checked={mentalStatus.find((i) => i.name === "depressed")} name="depressed" label="Depressed/Anxious" value={mentalStatus.find((i) => i.name === "depressed")?.value} onClick={mentalStatusClick} onValueChange={mentalStatusValueChange} />
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
                    Close
                </button>
                <button type="button" className="btn btn-primary" disabled={false /*invalid*/} onClick={handleSave}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

const MentalStatus = ({ name, label, value, checked, onValueChange, onClick }) => {
    return (
        <>
            <div className="col-md-3 small pb-1">
                <input name={name} type="checkbox" className="form-check-input" checked={checked} onClick={onClick} />
                <label className={`mb-1 ms-2`}>{label}</label>
            </div>
            <div className="col-md-3 ps-0 pb-1">{checked && <ToggleSwitch values={["Rarely", "Sometimes", "Always"]} selected={value} aselected={null /*homeSafetyData?.adequateSharpObjectsSecuredInitial ? homeSafetyData?.adequateSharpObjectsSecuredInitial : "N/A"*/} name={name} onChange={onValueChange} />}</div>
        </>
    );
};

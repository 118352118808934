import React, { useState, useEffect } from 'react';
import { callAPI } from '../../../lib/Global.js';
import PrimaryContactForClient from './PrimaryContactForClient.js';
import styles from './clientInfo.module.css';
import "../../../styles/common.css";
import { swalConfirmOptions, swalToast2, swalAlert } from "../../../lib/Swal.js";



const ClientContactList = (props) => {

    const { clientId } = props;
    const [contactList, setContactList] = useState([]);
    const [showPrimaryContactForClientModal, setShowPrimaryContactForClientModal] = useState(false);
    const [contact, setContact] = useState("");
    const [primaryContact, setPrimaryContact] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [familyMember, setFamilyMember] = useState([]);

    useEffect(() => {
        getContactListForClientById(clientId);
    }, []);

    const getContactListForClientById = async (clientId) => {
        setIsLoading(true);
        const r = await callAPI('GetPrimaryContactsByClientId', { id: clientId });
        const clientFamilyMemberDetailsList = await callAPI("GetClientFamilyMembersByClientId", { id: clientId });
        setFamilyMember(clientFamilyMemberDetailsList);
        if (clientFamilyMemberDetailsList.length > 0) {
            r.unshift({
                fullName: clientFamilyMemberDetailsList[0].firstName + " " + clientFamilyMemberDetailsList[0].lastName,
                phone: clientFamilyMemberDetailsList[0].phoneNumber,
                relationship: clientFamilyMemberDetailsList[0].relationshipToTheRecipientOfCare,
                contactType: clientFamilyMemberDetailsList[0].makePrimaryContact && clientFamilyMemberDetailsList[0].makeEmergencyContact
                    ? "Primary/Emergency" : clientFamilyMemberDetailsList[0].makePrimaryContact ? "Primary" : clientFamilyMemberDetailsList[0].makeEmergencyContact ? "Emergency" : null,
                textMuted: 'text-muted',
            })
        }
        setIsLoading(false);
        setContactList(r);

        // this condition for checking whether primary contact is available for this user or not
        if (r?.length) {

            let primaryContact = '';
            setPrimaryContact('');

            var getPrimaryContact = r.filter((i, j) => i.contactType === "Primary").map(primaryContact => primaryContact.contactType);

            if (getPrimaryContact.length) {
                setPrimaryContact(getPrimaryContact[0]);
            }

        }
    }

    const togglePrimaryContactForClientModal = async (isDataToReload, contact) => {
        if (isDataToReload) {
            getContactListForClientById(clientId);
        }
        setContact(contact);
        setShowPrimaryContactForClientModal(!showPrimaryContactForClientModal);
        props.togglebackDrop();
    }

    const getRelationship = (relationship) => {
        const relations = ['Friend', 'Neighbor', 'Other'];
        return !relations.includes(relationship) ? 'Relative' : 'Other';
    }

    const confirmDeleteContactModal = async (contact) => {
        const msg = "You are about to delete this Contact.";
        await swalConfirmOptions.fire({
            title: msg, html:
                contact.fullName + '<br><b>Do you want to continue?</b>'
        }).then(async (result) => {
            if (result.value) {
                const r = await callAPI('DeletePrimaryContactById', { id: contact.id });
                if (r.isSuccess) {
                    swalToast2('Primary Contact for Client Deleted successfully.');
                    getContactListForClientById(clientId);
                } else {
                    const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
                    swalAlert('e', errorMsg);
                }
            }
        });
    }

    return (
        <div>
            {showPrimaryContactForClientModal && <PrimaryContactForClient clientId={clientId} contactId={contact} togglePrimaryContactForClientModal={togglePrimaryContactForClientModal} primaryContact={primaryContact} />}

            {isLoading ? <div>Please Wait...</div> :
                <>
                    <h5 className="bold-style" style={{ fontSize: '14px' }}>Contacts</h5>
                    <hr />
                    <div className={styles.addContactWrapper} onClick={() => togglePrimaryContactForClientModal(false, '')}>
                        <i class="bi bi-person-plus-fill text-success"></i>
                        <span className="medium-style hover-item" style={{ color: '#3CAF8A' }}> Add Contact</span>
                    </div>
                </>
            }

            {!isLoading && <table class="table table-hover table-sm table-borderless">
                <thead>
                    <tr className={styles.tHeadStyling}>
                        <th scope="col"> <span className="medium-style">Type</span></th>
                        <th scope="col"><span className="medium-style">Name</span></th>
                        <th scope="col"><span className="medium-style">Phone</span></th>
                        <th scope="col" colspan="3"><span className="medium-style">Relationship</span></th>
                    </tr>
                </thead>
                <tbody>
                    {contactList?.map((d, i) => {
                        return (<tr key={i} className="border-bottom">
                            <td width="10%" className={`medium-style ${d?.textMuted}`}>{d.contactType}</td>
                            <td width="30%" className={`medium-style ${d?.textMuted}`}>{d.fullName}</td>
                            <td width="10%" className={`medium-style ${d?.textMuted}`}>{d.phone}</td>
                            <td width="25%" className={`medium-style ${d?.textMuted}'}`}>{getRelationship(d.relationship)} - {d.relationship}</td>
                            {/* <td width="5%"><i class="bi bi-file-earmark-fill text-success"></i></td> */}
                            <td width="5%">{!d?.textMuted && <i class="bi bi-pencil-fill text-success" style={{ cursor: 'pointer' }} onClick={() => togglePrimaryContactForClientModal(false, d.id)}></i>}</td>
                            <td width="5%">{!d?.textMuted && <i class="bi bi-trash-fill text-success" role="button" onClick={() => confirmDeleteContactModal(d)}></i>}</td>
                        </tr>)
                    })}
                </tbody>
            </table>
            }
        </div >
    )
}


export default ClientContactList;

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import "moment-timezone";
import "./clientMedicationsEditDialog.css";

export const ClientMedicationsEditDialog = ({ data, show, setShow, handleSaveClick, handleClose }) => {
    //Start Date state
    const [startDate, setStartDate] = useState("");

    //Discontinued Date state
    const [discontinuedDate, setDiscontinuedDate] = useState("");

    //Medication state
    const [medication, setMedication] = useState("");

    //Medication Class state
    const [medicationClass, setMedicationClass] = useState("");

    //Controlled state
    const [controlled, setControlled] = useState(false);

    //Dose state
    const [dose, setDose] = useState("");

    //Frequency state
    const [frequency, setFrequency] = useState("");

    //Route state
    const [route, setRoute] = useState("");

    //Archived state
    const [archived, setArchived] = useState(false);

    useEffect(() => {
        setStartDate(data.startDate ? moment(data?.startDate).utc().tz("America/New_York").format("YYYY-MM-DD") : null);
        setDiscontinuedDate(data.discontinuedDate ? moment(data?.discontinuedDate).utc().tz("America/New_York").format("YYYY-MM-DD") : null);
        setMedication(data?.medication ?? "");
        setMedicationClass(data?.class ?? "");
        setControlled(data?.controlled ?? false);
        setDose(data?.dose ?? "");
        setFrequency(data?.frequency ?? "");
        setRoute(data?.route ?? "");
        setArchived(data?.archived ?? false);
    }, [data]);

    //=== Handlers ===========================================================

    const handleSave = () => {
        const saveObj = { startDate: startDate, discontinuedDate: discontinuedDate, medication: medication, class: medicationClass, controlled: controlled ?? false, dose: dose, frequency: frequency, route: route, archived: archived ?? false };
        handleSaveClick(saveObj);
        setShow(false);
    };

    const handleClassChange = (e) => {
        setMedicationClass(e.target.options[e.target.options.selectedIndex].text);
        //setHospiceId(e.target.options[e.target.options.selectedIndex].value === "" ? null : e.target.options[e.target.options.selectedIndex].value);
    };

    const handleMedicationChange = (e) => {
        let medicationNew = e.target.value;
        setMedication(medicationNew);
    };

    const handleControlledChange = (e) => {
        let controlledNew = e.target.checked;
        setControlled(controlledNew);
    };

    const handleDoseChange = (e) => {
        let doseNew = e.target.value;
        setDose(doseNew);
    };

    const handleFrequencyChange = (e) => {
        let frequencyNew = e.target.value;
        setFrequency(frequencyNew);
    };

    const handleRouteChange = (e) => {
        let routeNew = e.target.value;
        setRoute(routeNew);
    };

    const handleStartDateChange = (e) => {
        let startDateNew = e.target.value;
        setStartDate(startDateNew);
    };

    const handleDiscontinuedDateChange = (e) => {
        let discontinuedDateNew = e.target.value;
        setDiscontinuedDate(discontinuedDateNew);
    };

    const handleArchivedChange = (e) => {
        let archivedNew = e.target.checked;
        setArchived(archivedNew);
    };

    return (
        <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="text-primary">Edit Medication</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <>
                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Medication Class</span>
                            <select className="form-select" id="class" onChange={handleClassChange} value={medicationClass}>
                                <option value="">Please Select</option>
                                <option value="OTC">OTC</option>
                                <option value="Adrenocortical Steroid">Adrenocortical Steroid</option>
                                <option value="Anabolic Steriod/Androge">Anabolic Steriod/Androge</option>
                                <option value="Analgesic">Analgesic</option>
                                <option value="Antacid">Antacid</option>
                                <option value="Antianginal">Antianginal</option>
                                <option value="Antiarrhythmic">Antiarrhythmic</option>
                                <option value="Antiarthritics/Antigout">Antiarthritics/Antigout</option>
                                <option value="Antibiotic/Antibacterial">Antibiotic/Antibacterial</option>
                                <option value="Anticholinergic">Anticholinergic</option>
                                <option value="Anticoagulant">Anticoagulant</option>
                                <option value="Antidepressant">Antidepressant</option>
                                <option value="Antidiabetic">Antidiabetic</option>
                                <option value="Antidiarrheal">Antidiarrheal</option>
                                <option value="Antiemetic">Antiemetic</option>
                                <option value="Antifungal">Antifungal</option>
                                <option value="Antihelmintic">Antihelmintic</option>
                                <option value="Antihistamine">Antihistamine</option>
                                <option value="Antihypertensive">Antihypertensive</option>
                                <option value="Anti Inflammatory/non-ste">Anti Inflammatory/non-ste</option>
                                <option value="Antineoplastic">Antineoplastic</option>
                                <option value="Antiparkinson">Antiparkinson</option>
                                <option value="Antipyretic">Antipyretic</option>
                                <option value="Antitubercular">Antitubercular</option>
                                <option value="Antiulcer">Antiulcer</option>
                                <option value="Antiviral">Antiviral</option>
                                <option value="Brochodilator">Brochodilator</option>
                                <option value="Cardiac Glycoside">Cardiac Glycoside</option>
                                <option value="Cholinergic">Cholinergic</option>
                                <option value="Diuretic">Diuretic</option>
                                <option value="Hematopoietic Agent">Hematopoietic Agent</option>
                                <option value="Immune Glubulin">Immune Glubulin</option>
                                <option value="Immunosuppressant">Immunosuppressant</option>
                                <option value="Interferon">Interferon</option>
                                <option value="Laxative/Stool Softener">Laxative/Stool Softener</option>
                                <option value="Muscle Relaxant">Muscle Relaxant</option>
                                <option value="Nutirtional Additive/IV">Nutirtional Additive/IV</option>
                                <option value="Oxygen">Oxygen</option>
                                <option value="Sedative/Hypnotic">Sedative/Hypnotic</option>
                                <option value="Thyroid Agent">Thyroid Agent</option>
                                <option value="Tranquilizer/Anti Psychot">Tranquilizer/Anti Psychot</option>
                                <option value="Vasodilator">Vasodilator</option>
                            </select>
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">Medication</span>
                            <input type="text" className="form-control" placeholder={`Enter Medication`} value={medication} name="medication" onChange={handleMedicationChange} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-4 pt-4 mt-3">
                            <input name="controlled" type="checkbox" className="form-check-input" checked={controlled} onClick={handleControlledChange} />
                            <label className={`mb-1 ms-2`}>Controlled Substance</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Dose</span>
                            <input type="text" className="form-control" placeholder={`Enter Dose`} value={dose} name="dose" onChange={handleDoseChange} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">Frequency</span>
                            <input type="text" className="form-control" placeholder={`Enter Frequency`} value={frequency} name="dose" onChange={handleFrequencyChange} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">Route</span>
                            <input type="text" className="form-control" placeholder={`Enter Route`} value={route} name="dose" onChange={handleRouteChange} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <span className="my-1 d-block">Start Date</span>
                            <input type="date" className="form-control" placeholder={`Enter Date`} value={startDate} name="dose" onChange={handleStartDateChange} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-4">
                            <span className="my-1 d-block">Discontinued Date</span>
                            <input type="date" className="form-control" placeholder={`Enter Date`} value={discontinuedDate} name="dose" onChange={handleDiscontinuedDateChange} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-4 pt-4 mt-3">
                            <input name="archived" type="checkbox" className="form-check-input" checked={archived} onClick={handleArchivedChange} />
                            <label className={`mb-1 ms-2`}>Archived</label>
                        </div>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
                    Close
                </button>
                <button type="button" className="btn btn-primary" disabled={false /*invalid*/} onClick={handleSave}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

import React from 'react';
import styles from '../styles/common.module.css';

const CheckBox = ({ handler, touched, hasError, meta }) => (
    <div className="form-check mb-1">
        <input className="form-check-input" onClick={meta.togglePrimaryContactCheckbox} {...handler("checkbox")} />
        <label className={`form-check-label ${styles.labelStyling}`}>{meta.label}</label>
    </div>
);

export default CheckBox;
import React from 'react';
import { callAPI, callAPIforFileUpload, isFileWithInMBof } from '../../../lib/Global.js';
import { Link, withRouter, useParams } from 'react-router-dom';
import '../../../styles/common.css';
import styles from './clientInfo.module.css';
import { swalAlert, swalToast, swalToast2 } from '../../../lib/Swal.js';
import UpdateClientBranchCardModal from './UpdateClientBranchCardModal.js';
import { getAllBranches } from './admin/components/branchList/branchListRest.js';


const ClientBranchCard = (props) => {

    const { clientBranchId, clientId } = props;

    const [showClientBranchModal, setShowClientBranchModal] = React.useState(false);
    const [branchList, setBranchList] = React.useState([]);
    const [updatedClientBranch, setUpdatedClientBranch] = React.useState('');

    const [clientData, setClientData] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);

    const [clientBranch, setClientBranch] = React.useState();

    React.useEffect(() => {
            getAllBranchesList();
    }, []);

    const getAllBranchesList = () => {
        getAllBranches().then((data) => {
            let branchesListArr = [];
            data.map((i) => {
                branchesListArr.push({
                    text: i.name,
                    value: i.id
                });
            });
            setBranchList(branchesListArr);
            let branch = branchesListArr.find(branch => branch.value === clientBranchId)
            setClientBranch(branch);
        });
    }


    const toggleClientBranchCardModal = () => {
        setShowClientBranchModal(!showClientBranchModal);
        props.togglebackDrop();
    }

    const updateClientBranch = async (newClientBranchId) => {

        setIsLoading(true);

        const r = await callAPI('UpdateClientBranch', { clientId: clientId, clientBranchId: newClientBranchId });

        if (r.isSuccess) {
            await getClient();
            swalToast2('Client Branch updated successfully');
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    const getClient = async () => {

        const r = await callAPI('GetClient', { id: clientId });

        setClientBranch(branchList?.find(branch => branch.value === r?.branch));
        setIsLoading(false);
    }

    return (
        <>
            <div className={`col-2 mx-1 ${styles.card}`} onClick={() => toggleClientBranchCardModal()}>
                <div className="form-group mb-2">
                    <div className="p-2 text-center"><label for="" className="mb-1 bold-color-style">Branch</label></div>
                    <div className="medium-style text-center">{isLoading ? 'Please Wait...' : clientBranch?.text || ''}</div>
                </div>
            </div>
            {showClientBranchModal && <UpdateClientBranchCardModal branchList={branchList} toggleClientBranchCardModal={toggleClientBranchCardModal} newClientBranch={updateClientBranch} currentClientBranch={clientBranch} />}
        </>
    )
}

export default ClientBranchCard;
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { callAPI } from '../../../lib/Global.js';
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import TextInput from '../../../Controls/TextInput.js';
import TextArea from '../../../Controls/TextArea.js';
import SelectInput from '../../../Controls/SelectInput.js';
import { swalAlert, swalToast2 } from '../../../lib/Swal.js';

const clientAddressForm = FormBuilder.group({
    id: null,
    clientId: [null, Validators.required],
    addressType: [null, Validators.required],
    buildingName: null,
    addressLine1: [null, Validators.required],
    addressLine2: null,
    city: [null, [Validators.required, Validators.maxLength(50)]],
    state: [null, [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
    zipCode: [null, [Validators.required, Validators.maxLength(5), Validators.minLength(5), Validators.pattern("^[0-9]*$")]],
    addressNotes: null
});

const ClientAddressModal = ({ show, handleClose, clientId, selectedAddr, getAddressListByClientId }) => {
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        if (!selectedAddr?.id) {
            clientAddressForm.patchValue({ clientId: clientId });
        } else {
            clientAddressForm.patchValue({ ...selectedAddr });
        }
    }, [selectedAddr,clientId]);

    const handleResetForm = () => {
        clientAddressForm.reset();
        clientAddressForm.patchValue({ clientId: clientId });
        handleClose();
    }

    const handleSave = async () => {

        clientAddressForm.updateValueAndValidity(clientAddressForm.value);
        setisLoading(true);

        const apiText = clientAddressForm.value.id ? 'UpdateAddressForClient' : 'CreateAddressForClient';
        let message = clientAddressForm.value.id !== null ? 'Updated' : 'Saved';
        const r = await callAPI(apiText, clientAddressForm.value);
        setisLoading(false);
        clientAddressForm.updateValueAndValidity(clientAddressForm.value);

        if (r.isSuccess) {
            swalToast2('Client Address ' + message + ' successfully.');
            handleResetForm();
            getAddressListByClientId(clientId);
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    return (
        <Modal dialogClassName="modal-90w"
            show={show}
            onHide={handleResetForm}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title >
                    <h5 className="modal-title text-success" id="exampleModalLabel">{selectedAddr?.id ? 'Edit ' : 'Add New '}Address</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FieldGroup
                    control={clientAddressForm}
                    render={({ get, invalid, pristine }) => (
                        <form>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <FieldControl
                                        name="addressType"
                                        render={SelectInput}
                                        meta={{
                                            label: "Address Type",
                                            options: [
                                                { text: 'House' },
                                                { text: 'Apartment' },
                                                { text: 'ALF' },
                                                { text: 'Subacute' },
                                                { text: 'Temporary Residence' }
                                            ]
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <FieldControl
                                        name="buildingName"
                                        render={TextInput}
                                        meta={{ label: "Building Name", maxLengthVal: 40 }}
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <FieldControl
                                        name="addressLine1"
                                        render={TextInput}
                                        meta={{ label: "Address Line 1", maxLengthVal: 255 }}
                                    />
                                </div>
                                <div className="col-6">
                                    <FieldControl
                                        name="addressLine2"
                                        render={TextInput}
                                        meta={{ label: "Address Line 2", maxLengthVal: 255 }}
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <FieldControl
                                        name="city"
                                        render={TextInput}
                                        meta={{ label: "City", maxLengthVal: 50 }}
                                    />
                                </div>

                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-8">
                                            <FieldControl
                                                name="state"
                                                render={TextInput}
                                                meta={{ label: "State", maxLengthVal: 2, minLengthVal: 2 }}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <FieldControl
                                                name="zipCode"
                                                render={TextInput}
                                                meta={{ label: "ZIP", maxLengthVal: 5, minLengthVal: 5, patternErrMsg: "Only numbers are allowed" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-12">
                                    <FieldControl
                                        name="addressNotes"
                                        render={TextArea}
                                        meta={{ label: "Contact notes", maxLengthVal: 250 }}
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <div className="d-grid gap-2 pb-1">
                                    <button type="button" onClick={() => handleSave()} className="btn btn-lg btn-success rounded-pill" disabled={invalid || pristine || isLoading}>{isLoading ? 'Please wait...' : 'Save'}</button>
                                    <button type="button" onClick={() => handleResetForm()} className="btn btn-link">Cancel</button>
                                </div>
                            </div>

                        </form>
                    )}
                />
            </Modal.Body>
        </Modal>
    )
}

export default ClientAddressModal;
import React, { useEffect } from 'react';
import '../../../styles/common.css'



const Dashboard = (props) => {
    useEffect(() => {

        return () => {
            props.toggleCardManager(false);
        }
    },[])
    return (
        <div className="container-fluid pt-3">
            <div className="row">
                <div className="col-7">
                    <div className="row">
                        {props.dashboardTiles[0]?.isSelected && <div className="col-lg-6 col-12">
                            <div className="card dasboard-card-style" style={{ height: '379px' }}>
                                <div className="card-body">
                                    <span className="card-title medium-style" style={{ fontSize: '18px', letterSpacing: '0.1px' }}>Profile Search</span>
                                </div>
                            </div>
                        </div>}
                        <div className="col-lg-6 col-12">
                            <div className="row">
                                {props.dashboardTiles[1]?.isSelected && <div className="col-12">
                                    <div className="card dasboard-card-style" style={{ height: '177px' }}>
                                        <div className="card-body">
                                            <span className="card-title medium-style" style={{ fontSize: '18px', letterSpacing: '0.1px' }}>Latest Updates</span>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className="row pt-3">
                                {props.dashboardTiles[2]?.isSelected && <div className="col-6">
                                    <div className="card dasboard-card-style" style={{ height: '182px' }}>
                                        <div className="card-body">
                                            <span className="card-title medium-style" style={{ fontSize: '18px', letterSpacing: '0.1px' }}>Add New</span>
                                        </div>
                                    </div>
                                </div>}
                                {props.dashboardTiles[3]?.isSelected && <div className="col-6">
                                    <div className="card dasboard-card-style" style={{ height: '182px' }}>
                                        <div className="card-body">
                                            {/* <span className="card-title medium-style"  style={{ fontSize: '18px', letterSpacing: '0.1px' }}>Profile Search</span> */}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="row pt-3">
                        {props.dashboardTiles[4]?.isSelected && <div className="col-12">
                            <div className="card dasboard-card-style" style={{ height: '379px' }}>
                                <div className="card-body">
                                    <span className="card-title medium-style" style={{ fontSize: '18px', letterSpacing: '0.1px' }}>Action Feed</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
export const TAB_HEADING = {
    BASIC_INTAKE: 1,
    PROFILE: 2,
    CONTACTS: 3,
    FAMILY_DYNAMICS: 4,
    START_OF_CARE: 5,
    FAMILY_MEMBERS: 6,
    LIFESTYLE_BACKGROUND: 7,
    CARE_STAFF: 8,
    PAYMENT: 9,
    HEALTH_ASSESSMENT: 10,
    SERVICES: 11,
    ADDRESSES: 12,
    DOCUMENTS: 13,
    JOURNAL: 14,
    PSYCOTHERAPY: 15,
    MEDICATIONS: 16,
    DIETARY: 17,
    MOBILITY: 18,
    EATING: 19,
    PHYSICIAN: 20,
};

export const TAB_DATA = [

    {
        index: 2,
        tabName: "Profile",
    },
    {
        index: 3,
        tabName: "Contacts",
    },
    {
        index: 1,
        tabName: "Basic Intake",
    },
    {
        index: 7,
        tabName: "LifeStyle and Background",
    },
    {
        index: 4,
        tabName: "Family Dynamics",
    },
    {
        index: 20,
        tabName: "Physician"
    },
    {
        index: 18,
        tabName: "Mobility"
    },
    {
        index: 11,
        tabName: "Services",
    },
    {
        index: 17,
        tabName: "Dietary"
    },
    /*{
        index: 5,
        tabName: 'Start of Care'
    },*/
    // {
    //     index: 6,
    //     tabName: "Family Members",
    // },

    {
        index: 8,
        tabName: "Care Preferences",
    },

    // {
    //     index: 10,
    //     tabName: "Health Assessments",
    // },

    // {
    //     index: 12,
    //     tabName: "Addresses",
    // },
    // {
    //     index: 13,
    //     tabName: "Documents",
    // },
    // {
    //     index: 14,
    //     tabName: 'Journal'
    // },
    {
        index: 15,
        tabName: 'Psychotherapy'
    },
    {
        index: 9,
        tabName: "Payment",
    },
    // {
    //     index: 16,
    //     tabName: "Medications",
    // },
    // {
    //     index: 19,
    //     tabName: "Eating"
    // },
];

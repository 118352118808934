import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormBuilder, FieldGroup, FieldControl, Validators } from "react-reactive-form";

const TextInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group">
        <label htmlFor="formControlInput1" className={`mb-1`}>
            {meta.label}
        </label>
        <input type="text" className={!touched ? "form-control" : hasError("required") ? "form-control is-invalid" : "form-control is-valid"} placeholder={`Enter ${meta.label}`} {...handler()} maxLength="50" />
        <div className="d-block text-danger small text-end">
            &nbsp;
            {touched && hasError("required") && `${meta.label} is required`}
            {hasError("email") && `${meta.emailErrMsg}`}
            {hasError("pattern") && `${meta.patternErrMsg}`}
        </div>
    </div>
);

//Form fields definition
const basicIntakeLimitationFormDefinition = FormBuilder.group({
    basicIntakeLimitationName: [null, [Validators.required]],
});

export const BasicIntakeLimitationEditDialog = ({ show, setShow, handleSaveClick, handleClose }) => {
    //=== Handlers ===========================================================

    const handleSave = () => {
        handleSaveClick(basicIntakeLimitationFormDefinition.value);
        setShow(false);
    };

    //========================================================================
    useEffect(() => {
        basicIntakeLimitationFormDefinition.reset();
    });

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="text-primary">Add Limitation</Modal.Title>
            </Modal.Header>

            <FieldGroup
                control={basicIntakeLimitationFormDefinition}
                render={({ get, invalid }) => (
                    <form>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    <FieldControl
                                        name="basicIntakeLimitationName"
                                        render={TextInput}
                                        meta={{
                                            label: "Name",
                                            maxLengthVal: 50,
                                        }}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
                                Close
                            </button>
                            <button type="button" className="btn btn-primary" disabled={invalid} onClick={handleSave}>
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </form>
                )}
            />
        </Modal>
    );
};

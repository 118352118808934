import React from "react";
import { FormBuilder, Validators, FieldControl, FieldGroup } from "react-reactive-form";
import { withRouter } from "react-router-dom";
import { swalAlert, swalToast, swalToast2 } from "../../../lib/Swal.js";
import styles from "../../../styles/common.module.css";
import { callAPI } from "../../../lib/Global.js";
import TextInput from "../../../Controls/TextInput";
import SelectInput from "../../../Controls/SelectInput.js";
// import Values from "../lib/FormValue.js";

class FamilyDynamicsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, isDataUpdated: false };
    }

    componentWillUnmount() {
        this.familyDynamicsForm.get("maritalStatus").valueChanges.unsubscribe();
        this.familyDynamicsForm.get("isThereBudgetForCare").valueChanges.unsubscribe();
    }

    async componentDidMount() {
        await this.getFamilyDynamicsByClientId();
        this.familyDynamicsForm.get("maritalStatus").valueChanges.subscribe((v) => {
            this.familyDynamicsForm.patchValue({ howLongAgoWidowedOrWidowered: null });
            if (v === "Widowed/Widowered") {
                this.familyDynamicsForm.get("howLongAgoWidowedOrWidowered").enable();
            } else {
                this.familyDynamicsForm.get("howLongAgoWidowedOrWidowered").disable();
            }
        });

        this.familyDynamicsForm.get("isThereBudgetForCare").valueChanges.subscribe((v) => {
            this.familyDynamicsForm.patchValue({ budget: null });
            if (v === "Yes") {
                this.familyDynamicsForm.get("budget").enable();
            } else {
                this.familyDynamicsForm.get("budget").disable();
            }
        });
    }

    familyDynamicsForm = FormBuilder.group({
        id: [null],
        clientId: [null, Validators.required],
        maritalStatus: [null, [Validators.required, Validators.maxLength(25)]],
        howLongAgoWidowedOrWidowered: [null],
        powerOfAttorney: [null, [Validators.required, Validators.maxLength(10)]],
        healthcareProxy: [null, [Validators.required, Validators.maxLength(10)]],
        isFamilyConcernedAboutFinances: [null, [Validators.required, Validators.maxLength(10)]],
        isThereBudgetForCare: [null, [Validators.required, Validators.maxLength(10)]],
        budget: [null, Validators.pattern("^[0-9]*$")],
        // payPeriod: [null, [Validators.required, Validators.maxLength(20)]]
    });

    getFamilyDynamicsByClientId = async () => {
        this.setState({ isLoading: true });

        const r = await callAPI("GetClientFamilyDynamicsByClientId", { id: this.props.clientId });
        this.setState({ isLoading: false });

        this.familyDynamicsForm.patchValue(r ? r : { clientId: this.props.clientId });

        if (r?.maritalStatus === "Widowed/Widowered") {
            this.familyDynamicsForm.get("howLongAgoWidowedOrWidowered").enable();
        } else {
            this.familyDynamicsForm.get("howLongAgoWidowedOrWidowered").disable();
        }

        if (r?.isThereBudgetForCare === "Yes") {
            this.familyDynamicsForm.get("budget").enable();
        } else {
            this.familyDynamicsForm.get("budget").disable();
        }
    };

    // markFormPurpose2Update = () => {
    //     this.familyDynamicsForm.updateValueAndValidity(this.familyDynamicsForm.value);
    //     this.setState({ formPurpose: 'U' });
    // }

    handleSubmit = async (e) => {
        e.preventDefault();

        this.setState({ isDataUpdated: !this.state.isDataUpdated });
        const v = { ...this.familyDynamicsForm.value };
        if (v.maritalStatus !== "Widowed/Widowered") {
            v.howLongAgoWidowedOrWidowered = null;
        }

        if (v.isThereBudgetForCare === "No") {
            v.budget = null;
        }

        if (v.maritalStatus === "Widowed/Widowered" && !v.howLongAgoWidowedOrWidowered) {
            swalAlert("e", "Please select How long ago widowed/widowered?.");
            return;
        }

        if (v.isThereBudgetForCare === "Yes" && !v.budget) {
            swalAlert("e", "Please enter budget value.");
            return;
        }

        this.familyDynamicsForm.updateValueAndValidity(this.familyDynamicsForm.value);
        this.setState({ isLoading: true });

        const apiText = this.familyDynamicsForm.value.id ? "UpdateClientFamilyDynamics" : "CreateClientFamilyDynamics";
        const r = await callAPI(apiText, v);
        this.setState({ isLoading: false });

        this.familyDynamicsForm.updateValueAndValidity(this.familyDynamicsForm.value);

        if (r?.isSuccess) {
            swalToast2("Client Family Dynamics saved successfully.");
            this.closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || "Something went wrong!!!"}</span>`;
            swalAlert("e", errorMsg);
        }
    };

    closeModal = (isDataToReload) => {
        this.props.closeClientIdentityModal(isDataToReload);
    };

    render() {
        // <Values value={value} /> // for form value debug purpose.
        return (
            <div className={`modal fade show`} style={{ display: "block" }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="exampleModalLabel">
                                Family Dynamics
                            </h5>
                            <i className="bi bi-x-lg" onClick={() => this.closeModal(false)} style={{ cursor: "pointer" }}></i>
                        </div>
                        <div className="modal-body px-0 mx-0">
                            <div className="container mx-0 px-0">
                                <div className="row">
                                    {/* <h6 className="modal-title text-success">Family Dynamics </h6> */}
                                    {/* <i className="bi bi-pencil" onClick={() => this.markFormPurpose2Update()} style={{ cursor: 'pointer' }}></i> */}
                                </div>
                                <form className="row mx-0 px-0">
                                    {this.state.isLoading && !this.state.isDataUpdated && (
                                        <>
                                            <div>Please Wait...</div>
                                        </>
                                    )}

                                    {this.state.isDataUpdated && (
                                        <>
                                            <div>Completed...</div>
                                        </>
                                    )}

                                    {!this.state.isLoading && (
                                        <>
                                            <FieldGroup
                                                control={this.familyDynamicsForm}
                                                render={({ invalid, pristine, value }) => (
                                                    <>
                                                        <div className="row mb-2">
                                                            <div className="col-12">
                                                                <FieldControl
                                                                    name="maritalStatus"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <>
                                                                            <div className="row my-1">
                                                                                <div className="col-12">
                                                                                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                                                                                        Marital Status
                                                                                    </label>
                                                                                    <div className="form-check form-check-inline">
                                                                                        <input className="form-check-input" {...handler("radio", "Single")} />
                                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioSingle">
                                                                                            Single
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-inline">
                                                                                        <input className="form-check-input" {...handler("radio", "Married")} />
                                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioMarried">
                                                                                            Married
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="form-check form-check-inline">
                                                                                        <input className="form-check-input" {...handler("radio", "Divorced")} />
                                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioDivorced">
                                                                                            Divorced
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-inline">
                                                                                        <input className="form-check-input" {...handler("radio", "Widowed/Widowered")} />
                                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioWidowed/Widowered">
                                                                                            Widowed/Widowered
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    meta={{ label: "Marital Status" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col-12">
                                                                <FieldControl
                                                                    name="howLongAgoWidowedOrWidowered"
                                                                    render={SelectInput}
                                                                    meta={{
                                                                        label: "How long ago widowed/widowered?",
                                                                        options: [{ text: "0 - 5 years" }, { text: "6 - 10 years" }, { text: "11+ years" }],
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col-5">
                                                                <FieldControl
                                                                    name="powerOfAttorney"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <>
                                                                            <label for="formCheck1" className={`mb-1 col-12 d-block ${styles.labelStyling}`}>
                                                                                {meta.label}
                                                                            </label>
                                                                            <div className="form-check form-check-inline">
                                                                                <input class="form-check-input" {...handler("radio", "Yes")} />
                                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioYes">
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input class="form-check-input" {...handler("radio", "No")} />
                                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioNo">
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    meta={{ label: "Power of Attorney?" }}
                                                                />
                                                            </div>

                                                            <div className="col-6">
                                                                <FieldControl
                                                                    name="healthcareProxy"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <>
                                                                            <label for="formCheck1" className={`mb-1 col-12 d-block ${styles.labelStyling}`}>
                                                                                {meta.label}
                                                                            </label>
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input" {...handler("radio", "Yes")} />
                                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioYes">
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input class="form-check-input" {...handler("radio", "No")} />
                                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioNo">
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    meta={{ label: "Healthcare proxy?" }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-2">
                                                            <div className="col-5 pt-2">
                                                                <FieldControl
                                                                    name="isFamilyConcernedAboutFinances"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <>
                                                                            <label for="formCheck1" className={`mb-1 col-12 d-block ${styles.labelStyling}`}>
                                                                                {meta.label}
                                                                            </label>
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input" {...handler("radio", "Yes")} />
                                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioYes">
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input class="form-check-input" {...handler("radio", "No")} />
                                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioNo">
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    meta={{ label: "Is family concerned about finances?" }}
                                                                />
                                                            </div>

                                                            <div className="col-4 pt-2">
                                                                <FieldControl
                                                                    name="isThereBudgetForCare"
                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                        <>
                                                                            <label for="formCheck1" className={`mb-1 col-12 d-block ${styles.labelStyling}`}>
                                                                                {meta.label}
                                                                            </label>
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input" {...handler("radio", "Yes")} />
                                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioYes">
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input class="form-check-input" {...handler("radio", "No")} />
                                                                                <label class={`form-check-label ${styles.labelStyling}`} for="RadioNo">
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    meta={{ label: "Is there budget for care?" }}
                                                                />
                                                            </div>
                                                            <div className="col-3 pt-1">
                                                                <FieldControl name="budget" render={TextInput} meta={{ label: "Annual Budget($)", maxLengthVal: 10, patternErrMsg: "Only numbers are allowed" }} />
                                                            </div>
                                                        </div>

                                                        {/* <div className="row mb-2">
                                                        <div className="col-6">
                                                            <FieldControl
                                                                name="payPeriod"
                                                                render={SelectInput}
                                                                meta={{
                                                                    label: "Select pay period",
                                                                    options: [
                                                                        { text: 'Per Hour' },
                                                                        { text: 'Per day' },
                                                                        { text: 'Per week' },
                                                                        { text: 'Per two weeks' },
                                                                        { text: 'Per month' },
                                                                        { text: 'Per year' }
                                                                    ]
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                        </div>
                                                    </div> */}

                                                        <div className="row text-lg-end pb-1 pt-3 mx-0 px-0" style={{ borderTop: "1px solid lightgray" }}>
                                                            <div className="col-md-12">
                                                                <button type="button" onClick={() => this.closeModal(false)} className="btn btn-outline-secondary">
                                                                    Close
                                                                </button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" onClick={(e) => this.handleSubmit(e)} className="btn btn-primary" disabled={invalid || pristine || this.state.isLoading}>
                                                                    {this.state.isLoading ? "Please wait..." : "Save"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            />
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FamilyDynamicsModal);

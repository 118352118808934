import { useEffect, useState } from 'react';
import { ReCaptcha_SITE_KEY } from '../../../../lib/Global.js';

function AddReCaptchaScript() {
    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${ReCaptcha_SITE_KEY}`, function () { });
    }, []);

    return (
        <>
        </>
        // <button className="g-recaptcha" onClick={handleOnClick} data-sitekey={ReCaptcha_SITE_KEY}>Submit</button>
    );
}

export default AddReCaptchaScript;
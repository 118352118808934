import React from 'react';
import {
    FormBuilder,
    Validators,
    FieldControl,
    FieldGroup
} from "react-reactive-form";
import { withRouter } from 'react-router-dom';
import { swalAlert, swalToast } from './../../../../lib/Swal.js';
import { callAPI } from '../../../../lib/Global.js';
import styles from '../Login/login.module.css';

class CantAccessYourAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false };
    }

    cantAccessYourAccountForm = FormBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        requestor: ['Infinity', Validators.required] // requestor is a front-end name.
    });

    handleSubmit = async (e) => {
        e.preventDefault();

        this.cantAccessYourAccountForm.updateValueAndValidity(this.cantAccessYourAccountForm.value);
        this.setState({ isLoading: true });

        const r = await callAPI('ResetPasswordLink', this.cantAccessYourAccountForm.value);
        this.setState({ isLoading: false });
        this.cantAccessYourAccountForm.updateValueAndValidity(this.cantAccessYourAccountForm.value);
        if (r.isResetPasswordLinkSent) {
            swalToast('An email has been sent to the address on file with further instructions.');
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    render() {
        return (
            <>
                <div style={{
                    height: '100vh',
                    position: 'relative',
                    backgroundImage: `url('../img/login-background-img.jpg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}>
                    <div style={{
                        position: 'absolute',
                        height: '100%',
                        backgroundColor: 'rgb(0, 0, 0)',
                        width: '100%',
                        opacity: '0.7'
                    }}>
                    </div>
                </div>
                <img src="./img/caring-people-logo.svg" alt="Caring people Logo" className={styles.loginLogoImgStyling} />
                <div className="container d-flex align-items-center flex-column position-absolute top-50 start-50 translate-middle">
                    <div className={`card border-0 py-5 px-3 ${styles.loginCardStyling}`}>
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src="./img/login-header-image.svg" className={`card-img-top ${styles.loginImgStyling}`} alt="..." />
                            </div>
                            <div className="col-12 text-center">
                                <span className={`${styles.loginTitleStyling}`}>Built On Passion and Excellence</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <h4 className="card-title"><span className={styles.signInLabelStyling}>Can't Access Your Account?</span></h4>
                            <footer className="text-muted pb-6"><span className={styles.contInfinityLabelStyling}>Please enter your email address to reset your password </span></footer>
                            <form className="row g-3 pt-4" onSubmit={() => this.handleSubmitLoginOtp}>
                                <FieldGroup
                                    control={this.cantAccessYourAccountForm}
                                    render={({ invalid, pristine, pending }) => (
                                        <>
                                            <FieldControl
                                                name="email"
                                                render={({ handler, touched, hasError }) => (
                                                    <div className="input-group mb-3">
                                                        <input type="text" className={`form-control rounded-0 ${styles.loginInputStyling} ` + (pristine ? '' : touched && (hasError("required") || hasError("email")) ? 'is-invalid' : 'is-valid')} placeholder="Enter Username" {...handler()} required />
                                                        <div className="invalid-feedback">
                                                            <span>
                                                                {touched &&
                                                                    hasError("required") &&
                                                                    "Username is required"}
                                                                {touched &&
                                                                    hasError("email") &&
                                                                    "Invalid email id"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            <div className="row g-3">
                                                <div className="d-grid gap-2 col-6 mx-auto">
                                                    <button className={`btn ${styles.signInButtonStyling} `} disabled={invalid || pristine || this.state.isLoading} onClick={e => this.handleSubmit(e)}>{this.state.isLoading ? 'Please wait...' : 'Send'}</button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                />
                            </form>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(CantAccessYourAccount);

import React from "react";
import moment from "moment";
import { callAPI, phoneNumberWithDashes } from "../../../lib/Global.js";
import { withRouter } from "react-router-dom";
import FamilyDynamics from "./FamilyDynamics.js";
import StartOfCareForClient from "./StartOfCareForClient.js";
import FamilyMembers from "./FamilyMembers.js";
import ClientCareStaffPreferences from "./CareStaffPreferences.js";
import "../../../styles/common.css";
import styles from "./clientInfo.module.css";
import StaticProperties from "../../../utils/StaticProperties.js";
import ClientBranchCard from "./ClientBranchCard.js";
import { MemoizedClientStatus } from "./ClientStatus.js";
import ClientPhoto from "./ClientPhoto.js";
import { ClientCASScore } from "./clientCASScore/clientCASScore.jsx";
import Tabs from '../../../Shared/Tabs/Tabs.js'
import { ageCalculation } from '../../../lib/DateTimeOps.js';
import BackDrop from "../../../Shared/AdminSidePanel/BackDrop";
import ClientModal from './ClientModal';
import { getAllBranches } from './admin/components/branchList/branchListRest.js';

class ClientProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showFamilyDynamicsModal: false,
            showStartOfCareModal: false,
            showFamilyMemberModal: false,
            showCareStaffPreferenceModal: false,
            imageFile: " ",
            previewImage: "",
            isLoadingImage: false,
            isPreview: false,
            isUploaded: false,
            blobName: "",
            latestStartOfCare: "",
            showBackdrop: false
        };
    }

    async componentDidMount() {
        const clientId = this.props.match.params.clientId;
        await this.getClient(clientId);
        await this.getLatestStartOfCare(clientId);
        this.getAllBranchesList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.showModal !== prevProps.showModal) {
            this.setState({ showBackdrop: this.props.showModal });
        }
    }

    componentWillUnmount() {
        StaticProperties.clientNameWithId = "";
    }

    getClient = async (clientId) => {
        this.setState({ isLoading: true });
        const r = await callAPI("GetClient", { id: clientId });
        this.setState({ clientData: r, leadStatus: r.leadStatus, isLoading: false });
        this.props.setBreadCrumbName({ id: clientId, clientName: r.recipientOfCareFirstName + " " + r.recipientOfCareLastName });
    };

    getLatestStartOfCare = async (clientId) => {
        this.setState({ isLoading: true });
        const r = await callAPI("GetStartOfCareDateByClientId", { id: clientId });
        this.setState({ latestStartOfCare: r, isLoading: false });
    };

    branchesListArr = [];

    getAllBranchesList = () => {
        getAllBranches().then((data) => {
            data.map((i) => {
                this.branchesListArr.push({
                    text: i.name,
                    value: i.id
                });
            })
        });
    }

    toggleFamilyDynamicsModal = async (isDataToReload) => {
        if (isDataToReload) {
            // call list of addresses here...
        }
        this.setState({ showFamilyDynamicsModal: !this.state.showFamilyDynamicsModal });
    };

    leadStatusChange = async () => {
        const id = this.state.clientData.id;
        await this.getClient(id);
    };

    toggleStartOfCareModal = async (isDataToReload) => {
        if (isDataToReload) {
            await this.getLatestStartOfCare(this.state.clientData.id);
        }
        this.setState({ showStartOfCareModal: !this.state.showStartOfCareModal });
    };

    toggleFamilyMemberModal = async () => {
        this.setState({ showFamilyMemberModal: !this.state.showFamilyMemberModal });
    };

    toggleCareStaffPreferencesModal = async () => {
        this.setState({ showCareStaffPreferenceModal: !this.state.showCareStaffPreferenceModal });
    };

    togglebackDrop = () => {
        this.setState(({ showBackdrop: !this.state.showBackdrop }));
    }

    toggleModal = async (isDataToReload) => {
        if (isDataToReload) {

        }
        this.togglebackDrop();
        this.props.toggleModal();
    }

    render() {
        if (this.state.isLoading) {
            return <div>Please wait...</div>;
        }

        const clientId = this.state.clientData?.id;
        const recipientOfCareFirstName = this.state.clientData?.recipientOfCareFirstName;
        const photo = this.state.clientData?.photo;
        const recipientOfCareLastName = this.state.clientData?.recipientOfCareLastName;
        // const age = this.state.clientData?.age;
        const recipientOfCarePhoneNumber = this.state.clientData?.recipientOfCarePhoneNumber;
        const clientBranchName = this.state.clientData?.clientBranchName;
        const leadStatus = this.state.clientData?.leadStatus;
        const addressLine1 = this.state.clientData?.addressLine1;
        const addressLine2 = this.state.clientData?.addressLine2 === null ? " " : this.state.clientData?.addressLine2 + ", ";
        const city = this.state.clientData?.city;
        const state = this.state.clientData?.state;
        const zipCode = this.state.clientData?.zipCode;
        return (
            <>
                {/* <h3>Client ID: {clientId} - {primaryContactFirstName} - {leadStatus}</h3> */}
                {this.state.showBackdrop && <BackDrop />}
                {this.props.showModal && <ClientModal branchesListArr={this.branchesListArr} toggleModal={this.toggleModal} {...this.props} />}
                <div className="container-fluid mb-3">
                    <div className="row py-4 gx-0">
                        <div className="col-12 col-lg-1">
                            <ClientPhoto photo={photo} clientId={clientId} />
                        </div>
                        <div className="col-lg-2 ps-lg-3 col-2">
                            <div>
                                <span className="bold-style">
                                    {recipientOfCareFirstName} {recipientOfCareLastName}
                                </span>
                            </div>
                            <div>
                                <span className="medium-style">
                                    {ageCalculation(this.state.clientData?.birthDate)} years old ({moment(this.state.clientData?.birthDate).format("MM/DD/YYYY")})
                                </span>
                            </div>
                            <div>
                                <i class="bi bi-telephone-fill text-success"></i>
                                <span className="ms-2 medium-style">{phoneNumberWithDashes(recipientOfCarePhoneNumber)}</span>
                            </div>
                            <div>
                                <span className="medium-style">{addressLine1 + "," + addressLine2 + city + " " + state + " " + zipCode}</span>
                            </div>
                        </div>
                        <div className="col-2">
                            <MemoizedClientStatus branchesListArr={this.branchesListArr} clientId={clientId} clientBranchId={this.state.clientData?.branch} primaryContactFirstName={recipientOfCareFirstName} primaryContactLastName={recipientOfCareLastName} leadStatus={leadStatus} leadStatusChange={this.leadStatusChange} togglebackDrop={this.togglebackDrop} />
                        </div>
                        <ClientBranchCard clientBranchId={this.state.clientData?.branch} clientId={this.state.clientData?.id} togglebackDrop={this.togglebackDrop} />
                        <div className={`col-2 ${styles.card}`}>
                            <div className="p-2 text-center">
                                <label for="formControlSelect1" className="mb-1 bold-color-style">
                                    Latest SOC
                                </label>
                            </div>
                            <div className="medium-style text-center">{this.state.latestStartOfCare && moment(this.state.latestStartOfCare).format("MM/DD/YYYY")}</div>
                        </div>
                        <div className={`col-2 ${styles.card}`}>
                            <div className="p-2 text-center">
                                <label for="formControlSelect1" className="mb-1 bold-color-style">
                                    Lead Status
                                </label>
                            </div>
                            <div className="medium-style text-center">{leadStatus}</div>
                        </div>
                        {/* <div className={`col-2 ${styles.card}`}>
                            <ClientCASScore clientId={clientId} />
                        </div> */}
                    </div>
                </div>

                <div className="row text-lg-center pb-1 gx-0">
                    <div className="col-12"></div>
                </div>

                <Tabs clientId={clientId} toggleStartOfCareModal={this.toggleStartOfCareModal} togglebackDrop={this.togglebackDrop} />

                {this.state.showFamilyDynamicsModal && <FamilyDynamics clientId={clientId} toggleFamilyDynamicsModal={this.toggleFamilyDynamicsModal} />}
                {this.state.showStartOfCareModal && <StartOfCareForClient clientId={clientId} toggleStartOfCareModal={this.toggleStartOfCareModal} />}
                {this.state.showFamilyMemberModal && <FamilyMembers clientId={clientId} toggleFamilyMemberModal={this.toggleFamilyMemberModal} />}
                {this.state.showCareStaffPreferenceModal && <ClientCareStaffPreferences clientId={clientId} toggleCareStaffPreferencesModal={this.toggleCareStaffPreferencesModal} />}
            </>
        );
    }
}

export default withRouter(ClientProfile);

import React, { Component } from 'react';
import values from 'lodash/values';

import TreeNode from './TreeNode';

const data = {

    // 1st root node
    '/Admin Home': {
        path: '/Admin Home',
        isRoot: true,
        component: 'Admin Landing Page',
    },

    // 2nd root node
    '/Account': {
        path: '/Account',
        type: 'folder',
        isRoot: true,
        children: ['/Account/Roles & Permissions', '/Account/Single Sign-On', '/Account/Email settings', '/Account/Email logs', '/Account/Audit'],
    },

    // children of 2nd root node
    '/Account/Roles & Permissions': {
        path: '/Account/Roles & Permissions',
        component: 'Roles & Permissions',
    },
    '/Account/Single Sign-On': {
        path: '/Account/Single Sign-On',
    },
    '/Account/Email settings': {
        path: '/Account/Email settings',
    },
    '/Account/Email logs': {
        path: '/Account/Email logs',
    },
    '/Account/Audit': {
        path: '/Account/Audit',
    },

    // 3rd root node
    '/Users & Groups': {
        path: '/Users & Groups',
        type: 'folder',
        isRoot: true,
        children: ['/Users & Groups/Users', '/Users & Groups/Groups']
    },

    //children of 3rd root node
    '/Users & Groups/Users': {
        path: '/Users & Groups/Users',
        // component: 'Users'
    },
    '/Users & Groups/Groups': {
        path: '/Users & Groups/Groups',
        // component: 'Groups'
    },

    // 4th root node
    '/Global settings': {
        path: '/Global settings',
        type: 'folder',
        isRoot: true,
        children: ['/Global settings/Service Portal', '/Global settings/Custom States'],
    },

    // children of 4th root node
    '/Global settings/Service Portal': {
        path: '/Global settings/Service Portal',
        component: 'Service Portal',
    },
    '/Global settings/Custom States': {
        path: '/Global settings/Custom States',
    },

    // 5th root node
    '/Activity logging': {
        path: '/Activity logging',
        isRoot: true,
    },
};

export default class Tree extends Component {

    state = {
        nodes: data,
        prevNode: []
    };

    getRootNodes = () => {
        const { nodes } = this.state;
        return values(nodes).filter(node => node.isRoot === true);
    }

    getChildNodes = (node) => {
        const { nodes } = this.state;
        if (!node.children) return [];
        return node.children.map(path => nodes[path]);
    }

    onToggle = (node) => {

        const { nodes } = this.state;

        nodes[node.path].isOpen = !node?.isOpen;
        nodes[node.path].backgroundColor = '#ccc';

        if (nodes[node.path]?.isRoot && nodes[node.path]?.type) {
            nodes[node.path].backgroundColor = '';
        }

        this.setState({ nodes });

        this.setState({
            prevNode: node
        });

        if (nodes[this.state.prevNode.path] !== undefined) {
            nodes[this.state.prevNode.path].backgroundColor = ''
        }

    }

    onNodeSelect = node => {
        const { onSelect } = this.props;
        onSelect(node);
    }

    render() {
        const rootNodes = this.getRootNodes();
        return (
            <div style={{ padding: "5px",marginLeft:'30px' }}>
                {rootNodes.map((node, key) => (
                    <TreeNode
                        key={key}
                        node={node}
                        getChildNodes={this.getChildNodes}
                        onToggle={this.onToggle}
                        onNodeSelect={this.onNodeSelect}
                    />
                ))}
            </div>
        )
    }
}
import React from "react";
import ClientProfileIdentity from "./ClientProfileIdentity.js";
import ClientAddressList from "./ClientAddressList.js";


const ClientIdentityAndAddresses = (props) => {
    return (
        <>
            <ClientProfileIdentity {...props} />
            <ClientAddressList {...props} />
        </>
    )
}

export default ClientIdentityAndAddresses;
import React from "react";
import { callAPI } from "../../../lib/Global.js";
import { FormBuilder, FieldGroup, FieldControl, Validators } from "react-reactive-form";
import TextInput from "../../../Controls/TextInput.js";
import SelectInput from "../../../Controls/SelectInput.js";
import { swalAlert, swalToast, swalToast2 } from "../../../lib/Swal.js";
import styles from "../../../styles/common.module.css";

class LifestyleBackground extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, isDataUpdated: false, isDisabled: false };
    }

    componentDidMount() {
        this.getClientLifestyleAndBackGroundDetails(this.props.LifeStyleData);
    }

    componentWillUnmount() {
        this.lifestyleBackgroundForm.get("othersLivingWithClient").valueChanges.unsubscribe();
        this.lifestyleBackgroundForm.get("livingSituation").valueChanges.unsubscribe();
    }

    getClientLifestyleAndBackGroundDetails = (clientLifestyleAndBackgroundDetails) => {
        this.populateLifestyleBackgroundForm(clientLifestyleAndBackgroundDetails);

        this.lifestyleBackgroundForm.get("othersLivingWithClient").valueChanges.subscribe((selectedValue) => {
            if (selectedValue === "Yes") {
                this.lifestyleBackgroundForm.get("othersLivingWithClientName").enable();
                this.lifestyleBackgroundForm.get("relationship").enable();
                this.lifestyleBackgroundForm.patchValue({ othersLivingWithClientName: null, relationship: null });
                this.setState({ isDisabled: false });
            } else {
                this.lifestyleBackgroundForm.get("othersLivingWithClientName").disable();
                this.lifestyleBackgroundForm.get("relationship").disable();
                this.lifestyleBackgroundForm.patchValue({ othersLivingWithClientName: null, relationship: "Myself" });
                this.setState({ isDisabled: true });
                this.lifestyleBackgroundForm.updateValueAndValidity(this.lifestyleBackgroundForm.value);
            }
        });

        this.lifestyleBackgroundForm.get("livingSituation").valueChanges.subscribe((selectedValue) => {
            if (selectedValue === "Alone") {
                this.lifestyleBackgroundForm.get("othersLivingWithClient").disable();
                this.lifestyleBackgroundForm.get("othersLivingWithClientName").disable();
                this.lifestyleBackgroundForm.get("relationship").disable();
                this.lifestyleBackgroundForm.patchValue({ othersLivingWithClient: "No", othersLivingWithClientName: null, relationship: "Myself" });
            } else {
                this.lifestyleBackgroundForm.get("othersLivingWithClient").enable();
                this.lifestyleBackgroundForm.get("othersLivingWithClientName").disable();
                this.lifestyleBackgroundForm.get("relationship").disable();
                this.lifestyleBackgroundForm.patchValue({ othersLivingWithClient: "No", othersLivingWithClientName: null, relationship: "Myself" });
            }
        });

        if (clientLifestyleAndBackgroundDetails) {
            const language = clientLifestyleAndBackgroundDetails.language?.split(",") || [];

            this.lifestyleBackgroundForm.patchValue({ language: language });
            this.lifestyleBackgroundForm.updateValueAndValidity(this.lifestyleBackgroundForm.value);
        }
    };

    populateLifestyleBackgroundForm = (clientLifestyleAndBackgroundDetails) => {
        if (clientLifestyleAndBackgroundDetails?.id) {
            this.lifestyleBackgroundForm.patchValue({
                ...clientLifestyleAndBackgroundDetails,
            });
            if (clientLifestyleAndBackgroundDetails.othersLivingWithClient === "Yes") {
                this.lifestyleBackgroundForm.get("othersLivingWithClientName").enable();
                this.lifestyleBackgroundForm.get("relationship").enable();
                this.setState({ isDisabled: false });
            } else {
                this.setState({ isDisabled: true });
                this.lifestyleBackgroundForm.get("othersLivingWithClientName").disable();
                this.lifestyleBackgroundForm.get("relationship").disable();
                this.lifestyleBackgroundForm.patchValue({ othersLivingWithClientName: null, relationship: "Myself" });
            }
            if (clientLifestyleAndBackgroundDetails.livingSituation === "Alone") {
                this.lifestyleBackgroundForm.get("othersLivingWithClient").disable();
                this.lifestyleBackgroundForm.get("othersLivingWithClientName").disable();
                this.lifestyleBackgroundForm.get("relationship").disable();
                this.lifestyleBackgroundForm.patchValue({ othersLivingWithClient: "No", othersLivingWithClientName: null, relationship: "Myself" });
            } else {
                this.lifestyleBackgroundForm.get("othersLivingWithClient").enable();
            }
        } else {
            this.lifestyleBackgroundForm.patchValue({ clientId: this.props.clientId });
        }
    };

    lifestyleBackgroundForm = FormBuilder.group({
        id: null,
        clientId: [null, Validators.required],
        livingSituation: [null, Validators.required],
        smoking: [null, Validators.required],
        othersLivingWithClient: [null, Validators.required],
        othersLivingWithClientName: [null, Validators.required],
        relationship: [null, Validators.required],
        language: [null, Validators.required],
        religion: null,
        pet: null,
        occupation: null,
        education: null,
        howOftenEnjoysOutdoors: null,
        enjoySocializing: [null, Validators.required],
        wouldYouLikeToSocializeMore: [null, Validators.required],
    });

    handleSave = async (e) => {
        this.setState({ isLoading: true });
        this.setState({ isDataUpdated: !this.state.isDataUpdated });

        let api = this.lifestyleBackgroundForm.value.id !== null ? "UpdateClientLifestyle" : "CreateClientLifestyle";
        let message = this.lifestyleBackgroundForm.value.id !== null ? "Updated" : "Created";

        this.lifestyleBackgroundForm.patchValue({ language: this.lifestyleBackgroundForm.get("language").value.join() });
        this.lifestyleBackgroundForm.updateValueAndValidity(this.lifestyleBackgroundForm.value);

        if (this.lifestyleBackgroundForm.value.livingSituation === "Alone") {
            this.lifestyleBackgroundForm.value.othersLivingWithClient = "No";
        }

        const r = await callAPI(api, this.lifestyleBackgroundForm.value);
        this.setState({ isLoading: false });

        if (r?.isSuccess) {
            swalToast2("LifeStyle and Background for Client " + message + " successfully.");
            this.getClientLifestyleAndBackGroundDetails(this.props.clientId);
            this.closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || "Something went wrong!!!"}</span>`;
            swalAlert("e", errorMsg);
        }
    };

    closeModal = (isDataReload) => {
        this.props.closeClientIdentityModal(isDataReload);
    };

    render() {
        return (
            <div className={`modal fade show`} style={{ display: "block" }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="exampleModalLabel">
                                Lifestyle & Background
                            </h5>
                            <i className="bi bi-x-lg" onClick={() => this.closeModal(false)} style={{ cursor: "pointer" }}></i>
                        </div>
                        <div className="modal-body">
                            {this.state.isLoading && !this.state.isDataUpdated && (
                                <>
                                    <div>Please Wait...</div>
                                </>
                            )}

                            {this.state.isDataUpdated && (
                                <>
                                    <div>Completed...</div>
                                </>
                            )}

                            {!this.state.isLoading && !this.state.isDataUpdated && (
                                <>
                                    <FieldGroup
                                        control={this.lifestyleBackgroundForm}
                                        render={({ get, invalid, pristine }) => (
                                            <form>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="livingSituation"
                                                            render={SelectInput}
                                                            meta={{
                                                                label: "Select living situation",
                                                                options: [{ text: "Alone" }, { text: "Living with spouse" }, { text: "Living with family members" }, { text: "Living with companion(s)" }],
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="smoking"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="pt-2">
                                                                    <span for="formCheck1" className={`mb-1 d-block ${styles.labelStyling}`}>
                                                                        {meta.label}
                                                                    </span>
                                                                    <div class="form-check form-check-inline">
                                                                        <input className="form-check-input" {...handler("radio", "Yes")} />
                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioYes">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" {...handler("radio", "No")} />
                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioNo">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            meta={{ label: "smoking?" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="othersLivingWithClient"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="pt-2">
                                                                    <span for="formCheck1" className={`mb-1 d-block ${styles.labelStyling}`}>
                                                                        {meta.label}
                                                                    </span>
                                                                    <div class="form-check form-check-inline">
                                                                        <input className="form-check-input" {...handler("radio", "Yes")} />
                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioYes">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" {...handler("radio", "No")} />
                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioNo">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            meta={{ label: "Others living with client" }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        {this.state.isDisabled ? <FieldControl name="othersLivingWithClientName" render={TextInput} meta={{ label: "If Yes, Name", maxLengthVal: 20, disabled: this.state.isDisabled }} />
                                                            : <div><FieldControl name="othersLivingWithClientName" render={TextInput} meta={{ label: "If Yes, Name", maxLengthVal: 20 }} /> </div>}
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="col-6">
                                                            <FieldControl
                                                                name="relationship"
                                                                render={SelectInput}
                                                                meta={{
                                                                    label: "Relationship",
                                                                    options: [{ text: "Myself" }, { text: "Parent" }, { text: "Grandparent" }, { text: "Sibling" }, { text: "Spouse" }, { text: "Friend" }, { text: "Child" }, { text: "Aunt or Uncle" }, { text: "Niece or Nephew" }, { text: "Cousin" }, { text: "Neighbor" }, { text: "Other" }],
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <FieldControl
                                                                name="religion"
                                                                render={SelectInput}
                                                                meta={{
                                                                    label: "Religion",
                                                                    options: [{ text: "Christianity" }, { text: "Judaism" }, { text: "Islam" }, { text: "Buddhism" }, { text: "Jainism" }, { text: "Sikhism" }, { text: "Taoism" }, { text: "Hinduism" }, { text: "Unitarian Universalism" }, { text: "Spiritualism" }, { text: "Scientology" }, { text: "Other" }],
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="language"
                                                            render={SelectInput}
                                                            meta={{
                                                                size: 4,
                                                                multiple: "multiple",
                                                                label: "Language",
                                                                options: [
                                                                    { text: "English" },
                                                                    { text: "Arabic" },
                                                                    { text: "Armenian" },
                                                                    { text: "Bengali" },
                                                                    { text: "Chinese" },
                                                                    { text: "French and French Creole" },
                                                                    { text: "German" },
                                                                    { text: "Greek" },
                                                                    { text: "Gujarati" },
                                                                    { text: "Hebrew" },
                                                                    { text: "Hindi" },
                                                                    { text: "Hmong" },
                                                                    { text: "Italian" },
                                                                    { text: "Japanese" },
                                                                    { text: "Korean" },
                                                                    { text: "Panjabi" },
                                                                    { text: "Persian" },
                                                                    { text: "Polish" },
                                                                    { text: "Portuguese" },
                                                                    { text: "Russian" },
                                                                    { text: "Spanish" },
                                                                    { text: "Tagalog" },
                                                                    { text: "Telugu" },
                                                                    { text: "Urdu" },
                                                                    { text: "Vietnamese" },
                                                                ],
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl name="occupation" render={TextInput} meta={{ label: "Type Of occupation" }} />
                                                    </div>
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="pet"
                                                            render={SelectInput}
                                                            meta={{
                                                                label: "Pet",
                                                                options: [{ text: "Dog" }, { text: "Cat" }, { text: "Hamster" }, { text: "Fish" }, { text: "Mice" }, { text: "Guinea Pigs" }, { text: "Birds" }, { text: "Snakes" }, { text: "Iguanas" }, { text: "Horse" }, { text: "Other" }],
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="howOftenEnjoysOutdoors"
                                                            render={SelectInput}
                                                            meta={{
                                                                label: "How often enjoys outdoors?",
                                                                options: [{ text: "Once a week" }, { text: "Several times a week" }, { text: "Several times a month" }],
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="education"
                                                            render={SelectInput}
                                                            meta={{
                                                                label: "Level Of Education",
                                                                options: [{ text: "Less than High School" }, { text: "High School Diploma" }, { text: "Some College-No degree" }, { text: "Post secondary non-degree award" }, { text: "Associate's degree" }, { text: "Bachelor's degree" }, { text: "Master's degree" }, { text: "Doctoral or Professional degree" }],
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="enjoySocializing"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <>
                                                                    <span for="formCheck1" className={`mb-1 d-block ${styles.labelStyling}`}>
                                                                        {meta.label}
                                                                    </span>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" {...handler("radio", "Yes")} />
                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioYes">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" {...handler("radio", "No")} />
                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioNo">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )}
                                                            meta={{ label: "Enjoy socializing?" }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="wouldYouLikeToSocializeMore"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <>
                                                                    <span for="formCheck1" className={`mb-1 d-block ${styles.labelStyling}`}>
                                                                        {meta.label}
                                                                    </span>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" {...handler("radio", "Yes")} />
                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioYes">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" {...handler("radio", "No")} />
                                                                        <label className={`form-check-label ${styles.labelStyling}`} for="RadioNo">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )}
                                                            meta={{ label: "Would you like to socialize more (Recipient of care)?" }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row text-lg-end pb-1 pt-3 mx-0 px-0" style={{ borderTop: "1px solid lightgray" }}>
                                                    <div className="col-md-12">
                                                        <button type="button" onClick={() => this.closeModal(false)} className="btn btn-outline-secondary">
                                                            Close
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button type="button" onClick={() => this.handleSave()} className="btn btn-primary" disabled={invalid || pristine || this.state.isLoading}>
                                                            {this.state.isLoading ? "Please wait..." : "Save"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LifestyleBackground;

import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/common.module.css';
import { withRouter, useLocation } from 'react-router-dom';
import '../../styles/common.css';
import AutoComplete from '../../Modules/HCC/Components/AutoComplete';

function Navbar(props) {
    const location = useLocation();
    const clientModulePaths = ["clients", "clientprofile"]
    const toggleModal = () => {
        if (clientModulePaths.includes(location.pathname.split("/")[1])) {
            props.toggleModal();
        }
        if (location.pathname.split("/")[1] === 'admin') {
            props.toggleAdminSidePanel();
        }
    }

    const handleSearch = () => {
        if (location.pathname === '/clients') {
            props.handleSearch();
        }
    }

    const toggleCardManager = () => {
        if (location.pathname === '/home') {
            props.toggleCardManager();
        }
    }

    return (
        <div className="container-fluid">
            <nav className={`navbar fixed-top ${styles.navBarStyling} p-0`}>
                <Link to="/" className="navbar-brand ms-4">
                    <img src={window.location.origin + "/img/company-logo.svg"} alt="Infinty Logo" width={148} height={30} />
                </Link>
                <div className={`form-group ${styles.containerSearch}`}>
                    <i className="bi bi-search" onClick={() => handleSearch()} style={{ position: 'absolute', top: '8px', right: '16px', color: '#3CAF8A', fontSize: '1.2rem', cursor: "pointer" }}></i>
                    <AutoComplete {...props} />
                </div>
                <div className="d-flex align-items-center me-3">
                    <div className="mx-2">
                        <span>Welcome, {sessionStorage.getItem("loggedInUserName")} <i class="bi bi-person-circle"></i></span>

                        {/*<img src="" className="img-profile mx-1" style={{ width: '30px', height: '30px' }} />*/}
                    </div>
                    {clientModulePaths.includes(location.pathname.split("/")[1]) && <i className="bi bi-plus-circle-fill" onClick={() => toggleModal()} style={{ fontSize: '2rem', cursor: "pointer", color: '#3CAF8A', fontWeight: '400', fontStyle: 'normal', paddingRight: '8px' }}></i>}
                    {/* {location.pathname.includes('/clientprofile/') && <i className="bi bi-plus-circle-fill" onClick={() => toggleModal()} style={{ fontSize: '2rem', cursor: "pointer", color: '#3CAF8A', fontWeight: '400', fontStyle: 'normal', paddingRight: '8px' }}></i>} */}
                    {location.pathname === '/home' && <i className="bi bi-gear-fill" onClick={() => toggleCardManager()} style={{ fontSize: '1.2rem', cursor: "pointer", color: '#3CAF8A', fontWeight: '400', fontStyle: 'normal', padding: '8px' }}></i>}
                </div>
            </nav>
        </div>
    )
}

export default withRouter(Navbar);
//All Constants related to Apis for Admin Module Goes here


const adminBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL;
const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;

const k = process.env.REACT_APP_ADMIN_KEY_CODE;
const k1 = process.env.REACT_APP_AUTH_KEY_CODE;



export const AdminApis = {
    // CreateRoles: adminBaseUrl + 'Role' + k, // may be used only to create/update Roles
    Roles: adminBaseUrl + 'Role' + k, // can be used to create/update, delete and get all roles.
    RolePermissions: adminBaseUrl + 'RolePermissions' + k,
    User: authBaseUrl + 'User' + k1,
    GetAllUsers: authBaseUrl + 'GetAllUsers' + k1,
}

export const moduleSubjectScope = [

    { module: 'All', subject: 'All', scope: 'All' },
    { module: 'All', subject: 'All', scope: 'Type' },
    { module: 'All', subject: 'All', scope: 'Relationship' },
    { module: 'All', subject: 'All', scope: 'Phone' },
    { module: 'All', subject: 'All', scope: 'Name' },

    { module: 'client', subject: 'Basic Intake', scope: 'Type' },
    { module: 'client', subject: 'Basic Intake', scope: 'Relationship' },
    { module: 'client', subject: 'Basic Intake', scope: 'Phone' },
    { module: 'client', subject: 'Basic Intake', scope: 'Name' },

    { module: 'client', subject: 'Profile', scope: 'Type' },
    { module: 'client', subject: 'Profile', scope: 'Relationship' },
    { module: 'client', subject: 'Profile', scope: 'Phone' },
    { module: 'client', subject: 'Profile', scope: 'Name' },

    { module: 'client', subject: 'Contacts', scope: 'Type' },
    { module: 'client', subject: 'Contacts', scope: 'Relationship' },
    { module: 'client', subject: 'Contacts', scope: 'Phone' },
    { module: 'client', subject: 'Contacts', scope: 'Name' },

    { module: 'client', subject: 'Family Dynamics', scope: 'Type' },
    { module: 'client', subject: 'Family Dynamics', scope: 'Relationship' },
    { module: 'client', subject: 'Family Dynamics', scope: 'Phone' },
    { module: 'client', subject: 'Family Dynamics', scope: 'Name' },

    { module: 'client', subject: 'Start of Care', scope: 'Type' },
    { module: 'client', subject: 'Start of Care', scope: 'Relationship' },
    { module: 'client', subject: 'Start of Care', scope: 'Phone' },
    { module: 'client', subject: 'Start of Care', scope: 'Name' },

    { module: 'client', subject: 'Family Members', scope: 'Type' },
    { module: 'client', subject: 'Family Members', scope: 'Relationship' },
    { module: 'client', subject: 'Family Members', scope: 'Phone' },
    { module: 'client', subject: 'Family Members', scope: 'Name' },

    { module: 'client', subject: 'LifeStyle & Background', scope: 'Type' },
    { module: 'client', subject: 'LifeStyle & Background', scope: 'Relationship' },
    { module: 'client', subject: 'LifeStyle & Background', scope: 'Phone' },
    { module: 'client', subject: 'LifeStyle & Background', scope: 'Name' },

    { module: 'client', subject: 'Care Staff', scope: 'Type' },
    { module: 'client', subject: 'Care Staff', scope: 'Relationship' },
    { module: 'client', subject: 'Care Staff', scope: 'Phone' },
    { module: 'client', subject: 'Care Staff', scope: 'Name' },

    { module: 'client', subject: 'Payment', scope: 'Type' },
    { module: 'client', subject: 'Payment', scope: 'Relationship' },
    { module: 'client', subject: 'Payment', scope: 'Phone' },
    { module: 'client', subject: 'Payment', scope: 'Name' },

    { module: 'client', subject: 'Health Assessments', scope: 'Type' },
    { module: 'client', subject: 'Health Assessments', scope: 'Relationship' },
    { module: 'client', subject: 'Health Assessments', scope: 'Phone' },
    { module: 'client', subject: 'Health Assessments', scope: 'Name' },

    { module: 'client', subject: 'Services', scope: 'Type' },
    { module: 'client', subject: 'Services', scope: 'Relationship' },
    { module: 'client', subject: 'Services', scope: 'Phone' },
    { module: 'client', subject: 'Services', scope: 'Name' },


    { module: 'Employees', subject: 'Employees', scope: 'employee_scope1' },
    { module: 'Employees', subject: 'Employees', scope: 'employee_scope2' },

    { module: 'Reports', subject: 'Reports', scope: 'reports_scope1' },
    { module: 'Reports', subject: 'Reports', scope: 'reports_scope2' },

    { module: 'Payroll', subject: 'Payroll', scope: 'payroll_scope1' },
    { module: 'Payroll', subject: 'Payroll', scope: 'payroll_scope2' },
]
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { callAPI, callAPIWithpathParameter } from '../../../lib/Global.js';
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import TextInput from '../../../Controls/TextInput.js';
import TextArea from '../../../Controls/TextArea.js';
import SelectInput from '../../../Controls/SelectInput.js';
import { swalAlert, swalToast2 } from '../../../lib/Swal.js';
import CheckBox from '../../../Controls/CheckBox.js';
import { getAllProducts } from './admin/components/productList/productListRest.js';


const ClientJournalModal = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    const clientJournalForm = FormBuilder.group({
        id: null,
        clientId: null,
        medium: [null, Validators.required],
        category: [null, Validators.required],
        subCategory: null,
        journal: [null, Validators.required]
    });

    useEffect(() => {
        getClientJournalData(props.clientJournalId);
    }, []);

    // populate the clicked client journal form with values
    const getClientJournalData = async (id) => {

        if (id) {
            const r = await callAPIWithpathParameter("Journal", id, 'GET', '');
            clientJournalForm.patchValue({ ...r });
        } else {
            clientJournalForm.patchValue({ clientId: props.clientId })
        }
    }

    const closeModal = (val) => {
        props.toggleJournalForClientModal(val);
    }

    const handleSave = async () => {

        clientJournalForm.updateValueAndValidity(clientJournalForm.value);
        setIsLoading(true);

        const apiHttpVerb = clientJournalForm.value.id ? 'PUT' : 'POST';
        let message = clientJournalForm.value.id ? 'Updated' : 'Created';
        const r = await callAPI("Journal", clientJournalForm.value, apiHttpVerb);

        setIsLoading(false);
        clientJournalForm.updateValueAndValidity(clientJournalForm.value);

        if (r?.isSuccess) {
            swalToast2('Journal for Client ' + message + ' successfully.');
            closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    return (
        <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-success" id="exampleModalLabel">{props.clientJournalId ? "Modify Journal" : "New Journal"}</h5>
                        <i className="bi bi-x-lg" onClick={() => closeModal(false)} style={{ cursor: 'pointer' }}></i>
                    </div>
                    <div className="modal-body">
                        <FieldGroup
                            control={clientJournalForm}
                            render={({ get, invalid, pristine }) => (
                                <form>
                                    <div className="row">
                                        <div className="col-4 my-4">
                                            <label className="d-block">Medium</label>
                                        </div>
                                        <div className="col-8">
                                            <FieldControl
                                                name="medium"
                                                render={SelectInput}
                                                meta={{
                                                    options: [
                                                        { text: 'Phone' },
                                                        { text: 'Email' },
                                                        { text: 'Fax' },
                                                        { text: 'face-to-face' },
                                                        { text: 'Mail' },
                                                    ]
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 my-4">
                                            <label className="d-block">Category</label>
                                        </div>
                                        <div className="col-8">
                                            <FieldControl
                                                name="category"
                                                render={SelectInput}
                                                meta={{
                                                    options: [
                                                        { text: 'Scheduling' },
                                                        { text: 'Insurance' },
                                                        { text: 'Finance' },
                                                        { text: 'Clinical' },
                                                        { text: 'Marketing' },
                                                        { text: 'Other' },
                                                        { text: 'Complaint' },
                                                        { text: 'Incident' },
                                                        { text: 'Billing' },
                                                        { text: 'Audit' },
                                                        { text: 'Care-points' },
                                                        { text: 'Customer-service' },
                                                        { text: 'Aide-Liaison-Visit' },
                                                        { text: 'Social Work' },
                                                        { text: 'Inquiry' },
                                                        { text: 'Paycheck Issue' },
                                                        { text: 'Orientation/Supervision' },
                                                        { text: 'Evolution Program' },
                                                        { text: 'Transportation' },
                                                        { text: 'Transitional Care' },
                                                        { text: 'Discharge' },
                                                        { text: 'Client Complaint' },
                                                        { text: 'Facility Complaint' },
                                                        { text: 'Contracted Payer' },
                                                        { text: 'Client Hospitalization' },
                                                        { text: 'Client Fall' },
                                                        { text: 'Tablet Training' },
                                                        { text: 'Tablet' },
                                                        { text: 'Tech Support' },
                                                        { text: 'Care Management' },
                                                        { text: 'HR	' },
                                                        { text: 'COD' },
                                                        { text: 'COD-Tech Issue' },
                                                        { text: 'Recruitment' },
                                                        { text: 'Caregiver Engagement' },
                                                        { text: 'Caregiver Complaint' },
                                                        { text: 'CPU Training' },
                                                        { text: 'Client Compliment' },
                                                        { text: 'Caregiver Ladder' },
                                                        { text: 'Survey' },
                                                        { text: 'Change Order' },
                                                        { text: 'Counseling' },
                                                        { text: 'Polaris' },
                                                        { text: 'Non-Clinical Admission' },
                                                        { text: 'ALF Liaison Visit' },

                                                    ]
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 my-4">
                                            <label className="d-block">Sub Category</label>
                                        </div>
                                        <div className="col-8">
                                            <FieldControl
                                                name="subCategory"
                                                render={SelectInput}
                                                meta={{
                                                    options: [
                                                        { text: '30 Day' },
                                                        { text: '48 Hours' },
                                                        { text: '6 Month' },
                                                        { text: '60 Day' },
                                                        { text: '90 Day' },
                                                        { text: 'Admin Follow Up Call' },
                                                        { text: 'Admin Home Visits' },
                                                        { text: 'Admission Visit' },
                                                        { text: 'Aide Switch Out' },
                                                        { text: 'Auto Billing' },
                                                        { text: 'Call Out Last Minute/Weekend' },
                                                        { text: 'Caregiver lack of professionalism' },
                                                        { text: 'Caregiver lack of skill' },
                                                        { text: 'Caregiver NCNS' },
                                                        { text: 'Caregiver Observations' },
                                                        { text: 'Caregiver tardy' },
                                                        { text: 'Change in Condition' },
                                                        { text: 'Complaint' },
                                                        { text: 'Compliance' },
                                                        { text: 'Connectivity' },
                                                        { text: 'Connectivity' },
                                                        { text: 'Damage to Client Property' },
                                                        { text: 'Difficult/Complex Case' },
                                                        { text: 'Disciplinary' },
                                                        { text: 'Discretionary' },
                                                        { text: 'During service hours' },
                                                        { text: 'ER or Hospital transfer' },
                                                        { text: 'eSignature Requested' },
                                                        { text: 'Failure of Office to Communicate' },
                                                        { text: 'Fall/Accident Occurence' },
                                                        { text: 'Financial Complaint' },
                                                        { text: 'First Week' },
                                                        { text: 'Follow Up Call to Primary Contact' },
                                                        { text: 'Hardware Issue' },
                                                        { text: 'Hardware Issue' },
                                                        { text: 'Helping in Last Minute' },
                                                        { text: 'Hospitalization Follow Up' },
                                                        { text: 'Inconsistent Staff' },
                                                        { text: 'Infections' },
                                                        { text: 'Injury Caused to Client' },
                                                        { text: 'Inquiry Follow Up' },
                                                        { text: 'Inventory' },
                                                        { text: 'Lack of Professionalism' },
                                                        { text: 'Manual Billing' },
                                                        { text: 'Med Pour' },
                                                        { text: 'Missing Paperwork' },
                                                        { text: 'New Care Navigator' },
                                                        { text: 'No Call/No Show' },
                                                        { text: 'Not Compliant' },
                                                        { text: 'Not Conforming to Company Policy' },
                                                        { text: 'Not during service hours' },
                                                        { text: 'Notification' },
                                                        { text: 'Patient Compliment' },
                                                        { text: 'Post Discharge Follow Up' },
                                                        { text: 'Post Fall Visit' },
                                                        { text: 'Post Hospital Visit' },
                                                        { text: 'Promptly Report Patient Change' },
                                                        { text: 'Refer a Friend/Patient' },
                                                        { text: 'Reminder' },
                                                        { text: 'Repeat fall (within 30 days)' },
                                                        { text: 'Request time off/vacation' },
                                                        { text: 'Patient Compliment' },
                                                        { text: 'Scheduling' },
                                                        { text: 'Shipment' },
                                                        { text: 'ShortPay' },
                                                        { text: 'Software Issue' },
                                                        { text: 'Supervisory Visit' },
                                                        { text: 'Surcharge' },
                                                        { text: 'Tablet' },
                                                        { text: 'Tablet inquiry' },
                                                        { text: 'Tardiness' },
                                                        { text: 'Training' },
                                                        { text: 'Verification' },
                                                        { text: 'Verified Complaint' },
                                                        { text: 'Within 3 Days of Discharge' },
                                                        { text: 'Other' },

                                                    ]
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-12">
                                            <FieldControl
                                                name="journal"
                                                render={TextArea}
                                                meta={{ label: 'Journal', maxLengthVal: 250, minLengthVal: 50 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row text-lg-end pb-1 pt-3 mx-0 px-0" style={{ borderTop: "1px solid lightgray" }}>
                                        <div className="col-md-12">
                                            <button type="button" onClick={() => closeModal(false)} className="btn btn-outline-secondary">Cancel</button> &nbsp;&nbsp;
                                            <button type="button" onClick={() => handleSave()} className="btn btn-primary" disabled={isLoading || pristine || invalid} >{isLoading ? 'Please wait...' : 'Save'}</button>

                                        </div>
                                    </div>

                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientJournalModal;
export const authS = process.env.REACT_APP_AUTH_BASE_URL;
// const authS = process.env.REACT_APP_LOCAL_BASE_URL;
export const authK = process.env.REACT_APP_AUTH_KEY_CODE;

export const AuthApis = {
    EmailLogin: authS + 'user/login/email' + authK,
    ValidateLoginOtp: authS + 'user/login/validateloginotp' + authK,
    SSOAssert: authS + 'sso/assert' + authK,
    ResetPasswordLink: authS + 'user/ResetPasswordLink' + authK,
    ResetPassword: authS + 'user/ResetPassword' + authK,
    VerifyReCaptcha: authS + 'VerifyReCaptcha' + authK,
    OverWriteSSO: authS + 'User/OverWriteSSO' + authK,
}
import React, { Component } from "react";
import styled from "styled-components";

export const Switch = styled.div`
    font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
    position: relative;
    height: 26px;
    width: 180px;
    color: #000;
    background-color: #e4e4e4;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
`;

export const SwitchRadio = styled.input`
    display: none;
`;

export const SwitchSelection = styled.span`
    display: block;
    position: absolute;
    z-index: 1;
    top: 0px;
    color: #fff;
    left: 0px;
    width: 60px;
    height: 26px;

    background: #3caf8a;
    border-radius: 3px;
    transition: left 0.25s ease-out;
`;

export const SwitchLabel = styled.label`
    position: relative;
    z-index: 2;
    float: left;
    width: 60px;
    line-height: 26px;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    cursor: pointer;

    ${SwitchRadio}:checked + & {
        transition: 0.15s ease-out;
        acolor: #fff;
    }
`;

const titleCase = (str) =>
    str
        .split(/\s+/)
        .map((w) => w[0].toUpperCase() + w.slice(1))
        .join(" ");

const ClickableLabel = ({ title, onChange, id }) => (
    <SwitchLabel onClick={() => onChange(title)} className={id}>
        {titleCase(title)}
    </SwitchLabel>
);

const ConcealedRadio = ({ value, selected }) => <SwitchRadio type="radio" name="switch" checked={selected === value} />;

export class ToggleSwitch extends Component {
    state = { selected: this.props.selected };

    handleChange = (val) => {
        this.setState({ selected: val });

        if (this.props.onChange && this.props.name) {
            this.props.onChange({ name: this.props.name, value: val });
        }
    };

    selectionStyle = () => {
        return {
            left: `${(this.props.values.indexOf(this.state.selected) / 3) * 100}%`,
        };
    };

    render() {
        const { selected } = this.state;
        return (
            <Switch>
                {this.props.values.map((val) => {
                    return (
                        <span>
                            <ConcealedRadio value={val} selected={selected} />
                            <ClickableLabel title={val} onChange={this.handleChange} />
                        </span>
                    );
                })}
                <SwitchSelection style={this.selectionStyle()} />
            </Switch>
        );
    }
}

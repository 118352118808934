//use:
//  sortJson(jsonData , "name", "string", true);
//  sortJson(jsonData , "id", "int", true);

export const sortJson = (jsonData, prop, propType, asc) => {
    switch (propType) {
        case "int":
            jsonData = jsonData.sort(function (a, b) {
                if (asc) {
                    return parseInt(a[prop]) > parseInt(b[prop]) ? 1 : parseInt(a[prop]) < parseInt(b[prop]) ? -1 : 0;
                } else {
                    return parseInt(b[prop]) > parseInt(a[prop]) ? 1 : parseInt(b[prop]) < parseInt(a[prop]) ? -1 : 0;
                }
            });
            break;

        default:
            jsonData = jsonData.sort(function (a, b) {
                const va = a[prop] ? a[prop] : "";
                const vb = b[prop] ? b[prop] : "";
                if (asc) {
                    return va.toLowerCase() > vb.toLowerCase() ? 1 : va.toLowerCase() < vb.toLowerCase() ? -1 : 0;
                } else {
                    return vb.toLowerCase() > va.toLowerCase() ? 1 : vb.toLowerCase() < va.toLowerCase() ? -1 : 0;
                }
            });
    }

    return jsonData;
};

import React, { useState, useEffect } from "react";
import { callAPI, callAPIForCityStateByZipCode } from "../../../lib/Global.js";
import { FormBuilder, FieldGroup, FieldControl, Validators } from "react-reactive-form";
import TextInput from "../../../Controls/TextInput.js";
import TextArea from "../../../Controls/TextArea.js";
import SelectInput from "../../../Controls/SelectInput.js";
import CheckBox from "../../../Controls/CheckBox.js";
import { swalAlert, confirmationPopup, swalToast2 } from "../../../lib/Swal.js";
import styles from "../../../styles/common.module.css";

const PrimaryContactForClient = (props) => {
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        getPrimaryContactsById(props.contactId);
        primaryContactForClientForm.get("zipCode").valueChanges.subscribe((selectedValue) => {
            if (selectedValue?.length === 5) {
                getCityAndStateZipCode(selectedValue);
            } else if (selectedValue?.length === 4) {
                primaryContactForClientForm.patchValue({ city: null, state: null });
            }
        });
        return () => {
            primaryContactForClientForm.get("zipCode").valueChanges.unsubscribe();
        };
    }, []);

    const getPrimaryContactsById = async (contactId) => {
        if (contactId) {
            const r = await callAPI("GetPrimaryContactsById", { id: contactId });
            primaryContactForClientForm.patchValue({ ...r });
        } else {
            primaryContactForClientForm.patchValue({ clientId: props.clientId, isPrimaryBillingContact: false, isEmergencyContact: false });
        }
    };
    const primaryContactForClientForm = FormBuilder.group({
        id: null,
        clientId: [null, Validators.required],
        primaryContactFirstName: [null, [Validators.required, Validators.maxLength(50)]],
        primaryContactLastName: [null, [Validators.required, Validators.maxLength(50)]],
        isPrimaryBillingContact: [false, Validators.required],
        isEmergencyContact: [false, Validators.required],
        relationshipToTheRecipientOfCare: [null, [Validators.required, Validators.maxLength(100)]],
        primaryContactHomeNumber: [null, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+0-9]*$")]],
        primaryContactMobileNumber: [null, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+0-9]*$")]],
        primaryContactEmail: [null, [Validators.email, Validators.maxLength(150)]],
        preferredContactMethod: [null, Validators.maxLength(10)],
        preferredTime: [null, Validators.maxLength(50)],
        addressLine1: [null, [Validators.required, Validators.maxLength(255)]],
        addressLine2: [null, [Validators.maxLength(255)]],
        city: [null, [Validators.required, Validators.maxLength(100)]],
        state: [null, [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
        zipCode: [null, [Validators.required, Validators.maxLength(5), Validators.minLength(5), Validators.pattern("^[0-9]*$")]],
        contactNotes: [null, [Validators.maxLength(255)]],
    });

    // this is for making primary billing contact checkbox checked or un-checked
    const toggleConfirmationPopup = () => {
        if (props.primaryContact && !primaryContactForClientForm.value.isPrimaryBillingContact) {
            confirmationPopup('Do you want to make this your "Primary billing contact?"', primaryContactForClientForm);
        }
    };

    const handleSave = async () => {
        primaryContactForClientForm.updateValueAndValidity(primaryContactForClientForm.value);
        setisLoading(true);

        const apiText = primaryContactForClientForm.value.id ? "UpdatePrimaryContactForClient" : "CreatePrimaryContactForClient";
        let message = primaryContactForClientForm.value.id !== null ? "Updated" : "Saved";
        const r = await callAPI(apiText, primaryContactForClientForm.value);
        setisLoading(false);
        primaryContactForClientForm.updateValueAndValidity(primaryContactForClientForm.value);

        if (r.isSuccess) {
            swalToast2("Primary Contact for Client " + message + " successfully.");
            closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || "Something went wrong!!!"}</span>`;
            swalAlert("e", errorMsg);
        }
    };

    const closeModal = (isDataToReload) => {
        props.togglePrimaryContactForClientModal(isDataToReload, "");
    };

    const getCityAndStateZipCode = async (zipCode) => {
        const p = await callAPIForCityStateByZipCode(zipCode);
        // Destructuring the City State object to get required city and state
        const { CityStateLookupResponse: { ZipCode: { City: { "#text": city } = {}, State: { "#text": state } = {} } } } = p;
        primaryContactForClientForm.patchValue({ city: city, state: state });
        if (!city || !state) {
            swalAlert('e', 'Invalid Zip Code');
        }
    }

    return (
        <div className={`modal fade show`} style={{ display: "block" }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-success" id="exampleModalLabel">
                            {props.contactId ? "Contact" : "New Contact"}
                        </h5>
                        <i className="bi bi-x-lg" onClick={() => closeModal(false)} style={{ cursor: "pointer" }}></i>
                    </div>
                    <div className="modal-body">
                        <FieldGroup
                            control={primaryContactForClientForm}
                            render={({ get, invalid, pristine }) => (
                                <form>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <FieldControl name="primaryContactFirstName" render={TextInput} meta={{ label: "First name", maxLengthVal: 50 }} />
                                        </div>
                                        <div className="col-6">
                                            <FieldControl name="primaryContactLastName" render={TextInput} meta={{ label: "Last name", maxLengthVal: 50 }} />
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <label for="formControlInput1" className={`mb-1 ${styles.labelStyling}`}>
                                            Contact Type
                                        </label>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <FieldControl name="isPrimaryBillingContact" render={CheckBox} meta={{ label: "Primary billing contact", togglePrimaryContactCheckbox: toggleConfirmationPopup }} />
                                        </div>
                                        <div className="col-6">
                                            <FieldControl name="isEmergencyContact" render={CheckBox} meta={{ label: "Emergency contact" }} />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <FieldControl
                                                name="relationshipToTheRecipientOfCare"
                                                render={SelectInput}
                                                meta={{
                                                    label: "Relationship with client",
                                                    options: [
                                                        // { text: 'Myself' },
                                                        { text: "Parent" },
                                                        { text: "Grandparent" },
                                                        { text: "Sibling" },
                                                        { text: "Spouse" },
                                                        { text: "Friend" },
                                                        { text: "Child" },
                                                        { text: "Aunt or Uncle" },
                                                        { text: "Niece or Nephew" },
                                                        { text: "Cousin" },
                                                        { text: "Neighbor" },
                                                        { text: "Other" },
                                                    ],
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <FieldControl name="primaryContactHomeNumber" render={TextInput} meta={{ label: "Home phone", maxLengthVal: 10, minLengthVal: 10, patternErrMsg: "Only numbers are allowed" }} />
                                                </div>
                                                <div className="col-6">
                                                    <FieldControl name="primaryContactMobileNumber" render={TextInput} meta={{ label: "Mobile phone", maxLengthVal: 10, minLengthVal: 10, patternErrMsg: "Only numbers are allowed" }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <FieldControl name="primaryContactEmail" render={TextInput} meta={{ label: "Email", emailErrMsg: "Invalid Email", maxLengthVal: 150 }} />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <FieldControl
                                                name="preferredContactMethod"
                                                render={SelectInput}
                                                meta={{
                                                    label: "Preferred method",
                                                    options: [{ text: "Home" }, { text: "Phone" }, { text: "Mobile" }, { text: "Email" }],
                                                }}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <FieldControl
                                                name="preferredTime"
                                                render={SelectInput}
                                                meta={{
                                                    label: "Preferred time",
                                                    options: [{ text: "Anytime" }, { text: "Morning" }, { text: "Afternoon" }, { text: "Evening" }, { text: "Night" }],
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <FieldControl name="addressLine1" render={TextInput} meta={{ label: "Address Line 1", maxLengthVal: 255 }} />
                                        </div>
                                        <div className="col-6">
                                            <FieldControl name="addressLine2" render={TextInput} meta={{ label: "Address Line 2", maxLengthVal: 255 }} />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-2">
                                            <FieldControl name="zipCode" render={TextInput} meta={{ label: "ZIP", maxLengthVal: 5, minLengthVal: 5, patternErrMsg: "Only numbers are allowed" }} />
                                        </div>
                                        <div className="col-4">
                                            <FieldControl name="city" render={TextInput} meta={{ label: "Town", maxLengthVal: 100, disabled: true }} />
                                        </div>

                                        <div className="col-4">
                                            <FieldControl name="state" render={TextInput} meta={{ label: "State", maxLengthVal: 2, minLengthVal: 2, disabled: true }} />
                                        </div>

                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <FieldControl name="contactNotes" render={TextArea} meta={{ label: "Contact notes", maxLengthVal: 255 }} />
                                        </div>
                                    </div>

                                    <div className="row text-lg-end pb-1 pt-3 mt-3" style={{ borderTop: "1px solid lightgray" }}>
                                        <div className="col-md-12">
                                            <button type="button" onClick={() => closeModal(false)} className="btn btn-outline-secondary">
                                                Close
                                            </button>
                                            &nbsp;&nbsp;
                                            <button type="button" onClick={() => handleSave()} className="btn btn-primary" disabled={invalid || pristine || isLoading}>
                                                {isLoading ? "Please wait..." : "Save"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrimaryContactForClient;

import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import { callAPI } from '../../../lib/Global.js';
import { swalAlert, swalToast2 } from '../../../lib/Swal.js';
import styles from "../../../styles/common.module.css";

const TextInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group d-flex">
        <label for="formControlInput1" className={`mb-1 ${styles.labelStyling} col-2`}>{meta.label}</label>
        <div className="col-4">
            <input type="text" maxLength={meta.maxLengthVal} className={`form-control ${styles.modalInputStyling}`} placeholder={`${meta.label}`} {...handler()} disabled={meta.disabled} />
            <span className="d-block text-danger">
                {touched
                    && hasError("required")
                    && `${meta.label} is required `}
                {hasError("email")
                    && `${meta.emailErrMsg} `}
                {hasError("pattern") && `${meta.patternErrMsg}`}
                {hasError("maxLength") && `Max Length of ${meta.maxLengthVal} Exceeded `}
                {hasError("minLength") && `Min Length of ${meta.minLengthVal} Required `}
                {hasError(meta.customError) && `${meta.customErrorMsg}`}
            </span>
        </div>
    </div>
);

const clientPhysicianForm = new FormBuilder.group({
    id: null,
    clientId: [null, Validators.required],
    physicianName: null,
    phoneNumber: [null, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+0-9]*$")]],
    faxNumber: [null, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+0-9]*$")]],
    email: [null, [Validators.email, Validators.maxLength(150)]],
});


const ClientPhysicianDetails = ({ clientId }) => {
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        getClientPhysicianDetails(clientId);
        return () => {
            clientPhysicianForm.reset();
        }
    }, [clientId]);


    const getClientPhysicianDetails = async (clientId) => {
        setisLoading(true)
        const r = await callAPI("GetClientPhysicianDetailsByClientId", { id: clientId });

        if (r) {
            clientPhysicianForm.patchValue({ ...r });
            clientPhysicianForm.updateValueAndValidity(clientPhysicianForm.value);
        } else {
            clientPhysicianForm.patchValue({ clientId: clientId });
        }
        setisLoading(false);
    }

    const handleSave = async () => {
        clientPhysicianForm.updateValueAndValidity(clientPhysicianForm.value);
        setisLoading(true);

        const apiText = clientPhysicianForm.value.id ? 'UpdateClientPhysicianDetails' : 'AddClientPhysicianDetails';
        let message = clientPhysicianForm.value.id !== null ? 'Updated' : 'Saved';
        const r = await callAPI(apiText, clientPhysicianForm.value);
        setisLoading(false);
        clientPhysicianForm.updateValueAndValidity(clientPhysicianForm.value);

        if (r.isSuccess) {
            swalToast2('Client Physician Details ' + message + ' successfully.');
            getClientPhysicianDetails(clientId);
            clientPhysicianForm.markAsPristine();
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    return (
        <>
            <div className="row mb-2">
                <div className="col-12">
                    <h5 className="bold-style" style={{ fontSize: '14px' }}>Physician Information</h5>
                </div>
            </div>
            <hr />
            {isLoading ? <div>Please Wait...</div> :
                <div>
                    <FieldGroup
                        control={clientPhysicianForm}
                        render={({ get, invalid, pristine, dirty }) => (
                            <form>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl name="physicianName" render={TextInput} meta={{
                                            label: "Physician Name"
                                        }} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl name="phoneNumber" render={TextInput} meta={{
                                            label: "Phone",
                                            patternErrMsg: "Please, Enter 10 digit Mobile Number", maxLengthVal: 10, minLengthVal: 10
                                        }} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl name="faxNumber" render={TextInput} meta={{
                                            label: "Fax",
                                            patternErrMsg: "Please, Enter 10 Fax Number", maxLengthVal: 10, minLengthVal: 10
                                        }} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl name="email" render={TextInput} meta={{
                                            label: "Email Address",
                                            emailErrMsg: "Invalid Email", maxLengthVal: 150
                                        }} />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <div className="d-grid gap-2 pb-1">
                                        <button type="button" onClick={() => handleSave()} className="btn btn-primary" disabled={isLoading || invalid || pristine || !dirty}>{isLoading ? 'Please wait...' : 'Save'}</button>

                                    </div>
                                </div>

                            </form>
                        )}
                    />
                </div>
            }
        </>
    )
}

export default ClientPhysicianDetails;
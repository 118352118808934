import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { callAPI, getMimetypeForAFile } from '../../../lib/Global.js';
import { swalConfirmOptions, swalToast2, swalAlert } from "../../../lib/Swal.js";
import styles from './clientInfo.module.css';
import "../../../styles/common.css";




const ClientDocumentList = (props) => {

    const { clientId } = props;
    const [documentList, setDocumentList] = useState([]);
    const [contact, setContact] = useState("");
    const [primaryContact, setPrimaryContact] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getDocumentListForClientById(clientId);
    }, [props.isUploaded, clientId]);

    const getDocumentListForClientById = async (clientId) => {
        setIsLoading(true);
        const r = await callAPI('GetDocumentsByClientId', { id: clientId });
        setIsLoading(false);
        setDocumentList(r);
    }

    const openDocumentForView = async (blobName) => {
        const p = await callAPI('ClientPhotoDownload', { blobName: blobName });
        const { blobNameInFull, blobBase64 } = p;
        //     if (p && p.blobBase64) {
        //         setPreviewimage(initBase64Content + p.blobBase64);
        //         setIsLoading(false);
        //         setBlobName(p.blobNameInFull);
        //     }

        const dispName = blobNameInFull.split('|')[0];
        const initBase64Content = getMimetypeForAFile(dispName.substring(dispName.lastIndexOf('.') + 1));
        const downloadLink = document.createElement('a');

        downloadLink.href = initBase64Content + blobBase64;

        downloadLink.download = dispName;

        downloadLink.click();
    }

    const confirmDeleteDocumentModal = async (id, blobName) => {
        const msg = "You are about to delete this document.<br> Do you want to continue?";
        await swalConfirmOptions.fire({ title: msg }).then(async (result) => {
            if (result.value) {
                const r = await callAPI("ClientDocumentDelete", { id: id, blobName: blobName });
                if (r.isSuccess) {
                    swalToast2('Document deleted successfully.');
                    getDocumentListForClientById(clientId);
                } else {
                    const errorMsg = `<span class="text-danger">${r.errorMsg}</span>`;
                    swalAlert('e', errorMsg);
                }
            }
        });
    }

    return (
        <div>
            {!isLoading && <table class="table table-hover table-sm table-borderless mt-2">
                <thead>
                    <tr className={styles.tHeadStyling}>
                        <th scope="col"> <span className="medium-style ms-4">Document Name</span></th>
                        <th scope="col"><span className="medium-style">Category</span></th>
                        <th scope="col"><span className="medium-style">Uploaded By</span></th>
                        <th scope="col" colspan="3"><span className="medium-style">Uploaded At</span></th>
                    </tr>
                </thead>
                <tbody>
                    {documentList?.map((d, i) => {
                        return (<tr key={i} className="border-bottom">
                            <td width="30%"><div className="ms-4"><span style={{ color: "#800000", textDecoration: 'underline' }} className={`medium-style ${styles.labelStyling}`}>{d.documentName}</span></div></td>
                            <td width="15%" className="medium-style">{d.category}</td>
                            <td width="20%" className="medium-style">{d.uploadedBy}</td>
                            <td width="15%" className="medium-style">{moment(d.uploadAt).format("YYYY/MM/DD HH:mm")}</td>
                            <td width="3%"><i class="bi bi-file-earmark-fill text-success" role="button" onClick={() => openDocumentForView(d.blobName)}></i></td>
                            {/* <td width="5%"><i class="bi bi-pencil-fill text-success" style={{ cursor: 'pointer' }} onClick={() => togglePrimaryContactForClientModal(false, d.id)}></i></td>*/}
                            <td width="3%"><i class="bi bi-trash-fill text-success" role="button" onClick={() => confirmDeleteDocumentModal(d.id, d.blobName)}></i></td>
                        </tr>)
                    })}
                </tbody>
            </table>
            }
        </div>
    )
}


export default ClientDocumentList;

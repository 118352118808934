import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './Sidebar.css';
import { SidebarData } from './SidebarData.js'
// import { can } from '../../lib/Global.js';
import Tree from '../../Modules/Admin/Components/Tree';
import { canModuleAllowed } from '../../lib/Global';

class Sidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expandedAdmin: false
        };
    }

    // isUserInTheseRoles = (d) => {
    //     const { userRoles } = this.props;
    //     let urlRoles = d.toString().split(',');
    //     urlRoles = urlRoles.map(el => el.trim());
    //     console.log(global);
    //     // const urs = global.userRoles; // User Roles
    //     return userRoles.some(a => urlRoles.includes(a));
    //     // return true;
    // }

    onSelect = (file) => {
        const { match } = this.props;
        if (file.path === "/Admin Home") {
            this.props.history.push("/admin");
        } else {
            this.props.history.push(`/admin${file.path}`);
        }
    };

    expandAdminItem = (title) => {
        if (title === 'Admin') {
            this.props.setExpandedAdmin(!this.props.expandedAdmin);
            this.props.setOpenSideBar(true);
        } else {
            this.props.setExpandedAdmin(false);
        }

    }

    render() {
        return (
            <>
                <nav className={this.props.openSideBar ? 'nav-menu navbar-scroll active' : 'nav-menu nav--collapsed active'}>
                    <ul className='nav-menu-items'>
                        <li className='navbar-toggle'>
                            <div className="nav-static-item">
                                <div className="icon-container" onClick={this.props.openAndCloseSideBar}> {this.props.openSideBar ? <i className="bi bi-x-lg"></i> : <i className="bi bi-list" style={{ fontSize: '20px' }}></i>}</div>
                            </div>
                        </li>
                        {SidebarData.map((item, index) => {
                            // if (item.permittedRoles && !this.isUserInTheseRoles(item.permittedRoles)) {
                            //     return null;
                            // }
                            if (!canModuleAllowed(item.module)) {
                                return null;
                            }
                            return (
                                <>
                                    <li key={index} className={item.cName} onClick={() => this.expandAdminItem(item.title)}>
                                        <Link to={item.path}>
                                            <div className="icon-container"> {item.icon}</div>
                                            <span className={!this.props.openSideBar ? 'nav-label-hide' : 'nav-label'}>{item.title}</span>
                                        </Link>
                                    </li>
                                    {this.props.expandedAdmin && this.props.openSideBar && item.title === 'Admin' && <Tree onSelect={this.onSelect} />}
                                </>
                            )
                        })}
                        <li className='navbar-toggle'>
                            <div className="nav-static-item" onClick={e => { this.props.markAsLogOut(e); }}>
                                <div className="icon-container"> <i class="bi bi-box-arrow-right"></i></div>
                                <span className={!this.props.openSideBar ? 'nav-label-hide' : 'nav-label'}>Logout</span>
                            </div>
                        </li>
                    </ul>
                </nav>
            </>
        )
    }
}

export default withRouter(Sidebar);
import React from 'react';
import styles from '../styles/common.module.css';

const SelectInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group" >
        <label for="formControlSelect1" className={`mb-1 ${styles.labelStyling}`}>{meta.label}</label>
        <select onClick={meta.toggleStatus} multiple={meta.multiple} size={meta.size} className="form-control form-select" id="exampleFormControlSelect1" {...handler()}>
            <option disabled={true} selected value="">-- Please Select --</option>
            {
                meta.options?.map((m, i) => {
                    return (
                        <option key={i} value={m.value ? m.value : m.text}>{m.text}</option>
                    );
                })
            }
        </select>
        <span className="d-block text-danger mt-1">
            {touched && hasError("required")
                && `Please select an option`}
        </span>
    </div >
);

export default SelectInput;
import React, { useState, useEffect } from 'react';
import { callAPI, callAPIWithpathParameter } from '../../../lib/Global'
import styles from '../../Admin/style/userInfo.module.css';
import "../../../styles/common.css";
import moment from 'moment';
import UsersModal from '../UsersModal';
import { swalAlert, swalToast2, swalConfirmOptions } from '../../../lib/Swal.js';

const UsersList = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    const [showUsersModal, setShowUsersModal] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [allRoles, setAllRoles] = useState([]);

    const [userId, setUserId] = useState();
    var allRolesArr = [];
    useEffect(() => {
        getAllUsersList();
        getRolePermissionData();
    }, []);

    const getRolePermissionData = async () => {

        setIsLoading(true);
        const r = await callAPIWithpathParameter("RolePermissions", '', 'GET', '', "Admin");

        if (r.success) {
            r?.data.map((i) => {
                allRolesArr.push({
                    text: i.roleName
                });
            });
            setAllRoles(allRolesArr);
        }
        setIsLoading(false);
    }

    const getAllUsersList = async () => {
        setIsLoading(true);
        const r = await callAPIWithpathParameter('User', '', 'GET', '');
        setIsLoading(false);
        setUsersData(r?.data);
    }


    const toggleUsersModal = async (isDataToReload, id = '') => {

        if (isDataToReload) {
            getAllUsersList();
        }

        id ? setUserId(id) : setUserId('');

        setShowUsersModal(!showUsersModal);
    }

    const toggleUsersStatusModal = async (id) => {
        const msg = 'You are about to disable this user. Are you sure?';
        swalConfirmOptions.fire({ title: msg }).then(async (result) => {
            if (result.value) {
                setIsLoading(true);
                await callAPIWithpathParameter('User', id, 'DELETE', '');
                setIsLoading(false);
                swalToast2('User account status changed successfully.');
                getAllUsersList();
            }
        });
    }

    return (
        <div>
            {showUsersModal && <UsersModal toggleUsersModal={toggleUsersModal} userId={userId} allRoles={allRoles} />}

            {isLoading ? <div>Please Wait...</div> :
                <>
                    <div class="row">
                        <div class="col-md-6">
                            <h5 className="bold-style" style={{ fontSize: '14px' }}>User Management</h5>
                        </div>
                        <div class="col-md-6">
                            <div className={`float-end`} onClick={() => toggleUsersModal(false)} >
                                <i class="bi bi-person-plus-fill text-success"></i>
                                <span className="medium-style hover-item" style={{ color: '#3CAF8A', cursor: 'pointer' }}> Add User</span>
                            </div>

                        </div>

                        <hr />
                    </div>
                </>
            }

            {!isLoading && <table class="table table-hover table-sm table-borderless">
                <thead>
                    <tr className={styles.tHeadStyling} >
                        <th scope="col"><span className="medium-style">Name</span></th>
                        <th scope="col"><span className="medium-style">Email</span></th>
                        <th scope="col"><span className="medium-style">Job Title</span></th>
                        <th scope="col" width="20%"><span className="medium-style">Role Assigned</span></th>
                        <th scope="col"><span className="medium-style">Created</span></th>
                        <th scope="col"><span className="medium-style">Status</span></th>
                        <th scope="col"><span className="medium-style"></span></th>
                        <th scope="col"><span className="medium-style"></span></th>
                    </tr>
                </thead>
                <tbody>
                    {usersData?.map((d, i) => {

                        return (<tr key={i} className="border-bottom">
                            <td width="20%" className="medium-style">{d.name}</td>
                            <td width="10%" className="medium-style">{d.email}</td>
                            <td width="10%" className="medium-style">{d.jobTitle}</td>
                            <td width="10%" className="medium-style">{d.roleIdsAssigned?.join(', ')}</td>
                            <td width="15%" className="medium-style">{moment(d.createDt).format('MM:DD:YYYY')}</td>
                            <td width="10%" className="medium-style"><span className={d.isSSOUser ? 'text-muted' : ''}>{d.isBlocked ? 'Disabled' : 'Enabled'}</span></td>

                            {!d.isSSOUser && <td width="5%"><i class="bi bi-pencil-fill text-success" style={{ cursor: 'pointer' }} onClick={() => toggleUsersModal(false, d.id)}></i></td>}
                            {!d.isSSOUser && <td width="5%"><i class="bi bi-trash text-muted" style={{ cursor: 'pointer' }} onClick={() => toggleUsersStatusModal(d.id)} ></i></td>}

                        </tr>)
                    })}
                </tbody>
            </table>
            }
        </div>
    )
}

export default UsersList;
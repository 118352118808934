import React from "react";
import moment from "moment";
import { callAPI, dateValidator } from "../../../lib/Global.js";
import { FormBuilder, FieldGroup, FieldControl, Validators } from "react-reactive-form";
import TextInput from "../../../Controls/TextInput.js";
import SelectInput from "../../../Controls/SelectInput.js";
import DateInput from "../../../Controls/DateInput.js";
import { swalAlert, swalToast2 } from "../../../lib/Swal.js";

class ClientIndentity extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false };
    }

    componentDidMount() {
        this.clientIdentityForm.patchValue({ ...this.clientIdentityForm.value, ...this.props.clientIdentityData, birthDate: this.handleFormatDate(this.props.clientIdentityData.birthDate) });
    }

    handleFormatDate = (date) => {
        return date ? moment(date).format("YYYY-MM-DD") : null;
    };

    inchesRangeValidator = (control) => {
        if (parseInt(control?.value) > 0 && parseInt(control?.value) <= 11) {
            return null;
        } else if (control?.value && control?.value.length > 0) {
            return { invalidRange: true };
        }
    };

    clientIdentityForm = FormBuilder.group({
        id: null,
        clientId: [null, Validators.required],
        recipientOfCareFirstName: [null, Validators.required],
        recipientOfCareLastName: [null, Validators.required],
        nickName: null,
        gender: [null, Validators.required],
        recipientOfCarePhoneNumber: [null, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+0-9]*$")]],
        recipientOfCareEmail: [null, [Validators.email, Validators.maxLength(150)]],
        birthDate: [null, [Validators.required, dateValidator]],
        clientSize: [null, Validators.required],
        weightInLbs: [null, Validators.pattern("^[0-9]*$")],
        heightInFt: [null, Validators.pattern("^[0-9]*$")],
        heightInInches: [null, [Validators.pattern("^[0-9]*$"), this.inchesRangeValidator]],
    });

    handleSave = async (e) => {
        this.setState({ isLoading: true });
        this.clientIdentityForm.updateValueAndValidity(this.clientIdentityForm.value);
        const r = await callAPI("UpdateClientProfileViewByClientId", this.clientIdentityForm.value);
        this.setState({ isLoading: false });

        if (r.isSuccess) {
            swalToast2("Identity for Client Updated successfully.");
            this.closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || "Something went wrong!!!"}</span>`;
            swalAlert("e", errorMsg);
        }
    };

    closeModal = (isDataToReload) => {
        this.props.closeClientIdentityModal(isDataToReload);
    };

    render() {
        const { isLoading } = this.state;
        return (
            <div className={`modal fade show`} style={{ display: "block" }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="exampleModalLabel">
                                Identity
                            </h5>
                            <i className="bi bi-x-lg" onClick={() => this.closeModal(false)} style={{ cursor: "pointer" }}></i>
                        </div>
                        <div className="modal-body">
                            <FieldGroup
                                control={this.clientIdentityForm}
                                render={({ get, invalid, pristine }) => (
                                    <form>
                                        <div className="row mb-2">
                                            <div className="col-4">
                                                <FieldControl
                                                    name="recipientOfCareFirstName"
                                                    render={TextInput}
                                                    meta={{
                                                        label: "First Name",
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <FieldControl
                                                    name="recipientOfCareLastName"
                                                    render={TextInput}
                                                    meta={{
                                                        label: "Last Name",
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <FieldControl name="nickName" render={TextInput} meta={{ label: "Nick Name", maxLengthVal: 50 }} />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-2">
                                                <FieldControl
                                                    name="gender"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Gender",
                                                        options: [
                                                            { value: "M", text: "Male" },
                                                            { value: "F", text: "Female" },
                                                        ],
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <FieldControl name="birthDate" render={DateInput} meta={{ label: "Birth Date" }} />
                                            </div>
                                            <div className="col-3">
                                                <FieldControl name="recipientOfCarePhoneNumber" render={TextInput} meta={{ label: "Phone", patternErrMsg: "Please, Enter 10 digit Mobile Number", maxLengthVal: 10, minLengthVal: 10 }} />
                                            </div>
                                            <div className="col-4">
                                                <FieldControl name="recipientOfCareEmail" render={TextInput} meta={{ label: "Email Address", emailErrMsg: "Invalid Email", maxLengthVal: 150 }} />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <FieldControl
                                                    name="clientSize"
                                                    render={SelectInput}
                                                    meta={{
                                                        label: "Client Size",
                                                        options: [{ text: "Small" }, { text: "Medium" }, { text: "Large" }, { text: "X-Large" }],
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <FieldControl
                                                    name="weightInLbs"
                                                    render={TextInput}
                                                    meta={{
                                                        label: "Weight (Lbs)",
                                                        maxLengthVal: 4,
                                                        patternErrMsg: "Only numbers are allowed",
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <FieldControl
                                                    name="heightInFt"
                                                    render={TextInput}
                                                    meta={{
                                                        label: "Height (Ft)",
                                                        maxLengthVal: 1,
                                                        patternErrMsg: "Only numbers are allowed",
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <FieldControl
                                                    name="heightInInches"
                                                    render={TextInput}
                                                    meta={{
                                                        label: "Height (Inches)",
                                                        maxLengthVal: 2,
                                                        patternErrMsg: "Only numbers are allowed",
                                                        customError: 'invalidRange',
                                                        customErrorMsg: ' Inches only between 1 - 11'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row text-lg-end pb-1 pt-3" style={{ borderTop: "1px solid lightgray" }}>
                                            <div className="col-md-12">
                                                <button type="button" onClick={() => this.closeModal(false)} className="btn btn-outline-secondary">
                                                    Close
                                                </button>
                                                &nbsp;&nbsp;
                                                <button type="button" onClick={() => this.handleSave()} className="btn btn-primary" disabled={invalid || pristine || isLoading}>
                                                    {isLoading ? "Please wait..." : "Save"}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientIndentity;

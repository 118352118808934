import { Modal } from "react-bootstrap";
import "./modalDeleteConfirmation.css";

export const ModalDeleteConfirmation = ({ show, setShow, handleConfirmRemoveClick, id }) => {
    const handleClose = () => setShow(false);

    const handleConfirmDelete = () => {
        handleConfirmRemoveClick(id);
        setShow(false);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div className="icon-box">
                    <i
                        className="fa fa-trash"
                        aria-hidden="true"
                        style={{
                            fontSize: "3rem",
                            color: "red",
                            paddingTop: "0.6rem",
                        }}
                    ></i>
                </div>
                <h4 className="confirmationMessage w-100">Are you sure?</h4>
                <p>Do you really want to delete? This process cannot be undone.</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-secondary" onClick={handleClose}>
                    Cancel
                </button>
                <button className="btn btn-danger" onClick={handleConfirmDelete}>
                    Delete
                </button>
            </Modal.Footer>
        </Modal>
    );
};

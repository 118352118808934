import React from 'react';
import './CardManager.css';
import '../../styles/common.css';




class CardManagerSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dashboardTiles: [{ isSelected: true, name: 'Search' },
            { isSelected: true, name: 'Dashboard Tile #2' },
            { isSelected: true, name: 'Dashboard Tile #3' },
            { isSelected: true, name: 'Dashboard Tile #4' },
            { isSelected: true, name: 'Dashboard Tile #5' }]
        }
    }

    componentDidMount() {
        this.props.toggleDasboardTiles(this.state.dashboardTiles);
    }

    manageTiles = (event, index, tile) => {
        let dashboardTiles = this.state.dashboardTiles;
        tile.isSelected = event.target.checked
        dashboardTiles[index] = tile;
        this.props.toggleDasboardTiles(dashboardTiles);
        this.setState({ dashboardTiles: dashboardTiles });
    }


    render() {
        return (
            <>
                <nav className={this.props.openCardManager ? 'nav-card-menu active' : 'nav-card-menu'}>
                    <ul className='nav-menu-items'>
                        <li className="ps-4 py-3" style={{ listStyleType: 'none' }}>
                            <span className="medium-style" style={{ color: '#ffffff', fontSize: '18px', letterSpacing: '0.1px' }}>Your Dashboard Tiles</span>
                        </li>
                        {this.state.dashboardTiles.map((tile, index) => {
                            return (<li className="nav-item-container">
                                <div className="form-check form-check-inline">
                                    <input type="checkbox" checked={tile.isSelected ? 'checked' : ''} onChange={(event) => this.manageTiles(event, index, tile)} />
                                    <label className="form-check-label medium-style" style={{ color: '#ffffff', fontSize: '14px', paddingLeft: '15px' }} for="flexCheckDefault">
                                        {tile.name}
                                    </label>
                                </div>
                            </li>)
                        })}

                    </ul>
                </nav>
            </>
        )
    }
}


export default CardManagerSidebar;
import React from "react";

export const SidebarData = [
    {
        title: "Dashboard",
        path: "/home",
        icon: <i className="bi bi-grid-1x2-fill"></i>,
        cName: "nav-text",
        permittedRoles: null,
        module: "dashboard"
    },
    {
        title: "Clients",
        path: "/clients",
        icon: <i className="bi bi-people-fill"></i>,
        cName: "nav-text",
        permittedRoles: 'Clients',
        module: "client"
    },
    {
        title: "Employee",
        path: "/",
        icon: <i className="bi bi-person"></i>,
        cName: "nav-text",
        permittedRoles: null,
        module: "employee"
    },
    {
        title: "Schedule",
        path: "/",
        icon: <i className="bi bi-calendar-event"></i>,
        cName: "nav-text",
        permittedRoles: null,
    },
    {
        title: "Billing",
        path: "/",
        icon: <i className="bi bi-credit-card"></i>,
        cName: "nav-text",
        permittedRoles: null,
    },
    {
        title: "Payroll",
        path: "/",
        icon: <i className="bi bi-currency-dollar"></i>,
        cName: "nav-text",
        permittedRoles: null,
    },
    {
        title: "Reports",
        path: "/",
        icon: <i className="bi bi-file-bar-graph-fill"></i>,
        cName: "nav-text",
        permittedRoles: null,
    },
    {
        title: "Admin",
        path: "/admin",
        icon: <i className="bi bi-gear-fill"></i>,
        cName: "nav-text",
        permittedRoles: "Admin, Power User"
    },
];

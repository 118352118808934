import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./clientServicesEditDialog.css";
import Select from "react-select";
import moment from "moment";

export const ClientServicesEditDialog = ({ services, listAssessmentTypes, listClientServicesNeeded, show, setShow, handleSaveClick, handleClose }) => {
    //Touched Controls state
    const [touchedControls, setTouchedControls] = useState({
        firstVisit: false,
        servicesNeeded: false,
        assessmentDate: false,
        assessmentTypes: false,
    });

    //First Visit state
    const [firstVisit, setFirstVisit] = useState("");

    //Services Needed state
    // const [servicesNeeded, setServicesNeeded] = useState([]);

    //Assessment Date state
    const [assessmentDate, setAssessmentDate] = useState("");

    //Assessment Types state
    const [assessmentTypes, setAssessmentTypes] = useState([]);

    useEffect(() => {
        setFirstVisit(moment(services.firstVisit).utc().tz("America/New_York").format("YYYY-MM-DD"));
        setAssessmentDate(moment(services.assessmentDate).utc().tz("America/New_York").format("YYYY-MM-DD"));
        setAssessmentTypes(services?.assessmentTypes);
        // setServicesNeeded(services?.servicesNeeded);
    }, [services]);

    //=== Handlers ===========================================================

    const handleSave = () => {
        const saveObj = { firstVisit: firstVisit, assessmentDate: assessmentDate != "Invalid date" ? assessmentDate : null, assessmentTypes: assessmentTypes };
        handleSaveClick(saveObj);
        setShow(false);
    };

    // const servicesNeededChangeHandler = (e) => {
    //     let servicesNeededNew = e?.map((i) => i.value);
    //     setServicesNeeded(servicesNeededNew);
    //     setTouchedControls({ ...touchedControls, servicesNeeded: true });
    // };

    const assessmentTypesChangeHandler = (e) => {
        let assessmentTypesNew = e?.map((i) => i.value);
        setAssessmentTypes(assessmentTypesNew);
        setTouchedControls({ ...touchedControls, assessmentTypes: true });
    };

    const firstVisitChangeHandler = (e) => {
        const firstVisitNew = e.target.value;
        setFirstVisit(firstVisitNew);
        setTouchedControls({ ...touchedControls, firstVisit: true });
    };

    const assessmentDateChangeHandler = (e) => {
        const assessmentDateNew = e.target.value;
        setAssessmentDate(assessmentDateNew);
        setTouchedControls({ ...touchedControls, assessmentDate: true });
    };

    return (
        <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="text-primary">Client Services</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <>
                    <div className="row">
                        <div className="col-6">
                            <span className="my-1 d-block">Initial Assessment Date</span>
                            <input type="date" className="form-control" placeholder={`Enter Assessment Date`} value={assessmentDate} name="assessmentDate" onChange={assessmentDateChangeHandler} maxLength="50" />
                            <div className="d-block text-danger small text-end">&nbsp;</div>
                        </div>

                        <div className="col-6">
                            <span className="my-1 d-block">First Visit</span>
                            <input type="date" className="form-control" placeholder={`Enter First Visit`} value={firstVisit} name="firstVisit" onChange={firstVisitChangeHandler} maxLength="50" />
                            <div className="d-block text-danger small text-end">
                                &nbsp;
                                {touchedControls?.firstVisit && !firstVisit && `First Visit is required`}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label className={`mb-1`}>Assessment Types</label>
                            <Select isMulti menuPlacement="top" options={listAssessmentTypes} value={listAssessmentTypes.value} defaultValue={services?.assessmentTypes?.map((frd) => listAssessmentTypes?.find((i) => i.value === frd))} onChange={assessmentTypesChangeHandler}></Select>
                            <div className="d-block text-danger small text-end">
                                &nbsp;
                                {touchedControls?.assessmentTypes && !assessmentTypes?.length && `Assessment Types are required`}
                            </div>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-md-12">
                            <label className={`mb-1`}>Ongoing Services Needed</label>
                            <Select isMulti menuPlacement="top" options={listClientServicesNeeded} value={listClientServicesNeeded.value} defaultValue={services?.servicesNeeded?.map((frd) => listClientServicesNeeded?.find((i) => i.value === frd))} onChange={servicesNeededChangeHandler}></Select>
                            <div className="d-block text-danger small text-end">
                                &nbsp;
                                {touchedControls?.servicesNeeded && !servicesNeeded?.length && `Services Needed are required`}
                            </div>
                        </div>
                    </div> */}
                </>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
                    Close
                </button>
                <button type="button" className="btn btn-primary" disabled={!(firstVisit && assessmentTypes?.length)} onClick={handleSave}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

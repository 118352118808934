import { useEffect, useState } from "react";
import { JobTitleEditDialog } from "./components/jobTitleEditDialog";
import { JobTitleListTableBody } from "./components/jobTitleListTableBody";
import { JobTitleListTableFooter } from "./components/jobTitleListTableFooter";
import { ModalDeleteConfirmation } from "../../../../../../Shared/modalDeleteConfirmation/modalDeleteConfirmation";
import { ErrorMessage } from "../../../../../../Shared/errorMessage/errorMessage.jsx";
import { Loading } from "../../../../../../Shared/loading/loading";
import { createJobTitles, deleteJobTitles, getAllJobTitles } from "./jobTitleListRest";

//=== Helpers =====
import { sortJson } from "../../../../../../lib/sortJson";
import { JSONToCSVConvertor } from "../../../../../../lib/jsonToCSVConvertor";
import { fileTimeStamp } from "../../../../../../lib/fileTimeStamp";

//=== Style =====
import "./jobTitleListStyle.css";

export const JobTitleList = () => {
    //Modal display state
    const [show, setShow] = useState(false);
    //Modal Delete Confirmation state
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    //Loading data flag state
    const [loading, setLoading] = useState(false);
    //errors state
    const [loadingError, setLoadingError] = useState(null);
    const [operationError, setOperationError] = useState(null);
    //JobTitle data state
    const [data, setData] = useState([]);
    //JobTitle filtered data state
    const [displayData, setDisplayData] = useState([]);
    //Selected id
    const [id, setId] = useState(null);

    //Grid records per page state
    const [pageSize, setPageSize] = useState(5);
    //Grid page # state
    const [pageNumber, setPageNumber] = useState(1);
    //Grid number of pages state
    const [numPages, setNumPages] = useState(null);

    //filter value state
    const [filter, setFilter] = useState(null);
    //order by direction state
    const [sortAsc, setSortAsc] = useState(true);

    //Event handlers =====================================================

    //show add new Job Title modal
    const handleAddNewClick = () => {
        setShow(true);
    };

    //handle close create dialog
    const handleCloseClick = () => setShow(false);

    //handle confirm remove jobTitle button click
    const handleConfirmRemoveClick = (id) => {
        deleteJobTitles(id)
            .then((ret) => {
                setData(data.filter((jobTitle) => jobTitle.id !== id));
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error deleting data (fetch)");
                }
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });
    };

    //handle download csv
    const handleDownloadClick = () => {
        JSONToCSVConvertor(displayData, `Infinity_ClientHealthAssessment_${fileTimeStamp()}.csv`, true);
    };

    //handle page number changes
    const handlePageNumberChange = (e) => {
        setPageNumber(e.target.value);
    };

    //handle page size changes
    const handlePageSizeChange = (e) => {
        e.target.value === "All" ? setPageSize(data.length) : setPageSize(e.target.value);
        //when pagesize changes, reset to page 1
        setPageNumber(1);
    };

    //handle reload button click
    const handleReloadClick = () => {
        loadAllJobTitles();
    };

    //show remove confirmation dialog
    const handleRemoveClick = (id) => {
        setId(id);
        setShowConfirmDelete(true);
    };

    //handle save jobTitle click
    const handleSaveJobTitleClick = ({ jobTitleName }) => {
        createJobTitles({ name: jobTitleName })
            .then((ret) => {
                const newArrayData = [...data];
                newArrayData.push(ret);
                setData(newArrayData);
            })
            .catch((err) => {
                try {
                    setOperationError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setOperationError("Error saving data (fetch)");
                }
                //hide message after 5s
                setTimeout(() => setOperationError(null), 5000);
            });
    };

    //handle search input change
    const handleSearchInput = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    //handle sort columns
    const handleSortClick = (prop, propType) => {
        setSortAsc((sortAsc) => !sortAsc);
        setDisplayData(sortJson(displayData, prop, propType, sortAsc));
    };

    //=== Methods ============================================================

    const isError = (obj) => Object.prototype.toString.call(obj) === "[object Error]";

    const loadAllJobTitles = () => {
        setLoading(true);
        getAllJobTitles()
            .then((data) => {
                setData(data);
                setDisplayData(data);
                setLoading(false);
            })
            .catch((err) => {
                try {
                    setLoadingError(() => (isError(err) ? err.message : err));
                } catch (error) {
                    setLoadingError("Error loading data");
                }
                setLoading(false);
            });
    };

    //Use Effects ===========================================================

    //Loading jobTitles...
    useEffect(() => {
        loadAllJobTitles();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //update # pages when displayData || pageSize changes
    useEffect(() => {
        pageSize === "All" ? setNumPages(1) : setNumPages(Math.ceil(displayData.length / pageSize));
    }, [displayData, pageSize]);

    //update display data when data || filter changes
    useEffect(() => {
        setDisplayData(
            !filter
                ? data
                : data.filter(({ name }) => {
                      return name.toLowerCase().includes(filter);
                  })
        );

        //when filter, reset to page 1
        setPageNumber(1);
    }, [data, filter]);

    //========================================================================

    return (
        <section id="JobTitleListSection">
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="text-primary py-3">Job Title List</h2>
                        </div>
                        <div className="col-md-8">{operationError && <ErrorMessage msg={operationError} />}</div>
                    </div>

                    <div className="row">
                        <div className="col-md-9">
                            <button type="button" className="btn btn-primary btn-sm" onClick={handleAddNewClick}>
                                Add New Job Title
                            </button>
                            {loading && <Loading />}
                        </div>
                        <div className="col-md-3 align-end">
                            <div className="input-group">
                                <input type="text" className="form-control form-control-sm" placeholder="Search...." onChange={handleSearchInput} />
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Reload" onClick={handleReloadClick}>
                                    <i className="fa fa-refresh"></i>
                                </button>
                                &nbsp;
                                <button className="btn btn-sm btn-primary" title="Download" onClick={handleDownloadClick}>
                                    <i className="fa fa-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped table-hover table-sm jobTitleList mt-1">
                        <thead>
                            <tr>
                                <th className="sort" onClick={() => handleSortClick("name", "string")}>
                                    Job Title Name
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <JobTitleListTableBody currentPage={Number(pageNumber)} numberPerPage={Number(pageSize)} items={displayData} filter={filter} handleRemoveClick={handleRemoveClick} loading={loading} loadingError={loadingError} />
                        </tbody>
                        <tfoot>
                            <JobTitleListTableFooter handlePageNumberChange={handlePageNumberChange} handlePageSizeChange={handlePageSizeChange} numPages={numPages} numRecords={displayData.length} />
                        </tfoot>
                    </table>
                </div>
            </div>
            <ModalDeleteConfirmation show={showConfirmDelete} setShow={setShowConfirmDelete} handleConfirmRemoveClick={handleConfirmRemoveClick} id={id} />
            <JobTitleEditDialog show={show} setShow={setShow} handleSaveClick={handleSaveJobTitleClick} handleClose={handleCloseClick} />
        </section>
    );
};

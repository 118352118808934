import React, { useEffect, useState } from 'react';
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import { callAPI, callAPIWithpathParameter } from '../../../lib/Global';
import TextInput from '../../../Controls/TextInput.js';
import TextArea from '../../../Controls/TextArea.js';
import { swalToast2, swalAlert } from '../../../lib/Swal.js';

const CreateRoles = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.roleData !== undefined)
            populateRoleModal(props.roleData);
    }, [])

    const RolesForm = FormBuilder.group({
        id: null,
        roleName: [null, Validators.required],
        description: [null, Validators.required]
    });

    const populateRoleModal = (data) => {

        RolesForm.patchValue({
            id: data.id,
            roleName: data.roleName,
            description: data.description
        });

    }

    const handleSave = async () => {
        setIsLoading(true);
        RolesForm.updateValueAndValidity(RolesForm.value);

        let msg = RolesForm.value.id ? 'updated' : 'created';
        let http_method = RolesForm.value.id ? 'PUT' : 'POST';

        const r = await callAPI('Roles', RolesForm.value, http_method);

        setIsLoading(false);

        if (r?.success) {
            swalToast2(`Role ${msg} successfully.`);
            props.toggleRolesModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }

    }

    return (
        <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content bg-light">
                    <div className="modal-header">
                        <h5 className="modal-title text-success" id="exampleModalLabel">New Role</h5>
                        <i className="bi bi-x-lg" onClick={() => props.toggleRolesModal()} style={{ cursor: 'pointer' }}></i>
                    </div>
                    <div className="modal-body">
                        <FieldGroup
                            control={RolesForm}
                            render={({ get, invalid, pristine }) => (
                                <form>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <FieldControl
                                                name="roleName"
                                                render={TextInput}
                                                meta={{
                                                    label: "Role Name",
                                                    maxLengthVal: 50
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <FieldControl
                                                name="description"
                                                render={TextArea}
                                                meta={{
                                                    label: "Description",
                                                    maxLengthVal: 120
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <div className="d-grid gap-2 pb-1">
                                            <button type="button" onClick={() => handleSave()} className="btn btn-lg btn-success rounded-pill" disabled={invalid || pristine || isLoading}>{isLoading ? 'Please wait...' : 'Save'}</button>
                                            {/* <button type="button" onClick={() => closeModal(false)} className="btn btn-link">Cancel</button> */}
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CreateRoles;
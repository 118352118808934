import React from "react";
import { withRouter, Link, useLocation } from "react-router-dom";
import "./Breadcrumb.css";

const bradKeysAndValue = {
    clientprofile: "Clients",
    clients: "Clients",
    admin: "Admin",
    limitation: "Client Limitations",
    need: "Client Needs",
    branch: "Branches",
    casetype: "Case Types",
    hospice: "Hospices",
    hospital: "Hospitals",
    product: "Products",
    Account: "Account",
    Groups: "Groups",
    Users: "Users",
    "Roles & Permissions": "Roles & Permissions",
    "Users & Groups": "Users & Groups",
    "Global settings": "Global settings",
    "Service Portal": "Service Portal",
    "Activity logging": "Activity logging"
};

function Breadcrumb(props) {
    const location = useLocation();
    let pathName = location.pathname;
    let pathNameArr = pathName.split("/");
    pathNameArr.shift();

    const breadcrumbforLastElement = (f, prevElemValue) => {
        if (prevElemValue === "clientprofile") {
            let name = props.breadcrumbName?.id === f ? props.breadcrumbName.clientName : "";
            return name;
        }
        return null;
    };

    const BreadCrumbDisplay = (f, isLastElement, prevElemValue) => {
        if (isLastElement) {
            let breadVal = breadcrumbforLastElement(f, prevElemValue);
            if (breadVal) {
                return breadVal;
            } else {
                return getBreadKeysVal(f);
            }
        } else {
            return getBreadKeysVal(f);
        }
    };

    const getBreadKeysVal = (f) => {
        if (bradKeysAndValue[f]) {
            return bradKeysAndValue[f];
        }
    };
    let breadCrumbName = pathNameArr.map((f, i) => BreadCrumbDisplay(f, pathNameArr.length - 1 === i, i === 0 ? null : pathNameArr[i - 1]));
    return (
        <nav>
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/home">Dashboard</Link>
                </li>
                {breadCrumbName[0] === 'Admin' ?
                    breadCrumbName.map((v, i) => (
                        <li key={i} className={`breadcrumb-item ${breadCrumbName.length - 1 === i && "active"}`}>
                            {!(breadCrumbName.length - 1 === i) ? <Link to={v === 'Admin' ? "/admin" : "/admin/" + v}>{v}</Link> : <span>{v}</span>}
                        </li>
                    )) :
                    breadCrumbName.map((v, i) => (
                        <li key={i} className={`breadcrumb-item ${breadCrumbName.length - 1 === i && "active"}`}>
                            {!(breadCrumbName.length - 1 === i) ? <Link to={"/" + v?.toLowerCase()}>{v}</Link> : <span>{v}</span>}
                        </li>
                    ))}
                {/* <li class="breadcrumb-item active"></li> */}
            </ol>
        </nav>
    );
}

export default withRouter(Breadcrumb);

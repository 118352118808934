import React from "react";
import { callAPI } from "../../../lib/Global.js";
import { FormBuilder, FieldGroup, FieldControl, Validators } from "react-reactive-form";
import SelectInput from "../../../Controls/SelectInput.js";
import { swalAlert, swalToast, swalToast2 } from "../../../lib/Swal.js";
import styles from "../../../styles/common.module.css";

class ClientCareStaffModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, csaSelectedVals: [], isDataUpdated: false };
    }

    async componentDidMount() {
        await this.getClientCareStaffPrefByClientId(this.props.clientId);
    }

    getClientCareStaffPrefByClientId = async (clientId) => {
        this.setState({ isLoading: true });

        const r = await callAPI("GetClientCareStaffPrefByClientId", { id: clientId });
        this.setState({ isLoading: false });

        if (r) {
            const csaSelectedVals = r.careStaffAttributes?.split(",") || [];
            this.setState({ csaSelectedVals });
        }

        this.careStaffPreferencesForm.patchValue(r ? r : { clientId: this.props.clientId });
    };

    csaVals = ["Quiet", "Funny", "Punctual", "Energetic", "Open-minded", "Talkative", "Mature", "Creative", "Calm", "Conservative"];

    careStaffPreferencesForm = FormBuilder.group({
        id: null,
        clientId: [null, Validators.required],
        careStaffGender: [null, Validators.required],
        careStaffSmoking: [null, Validators.required],
        language: [null, Validators.required],
        driving: [null, Validators.required],
        careStaffAttributes: null,
    });

    handleSave = async (e) => {
        this.setState({ isLoading: true });
        this.setState({ isDataUpdated: !this.state.isDataUpdated });

        let api = this.careStaffPreferencesForm.value.id !== null ? "UpdateClientCareStaffPref" : "CreateClientCareStaffPref";
        let message = this.careStaffPreferencesForm.value.id !== null ? "Updated" : "Created";
        const r = await callAPI(api, this.careStaffPreferencesForm.value);
        this.setState({ isLoading: false });

        if (r?.isSuccess) {
            swalToast2("Care Staff Preference for Client " + message + " successfully.");
            this.closeModal(false);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || "Something went wrong!!!"}</span>`;
            swalAlert("e", errorMsg);
        }
    };

    isCSAValChecked = (v) => {
        // console.log(this.state.csaSelectedVals?.includes(v));
        return this.state.csaSelectedVals?.includes(v);
    };

    getAttributes = (e) => {
        let csaSelectedVals = this.state.csaSelectedVals;

        if (e.target.checked) {
            csaSelectedVals.push(e.target.value);
        } else {
            csaSelectedVals = csaSelectedVals.filter((f) => f !== e.target.value);
        }

        this.setState({ csaSelectedVals });

        this.careStaffPreferencesForm.patchValue({ careStaffAttributes: csaSelectedVals.join() });
        this.careStaffPreferencesForm.updateValueAndValidity(this.careStaffPreferencesForm.value);
        this.careStaffPreferencesForm.markAsDirty();

    };

    closeModal = (isDataReload) => {
        this.props.closeClientIdentityModal(isDataReload);
    };

    render() {
        return (
            <div className={`modal fade show`} style={{ display: "block" }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="exampleModalLabel">
                                Care Staff Preferences
                            </h5>
                            <i className="bi bi-x-lg" onClick={() => this.closeModal(true)} style={{ cursor: "pointer" }}></i>
                        </div>
                        <div className="modal-body">
                            {this.state.isLoading && !this.state.isDataUpdated && (
                                <>
                                    <div>Please Wait...</div>
                                </>
                            )}

                            {this.state.isDataUpdated && (
                                <>
                                    <div>Completed...</div>
                                </>
                            )}

                            {!this.state.isLoading && (
                                <>
                                    <FieldGroup
                                        control={this.careStaffPreferencesForm}
                                        render={({ get, invalid, dirty }) => (
                                            <form>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="careStaffGender"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <>
                                                                    <span for="formCheck1" className={`my-1 d-block ${styles.labelStyling}`}>
                                                                        {meta.label}
                                                                    </span>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "Male")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioYou">
                                                                            Male
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "Female")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                                            Female
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "No preference")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                                            No preference
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )}
                                                            meta={{ label: "Care Staff Gender" }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="careStaffSmoking"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <>
                                                                    <span for="formCheck1" className={`my-1 d-block ${styles.labelStyling}`}>
                                                                        {meta.label}
                                                                    </span>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "Yes")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioYou">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "No")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "No preference")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                                            No preference
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )}
                                                            meta={{ label: "Care Staff Smoking" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="language"
                                                            render={SelectInput}
                                                            meta={{
                                                                label: "Language",
                                                                options: [
                                                                    { text: "English" },
                                                                    { text: "Arabic" },
                                                                    { text: "Armenian" },
                                                                    { text: "Bengali" },
                                                                    { text: "Chinese" },
                                                                    { text: "French and French Creole" },
                                                                    { text: "German" },
                                                                    { text: "Greek" },
                                                                    { text: "Gujarati" },
                                                                    { text: "Hebrew" },
                                                                    { text: "Hindi" },
                                                                    { text: "Hmong" },
                                                                    { text: "Italian" },
                                                                    { text: "Japanese" },
                                                                    { text: "Korean" },
                                                                    { text: "Panjabi" },
                                                                    { text: "Persian" },
                                                                    { text: "Polish" },
                                                                    { text: "Portuguese" },
                                                                    { text: "Russian" },
                                                                    { text: "Spanish" },
                                                                    { text: "Tagalog" },
                                                                    { text: "Telugu" },
                                                                    { text: "Urdu" },
                                                                    { text: "Vietnamese" },
                                                                ],
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="driving"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <>
                                                                    <span for="formCheck1" className={`my-1 d-block ${styles.labelStyling}`}>
                                                                        {meta.label}
                                                                    </span>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "Needs a driver")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioYou">
                                                                            Needs a driver
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "Needs driver with car")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                                            Needs driver with car
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "No driver necessary")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                                            No driver necessary
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )}
                                                            meta={{ label: "Driving" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <>
                                                            <span for="formCheck1" className={`my-1 d-block ${styles.labelStyling}`}>
                                                                Care Staff Attributes
                                                            </span>
                                                            {this.csaVals.map((m) => (
                                                                <div className="form-check form-check-inline">
                                                                    <input class="form-check-input" type="checkbox" checked={this.isCSAValChecked(m) ? "checked" : ""} value={m} onChange={(e) => this.getAttributes(e)} />
                                                                    <label className={`form-check-label ${styles.labelStyling}`} for="flexCheckDefault">
                                                                        {m}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </>
                                                    </div>
                                                </div>
                                                <div className="row text-lg-end pb-1 pt-3 mx-0 px-0" style={{ borderTop: "1px solid lightgray" }}>
                                                    <div className="col-md-12">
                                                        <button type="button" onClick={() => this.closeModal(true)} className="btn btn-outline-secondary">
                                                            Close
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button type="button" onClick={() => this.handleSave()} className="btn btn-primary" disabled={invalid || !dirty || this.state.isLoading}>
                                                            {this.state.isLoading ? "Please wait..." : "Save"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientCareStaffModal;

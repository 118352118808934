import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormBuilder, FieldGroup, FieldControl, Validators } from "react-reactive-form";

const TextInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group">
        <label htmlFor="formControlInput1" className={`mb-1`}>
            {meta.label}
        </label>
        <input type="text" className={!touched ? "form-control" : hasError("required") ? "form-control is-invalid" : "form-control is-valid"} placeholder={`Enter ${meta.label}`} {...handler()} maxLength="50" />
        <div className="d-block text-danger small text-end">
            &nbsp;
            {touched && hasError("required") && `${meta.label} is required`}
            {hasError("email") && `${meta.emailErrMsg}`}
            {hasError("pattern") && `${meta.patternErrMsg}`}
        </div>
    </div>
);

//Form fields definition
const hospitalFormDefinition = FormBuilder.group({
    hospitalName: [null, [Validators.required]],
    state: [null, [Validators.required]],
    city: [null, [Validators.required]],
});

export const HospitalEditDialog = ({ show, setShow, handleSaveClick, handleClose }) => {
    //=== Handlers ===========================================================

    const handleSave = () => {
        handleSaveClick(hospitalFormDefinition.value);
        setShow(false);
    };

    //========================================================================
    useEffect(() => {
        hospitalFormDefinition.reset();
    });

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="text-primary">Add Hospital</Modal.Title>
            </Modal.Header>

            <FieldGroup
                control={hospitalFormDefinition}
                render={({ get, invalid }) => (
                    <form>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    <FieldControl
                                        name="hospitalName"
                                        render={TextInput}
                                        meta={{
                                            label: "Hospital Name",
                                            maxLengthVal: 50,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FieldControl
                                        name="state"
                                        render={({ handler, touched, hasError, meta }) => (
                                            <div className="form-group">
                                                <label htmlFor="state" className={`mb-1`}>
                                                    {meta.label}
                                                </label>
                                                <select className={!touched ? "form-select" : hasError("required") ? "form-select is-invalid" : "form-select is-valid"} id="state" {...handler()}>
                                                    <option value="">Please Select</option>
                                                    <option value="AK">Alaska (AK)</option>
                                                    <option value="AL">Alabama (AL)</option>
                                                    <option value="AR">Arkansas (AR)</option>
                                                    <option value="AZ">Arizona (AZ)</option>
                                                    <option value="CA">California (CA)</option>
                                                    <option value="CO">Colorado (CO)</option>
                                                    <option value="CT">Connecticut (CT)</option>
                                                    <option value="DC">District of Columbia (DC)</option>
                                                    <option value="DE">Delaware (DE)</option>
                                                    <option value="FL">Florida (FL)</option>
                                                    <option value="GA">Georgia (GA)</option>
                                                    <option value="HI">Hawaii (HI)</option>
                                                    <option value="IA">Iowa (IA)</option>
                                                    <option value="ID">Idaho (ID)</option>
                                                    <option value="IL">Illinois (IL)</option>
                                                    <option value="IN">Indiana (IN)</option>
                                                    <option value="KS">Kansas (KS)</option>
                                                    <option value="KY">Kentucky (KY)</option>
                                                    <option value="LA">Louisiana (LA)</option>
                                                    <option value="MA">Massachusetts (MA)</option>
                                                    <option value="MD">Maryland (MD)</option>
                                                    <option value="ME">Maine (ME)</option>
                                                    <option value="MI">Michigan (MI)</option>
                                                    <option value="MN">Minnesota (MN)</option>
                                                    <option value="MO">Missouri (MO)</option>
                                                    <option value="MS">Mississippi (MS)</option>
                                                    <option value="MT">Montana (MT)</option>
                                                    <option value="NC">North Carolina (NC)</option>
                                                    <option value="ND">North Dakota (ND)</option>
                                                    <option value="NE">Nebraska (NE)</option>
                                                    <option value="NH">New Hampshire (NH)</option>
                                                    <option value="NJ">New Jersey (NJ)</option>
                                                    <option value="NM">New Mexico (NM)</option>
                                                    <option value="NV">Nevada (NV)</option>
                                                    <option value="NY">New York (NY)</option>
                                                    <option value="OH">Ohio (OH)</option>
                                                    <option value="OK">Oklahoma (OK)</option>
                                                    <option value="OR">Oregon (OR)</option>
                                                    <option value="PA">Pennsylvania (PA)</option>
                                                    <option value="PR">Puerto Rico (PR)</option>
                                                    <option value="RI">Rhode Island (RI)</option>
                                                    <option value="SC">South Carolina (SC)</option>
                                                    <option value="SD">South Dakota (SD)</option>
                                                    <option value="TN">Tennessee (TN)</option>
                                                    <option value="TX">Texas (TX)</option>
                                                    <option value="UT">Utah (UT)</option>
                                                    <option value="VA">Virginia (VA)</option>
                                                    <option value="VT">Vermont (VT)</option>
                                                    <option value="WA">Washington (WA)</option>
                                                    <option value="WI">Wisconsin (WI)</option>
                                                    <option value="WV">West Virginia (WV)</option>
                                                    <option value="WY">Wyoming (WY)</option>
                                                </select>
                                                <div className="d-block text-danger small text-end">
                                                    &nbsp;
                                                    {touched && hasError("required") && `${meta.label} is required`}
                                                </div>
                                            </div>
                                        )}
                                        meta={{ label: "State" }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FieldControl
                                        name="city"
                                        render={TextInput}
                                        meta={{
                                            label: "City",
                                            maxLengthVal: 50,
                                        }}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
                                Close
                            </button>
                            <button type="button" className="btn btn-primary" disabled={invalid} onClick={handleSave}>
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </form>
                )}
            />
        </Modal>
    );
};

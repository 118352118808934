import React from 'react';
import styles from '../../../styles/common.module.css';
import "../../../styles/common.css";




const AddClientDocumentsWebUI = (props) => {
    const { handleSelectedFile, setCategoryValue, fileName, handleSave, category, isLoading } = props;
    const categoryArr = ['Agreement', 'Billing', 'Clinical', 'Letters', 'Medications', 'Polaris', '485 / Verbal Orders'];
    return (
        <div>
            <h5 className="bold-style" style={{ fontSize: '14px' }}>Documents</h5>
            <hr />
            <div className="row mt-3">
                <div className="col-4 d-flex align-items-center">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Select Document</label>
                    <span className="regular-style file-name ms-2" style={{ width: '200px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{fileName}</span>
                    <label for="docFile-input" className="browse-btn ms-2">Browse</label>
                    <input name="file" id="docFile-input" type="file" style={{ display: 'none' }} onChange={(e) => handleSelectedFile(e)} />
                </div>
                <div className="col-4 d-flex align-items-center">
                    <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Category</label>
                    <select value={category} onChange={e => setCategoryValue(e)} name="category" className="form-control form-select ms-2" id="exampleFormControlSelect1">
                        <option selected value="">-- Please Select --</option>
                        {
                            categoryArr.map((cat, i) => {
                                return (
                                    <option key={i} value={cat}>{cat}</option>
                                )
                            })
                        }
                    </select>
                    <button type="button" className="btn btn-primary btn-sm ms-4" onClick={() => handleSave()}>{isLoading ? 'Please wait...' : 'Upload'}</button>
                </div>
            </div>
        </div>
    )
}

export default AddClientDocumentsWebUI;
import React from "react";
import { FormBuilder, Validators, FieldControl, FieldGroup } from "react-reactive-form";
import { withRouter } from "react-router-dom";
import { swalAlert, swalToast } from "../../../lib/Swal.js";
import styles from "../../../styles/common.module.css";
import { callAPI } from "../../../lib/Global.js";
import NumberInput from "../../../Controls/NumberInput.js";
import SelectInput from "../../../Controls/SelectInput.js";
import FamilyDynamicsModal from "../Components/FamilyDynamicsModal.js";

// import Values from "../lib/FormValue.js";

class FamilyDynamics extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, familyDynamicData: {}, setShowClientFamilyDynamicsModal: false };
    }

    componentWillUnmount() {
        this.familyDynamicsForm.get("maritalStatus").valueChanges.unsubscribe();
        this.familyDynamicsForm.get("isThereBudgetForCare").valueChanges.unsubscribe();
    }

    async componentDidMount() {
        await this.getFamilyDynamicsByClientId();
    }

    familyDynamicsForm = FormBuilder.group({
        id: [null],
        clientId: [null, Validators.required],
        maritalStatus: [null, [Validators.required, Validators.maxLength(25)]],
        howLongAgoWidowedOrWidowered: [null],
        powerOfAttorney: [null, [Validators.required, Validators.maxLength(10)]],
        healthcareProxy: [null, [Validators.required, Validators.maxLength(10)]],
        isFamilyConcernedAboutFinances: [null, [Validators.required, Validators.maxLength(10)]],
        isThereBudgetForCare: [null, [Validators.required, Validators.maxLength(10)]],
        budget: [null],
    });

    getFamilyDynamicsByClientId = async () => {
        this.setState({ isLoading: true });

        const r = await callAPI("GetClientFamilyDynamicsByClientId", { id: this.props.clientId });
        this.setState({ isLoading: false, formPurpose: r ? "D" : "C", familyDynamicData: r });

        this.familyDynamicsForm.patchValue(r ? r : { clientId: this.props.clientId });
    };

    toggleLifestyleBackgroundModal = async (isDataReload) => {
        if (isDataReload) {
            await this.getFamilyDynamicsByClientId(this.props.clientId);
        }
        this.setState({
            setShowClientFamilyDynamicsModal: !this.state.setShowClientFamilyDynamicsModal,
        });
        this.props.togglebackDrop();
    };

    render() {
        if (this.state.isLoading) {
            return <div>Please wait...</div>;
        }
        return (
            <>
                {this.state.setShowClientFamilyDynamicsModal && <FamilyDynamicsModal familyDynamicData={this.state.familyDynamicData} closeClientIdentityModal={this.toggleLifestyleBackgroundModal} clientId={this.props.clientId} />}
                <h5 className="bold-style" style={{ fontSize: "14px" }}>
                    Family Dynamics
                </h5>
                <hr />
                <div className="row mt-3">
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Marital Status
                        </label>
                        <span className="regular-style text-primary">{this.state.familyDynamicData?.maritalStatus || ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            How long ago widowed/widowered?
                        </label>
                        <span className="regular-style text-primary">{this.state.familyDynamicData?.howLongAgoWidowedOrWidowered || ""}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Power of Attorney?
                        </label>
                        <span className="regular-style text-primary">{this.state.familyDynamicData?.powerOfAttorney || ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Health Care Proxy?
                        </label>
                        <span className="regular-style text-primary">{this.state.familyDynamicData?.healthcareProxy || ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Concerned About Finances?
                        </label>
                        <span className="regular-style text-primary">{this.state.familyDynamicData?.isFamilyConcernedAboutFinances || ""}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Is There Budget For Care?
                        </label>
                        <span className="regular-style text-primary">{this.state.familyDynamicData?.isThereBudgetForCare || ""} </span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Annual Budget($)
                        </label>
                        <span className="regular-style text-primary">{this.state.familyDynamicData?.budget || ""} </span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-3">
                        <span className="btn btn-sm btn-primary" onClick={() => this.toggleLifestyleBackgroundModal(false)}>
                            Edit Family Dynamics
                        </span>
                    </div>
                </div>
                <hr />
            </>
        );
    }
}

export default withRouter(FamilyDynamics);

import React from "react";
import { callAPI } from "../../../lib/Global.js";
import styles from "../../../styles/common.module.css";
import ClientCareStaffModal from "../Components/ClientCareStaffModal.js";

class ClientCareStaffPreferences extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, csaSelectedVals: [], clientCareStaffData: {}, setShowClientCareStaffsModal: false, attr: "" };
    }

    async componentDidMount() {
        await this.getClientCareStaffPrefByClientId(this.props.clientId);
    }

    getClientCareStaffPrefByClientId = async (clientId) => {
        this.setState({ isLoading: true });

        const r = await callAPI("GetClientCareStaffPrefByClientId", { id: clientId });
        this.setState({ isLoading: false, clientCareStaffData: r });
        this.setState({ attr: "" });

        if (this.state.clientCareStaffData?.careStaffAttributes) {
            this.state.clientCareStaffData?.careStaffAttributes.split(",").map((i, k) => {
                this.state.attr += i + ", ";
            });
        }

        if (r) {
            const csaSelectedVals = r.careStaffAttributes?.split(",") || [];
            this.setState({ csaSelectedVals });
        }
    };

    csaVals = ["Quiet", "Funny", "Punctual", "Energetic", "Open-minded", "Talkative", "Mature", "Creative", "Calm", "Conservative"];

    toggleCareStaffModal = (isDataReload) => {
        if (!isDataReload) {
            this.getClientCareStaffPrefByClientId(this.props.clientId);
        }
        this.setState({
            setShowClientCareStaffsModal: !this.state.setShowClientCareStaffsModal,
        });
        this.props.togglebackDrop();
    };

    render() {
        if (this.state.isLoading) {
            return <div>Please wait...</div>;
        }

        return (
            <>
                {this.state.setShowClientCareStaffsModal && <ClientCareStaffModal closeClientIdentityModal={this.toggleCareStaffModal} clientId={this.props.clientId} />}
                <h5 className="bold-style" style={{ fontSize: "14px" }}>
                    Client Care Staff
                </h5>
                <hr />

                <div className="row mt-3">
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Care Staff Gender
                        </label>
                        <span className="regular-style text-primary">{this.state.clientCareStaffData?.careStaffGender || ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Care Staff Smoking
                        </label>
                        <span className="regular-style text-primary">{this.state.clientCareStaffData?.careStaffSmoking || ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Language
                        </label>
                        <span className="regular-style text-primary">{this.state.clientCareStaffData?.language || ""}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Driving
                        </label>
                        <span className="regular-style text-primary">{this.state.clientCareStaffData?.driving || ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Care Staff Attributes
                        </label>
                        <span className="regular-style text-primary">{this.state.attr.replace(/^,/, "") || ""}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-3">
                        <span className="btn btn-sm btn-primary" onClick={() => this.toggleCareStaffModal(true)}>
                            Edit Care Staff Preferences
                        </span>
                    </div>
                </div>
                <hr />
            </>
        );
    }
}

export default ClientCareStaffPreferences;

import React, { useEffect, useState } from 'react';
import CreateRoles from './CreateRoles';
import '../../../styles/common.css'
import rolePermissionStyle from '../style/common.module.css';
import { callAPI, callAPIWithpathParameter } from '../../../lib/Global';
import { swalAlert, swalToast, swalToast2, swalConfirmOptions } from '../../../lib/Swal.js';
// import swalConfirmOptions from '../../../lib/Swal.js';
import RolePermissionsModal from './rolePermissionModal';
import { Link } from 'react-router-dom';

const AdminRoles = {
    "roleName": "Administrator",
    "description": "All powerfull Administrator user!",
    "permissions": [
        {
            "type": "Permission",
            "action": "Manage",
            "module": "All",
            "subject": "",
            "scope": []
        }
    ],
    "id": "61e9a355700a54fz00b0e5fc",
    "createDt": "2022-01-20T18:08:37.001Z",
    "createBy": "LoggedInUserGoesHere",
    "updateDt": "2022-01-21T14:11:37.534Z",
    "updateBy": "UpdatedByGoesHere"
}

const RolesAndPermissions = (props) => {


    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showRolePermissionModal, setShowPermissionModal] = React.useState(false);
    const [roleId, setRoleId] = React.useState('');
    const [roleName, setRoleName] = React.useState('');

    const [roleAndPermissionsData, setRoleAndPermissionsData] = useState();
    const [roleData, setRoleData] = useState('');
    const [updateRolePermissionData, setUpdateRolePermissionData] = useState();
    const [permissionIndex, setPermissionIndex] = useState('');
    const [chips1, setChips] = useState(['js', 'nodejs', 'mongodb', 'c++']);

    useEffect(() => {
        getRolePermissionData();
    }, []);

    const getRolePermissionData = async () => {

        setIsLoading(true);
        const r = await callAPIWithpathParameter("RolePermissions", '', 'GET', '', "Admin");
        let rolesAndPermissionsWithAdmin = r?.data;
        rolesAndPermissionsWithAdmin.unshift(AdminRoles)
        setIsLoading(false);
        setRoleAndPermissionsData(r?.data);
    }

    const toggleRolesModal = (isDataReload, data) => {
        if (isDataReload) {
            getRolePermissionData();
        }
        setRoleData(data);
        setOpenModal(!openModal);
    }


    const toggleRolePermissionModal = (data = '', index = '', role_id, role_name, isDataReload) => {
        setUpdateRolePermissionData(data);
        setPermissionIndex(index);

        setShowPermissionModal(!showRolePermissionModal);
        setRoleId(role_id);
        setRoleName(role_name);
    }

    const deleteRole = async (roleId, roleName) => {
        const msg = 'You are about to delete this role. Are you sure you want to delete?';
        swalConfirmOptions.fire({ title: msg }).then(async (result) => {
            if (result.value) {
                setIsLoading(true);
                await callAPIWithpathParameter("Roles", roleId, 'DELETE', '', "Admin");
                setIsLoading(false);
                swalToast2(`Role ${roleName} is deleted successfully.`);
                getRolePermissionData();
            }
        });
    }

    const ModifyRole = () => {

    }

    const updateRolePermissionsObject = async (obj, roleId, isUpdatedRolePermissionData, index) => {

        var updatedRolePermission = roleAndPermissionsData.filter((i, j) => i.id === roleId).map(role => role);

        if (isUpdatedRolePermissionData) {
            updatedRolePermission[0].permissions[index] = obj;
        } else {
            updatedRolePermission[0].permissions.push(obj);
        }

        // updatedRolePermission.forEach(e => {
        //     e.permissions[0].scopes = e.permissions[0].scopes[0].split(); // converting to string[]
        //     e.permissions[0].scope = null; // removing existing , separated array
        // });

        let message = isUpdatedRolePermissionData ? 'Updated' : 'Created';

        const r = await callAPI("RolePermissions", updatedRolePermission[0]);
        setIsLoading(false);
        setShowPermissionModal(!showRolePermissionModal);

        if (r?.success) {
            swalToast2('Role permission ' + message + ' successfully.');
            getRolePermissionData();
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }


    // const adminRolePermissionList = () => {
    //     return (
    //         <>
    //             <div className="my-4">

    //                 <div className="col-md-6">
    //                     <h6 className="bold-style mb-0" style={{ fontSize: '14px' }}>Administrator</h6>
    //                     <small class="text-muted" style={{ fontSize: '11px' }}>All powerfull Administrator user!</small>
    //                 </div>

    //                 <table class="table table-hover table-sm table-borderless">
    //                     <thead>
    //                         <tr className={`${rolePermissionStyle.tHeadStyling}`}>
    //                             <th scope="col"> <span className="medium-style">Type</span></th>
    //                             <th scope="col"><span className="medium-style">Action</span></th>
    //                             <th scope="col"><span className="medium-style">Module</span></th>
    //                             <th scope="col"><span className="medium-style">Subject</span></th>
    //                             <th scope="col"><span className="medium-style">Scope</span></th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         <tr className="border-bottom">
    //                             <td width="2%" className="medium-style">Permission</td>
    //                             <td width="2%" className="medium-style">Manage</td>
    //                             <td width="2%" className="medium-style">All</td>
    //                             <td width="2%" className="medium-style"></td>
    //                             <td width="2%" className="medium-style"></td>
    //                         </tr>
    //                     </tbody>
    //                 </table>

    //             </div>
    //         </>
    //     )
    // }

    return (
        <>
            {openModal && <CreateRoles toggleRolesModal={toggleRolesModal} roleData={roleData} />}
            <div class="container">

                <h5 className="bold-style" style={{ fontSize: '14px' }}>Roles</h5>
                <hr />

                <div className="add-link-wrapper float-end" onClick={() => toggleRolesModal()}>
                    <i class="bi bi-plus text-success"></i>
                    <span className="medium-style hover-item" style={{ color: '#3CAF8A' }}>Add Role</span>
                </div>

                {/* {adminRolePermissionList()} */}

                {isLoading ?
                    <div>Please Wait...</div>
                    :
                    <section>
                        {showRolePermissionModal && <RolePermissionsModal toggleRolePermissionModal={toggleRolePermissionModal} roleId={roleId} roleName={roleName} updateRolePermissionsObject={updateRolePermissionsObject} index={permissionIndex} updateRolePermissionData={updateRolePermissionData} />}

                        <table class="table table-hover table-sm table-borderless">
                            <tbody>
                                {roleAndPermissionsData?.map((value, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className="mt-4">
                                                    <h6 className="bold-style mb-0" style={{ fontSize: '14px' }}>{value?.roleName}</h6>
                                                    <small class="text-muted" style={{ fontSize: '11px' }}>{value?.description}</small>
                                                </td>
                                                {value?.roleName !== "Administrator" && <td colSpan={4} style={{ verticalAlign: "middle" }}>

                                                    <span className={`btn btn-success btn-sm mx-2 ${rolePermissionStyle.actionBtn}`} onClick={() => toggleRolePermissionModal(null, '', value?.id, value?.roleName)} >Add Permission</span>
                                                    <span className={`btn btn-light btn-sm mx-2 ${rolePermissionStyle.actionBtn}`} onClick={() => deleteRole(value.id, value.roleName)}>Delete Role</span>
                                                    <span className={`btn btn-sm mx-2 ${rolePermissionStyle.actionBtn}`} onClick={() => toggleRolesModal('', value)} style={{ backgroundColor: 'gray' }}>Modify Role</span>

                                                </td>}
                                            </tr>
                                            <tr className="role-perm-header">
                                                <td scope="col"> <span className="medium-style">Type</span></td>
                                                <td scope="col"><span className="medium-style">Action</span></td>
                                                <td scope="col"><span className="medium-style">Module</span></td>
                                                <td scope="col"><span className="medium-style">Subject</span></td>
                                                <td scope="col"><span className="medium-style">Scope</span></td>
                                            </tr>
                                            {value.permissions?.map((v, i) => {
                                                return (
                                                    <>
                                                        <tr key={i} className="border-bottom">
                                                            <td className="medium-style"><Link onClick={() => toggleRolePermissionModal(v, i, value?.id, value?.roleName)}>{v.type}</Link></td>
                                                            <td className="medium-style">{v.action}</td>
                                                            <td className="medium-style">{v.module}</td>
                                                            <td className="medium-style">{v.subject}</td>
                                                            {/* <td width="2%" className="medium-style">{v.scope}</td> */}
                                                            <td className="medium-style">{v.scopes?.join()}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </>
                                    )
                                })}

                            </tbody>
                        </table>

                        {/* {roleAndPermissionsData?.map((value, index) => {
                            return (
                                <>
                                    <div className="my-4" key={index}>
                                        <div className="">
                                            <div className="row" >
                                                <div className="col-md-6">
                                                    <h6 className="bold-style mb-0" style={{ fontSize: '14px' }}>{value?.roleName}</h6>
                                                    <small class="text-muted" style={{ fontSize: '11px' }}>{value?.description}</small>
                                                </div>
                                                {value?.roleName !== "Administrator" && <div className="col-md-6" style={{ float: 'right' }}>
                                                    <span className={`btn btn-success btn-sm mx-2 ${rolePermissionStyle.actionBtn}`} onClick={() => toggleRolePermissionModal(null, '', value?.id, value?.roleName)} >Add Permission</span>
                                                    <span className={`btn btn-light btn-sm mx-2 ${rolePermissionStyle.actionBtn}`} onClick={() => deleteRole(value.id, value.roleName)}>Delete Role</span>
                                                    <span className={`btn btn-sm mx-2 ${rolePermissionStyle.actionBtn}`} onClick={() => toggleRolesModal('', value)} style={{ backgroundColor: 'gray' }}>Modify Role</span>
                                                </div>}
                                            </div>
                                            <table class="table table-hover table-sm table-borderless">
                                                <thead>
                                                    <tr className={`${rolePermissionStyle.tHeadStyling}`}>
                                                        <th scope="col"> <span className="medium-style">Type</span></th>
                                                        <th scope="col"><span className="medium-style">Action</span></th>
                                                        <th scope="col"><span className="medium-style">Module</span></th>
                                                        <th scope="col"><span className="medium-style">Subject</span></th>
                                                        <th scope="col"><span className="medium-style">Scope</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {value.permissions?.map((v, i) => {
                                                        return (
                                                            <tr key={i} className="border-bottom">
                                                                <td width="2%" className="medium-style"><Link onClick={() => toggleRolePermissionModal(v, i, value?.id, value?.roleName)}>{v.type}</Link></td>
                                                                <td width="2%" className="medium-style">{v.action}</td>
                                                                <td width="2%" className="medium-style">{v.module}</td>
                                                                <td width="2%" className="medium-style">{v.subject}</td>
                                                                <td width="2%" className="medium-style">{v.scopes?.join()}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )
                        })} */}
                    </section>
                }
            </div>
        </>
    )
}

export default RolesAndPermissions;
import React, { useState, useEffect } from 'react';
import { FormBuilder, FieldGroup, FieldControl, Validators, FormArray, FieldArray } from 'react-reactive-form';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { callAPI } from '../../../lib/Global.js';
import DateInput from '../../../Controls/DateInput.js';
import CheckBox from '../../../Controls/CheckBox.js';
import { swalAlert, swalToast, swalToast2 } from '../../../lib/Swal.js';
import '../../../styles/common.css';
import styles from '../../../styles/common.module.css';

let weekDays = ['Everyday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class StartOfCareForClient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            careForm: {
                id: null,
                clientId: this.props.clientId,
                careBeginsOn: null,
                careEndsOn: null
            },
            careDays: [],
            errorMessage: {
                careBeginsOn: false,
            },
            isLoading: false,
            isSameTimeError: {},
            changedValue: true
        }
    }

    componentDidMount() {
        this.getStartOfCareDetailsForClient(this.props.clientId);
    }

    getStartOfCareDetailsForClient = async (clientId) => {
        const startOfCareDetails = await callAPI('GetStartOfCareByClientId', { id: clientId });
        this.populateStartOfCareFom(startOfCareDetails);
    }

    populateStartOfCareFom = (clientStartOfCareDetails) => {
        let careDays = [];
        if (clientStartOfCareDetails?.id) {
            let careBeginsOn = this.handleFormatDate(clientStartOfCareDetails.careBeginsOn);
            let careEndsOn = clientStartOfCareDetails.careEndsOn === null ? null : this.handleFormatDate(clientStartOfCareDetails.careEndsOn);
            this.setState({
                careForm: {
                    id: clientStartOfCareDetails.id,
                    clientId: this.props.clientId,
                    careBeginsOn: careBeginsOn,
                }
            });
            careDays = clientStartOfCareDetails.careDays?.map(careDay => {
                return { ...careDay, careFrom: moment(careDay.careFrom).toDate(), careTo: moment(careDay.careTo).toDate() }
            });
        } else {
            this.setState({ ...this.state.careForm, clientId: this.props.clientId });
        }

        let weekDaysArr = weekDays.map(weekDay => {
            return this.careDayObj();
        });
        if (careDays?.length > 0 && !(weekDays.indexOf(careDays[0].careDayOfWeek) === 0)) {
            weekDaysArr.splice(weekDays.indexOf(careDays[0].careDayOfWeek), careDays.length, ...careDays);
        } else if (careDays?.length > 0 && weekDays.indexOf(careDays[0].careDayOfWeek) === 0) {
            weekDaysArr = [careDays[0]];
        }

        this.setState({
            careDays: weekDaysArr
        });
    }

    handleFormatDate = (date) => {
        return moment(date).format('YYYY-MM-DD');
    }


    handleOnChangeFormData = (event) => {
        this.setState({
            careForm: {
                ...this.state.careForm,
                [event.target.name]: event.target.value,
            }
        });
        this.setState({ changedValue: false });
        if (event.target.name === "careBeginsOn" && !event.target.value) {
            this.setState({
                errorMessage: {
                    careBeginsOn: true,
                }
            });
        } else {
            this.setState({
                errorMessage: {
                    careBeginsOn: false,
                }
            });
        }
    }



    careDayObj = () => {
        return {
            careDayOfWeek: null,
            careFrom: null,
            careTo: null,
            is24HrsCare: false,
            isLiveIn: false
        }
    }

    handleSave = async () => {
        const filteredCareDays = this.state.careDays.filter(careDay => {
            return careDay.careDayOfWeek && careDay;
        });
        const startOfCareForClientForm = { ...this.state.careForm, careDays: filteredCareDays }
        console.log(startOfCareForClientForm);

        this.setState({ isLoading: true });
        let api = startOfCareForClientForm.id !== null ? 'UpdateStartOfCare' : 'CreateStartOfCare';
        let message = startOfCareForClientForm.id !== null ? 'Updated' : 'Created';
        const r = await callAPI(api, startOfCareForClientForm);
        this.setState({ isLoading: false });

        if (r.isSuccess) {
            swalToast2('Start of Care for Client ' + message + ' successfully.');
            // getStartOfCareDetailsForClient(props.clientId);
            this.closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }

    }
    closeModal = (isDataToReload) => {
        this.props.toggleStartOfCareModal(isDataToReload);
    }

    handleCareBeginOnEmpty = (e) => {
        if (!e.target.value) {
            this.setState({
                errorMessage: {
                    careBeginsOn: true,
                }
            })
        } else {
            this.setState({
                errorMessage: {
                    careBeginsOn: false
                }
            });
        }

    }

    addOrRemoveCareDay = (e, index) => {
        if (e.target.checked && weekDays[index] === 'Everyday') {
            let careDays = this.state.careDays;
            let careDay = careDays[index];
            const everyDayOfWeek = { ...careDay, careDayOfWeek: weekDays[index] };
            this.setState({
                careDays: [everyDayOfWeek],
                changedValue: false
            });

        } else if (!e.target.checked && weekDays[index] === 'Everyday') {
            let weekDaysArr = weekDays.map(weekDay => {
                return this.careDayObj();
            });
            this.setState({
                careDays: weekDaysArr,
                changedValue: false
            });
        } else {
            let careDays = this.state.careDays;
            let careDay = careDays[index];
            const updatedDayOfWeek = { ...careDay, careDayOfWeek: e.target.checked ? weekDays[index] : null };
            careDays[index] = updatedDayOfWeek;
            this.setState({
                careDays: careDays,
                changedValue: false
            });
        }

    }

    setFromDate = (date, index) => {
        let careDays = this.state.careDays;
        let careDay = careDays[index];
        const updatedCareFrom = { ...careDay, careFrom: date };
        careDays[index] = updatedCareFrom;
        this.setState({
            careDays: careDays
        });
    }

    setToDate = (date, index) => {
        let careDays = this.state.careDays;
        let careDay = careDays[index];
        const updatedCareFrom = { ...careDay, careTo: date };
        careDays[index] = updatedCareFrom;
        this.setState({
            careDays: careDays
        });

        if (moment(date).isSame(updatedCareFrom.careFrom, "second")) {
            this.setState({ ...this.state, isSameTimeError: { ...this.state.isSameTimeError, [weekDays[index]]: true } })
        } else {
            this.setState({ ...this.state, isSameTimeError: { ...this.state.isSameTimeError, [weekDays[index]]: false } })
        }
    }

    toggle24hrsCare = (e, index) => {
        let careDays = this.state.careDays;
        let careDay = careDays[index];
        const updatedCareFrom = { ...careDay, is24HrsCare: e.target.checked };
        careDays[index] = updatedCareFrom;
        this.setState({
            careDays: careDays
        });
    }

    toggleLiveIn = (e, index) => {
        let careDays = this.state.careDays;
        let careDay = careDays[index];
        const updatedCareFrom = { ...careDay, isLiveIn: e.target.checked };
        careDays[index] = updatedCareFrom;
        this.setState({
            careDays: careDays
        });
    }

    handleValidForm = () => {
        return this.state.errorMessage.careBeginsOn || Object.values(this.state.isSameTimeError).find(property => property === true) || this.state.changedValue;
    }

    render() {
        return (
            <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-md modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="staticBackdropLabel">Start Of Care</h5>
                            <i className="bi bi-x-lg" onClick={() => this.closeModal(false)} style={{ cursor: 'pointer' }}></i>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row mb-2">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label for="formControlInput1" className={`mb-1 ${styles.labelStyling}`}>Care Begins On</label>
                                            <input type="date" value={this.state.careForm.careBeginsOn} className="form-control" placeholder={`mm/dd/yyyy`} name="careBeginsOn" onChange={(e) => this.handleOnChangeFormData(e)} onBlur={e => this.handleCareBeginOnEmpty(e)} />
                                            <span className="d-block text-danger">
                                                {this.state.errorMessage.careBeginsOn && `Care Begins On is required`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {this.state.careDays.map((careDay, index) => (
                                        <div className="row mb-2" key={index}>
                                            <div className="col-2 d-flex align-items-center ps-5">
                                                <div className="form-check" style={{ paddingLeft: '0px' }}>
                                                    <input class="form-check-input" type="checkbox" checked={careDay.careDayOfWeek && 'checked'} onChange={(e) => this.addOrRemoveCareDay(e, index)} />
                                                    <label className={`form-check-label ${styles.labelStyling}`} for="flexCheckDefault">
                                                        {weekDays[index]}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-4 pe-0 ps-0 align-items-center">
                                                <>
                                                    <label className={`form-check-label ${styles.labelStyling}`} for="RadioYou">
                                                        from
                                                    </label>
                                                    <DatePicker
                                                        selected={careDay.careFrom}
                                                        onChange={(date) => this.setFromDate(date, index)}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={5}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                    />

                                                </>
                                            </div>
                                            <div className="col-4 pe-0 ps-0 align-items-center">
                                                <>
                                                    <label className={`form-check-label ${styles.labelStyling}`} for="RadioYou">
                                                        To
                                                    </label>
                                                    <DatePicker
                                                        selected={careDay.careTo}
                                                        onChange={(date) => this.setToDate(date, index)}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={5}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"

                                                    />
                                                    {this.state.isSameTimeError[weekDays[index]] && <span className="text-danger fw-bold">!</span>}
                                                </>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-check ps-4" style={{ paddingLeft: '0px' }} >
                                                    <input class="form-check-input" type="checkbox" checked={careDay.is24HrsCare} onChange={(e) => this.toggle24hrsCare(e, index)} />
                                                    <label className={`form-check-label ${styles.labelStyling}`} for="flexCheckDefault">
                                                        Hourly
                                                    </label>
                                                </div>
                                                <div className="form-check ps-4" style={{ paddingLeft: '0px' }}>
                                                    <input class="form-check-input" type="checkbox" checked={careDay.isLiveIn} onChange={(e) => this.toggleLiveIn(e, index)} />
                                                    <label className={`form-check-label ${styles.labelStyling}`} for="flexCheckDefault">
                                                        Live-in
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="row text-lg-end pb-1">
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-success rounded-pill" disabled={this.handleValidForm()} onClick={() => this.handleSave()}>Next</button>
                                    </div>
                                    {/* // } */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default StartOfCareForClient;
import Swal from 'sweetalert2';

const alertTypes = {
    s: 'success',
    e: 'error',
    w: 'warning',
    i: 'info',
    q: 'question'
};

export const swalConfirmOptions = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-success shadow',
        cancelButton: 'ml-6 btn btn-link'
    },
    buttonsStyling: false,
    text: '',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    // reverseButtons: true,
    backdrop: true,
    allowOutsideClick: false,
    allowEscapeKey: false
});

const swalPromptOptions = Swal.mixin({
    allowOutsideClick: false,
    allowEscapeKey: false,
    focusConfirm: false
});

const swalToastOptions = Swal.mixin({
    toast: true,
    position: 'center-end',
    icon: 'success',
    showConfirmButton: false,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

// https://sweetalert2.github.io/
export const swalAlert = (alertType, msg, title = '') => {
    Swal.fire({
        icon: alertTypes[alertType.toLowerCase()],
        html: msg,
        title,
        allowOutsideClick: false,
        allowEscapeKey: false
    });
}

export const swalToast = (msg, action, duration = 5000) => {
    swalToastOptions.fire({
        timer: duration ? duration : 5000,
        title: msg,
        position: 'top-end'
    });
}

// this toast for displaying the alert messages on top of the screen with colour like success, danger, warning or other.
export const swalToast2 = (msg, bg = 'success', duration = 3000) => {

    var elem = document.createElement('div');
    var innerElem = document.createElement('span');

    elem.className = `alert alert-${bg} d-flex cpa-fade-in`;
    elem.style.position = 'fixed';
    elem.style.left = '50px';
    elem.style.right = '0';
    elem.style.top = '9%';
    innerElem.textContent = msg;
    innerElem.style.margin = 'auto';

    elem.appendChild(innerElem);
    document.body.appendChild(elem);

    setTimeout(() => { // this setTimeout is to add fadeOut effect
        elem.classList.add('cpa-fade-out');

        setTimeout(() => { // this setTimeout is to delete created element from the DOM
            elem.remove();
            innerElem.remove();
        }, 1000);

    }, duration);

}

// this is for future use, displaying message bar on the top
// add <div id="msgBar"></div> in the index.html file
// export const swalToast3 = (msg, bg = 'success', duration = 3000) => {
//     new MessageBar({ msg: msg, bg: bg, duration: duration });
//     var elem = "<div></div>";

//     setTimeout(() => {
//         ReactDOM.render(
//             elem,
//             document.querySelector("#msgBar")
//         )
//     }, duration + 3000);

//     ReactDOM.render(
//         <MessageBar />,
//         document.querySelector("#msgBar")
//     );
// }


export const confirmationPopup = (msg, formVal) => {
    swalConfirmOptions.fire({
        customClass: {
            confirmButton: 'ml-3 mx-3 btn btn-danger shadow',
            cancelButton: 'btn btn-info'
        },
        text: msg,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
        icon: ''
    }).then((result) => {
        if (result.isDismissed) {
            formVal.patchValue({ isPrimaryBillingContact: false })
        }
        if (result.isConfirmed) {
            formVal.patchValue({ isPrimaryBillingContact: true })
        }
    })
}
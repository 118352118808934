import React from 'react';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { callAPI, phoneNumberWithDashes } from '../../../lib/Global.js';
import styles from './clientInfo.module.css';
import ClientModal from './ClientModal';
import { ageCalculation } from '../../../lib/DateTimeOps.js';
import { getAllBranches } from './admin/components/branchList/branchListRest.js';
import BackDrop from "../../../Shared/AdminSidePanel/BackDrop";
import './clientInfo.module.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

function DateRenderer(params) {
    return (
        <span className="my-renderer">
            {params.value ? moment(params.value).format("MM/DD/YYYY") : ''}
        </span>
    );
}

function AgeRenderer(params) { // Donot use this, as this will not allow sorting...
    return (
        <span className="my-renderer">
            {ageCalculation(params.data.birthDate)}
        </span>
    );
}

function LinkRenderer(params) {
    return (
        <span className="my-renderer">
            <Link to={`/clientprofile/${params.data.id}`}> <span className="d-block text-dark">{params.data.recipientOfCare}</span></Link>
        </span>
    );
}

var dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split('/');
        var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
    minValidYear: 2000,
    maxValidYear: 2021,
};

class ClientInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { rowData: [], clientInfoData: [], isLoading: false, showModal: false, selectedLeadStatus: '' };
    }

    columnDefs = [
        { headerName: 'Recipient of Care', field: "recipientOfCare", width: 170, wrapText: true, resizable: true, sortable: true, filter: true, cellRenderer: LinkRenderer },
        { headerName: 'Gender', field: "gender", width: 100, resizable: true, sortable: true, filter: true },
        { headerName: 'DOB', field: 'birthDate', width: 110, type: 'dateColumn', resizable: true, sortable: true, filter: true, cellRenderer: DateRenderer },
        { headerName: 'Age', field: "age", width: 80, type: 'numericColumn', resizable: true, sortable: true, filter: true },
        { headerName: 'Phone', field: "phoneWithDash", width: 140, resizable: true, sortable: true, filter: true },
        { headerName: 'City', field: "city", width: 110, wrapText: true, resizable: true, sortable: true, filter: true },
        { headerName: 'State', field: "state", width: 100, resizable: true, sortable: true, filter: true },
        { headerName: 'Care started', field: "createDt", width: 150, resizable: true, sortable: true, filter: true, cellRenderer: DateRenderer },
        { headerName: 'Email', field: "recipientOfCareEmail", width: 250, wrapText: true, resizable: true, sortable: true, filter: true },
        { headerName: 'Lead status', field: "leadStatus", width: 150, resizable: true, sortable: true, filter: true },
    ];

    static getDerivedStateFromProps(props, state) {
        if (props.showModal !== state.showModal) {
            return {
                showModal: props.showModal
            };
        }
        return null;
    }

    async componentDidMount() {
        this.getAllBranchesList();
        this.setState({ isLoading: true });
        await this.getAllClients();
    }

    branchesListArr = [];

    getAllBranchesList = () => {
        getAllBranches().then((data) => {
            data.map((i) => {
                this.branchesListArr.push({
                    text: i.name,
                    value: i.id
                });
            })
        });
        console.log("============", this.branchesListArr)
    }

    componentDidUpdate(prevProps) {
        if (this.props.clientInfoData !== prevProps.clientInfoData) {
            this.setState({ clientInfoData: this.props.clientInfoData, isLoading: false });
        }
    }

    getAllClients = async () => {
        const r = await callAPI('GetAllClients', {});
        r?.clients.forEach(f => {
            f.age = ageCalculation(f.birthDate);
            f.phoneWithDash = phoneNumberWithDashes(f.recipientOfCarePhoneNumber);
        });
        this.setState({ rowData: r?.clients, clientInfoData: r, isLoading: false });
    }

    td = (v) => {
        return (
            <td className={styles.tDataStyling} style={{ backgroundColor: '#FFFFFF' }}>
                <div class="p-1 text-left">
                    <span className="d-block"> {v} </span>
                </div>
            </td>
        );
    }

    toggleModal = async (isDataToReload) => {
        if (isDataToReload) {
            await this.getAllClients();
        }
        this.setState({ showModal: !this.state.showModal });
        this.props.toggleModal();
    }

    handleSearch = async () => {
        const r = await callAPI('SearchClients', { str: this.state.searchInput?.trim() });
        this.setState({ clientInfoData: r, isLoading: false });
    }

    handleLeadStatusChange = async (leadStatusVal) => {
        this.setState({ isLoading: true, selectedLeadStatus: leadStatusVal });
        const r = await callAPI('FilterClientsByLeadStatus', { str: leadStatusVal });
        this.setState({ clientInfoData: r, isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Please wait...</div>
        }
        const { selectedLeadStatus } = this.state;
        return (
            <div className="container-fluid">
                {/*<div className="row pt-1 gx-0 pb-1">
                    <div className="col-4 offset-8">
                        <div className="form-group d-flex justify-content-end pe-2">
                            <select className={`form-select ${styles.leadStatusDropDownStyling}`} id="leadStatus" onChange={(e) => this.handleLeadStatusChange(e.target.value)} value={selectedLeadStatus}>
                                <option value="">Sort By</option>
                                <option value="Hot">Hot</option>
                                <option value="Warm">Warm</option>
                                <option value="Cold">Cold</option>
                                <option value="Non-Admit">Non-Admit</option>
                            </select>
                        </div>
                    </div>
                </div>*/}
                {this.state.showModal && <BackDrop />}
                {this.state.showModal && <ClientModal branchesListArr={this.branchesListArr} toggleModal={this.toggleModal} {...this.props} />}

                <div className="gx-0 pb-1 ag-theme-alpine" style={{ height: 600 }}>
                    <AgGridReact
                        animateRows={true}
                        pagination={true}
                        paginationPageSize={20}
                        rowData={this.state.rowData}
                        columnDefs={this.columnDefs}>
                    </AgGridReact>
                </div>

                {/* <table id="client-infos" className="table table-borderless table-sm mt-2">
                    <thead style={{ verticalAlign: 'middle' }}>
                        <tr className={styles.tHeadStyling}>
                            <th scope="col" className="medium-style ps-4 text-left">Recipient of Care</th>
                            <th scope="col" className="medium-style text-left">Gender</th>
                            <th scope="col" className="medium-style text-left">DOB</th>
                            <th scope="col" className="medium-style text-left">Age</th>
                            <th scope="col" className="medium-style text-left">Phone</th>
                            <th scope="col" className="medium-style text-left">City</th>
                            <th scope="col" className="medium-style text-left">State</th>
                            <th scope="col" className="medium-style text-left">Care started</th>
                            <th scope="col" className="medium-style text-left">Email</th>
                            <th scope="col" className="medium-style text-left">Lead status</th>
                        </tr>
                    </thead>
                    <tbody id="tableBody">
                        {
                            this.state.clientInfoData?.clients?.length === 0 ?
                                <tr>
                                    <span className="text-danger">No matches found</span>
                                </tr>
                                :
                                this.state.clientInfoData?.clients?.map((d, i) => {
                                    return (
                                        <tr key={i} className={`${styles.ClientTableRow} border-bottom`} style={{ cursor: "pointer", verticalAlign: 'middle' }}>
                                            <td className={styles.tDataStyling} style={{ backgroundColor: '#FFFFFF' }}>
                                                <div className="ps-4 text-left">
                                                    <Link to={`/clientprofile/${d.id}`}> <span className="d-block text-dark">{d.recipientOfCare}</span></Link>
                                                </div>
                                            </td>
                                            {this.td(d.gender)}
                                            {this.td(d.birthDate ? moment(d.birthDate).format("MM/DD/YYYY") : '')}
                                            {this.td(ageCalculation(d.birthDate))}
                                            {this.td(d.recipientOfCarePhoneNumber)}
                                            {this.td(d.city)}
                                            {this.td(d.state)}
                                            {this.td(moment(d.createDt).format("MM/DD/YYYY"))}
                                            {this.td(d.recipientOfCareEmail)}
                                            {this.td(d.leadStatus)}
                                        </tr>
                                    )
                                })
                        }

                    </tbody>
                    </table>*/}
            </div>
        )
    }
}

export default withRouter(ClientInfo);
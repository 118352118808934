import React from 'react';
import AddClientDocumentsWebUI from './AddClientDocumentsWEBUI.js';
import ClientDocumentList from './ClientDocumentList.js'
import { callAPI, callAPIforFileUpload, isFileWithInMBof } from '../../../lib/Global.js';
import { swalAlert, swalToast, swalToast2 } from '../../../lib/Swal.js';



class ClientDocuments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            file: "",
            category: "",
            fileName: "",
            newDocUploaded: false
        }
    }

    setCategoryValue = (e) => {
        this.setState({ category: e.target.value });
    }

    handleSelectedFile = async (e) => {
        let file = e.target.files[0];
        let allowedExtension = ['jpeg', 'png', 'PNG', 'JPEG', 'JPG', 'jpg', 'PDF', 'pdf', 'DOC', 'doc', 'TXT', 'txt', 'bmp', 'BMP'];
        let validFile = allowedExtension.includes(file.name.substring(file.name.lastIndexOf('.') + 1));
        if (!validFile) {
            swalAlert('e', `<span class="text-danger">Filetype not recognized. Please try another file type.</span>`);
        } else if (!isFileWithInMBof(file.size, 2)) {
            swalAlert('e', `<span class="text-danger">The file you are trying to upload is too large. Please select a file smaller than 2MB</span>`);
        } else {
            this.setState({ file: e.target.files[0], fileName: e.target.files[0].name });
        }
    }

    handleSave = async () => {
        const { file, category } = this.state;
        const { clientId } = this.props;
        this.setState({ isLoading: true });
        let data = new FormData();
        data.append('file', file);
        data.append('id', clientId);
        data.append('category', category);
        const r = await callAPIforFileUpload('ClientDocumentAdd', data);
        this.setState({ isLoading: false });
        if (r.isSuccess) {
            swalToast2('Document has been successfully uploaded');
            this.setState({ file: "", category: "", fileName: "", newDocUploaded: !this.state.newDocUploaded });
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    render() {
        const { fileName, category } = this.state;
        return (
            <>
                <AddClientDocumentsWebUI handleSelectedFile={this.handleSelectedFile} setCategoryValue={this.setCategoryValue} fileName={fileName} category={category} handleSave={this.handleSave} isLoading={this.state.isLoading} />
                <ClientDocumentList {...this.props} isUploaded={this.state.newDocUploaded} />
            </>
        )
    }

}


export default ClientDocuments;
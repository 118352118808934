import { useEffect, useState } from "react";
import { OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";

export const ClientHAKatzIndex = ({ katzIndexData, setKatzIndexData, acknowledgeMode }) => {
    //=== States =============================================================

    //Katz Index Score state
    const [katzIndexScore, setKatzIndexScore] = useState(0);

    //Use Effects ===========================================================

    //Loading lace tool and Katz Index dropdown options...
    useEffect(() => {
        //1. Bathing
        let score = parseInt(katzIndexData?.bathing === "Independence" ? 1 : 0);

        score = score ? score : 0;

        //2. Dressing
        score += parseInt(katzIndexData?.dressing === "Independence" ? 1 : 0);

        //3. Toileting
        score += parseInt(katzIndexData?.toileting === "Independence" ? 1 : 0);

        //4. Transferring
        score += parseInt(katzIndexData?.transferring === "Independence" ? 1 : 0);

        //5. Continence
        score += parseInt(katzIndexData?.continence === "Independence" ? 1 : 0);

        //6. Feeding
        score += parseInt(katzIndexData?.feeding === "Independence" ? 1 : 0);

        setKatzIndexScore(() => score);
    }, [katzIndexData]);

    //=== Handlers ===========================================================

    const bathingChangeHandler = (e) => {
        const katzIndexDataNew = { ...katzIndexData, bathing: e.target.value };
        setKatzIndexData(katzIndexDataNew);
    };

    const dressingChangeHandler = (e) => {
        const katzIndexDataNew = { ...katzIndexData, dressing: e.target.value };
        setKatzIndexData(katzIndexDataNew);
    };

    const toiletingChangeHandler = (e) => {
        const katzIndexDataNew = { ...katzIndexData, toileting: e.target.value };
        setKatzIndexData(katzIndexDataNew);
    };

    const transferringChangeHandler = (e) => {
        const katzIndexDataNew = { ...katzIndexData, transferring: e.target.value };
        setKatzIndexData(katzIndexDataNew);
    };

    const continenceChangeHandler = (e) => {
        const katzIndexDataNew = { ...katzIndexData, continence: e.target.value };
        setKatzIndexData(katzIndexDataNew);
    };

    const feedingChangeHandler = (e) => {
        const katzIndexDataNew = { ...katzIndexData, feeding: e.target.value };
        setKatzIndexData(katzIndexDataNew);
    };

    //=== Popovers  ==========================================================
    const bathingDependencePopover = (
        <Popover id="bathingDependencePopover">
            <PopoverBody>Need help with bathing more than one part of the body, getting in or out of the tub or shower. Requires total bathing.</PopoverBody>
        </Popover>
    );

    const bathingIndependencePopover = (
        <Popover id="bathingIndependencePopover">
            <PopoverBody>Bathes self completely or needs help in bathing only a single part of the body such as the back, genital area or disabled extremity.</PopoverBody>
        </Popover>
    );

    const dressingDependencePopover = (
        <Popover id="dressingDependencePopover">
            <PopoverBody>Needs help with dressing self or needs to be completely dressed.</PopoverBody>
        </Popover>
    );

    const dressingIndependencePopover = (
        <Popover id="dressingIndependencePopover">
            <PopoverBody>Get clothes from closets and drawers and puts on clothes and outer garments complete with fasteners. May have help tying shoes.</PopoverBody>
        </Popover>
    );

    const toiletingDependencePopover = (
        <Popover id="toiletingDependencePopover">
            <PopoverBody>Needs help transferring to the toilet, cleaning self or uses bedpan or commode.</PopoverBody>
        </Popover>
    );

    const toiletingIndependencePopover = (
        <Popover id="toiletingIndependencePopover">
            <PopoverBody>Goes to toilet, gets on and off, arranges clothes, cleans genital area without help.</PopoverBody>
        </Popover>
    );

    const transferringDependencePopover = (
        <Popover id="transferringDependencePopover">
            <PopoverBody>Needs help in moving from bed to chair or requires a complete transfer. </PopoverBody>
        </Popover>
    );

    const transferringIndependencePopover = (
        <Popover id="transferringIndependencePopover">
            <PopoverBody>Moves in and out of bed or chair unassisted. Mechanical transfer aids are acceptable.</PopoverBody>
        </Popover>
    );

    const continenceDependencePopover = (
        <Popover id="continenceDependencePopover">
            <PopoverBody>Is partially or totally incontinent of bowel or bladder.</PopoverBody>
        </Popover>
    );

    const continenceIndependencePopover = (
        <Popover id="continenceIndependencePopover">
            <PopoverBody>Exercises complete self control over urination and defecation.</PopoverBody>
        </Popover>
    );

    const feedingDependencePopover = (
        <Popover id="feedingDependencePopover">
            <PopoverBody>Needs partial or total help with feeding or requires parenteral feeding.</PopoverBody>
        </Popover>
    );

    const feedingIndependencePopover = (
        <Popover id="feedingIndependencePopover">
            <PopoverBody>Gets food from plate into mouth without help. Preparation of food may be done by another person.</PopoverBody>
        </Popover>
    );

    //=== Render  ============================================================

    return (
        <section id="clientHealthAssessmentKatzIndex">
            <>
                <div className="row">
                    {/*Bathing */}
                    <div className="col-6">
                        <>
                            <span className="my-1 d-block">Bathing</span>

                            {acknowledgeMode && (
                                <>
                                    <div className="ps-4 text-primary fw-bold">{katzIndexData?.bathing}</div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Independence" name="bathing" onChange={bathingChangeHandler} checked={katzIndexData?.bathing === "Independence"} />
                                        <label className="form-check-label">Independence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={bathingIndependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Dependence" name="bathing" onChange={bathingChangeHandler} checked={katzIndexData?.bathing === "Dependence"} />
                                        <label className="form-check-label">Dependence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={bathingDependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </>
                    </div>

                    {/*Dressing*/}
                    <div className="col-6">
                        <>
                            <span className="my-1 d-block">Dressing</span>

                            {acknowledgeMode && (
                                <>
                                    <div className="ps-4 text-primary fw-bold">{katzIndexData?.dressing}</div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Independence" name="dressing" onChange={dressingChangeHandler} checked={katzIndexData?.dressing === "Independence"} />
                                        <label className="form-check-label">Independence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={dressingIndependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Dependence" name="dressing" onChange={dressingChangeHandler} checked={katzIndexData?.dressing === "Dependence"} />
                                        <label className="form-check-label">Dependence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={dressingDependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </>
                    </div>

                    {/*Toileting*/}
                    <div className="col-6">
                        <>
                            <span className="my-1 d-block">Toileting</span>

                            {acknowledgeMode && (
                                <>
                                    <div className="ps-4 text-primary fw-bold">{katzIndexData?.toileting}</div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Independence" name="toileting" onChange={toiletingChangeHandler} checked={katzIndexData?.toileting === "Independence"} />
                                        <label className="form-check-label">Independence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toiletingIndependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Dependence" name="toileting" onChange={toiletingChangeHandler} checked={katzIndexData?.toileting === "Dependence"} />
                                        <label className="form-check-label">Dependence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toiletingDependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </>
                    </div>

                    {/*Transferring*/}
                    <div className="col-6">
                        <>
                            <span className="my-1 d-block">Transferring</span>

                            {acknowledgeMode && (
                                <>
                                    <div className="ps-4 text-primary fw-bold">{katzIndexData?.transferring}</div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Independence" name="transferring" onChange={transferringChangeHandler} checked={katzIndexData?.transferring === "Independence"} />
                                        <label className="form-check-label">Independence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={transferringIndependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Dependence" name="transferring" onChange={transferringChangeHandler} checked={katzIndexData?.transferring === "Dependence"} />
                                        <label className="form-check-label">Dependence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={transferringDependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </>
                    </div>

                    {/*Continence*/}
                    <div className="col-6">
                        <>
                            <span className="my-1 d-block">Continence</span>

                            {acknowledgeMode && (
                                <>
                                    <div className="ps-4 text-primary fw-bold">{katzIndexData?.continence}</div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Independence" name="continence" onChange={continenceChangeHandler} checked={katzIndexData?.continence === "Independence"} />
                                        <label className="form-check-label">Independence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={continenceIndependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Dependence" name="continence" onChange={continenceChangeHandler} checked={katzIndexData?.continence === "Dependence"} />
                                        <label className="form-check-label">Dependence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={continenceDependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </>
                    </div>

                    {/*Feeding*/}
                    <div className="col-6">
                        <>
                            <span className="my-1 d-block">Feeding</span>

                            {acknowledgeMode && (
                                <>
                                    <div className="ps-4 text-primary fw-bold">{katzIndexData?.feeding}</div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}

                            {!acknowledgeMode && (
                                <>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Independence" name="feeding" onChange={feedingChangeHandler} checked={katzIndexData?.feeding === "Independence"} />
                                        <label className="form-check-label">Independence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={feedingIndependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Dependence" name="feeding" onChange={feedingChangeHandler} checked={katzIndexData?.feeding === "Dependence"} />
                                        <label className="form-check-label">Dependence</label>
                                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={feedingDependencePopover}>
                                            <i className="fa fa-exclamation-circle ps-2" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-block text-danger small text-end">&nbsp;</div>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </>

            <div className="col-6">
                <KatzIndexScore score={katzIndexScore} />
            </div>
        </section>
    );
};

//====================================================================

const KatzIndexScore = ({ score }) => {
    return (
        <div className="form-group">
            <label className={`mb-1`}>Katz Index Score</label>
            <br></br>
            <div className="ps-4 text-primary fw-bold">{score}</div>
        </div>
    );
};

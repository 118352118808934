import { Http_BadRequest, Http_OK } from "../../../../lib/httpStatusCodes";

const medicationsApiUrl = `${process.env.REACT_APP_HCC_BASE_URL}ClientMedication`;
//const medicationsApiUrl = `${process.env.REACT_APP_LOCAL_BASE_URL}ClientMedication`;

//=======================================================================

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: null,
};

export const createClientMedication = (data) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${medicationsApiUrl}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to create data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

export const updateClientMedication = (data) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${medicationsApiUrl}/${data.id}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to update data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const deleteClientMedication = (id) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${medicationsApiUrl}/${id}`, { method: "DELETE", headers: headers })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to delete data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientMedicationsByClientId = (id) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${medicationsApiUrl}/ByClientId/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientMedicationById = (id) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${medicationsApiUrl}/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

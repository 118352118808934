import React from "react";
import { callAPI, phoneNumberWithDashes } from "../../../lib/Global.js";
import { FormBuilder, Validators } from "react-reactive-form";
import { swalAlert, swalToast2 } from "../../../lib/Swal.js";
import styles from "../../../styles/common.module.css";
import FamilyMembersModal from "../Components/FamilyMembersModal.js";
import "../../../styles/common.css";

class FamilyMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, familyMemberData: {}, setShowClientFamilyMembersModal: false };
    }

    componentDidMount() {
        this.getClientFamilyMembersDetails(this.props.clientId);
    }

    getClientFamilyMembersDetails = async (clientId) => {
        this.setState({ isLoading: true });
        const clientFamilyMemberDetailsList = await callAPI("GetClientFamilyMembersByClientId", { id: clientId });
        this.setState({ familyMemberData: clientFamilyMemberDetailsList });
        this.populateFamilyMembersForm(clientFamilyMemberDetailsList);
        this.setState({ isLoading: false });
    };

    populateFamilyMembersForm = (clientFamilyMemberDetailsList) => {
        if (Array.isArray(clientFamilyMemberDetailsList) && clientFamilyMemberDetailsList.length > 0) {
            let clientFamilyMemberDetails = clientFamilyMemberDetailsList[0];
            this.familyMembersForm.patchValue({
                id: clientFamilyMemberDetails.id,
                clientId: clientFamilyMemberDetails.clientId,
                firstName: clientFamilyMemberDetails.firstName,
                lastName: clientFamilyMemberDetails.lastName,
                phoneNumber: clientFamilyMemberDetails.phoneNumber,
                phoneNumberType: clientFamilyMemberDetails.phoneNumberType,
                email: clientFamilyMemberDetails.email,
                relationshipToTheRecipientOfCare: clientFamilyMemberDetails.relationshipToTheRecipientOfCare,
                makePrimaryContact: clientFamilyMemberDetails.makePrimaryContact,
                makeEmergencyContact: clientFamilyMemberDetails.makeEmergencyContact,
                receiveNotificationsAndStatusUpdatesViaEmail: clientFamilyMemberDetails.receiveNotificationsAndStatusUpdatesViaEmail,
            });
        } else {
            this.familyMembersForm.patchValue({ clientId: this.props.clientId });
        }
    };

    familyMembersForm = FormBuilder.group({
        id: null,
        clientId: [null, Validators.required],
        firstName: [null, [Validators.required, Validators.maxLength(50)]],
        lastName: [null, [Validators.required, Validators.maxLength(50)]],
        phoneNumber: [null, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+0-9]*$")]],
        phoneNumberType: [null, Validators.required],
        email: [null, [Validators.email, Validators.maxLength(150)]],
        relationshipToTheRecipientOfCare: [null, [Validators.required, Validators.maxLength(100)]],
        makePrimaryContact: null,
        makeEmergencyContact: null,
        receiveNotificationsAndStatusUpdatesViaEmail: null,
    });

    handleSave = async (e) => {
        let api = this.familyMembersForm.value.id !== null ? "UpdateClientFamilyMember" : "CreateClientFamilyMember";
        let message = this.familyMembersForm.value.id !== null ? "Updated" : "Created";
        this.setState({ isLoading: true });
        const r = await callAPI(api, this.familyMembersForm.value);
        this.setState({ isLoading: false });

        if (r?.isSuccess) {
            swalToast2("Family Members for Client " + message + " successfully.");
            this.getClientFamilyMembersDetails(this.props.clientId);
            this.closeModal();
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || "Something went wrong!!!"}</span>`;
            swalAlert("e", errorMsg);
        }
    };

    closeModal = () => {
        this.props.toggleFamilyMemberModal();
    };

    toggleFamilyMembersModal = async (isDataReload) => {
        this.setState({
            setShowClientFamilyMembersModal: !this.state.setShowClientFamilyMembersModal,
        });

        if (isDataReload) {
            await this.getClientFamilyMembersDetails(this.props.clientId);
        }
        this.props.togglebackDrop();
    };

    render() {
        if (this.state.isLoading) {
            return <div>Please wait...</div>;
        }

        return (
            <>
                {this.state.setShowClientFamilyMembersModal && <FamilyMembersModal familyMemberData={this.state.familyMemberData} closeClientIdentityModal={this.toggleFamilyMembersModal} clientId={this.props.clientId} />}
                <h5 className="bold-style" style={{ fontSize: "14px" }}>
                    Family Members
                </h5>
                <hr />
                <div className="row mt-3">
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            First Name
                        </label>
                        <span className="regular-style text-primary">{this.state.familyMemberData ? this.state.familyMemberData[0]?.firstName || "" : ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Last Name
                        </label>
                        <span className="regular-style text-primary">{this.state.familyMemberData ? this.state.familyMemberData[0]?.lastName || "" : ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Phone Number
                        </label>
                        <span className="regular-style text-primary">{this.state.familyMemberData ? phoneNumberWithDashes(this.state.familyMemberData[0]?.phoneNumber) || "" : ""}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Phone Number Type
                        </label>
                        <span className="regular-style text-primary">{this.state.familyMemberData ? this.state.familyMemberData[0]?.phoneNumberType || "" : ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Email
                        </label>
                        <span className="regular-style text-primary">{this.state.familyMemberData ? this.state.familyMemberData[0]?.email || "" : ""}</span>
                    </div>
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>
                            Relationship To The Recipient Of Care
                        </label>
                        <span className="regular-style text-primary">{this.state.familyMemberData ? this.state.familyMemberData[0]?.relationshipToTheRecipientOfCare || "" : ""} </span>
                    </div>
                </div>

                {/* <div className="row mt-3">
                    <div className="col-4">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Relationship To The Recipient Of Care</label>
                        <span className="regular-style">{this.state.familyMemberData[0]?.relationshipToTheRecipientOfCare || ''} </span>
                    </div>
                </div> */}

                {/* <div className="row mt-3">
                    <div className="col-2">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Make Primary Contact</label>
                        <span>{this.state.familyDynamicData[0]?.makePrimaryContact || ''}</span>
                    </div>
                    <div className="col-2">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Make Emergency Contact</label>
                        <span>{this.state.familyDynamicData[0]?.makeEmergencyContact || ''}</span>
                    </div>
                    <div className="col-2">
                        <label for="formControlInput1" className={`mb-1 d-block ${styles.labelStyling}`}>Receive Notifications And Status Updates Via Email</label>
                        <span>{this.state.familyDynamicData[0]?.receiveNotificationsAndStatusUpdatesViaEmail || ''} </span>
                    </div>
                </div> */}

                <div className="row mt-3">
                    <div className="col-3">
                        <span className="btn btn-sm btn-primary" onClick={() => this.toggleFamilyMembersModal(false)}>
                            Edit Family Members
                        </span>
                    </div>
                </div>
                <hr />
            </>
        );
    }
}

export default FamilyMembers;

import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import { callAPI } from '../../../lib/Global.js';
import { swalAlert, swalToast2 } from '../../../lib/Swal.js';
import styles from "../../../styles/common.module.css";

const TextInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group d-flex">
        <label for="formControlInput1" className={`mb-1 ${styles.labelStyling} col-2`}>{meta.label}</label>
        <div className="col-4">
            <input type="text" maxLength={meta.maxLengthVal} className={`form-control ${styles.modalInputStyling}`} placeholder={`${meta.label}`} {...handler()} disabled={meta.disabled} />
            <span className="d-block text-danger">
                {touched
                    && hasError("required")
                    && `${meta.label} is required `}
                {hasError("email")
                    && `${meta.emailErrMsg} `}
                {hasError("pattern") && `${meta.patternErrMsg}`}
                {hasError("maxLength") && `Max Length of ${meta.maxLengthVal} Exceeded `}
                {hasError("minLength") && `Min Length of ${meta.minLengthVal} Required `}
                {hasError(meta.customError) && `${meta.customErrorMsg}`}
            </span>
        </div>
    </div>
);

const eatingOptions = [{ value: 'Self', label: 'Self' },
{ value: 'Feed', label: 'Feed' },
{ value: 'AdaptiveDev', label: 'Adaptive Dev', },
{ value: 'tube/IV', label: 'Tube / IV', },
{ value: 'eatingPoor', label: 'Eating poor', },
{ value: 'Encourage', label: 'Encourage', },
{ value: 'mealPrep', label: 'Meal prep', },
{ value: 'Spousepreparesmeals', label: 'Spouse prepares meals', },
{ value: 'Recentweightloss', label: 'Recent weight loss', },
{ value: 'Recentweightgain', label: 'Recent weight gain', },
{ value: 'DifficultySwallowing', label: 'Difficulty swallowing', },
{ value: 'Difficultychewing', label: 'Difficulty chewing', },
];

const clientEatingForm = new FormBuilder.group({
    id: null,
    clientId: [null, Validators.required],
    eating: null,
    others: null
});


const ClientEatingDetails = ({ clientId }) => {
    const [isLoading, setisLoading] = useState(false);
    const [eatingArr, setEatingArr] = useState([]);

    useEffect(() => {
        getClientEatingDetails(clientId);
        return () => {
            clientEatingForm.reset();
        }
    }, [clientId]);

    const limitationChangeHandler = (e) => {
        let limitationsNew = e?.map((i) => i.value);
        clientEatingForm.patchValue({ eating: limitationsNew.join(',') });
        clientEatingForm.markAsDirty();
    };

    const getClientEatingDetails = async (clientId) => {
        setisLoading(true)
        const r = await callAPI("GetClientEatingDetailsByClientId", { id: clientId });

        if (r) {
            clientEatingForm.patchValue({ ...r });
            let defaultValue = r.eating.split(',').map((dr) => eatingOptions.find((i) => i.value === dr))
            setEatingArr(defaultValue);
            clientEatingForm.updateValueAndValidity(clientEatingForm.value);
        } else {
            clientEatingForm.patchValue({ clientId: clientId });
        }
        setisLoading(false);
    }

    const handleSave = async () => {
        clientEatingForm.updateValueAndValidity(clientEatingForm.value);
        setisLoading(true);

        const apiText = clientEatingForm.value.id ? 'UpdateClientEatingDetails' : 'AddClientEatingDetails';
        let message = clientEatingForm.value.id !== null ? 'Updated' : 'Saved';
        const r = await callAPI(apiText, clientEatingForm.value);
        setisLoading(false);
        clientEatingForm.updateValueAndValidity(clientEatingForm.value);

        if (r.isSuccess) {
            swalToast2('Client Eating Details ' + message + ' successfully.');
            getClientEatingDetails(clientId);
            clientEatingForm.markAsPristine();
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    return (
        <>
            <div className="row mb-2">
                <div className="col-12">
                    <h5 className="bold-style" style={{ fontSize: '14px' }}>Eating</h5>
                </div>
            </div>
            <hr />
            {isLoading ? <div>Please Wait...</div> :
                <div>
                    <FieldGroup
                        control={clientEatingForm}
                        render={({ get, invalid, pristine, dirty }) => (
                            <form>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="mobility"
                                            render={({ handler, touched, hasError, meta }) => (
                                                <div className="d-flex">
                                                    <label for="formCheck1" className={`mb-1 ${styles.labelStyling} col-2`}>{meta.label}</label>
                                                    <div class="col-4">
                                                        <Select isMulti menuPlacement="bottom" options={meta.options}
                                                            defaultValue={eatingArr}
                                                            value={meta.options.value} onChange={limitationChangeHandler}></Select>
                                                    </div>
                                                </div>
                                            )}
                                            meta={{
                                                label: "Eating",
                                                options: eatingOptions
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl name="others" render={TextInput} meta={{
                                            label: "Others"
                                        }} />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <div className="d-grid gap-2 pb-1">
                                        <button type="button" onClick={() => handleSave()} className="btn btn-primary" disabled={isLoading || invalid || pristine || !dirty}>{isLoading ? 'Please wait...' : 'Save'}</button>
                                    </div>
                                </div>

                            </form>
                        )}
                    />
                </div>
            }
        </>
    )
}

export default ClientEatingDetails;
const s = process.env.REACT_APP_HCC_BASE_URL;
// const s = process.env.REACT_APP_LOCAL_BASE_URL;
const k = process.env.REACT_APP_HCC_KEY_CODE;



export const ServerApis = {
    GetAllClients: s + 'GetAllClients' + k,
    GetClient: s + 'GetClient' + k,
    UpdateLeadStatus: s + 'UpdateLeadStatus' + k,
    SearchClients: s + 'SearchClients' + k,
    CreateClient: s + 'CreateClient' + k,

    CreatePrimaryContactForClient: s + 'CreatePrimaryContactForClient' + k,
    FilterClientsByLeadStatus: s + 'FilterClientsByLeadStatus' + k,
    GetClientFamilyDynamicsByClientId: s + 'GetClientFamilyDynamicsByClientId' + k,
    CreateClientFamilyDynamics: s + 'CreateClientFamilyDynamics' + k,
    UpdateClientFamilyDynamics: s + 'UpdateClientFamilyDynamics' + k,
    GetStartOfCareByClientId: s + 'GetStartOfCareByClientId' + k,
    CreateStartOfCare: s + 'CreateStartOfCare' + k,
    UpdateStartOfCare: s + 'UpdateStartOfCare' + k,
    GetClientFamilyMembersByClientId: s + 'GetClientFamilyMembersByClientId' + k,
    CreateClientFamilyMember: s + 'CreateClientFamilyMember' + k,
    UpdateClientFamilyMember: s + 'UpdateClientFamilyMember' + k,
    GetClientLifestyleByClientId: s + 'GetClientLifestyleByClientId' + k,
    CreateClientLifestyle: s + 'CreateClientLifestyle' + k,
    UpdateClientLifestyle: s + 'UpdateClientLifestyle' + k,
    GetClientCareStaffPrefByClientId: s + 'GetClientCareStaffPrefByClientId' + k,
    CreateClientCareStaffPref: s + 'CreateClientCareStaffPref' + k,
    UpdateClientCareStaffPref: s + 'UpdateClientCareStaffPref' + k,
    GetClientInsuranceByClientId: s + 'GetClientInsuranceByClientId' + k,
    CreateClientInsurance: s + 'CreateClientInsurance' + k,
    UpdateClientInsurance: s + 'UpdateClientInsurance' + k,
    CreateClientPayment: s + 'CreateClientPayment' + k,
    GetClientPaymentByClientId: s + 'GetClientPaymentByClientId' + k,
    UpdateClientPayment: s + 'UpdateClientPayment' + k,
    ClientPhotoAdd: s + 'ClientPhotoAdd' + k,
    ClientPhotoDownload: s + 'ClientPhotoDownload' + k,
    ClientPhotoDelete: s + 'ClientPhotoDelete' + k,
    GetClientProfileViewByClientId: s + 'GetClientProfileViewByClientId' + k,
    UpdateClientProfileViewByClientId: s + 'UpdateClientProfileViewByClientId' + k,
    GetStartOfCareDateByClientId: s + 'GetStartOfCareDateByClientId' + k,
    UpdateClientBranch: s + 'UpdateClientBranch' + k,
    SearchClientForAutocomplte: s + 'SearchClientForAutocomplte' + k,
    ClientStatus: s + 'ClientStatus' + k,
    CreateClientStatus: s + 'ClientStatus' + k,
    GetPrimaryContactsByClientId: s + 'GetPrimaryContactsByClientId' + k,
    GetPrimaryContactsById: s + 'GetPrimaryContactsById' + k,
    UpdatePrimaryContactForClient: s + 'UpdatePrimaryContactForClient' + k,
    getClientPaymentListByClientId: s + 'getClientPaymentListByClientId' + k,
    GetClientPaymentById: s + 'GetClientPaymentById' + k,
    CreateAddressForClient: s + 'CreateAddressForClient' + k,
    GetAddressesByClientId: s + 'GetAddressesByClientId' + k,
    GetAddressById: s + 'GetAddressById' + k,
    UpdateAddressForClient: s + 'UpdateAddressForClient' + k,
    GetJournalByClientId: s + 'GetJournalByClientId' + k,
    GetAllProducts: s + 'GetAllProducts' + k,
    DeletePrimaryContactById: s + 'DeletePrimaryContactById' + k,
    DeleteClientAddressById: s + 'DeleteClientAddressById' + k,
    Journal: s + 'Journal' + k,
    ClientDocumentAdd: s + 'ClientDocumentAdd' + k,
    GetDocumentsByClientId: s + 'GetDocumentsByClientId' + k,
    CreatePsycotherapyRiskForClient: s + 'CreatePsycotherapyRiskForClient' + k,
    GetPsycotherapyRiskByClientId: s + 'GetPsycotherapyRiskByClientId' + k,
    UpdatePsycotherapyRiskForClient: s + 'UpdatePsycotherapyRiskForClient' + k,
    ClientDocumentDelete: s + 'ClientDocumentDelete' + k,
    IsROCPhoneExists: s + 'HCC/IsROCPhoneExists' + k,
    GetClientDietRequirementByClientId: s + 'GetClientDietRequirementByClientId' + k,
    UpdateClientDietRequirements: s + 'UpdateClientDietRequirements' + k,
    AddClientDietRequirements: s + 'AddClientDietRequirements' + k,
    AddClientMobilityDetails: s + 'AddClientMobilityDetails' + k,
    UpdateClientMobilityDetails: s + 'UpdateClientMobilityDetails' + k,
    GetClientMobilityDetailsByClientId: s + 'GetClientMobilityDetailsByClientId' + k,
    AddClientEatingDetails: s + 'AddClientEatingDetails' + k,
    UpdateClientEatingDetails: s + 'UpdateClientEatingDetails' + k,
    GetClientEatingDetailsByClientId: s + 'GetClientEatingDetailsByClientId' + k,
    AddClientPhysicianDetails: s + 'AddClientPhysicianDetails' + k,
    UpdateClientPhysicianDetails: s + 'UpdateClientPhysicianDetails' + k,
    GetClientPhysicianDetailsByClientId: s + 'GetClientPhysicianDetailsByClientId' + k,
    IsROCClientExists: s + 'HCC/IsROCClientExists' + k,
}
import { Http_BadRequest, Http_OK } from "../../../../lib/httpStatusCodes";

const healthAssessmentApiUrl = `${process.env.REACT_APP_HCC_BASE_URL}ClientHealthAssessment`;
//const healthAssessmentApiUrl = `${process.env.REACT_APP_LOCAL_BASE_URL}ClientHealthAssessment`;

//=======================================================================

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: null,
};

export const createClientHealthAssessment = (data) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${healthAssessmentApiUrl}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to create data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

export const updateClientHealthAssessment = (data) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${healthAssessmentApiUrl}/${data.id}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data),
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to update data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

export const acknowledgeClientHealthAssessment = (id) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${healthAssessmentApiUrl}Acknowledge/${id}`, {
            method: "PUT",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to acknowledge (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientHealthAssessmentFallRiskToolOptionsList = () => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${healthAssessmentApiUrl}FallRiskGetOptionsList`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientHealthAssessmentLaceToolOptionsList = () => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${healthAssessmentApiUrl}LaceToolGetOptionsList`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientHealthAssessmentsByClientId = (id) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${healthAssessmentApiUrl}/ByClientId/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientHealthAssessmentsLastCASScoreByClientId = (id) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${healthAssessmentApiUrl}/LastCASScoreByClientId/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

export const getClientHealthAssessmentById = (id) => {
    const token = sessionStorage.getItem("token");
    headers.Authorization = token ? `bearer ${token.substring(0, token.length - 3).substring(3)}` : null;

    return new Promise((resolve, reject) => {
        fetch(`${healthAssessmentApiUrl}/${id}`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return new Promise((res, rej) => {
                    switch (response.status) {
                        case Http_BadRequest:
                            response.json().then((json) => {
                                return rej(json.errors);
                            });
                            break;

                        case Http_OK:
                            response.json().then((json) => {
                                return res(json.data);
                            });
                            break;
                        default:
                            rej(`Error to fetch data (${response.status})`);
                    }
                });
            })
            .then((ret) => resolve(ret))
            .catch((err) => reject(err));
    });
};

//=======================================================================

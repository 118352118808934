import React from 'react';
import { callAPI } from '../../../lib/Global.js';
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import TextInput from '../../../Controls/TextInput.js';
import SelectInput from '../../../Controls/SelectInput.js';
import { swalAlert, swalToast2 } from '../../../lib/Swal.js';
import styles from '../../../styles/common.module.css';

class ClientPaymentModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isLoading: false, isCreditDebitCardInfo: true }
    }

    componentDidMount() {
        this.clientPaymentCardForm.get("paymentMethod").valueChanges.subscribe(selectedValue => {
            if (selectedValue === 'Credit/Debit Card') {
                this.setState({ isCreditDebitCardInfo: true });
                this.clientPaymentCardForm.updateValueAndValidity(this.clientPaymentCardForm.value);
            } else {
                this.setState({ isCreditDebitCardInfo: false });
                this.clientPaymentBankForm.updateValueAndValidity(this.clientPaymentBankForm.value);
            }
        });
        const paymentId = this.props.paymentId;
        this.getClientPayment(paymentId);
    }

    componentWillUnmount() {
        this.clientPaymentCardForm.get("paymentMethod").valueChanges.unsubscribe();
    }

    async getClientPayment(paymentId) {
        if (paymentId) {
            this.setState({ isLoading: true });
            const r = await callAPI('GetClientPaymentById', { id: paymentId });
            this.setState({ isLoading: false });
            if (r && r.paymentMethod === 'Bank Account') {
                this.setState({ isCreditDebitCardInfo: false });
                this.clientPaymentBankForm.patchValue({
                    clientId: this.props.clientId, id: r.id, routingNumber: r.routingNumber,
                    accountNumber: r.accountNumber, bankName: r.bankName, accountType: r.accountType
                });
                this.clientPaymentCardForm.patchValue({ clientId: this.props.clientId, paymentMethod: r.paymentMethod });
            } else if (r && r.paymentMethod === 'Credit/Debit Card') {
                this.setState({ isCreditDebitCardInfo: true });
                this.clientPaymentCardForm.patchValue({
                    id: r.id, clientId: this.props.clientId, paymentMethod: r.paymentMethod,
                    cardHoldersFirstName: r.cardHoldersFirstName, cardHoldersLastName: r.cardHoldersLastName,
                    cardHoldersAddressLine1: r.cardHoldersAddressLine1, cardHoldersAddressLine2: r.cardHoldersAddressLine2,
                    city: r.city, state: r.state, zipCode: r.zipCode, cardNumber: r.cardNumber, dateExp: r.dateExp,
                    cvv: r.cvv
                });
                this.clientPaymentBankForm.patchValue({ id: r.id, clientId: this.props.clientId });
            }
        } else {
            this.clientPaymentCardForm.patchValue({ paymentMethod: "Credit/Debit Card" });
            this.clientPaymentCardForm.patchValue({ clientId: this.props.clientId });
            this.clientPaymentBankForm.patchValue({ clientId: this.props.clientId });
        }

    }

    clientPaymentCardForm = FormBuilder.group({
        id: null,
        clientId: [null, Validators.required],
        paymentMethod: [null, Validators.required],
        cardHoldersFirstName: [null, Validators.required],
        cardHoldersLastName: [null, Validators.required],
        cardHoldersAddressLine1: [null, Validators.required],
        cardHoldersAddressLine2: null,
        city: [null, Validators.required],
        state: [null, Validators.required],
        zipCode: [null, [Validators.maxLength(5), Validators.minLength(5), Validators.required]],
        cardNumber: [null, [Validators.required, Validators.minLength(16), Validators.pattern("^[0-9]*$"), Validators.maxLength(16)]],
        dateExp: [null, [Validators.required, Validators.pattern("^(0[1-9]|1[0-2])\/([0-9]{2})$"), Validators.maxLength(5), Validators.minLength(5)]],
        cvv: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern("^[0-9]*$")]],
    });


    clientPaymentBankForm = FormBuilder.group({
        id: null,
        clientId: [null, Validators.required],
        routingNumber: [null, [Validators.required, Validators.minLength(9), Validators.pattern("^[0-9]*$"), Validators.maxLength(9)]],
        accountNumber: [null, [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
        bankName: null,
        accountType: [null, Validators.required]
    })



    handleSave = async (e) => {
        let clientPayment = { ...this.clientPaymentCardForm.value, ...this.clientPaymentBankForm.value };

        this.clientPaymentCardForm.updateValueAndValidity(this.clientPaymentCardForm.value);
        this.clientPaymentBankForm.updateValueAndValidity(this.clientPaymentBankForm.value);
        this.setState({ isLoading: true });

        const apiText = clientPayment.id ? 'UpdateClientPayment' : 'CreateClientPayment';
        let message = clientPayment.id !== null ? 'Updated' : 'Saved';
        const r = await callAPI(apiText, clientPayment);
        this.setState({ isLoading: false });

        this.clientPaymentCardForm.updateValueAndValidity(this.clientPaymentCardForm.value);
        this.clientPaymentBankForm.updateValueAndValidity(this.clientPaymentBankForm.value);

        if (r?.isSuccess) {
            swalToast2('Client Payment ' + message + ' successfully.');
            this.closeModal(true);
        } else {
            const errorMsg = `<span class="text-danger">${r?.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }

    }

    closeModal = (isDataReload) => {
        this.props.toggleClientPaymentsModal(isDataReload, '');
    }

    render() {
        return (
            <>
                <div className={`modal fade show`} style={{ display: 'block' }} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-success" id="exampleModalLabel">Payment</h5>
                                <i className="bi bi-x-lg" onClick={() => this.closeModal(false)} style={{ cursor: 'pointer' }}></i>
                            </div>
                            <div className="modal-body">
                                {/* {(this.state.isLoading && !this.state.isCardDataUpdated) && <>
                                        <div>Please Wait...</div>
                                    </>}

                                    {this.state.isCardDataUpdated && <>
                                        <div>Completed...</div>
                                    </>} */}

                                <div>
                                    <FieldGroup
                                        control={this.clientPaymentCardForm}
                                        render={({ get, invalid, pristine }) => (
                                            <form>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="paymentMethod"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <>
                                                                    <span for="formCheck1" className={`my-1 me-2 ${styles.labelStyling}`}>{meta.label}</span>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" {...handler("radio", "Credit/Debit Card")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioYou">
                                                                            Credit/Debit Card
                                                                        </label>

                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input class="form-check-input"  {...handler("radio", "Bank Account")} />
                                                                        <label class={`form-check-label ${styles.labelStyling}`} for="RadioSomeOneElse">
                                                                            Bank Account
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )}
                                                            meta={{ label: "Add a payment method" }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    />
                                </div>
                                {this.state.isCreditDebitCardInfo && <>
                                    <FieldGroup
                                        control={this.clientPaymentCardForm}
                                        render={({ get, invalid, pristine }) => (
                                            <form>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <div className="mt-2 mb-2 text-success">Credit/Debit Card</div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="cardHoldersFirstName"
                                                            render={TextInput}
                                                            meta={{ label: "Card holder's first name" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="cardHoldersLastName"
                                                            render={TextInput}
                                                            meta={{
                                                                label: "Card holder's last name",
                                                                maxLength: 255
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="cardHoldersAddressLine1"
                                                            render={TextInput}
                                                            meta={{
                                                                label: "Card holder's address (line1)",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="cardHoldersAddressLine2"
                                                            render={TextInput}
                                                            meta={{
                                                                label: "Card holder's address (line2)",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="city"
                                                            render={TextInput}
                                                            meta={{ label: "City", maxLengthVal: 100 }}
                                                        />
                                                    </div>

                                                    <div className="col-3">
                                                        <FieldControl
                                                            name="state"
                                                            render={TextInput}
                                                            meta={{ label: "State", maxLengthVal: 2, minLengthVal: 2 }}
                                                        />
                                                    </div>
                                                    <div className="col-3">
                                                        <FieldControl
                                                            name="zipCode"
                                                            render={TextInput}
                                                            meta={{ label: "Zip Code", maxLengthVal: 5, minLengthVal: 5, patternErrMsg: "Only numbers are allowed" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <FieldControl
                                                            name="cardNumber"
                                                            render={TextInput}
                                                            meta={{ label: "Card Number", maxLengthVal: 16, minLengthVal: 16, patternErrMsg: "Invalid Card Number Digit,Must be 16" }}
                                                        />
                                                    </div>
                                                    <div className="col-3">
                                                        <FieldControl
                                                            name="dateExp"
                                                            render={TextInput}
                                                            meta={{ label: "Date Exp", maxLengthVal: 5, minLengthVal: 5, patternErrMsg: "Invalid Format,Must be in MM/YY" }}
                                                        />
                                                    </div>
                                                    <div className="col-3">
                                                        <FieldControl
                                                            name="cvv"
                                                            render={TextInput}
                                                            meta={{ label: "CVV", maxLengthVal: 5, minLengthVal: 5, patternErrMsg: "Only numbers are allowed" }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* </>} */}

                                                <div className="row text-lg-end pb-1">
                                                    <div className="d-flex justify-content-end">
                                                        <button type="button" onClick={() => this.handleSave()} className="btn btn-primary" disabled={pristine || invalid || this.state.isLoading}>{this.state.isLoading ? 'Please wait...' : 'Save'}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    />
                                </>}

                                {!this.state.isCreditDebitCardInfo && <>
                                    <FieldGroup
                                        control={this.clientPaymentBankForm}
                                        render={({ get, invalid, pristine }) => (
                                            <form>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <div className="mt-2 mb-2 text-success">Bank Account</div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="routingNumber"
                                                            render={TextInput}
                                                            meta={{
                                                                label: "Routing number",
                                                                maxLengthVal: 9,
                                                                minLengthVal: 9,
                                                                patternErrMsg: "Only numbers are allowed"
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="accountNumber"
                                                            render={TextInput}
                                                            meta={{
                                                                label: "Account number",
                                                                maxLengthVal: 12,
                                                                minLengthVal: 12,
                                                                patternErrMsg: "Only numbers are allowed"
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="bankName"
                                                            render={TextInput}
                                                            meta={{
                                                                label: "Bank Name",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <FieldControl
                                                            name="accountType"
                                                            render={SelectInput}
                                                            meta={{
                                                                label: "Select account type:",
                                                                options: [
                                                                    { text: "Checking" },
                                                                    { text: "Savings" },
                                                                    { text: "Certificate of Deposit (CD)" },
                                                                    { text: "Money Market account" },
                                                                    { text: "Individual Retirement Accounts (IRAs)" },
                                                                ]
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row text-lg-end pb-1">
                                                    <div className="d-flex justify-content-end">
                                                        <button type="button" onClick={() => this.handleSave()} className="btn btn-primary" disabled={pristine || invalid || this.state.isLoading}>{this.state.isLoading ? 'Please wait...' : 'Save'}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    />
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}


export default ClientPaymentModal;
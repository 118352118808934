import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { FormBuilder, FieldGroup, FieldControl, Validators } from 'react-reactive-form';
import { callAPI } from '../../../lib/Global.js';
import { swalAlert, swalToast2 } from '../../../lib/Swal.js';
import styles from "../../../styles/common.module.css";

const TextInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group d-flex">
        <label for="formControlInput1" className={`mb-1 ${styles.labelStyling} col-2`}>{meta.label}</label>
        <div className="col-4">
            <input type="text" maxLength={meta.maxLengthVal} className={`form-control ${styles.modalInputStyling}`} placeholder={`${meta.label}`} {...handler()} disabled={meta.disabled} />
            <span className="d-block text-danger">
                {touched
                    && hasError("required")
                    && `${meta.label} is required `}
                {hasError("email")
                    && `${meta.emailErrMsg} `}
                {hasError("pattern") && `${meta.patternErrMsg}`}
                {hasError("maxLength") && `Max Length of ${meta.maxLengthVal} Exceeded `}
                {hasError("minLength") && `Min Length of ${meta.minLengthVal} Required `}
                {hasError(meta.customError) && `${meta.customErrorMsg}`}
            </span>
        </div>
    </div>
);

const dietOptions = [{ value: 'regular', label: 'Regular' },
{ value: 'lowFat', label: 'Low Fat' },
{ value: 'lowSugar/diabetic', label: 'Low sugar/Diabetic', },
{ value: 'renal', label: 'Renal', },
{ value: 'lowSodium/NoAddedSalt', label: 'Low sodium/ No added salt', },
{ value: 'kosher', label: 'Kosher', },
{ value: 'chopped', label: 'Chopped', },
{ value: 'pureed', label: 'Pureed', }];

const clientDietForm = new FormBuilder.group({
    id: null,
    clientId: [null, Validators.required],
    dietaryRequirements: null,
    others: null
});


const ClientDietaryRequirements = ({ clientId }) => {
    const [isLoading, setisLoading] = useState(false);
    const [dietReqArr, setDietReqArr] = useState([]);

    useEffect(() => {
        getClientDietaryRequirements(clientId);
        return () => {
            clientDietForm.reset();
        }
    }, [clientId]);

    const limitationChangeHandler = (e) => {
        let limitationsNew = e?.map((i) => i.value);
        clientDietForm.patchValue({ dietaryRequirements: limitationsNew.join(',') });
        clientDietForm.markAsDirty();
    };

    const getClientDietaryRequirements = async (clientId) => {
        setisLoading(true)
        const r = await callAPI("GetClientDietRequirementByClientId", { id: clientId });

        if (r) {
            clientDietForm.patchValue({ ...r });
            let defaultValue = r.dietaryRequirements.split(',').map((dr) => dietOptions.find((i) => i.value === dr))
            setDietReqArr(defaultValue);
            clientDietForm.updateValueAndValidity(clientDietForm.value);
        } else {
            clientDietForm.patchValue({ clientId: clientId });
        }
        setisLoading(false);
    }

    const handleSave = async () => {
        clientDietForm.updateValueAndValidity(clientDietForm.value);
        setisLoading(true);

        const apiText = clientDietForm.value.id ? 'UpdateClientDietRequirements' : 'AddClientDietRequirements';
        let message = clientDietForm.value.id !== null ? 'Updated' : 'Saved';
        const r = await callAPI(apiText, clientDietForm.value);
        setisLoading(false);
        clientDietForm.updateValueAndValidity(clientDietForm.value);

        if (r.isSuccess) {
            swalToast2('Client Dietary Requirements ' + message + ' successfully.');
            getClientDietaryRequirements(clientId);
            clientDietForm.markAsPristine();
        } else {
            const errorMsg = `<span class="text-danger">${r.errorMsg || 'Something went wrong!!!'}</span>`;
            swalAlert('e', errorMsg);
        }
    }

    console.log(clientDietForm.value);
    return (
        <>
            <div className="row mb-2">
                <div className="col-12">
                    <h5 className="bold-style" style={{ fontSize: '14px' }}>Dietary</h5>
                </div>
            </div>
            <hr />
            {isLoading ? <div>Please Wait...</div> :
                <div>
                    <FieldGroup
                        control={clientDietForm}
                        render={({ get, invalid, pristine, dirty }) => (
                            <form>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl
                                            name="dietaryRequirements"
                                            render={({ handler, touched, hasError, meta }) => (
                                                <div className="d-flex">
                                                    <label for="formCheck1" className={`mb-1 ${styles.labelStyling} col-2`}>{meta.label}</label>
                                                    <div class="col-4">
                                                        <Select isMulti menuPlacement="bottom" options={meta.options}
                                                            defaultValue={dietReqArr}
                                                            value={meta.options.value} onChange={limitationChangeHandler}></Select>
                                                    </div>
                                                </div>
                                            )}
                                            meta={{
                                                label: "Dietary requirements",
                                                options: dietOptions
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <FieldControl name="others" render={TextInput} meta={{
                                            label: "Others"
                                        }} />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <div className="d-grid gap-2 pb-1">
                                        <button type="button" onClick={() => handleSave()} className="btn btn-primary" disabled={isLoading || invalid || pristine || !dirty}>{isLoading ? 'Please wait...' : 'Save'}</button>

                                    </div>
                                </div>

                            </form>
                        )}
                    />
                </div>
            }
        </>
    )
}

export default ClientDietaryRequirements;